import React from 'react'
import { Modal, Fade, Backdrop, Button, Typography, OutlinedInput, IconButton, Divider } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { closeModal } from '../../../store';
import { ModalType, OperatingMode } from '../../../core';
import clsx from 'clsx';
import { useStyles } from './AddUpdateForwardingNumber.style';
import {
    HighlightOff as HighlightOffIcon
} from '@material-ui/icons';
import { ForwardingToInput } from '../../shared';


export default function AddUpdateForwardingNumber() {
    const state = useSelector(state => state);
    const dispatch = useDispatch();
    const classes = useStyles();
    const modal = state.ui.modal[ModalType.AddForwardingNumber] || false;
    const forwardingPhoneNumber = state.account.forwardingPhoneNumber;
    const { selected, operatingMode } = forwardingPhoneNumber;
    const handleClose = () => {
        dispatch(closeModal(ModalType.AddForwardingNumber));
    }

    const isNew = Boolean(OperatingMode.New === operatingMode);

    return (
        <Modal
            aria-labelledby="add-forwarding-number-modal-title"
            aria-describedby="add-forwarding-number-modal-description"
            open={modal}
            onClose={handleClose}
            closeAfterTransition
            disableEnforceFocus
            BackdropComponent={Backdrop}
            className={classes.modal}
            BackdropProps={{
                timeout: 500
            }}
        >
            <Fade in={modal}>
                <div className={classes.paper}>
                    <div className={classes.modalHeader}>
                        <Typography variant="h6" className={classes.modalHeading}>
                            {isNew ? "New forwarding number" : "Edit forwarding number"}
                        </Typography>
                        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                            <HighlightOffIcon />
                        </IconButton>
                    </div>
                    <div className={classes.modalBody}>
                        <Typography variant="h5" className={classes.modalSubhead}>
                            Number
                        </Typography>
                        <OutlinedInput
                            type="text"
                            fullWidth
                            defaultValue={!isNew ? selected.forwardingPhoneNumber : ""}
                        />
                        <Divider className={classes.divider} />
                        <Typography className={classes.modalSubhead} variant="h5">
                            Forwarding to
                        </Typography>
                        <ForwardingToInput defaultValue={!isNew ? selected?.fullName : ""} />
                    </div>
                    <div className={classes.modalFooter}>
                        <Button className={clsx(classes.footerButton, classes.footerPrimaryBtn)} variant="contained" color="primary" onClick={handleClose}>
                            {isNew ? "Add" : "Save changes"}
                        </Button>
                        <Button className={classes.footerButton} variant="outlined" onClick={handleClose}>Cancel</Button>
                    </div>
                </div>
            </Fade>
        </Modal>
    )
}
