import React, { useState } from 'react'
import { BaseSearchDropdown } from '..'


const options = [
    {
        name: 'Sevahn Simonain',
    },
    {
        name: 'Guy Hawkins',
    },
    {
        name: 'Arlene McCoy',
    },
    {
        name: 'Floyd Miles',
    },
    {
        name: 'Ronald Richards',
    },
    {
        name: 'Ralph Edwards',
    },
    {
        name: "Jane Cooper",
    },
    {
        name: 'Ana Brown',
    }
];

export default function CallTransferedInput() {
    const [value, setValue] = useState([]);
    return (
        <BaseSearchDropdown options={options} multiple value={value} onChange={setValue} label="name" />
    )
}
