import { ROOMRENTALS } from "../../actions/action-types";
import moment from "moment";

const roomRentalsReducer = (state = { SearchedRoomPricing: {} }, action) => {
  switch (action.type) {
    case ROOMRENTALS.SET_ROOM_PRICE:
      return {
        ...state,
        SearchedRoomPricing: action.pricingObj,
      };
    default:
      return state;
  }
};
const peopleCap = (state = {}, action) => {
  switch (action.type) {
    case ROOMRENTALS.PEOPLE_CAP:
      state = action.data
      return state;
    default:
      return state;
  }
};

const seeAllRooms = (state = true, action) => {
  switch (action.type) {
    case ROOMRENTALS.All_ROOM:
      state = action.data
      return state;
    default:
      return state;
  }
};
let temp = {
  date: moment(new Date()).format("YYYY-MM-DD"),
  location: 'New York',
  from: moment(new Date()).format("HH:mm"),
  till: moment(new Date()).format("HH:mm"),
  price: "perHours",
  capacity: '4-6'
}
const checkAvailability = (state = temp, action) => {
  switch (action.type) {
    case ROOMRENTALS.CHECK_AVAIABLITY:
      state = action.data
      return state;
    default:
      return state;
  }
};

export { roomRentalsReducer, peopleCap, seeAllRooms, checkAvailability };
