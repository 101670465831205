import React, { Fragment } from "react";
import * as ModalPopups from "../components";

export default function Modal() {
  return (
    <Fragment>
      <ModalPopups.BuyPhoneNumber />
      <ModalPopups.AddUpdateDirectory />
      <ModalPopups.BuyAdditionalService />
      <ModalPopups.ManageYourAvailability />
      <ModalPopups.ManageAnsweringScript />
      <ModalPopups.AddNoteAgent />
      <ModalPopups.BuyMinutes />
      <ModalPopups.ChangePassword />
      <ModalPopups.AddUpdateForwardingNumber />
      <ModalPopups.AddUpdateForwardingAddress />
      <ModalPopups.EditCompanyAddress />
      <ModalPopups.AddUpdateTeamMembers />
      <ModalPopups.ManagePaymentInformation />
      <ModalPopups.ChangeCustomersPlan />
      <ModalPopups.EditVirtualPhoneNumber />
      <ModalPopups.JunkModal />
      <ModalPopups.RequestForwardModal />
      <ModalPopups.RequestHoldModal />
      <ModalPopups.RequestPhotoModal />
      <ModalPopups.RequestScanModal />
      <ModalPopups.ScanPreviewModal />
      <ModalPopups.ShredMailModal />
      <ModalPopups.CancelRequestModal />
      <ModalPopups.SuccessActionModal />
      <ModalPopups.RoomAvailabilityResultModal />
      <ModalPopups.RoomBookingModal />
      <ModalPopups.PlanDetailsModal />
      <ModalPopups.ImageSlider />
    </Fragment>
  );
}
