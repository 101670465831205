import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  succModalDiv: {
    width: "328px",
  },
  succModalTitleDiv: {
    background: "linear-gradient(90deg, #409A95 -17.74%, #34CEC6 100%)",
  },
  succModalTitle: {
    fontSize: "15px",
    letterSpacing: "0.01em",
    color: "#ffffff",
    fontFamily: "Roboto-Medium",
  },
  succModalIcon: {
    stroke: "#b8bfc5",
    float: "right",
    cursor: "pointer",
  },
  succModalContentText: {
    margin: 0,
    padding: "9px",
    fontWeight: "normal",
    fontSize: "14px",
    color: "#494949",
    fontFamily: "Roboto-Regular",
  },
  modalContent: {
    padding: 0,
  },
  backgroundImg: {
    objectFit: "cover",
    height: "120px",
    width: "100%",
  },
  boxSubHeader: {
    padding: "16px 32px",
  },
  info: {
    paddingBottom: "5px",
  },
}));

export { useStyles };
