import { SPACERENTAL } from "../../../actions";

const resultsReducer = (state = [], action) => {
  switch (action.type) {
    case SPACERENTAL.CLEAR:
    case SPACERENTAL.RESET_FILTERED_ROOMS:
      return [];
    case SPACERENTAL.SET_FILTERED_ROOMS:
      return action.data;
    default:
      return state;
  }
};

export { resultsReducer };
