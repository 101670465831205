import React from "react";
export default function ForwardedMailActionIcon(props) {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 6.72198L9.77778 0.499756V4.05531C3.55556 4.9442 0.888889 9.38865 0 13.8331C2.22222 10.722 5.33333 9.29976 9.77778 9.29976V12.9442L16 6.72198Z"
        fill="#2CA4C0"
      />
    </svg>
  );
}
