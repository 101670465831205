import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { ModalType, MailAction } from "../../../core";
import {
  closeModal,
  getCustomerId,
  openModal,
  setCustomerMailCounts,
  setErrorMessage,
  setMailAction,
  setMailItems,
  setMailOptions,
  setMMTotalPages,
  setTotalCount,
  getSelectedLocationId,
  loadMailItemsFailed,
} from "../../../store";
import "./junk-modal.css";
import CloseModalIcon from "../../../assets/icons/closeModalIcon";
import JunkModalCautionIcon from "../../../assets/icons/junkModalCoutionIcon";
import { apiMailAction } from "../../../apis";
export default function JunkModal() {
  const state = useSelector((state) => state);
  const locationId = useSelector((state) => getSelectedLocationId(state));
  const dispatch = useDispatch();
  const modal = state.ui.modal[ModalType.MailJunkModal] || false;
  const activeTab = state.mail.activeTab;
  const limit = state.mail.limit;
  const ids = state.mail.selectedRowIds;
  const modalSelected = state.ui.modal;
  const selectedRow = state.mail.selectedRow;
  const isBulkOperation = state.mail.isBulkOperation;
  const options = state.mail.options;
  const customerId = useSelector((state) => getCustomerId(state));
  const mailAccountId = state.auth.data.mail_account_id;
  const isCancelRequestModal =
    modalSelected[ModalType.CancelMailRequestModal] || false;
  const isForwardingModal =
    modalSelected[ModalType.MailRequestForwardModal] || false;

  const handleClose = () => {
    dispatch(closeModal(ModalType.MailJunkModal));
  };

  const handleConfirm = async () => {
    dispatch(setMailOptions({}));
    try {
      dispatch(setMailAction(MailAction.Junk));
      const data = {
        ids: isBulkOperation ? ids : [selectedRow.id],
        mailAction: MailAction.Junk,
        enabled: !isCancelRequestModal,
        customerPlanServiceId: options.customerPlanServiceId || 0,
        price: options.forward_price || 0,
        params: { page: 1, status: activeTab, limit: limit - ids.length },
        ...(isForwardingModal && {
          forward_address_id: options.forward_address_id,
          ...(options.delivery_days > 0 && {
            delivery_days: options.delivery_days,
          }),
        }),
      };
      const response = await apiMailAction(
        customerId,
        mailAccountId,
        locationId,
        data
      );
      const { mail_items, total_pages, total } = response;
      dispatch(setMailItems(mail_items));
      dispatch(setMMTotalPages(total_pages));
      dispatch(setTotalCount(total));
      dispatch(setCustomerMailCounts(total));
      if (!isCancelRequestModal)
        dispatch(openModal(ModalType.MailSuccessActionModal));
    } catch (error) {
      dispatch(setErrorMessage(error.toString()));
      dispatch(loadMailItemsFailed());
    }
    handleClose();
  };

  return (
    <Dialog
      open={modal}
      onClose={handleClose}
      aria-labelledby="junk-dialog-title"
      aria-describedby="junk-dialog-description"
    >
      <div className="junk-dialog-title">
        <DialogTitle id="junk-dialog-title" className="junkModalHeader">
          <Grid container sm={12} direction="row">
            <Grid sm={6}>
              <p className="junkModalTitle">Move To Junk</p>
            </Grid>
            <Grid sm={6}>
              <CloseModalIcon
                handleCloseIcon={handleClose}
                className="junkDialogCloseIcon"
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="junk-dialog-description">
            <p className="junkModalText">
              Are you sure you want to request <b>moving to junk</b> for the
              selceted mail.
            </p>
            <div className="junkModalBodyTextIcon">
              <JunkModalCautionIcon />
              <p className="junkModalCautionText">
                Moving mail to junk will destroy it permanently.
              </p>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="junkModalFooter">
          <Button
            variant="primary"
            className="junkModalFooterButton"
            onClick={handleConfirm}
          >
            Yes, move it.
          </Button>
          <Button className="junkModalFooterCancelButton" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}
