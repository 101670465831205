import { MAILMANAGEMENT } from "../../actions";

const limitReducer = (state = 30, action) => {
  switch (action.type) {
    case MAILMANAGEMENT.CLEAR:
      return 30;
    case MAILMANAGEMENT.SET_LIMIT:
      return action.limit;
    default:
      return state;
  }
};

export { limitReducer };
