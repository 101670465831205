import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  searchField: {
    float: "right",
    background: "white",
  },
  searchFieldGrid: {
    justifyContent: "flex-end",
  },
}));

export { useStyles };
