import { MAILMANAGEMENT } from "../../actions";

const dataReducer = (state = [], action) => {
  switch (action.type) {
    case MAILMANAGEMENT.CLEAR:
    case MAILMANAGEMENT.LOAD_MAIL_ITEMS:
    case MAILMANAGEMENT.SET_ACTIVE_TAB:
    case MAILMANAGEMENT.SET_CURRENT_PAGE:
    case MAILMANAGEMENT.SEARCH_MAIL_ITEMS:
      return [];
    case MAILMANAGEMENT.SET_MAIL_ITEMS:
      return action.data;
    default:
      return state;
  }
};

export { dataReducer };
