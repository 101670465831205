import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { AutoSizer } from "react-virtualized";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Button, Container, Box, IconButton } from "@material-ui/core";
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  Edit as EditIcon,
  DeleteOutline as DeleteOutlineIcon,
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import {
  setErrorMessage,
  setRooms,
  getSelectedLocationId,
} from "../../../../store";
import { apiFetchAllRooms, apiDeleteRoom } from "../../../../apis";
import { useStyles } from "./styles";
import { ConfirmationModal } from "../../../modal-popups";

export const columns = (width, handleEditClick, handleDeleteClick) => [
  {
    field: "name",
    headerName: "Room",
    width: width * 0.2,
  },
  {
    field: "location",
    headerName: "Address",
    width: width * 0.2,
  },
  {
    field: "capacity",
    headerName: "Capacity",
    width: width * 0.2,
  },
  {
    field: "hourlyPrice",
    headerName: "Hourly Rate",
    width: width * 0.2,
  },
  {
    field: "id",
    headerName: "Action",
    width: width * 0.2,
    hideSortIcons: true,
    renderCell: (param) => {
      return (
        <Box
          style={{
            display: "flex",
            // justifyContent: "space-between",
            width: "100%",
          }}
        >
          <IconButton
            color="primary"
            style={{ marginRight: "20px" }}
            onClick={() => handleEditClick(param.row)}
          >
            <EditIcon style={{ height: "20px" }} />
          </IconButton>
          <IconButton
            style={{ marginRight: "20px", color: "red" }}
            onClick={() => handleDeleteClick(param.row)}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Box>
      );
    },
  },
];

const NoRowsOverlay = () => {
  const classes = useStyles();
  return (
    <div className={classes.noRowOverlay}>
      <Typography variant="subtitle1">Nothing here yet</Typography>
      <Typography variant="body2">{`Rooms are displayed here. `}</Typography>
    </div>
  );
};

export default function RoomList() {
  const data = useSelector((state) => state.admin.room.data);
  const selectedLocationId = useSelector((state) =>
    getSelectedLocationId(state)
  );
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  let { push } = useHistory();
  const classes = useStyles();
  const [selected, setIsSelected] = useState({});
  const [open, setIsOpen] = useState(false);
  const state = useSelector((state) => state);

  useEffect(() => {
    setLoading(true);
    const fetchRooms = async () => {
      setLoading(true);
      try {
        const rooms = await apiFetchAllRooms(selectedLocationId);
        dispatch(setRooms(rooms));
        setLoading(false);
      } catch (error) {
        dispatch(setErrorMessage(error.toString()));
        setLoading(false);
      }
    };
    if (selectedLocationId) fetchRooms();
  }, [dispatch, selectedLocationId]);

  const handleEditClick = (row) => {
    push(`/admin/rooms/${row.id}/edit`);
  };

  const handleDeleteClick = async (row) => {
    setIsOpen(true);
    setIsSelected(row);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const onConfirm = async () => {
    setLoading(true);
    try {
      await apiDeleteRoom(selected.id);
      const rooms = await apiFetchAllRooms(selectedLocationId);
      dispatch(setRooms(rooms));
      setLoading(false);
      setIsOpen(false);
      setIsSelected({});
    } catch (error) {
      dispatch(setErrorMessage(error.toString()));
      setLoading(false);
    }
  };

  const handleAddClick = () => {
    push("/admin/rooms/add");
  };

  return (
    <Container style={{ maxWidth: 1111 }}>
      <section className={classes.section}>
        <div className={classes.addButtonContainer}>
          <Button
            variant="outlined"
            color="primary"
            style={{ height: "30px" }}
            onClick={handleAddClick}
          >
            Add new room
          </Button>
        </div>
        <div style={{ height: "calc(100vh - 80px)" }}>
          <AutoSizer style={{ width: "100%" }}>
            {({ height, width }) => (
              <div style={{ height, width: "100%", minWidth: "100%" }}>
                <DataGrid
                  headerHeight={40}
                  rowHeight={40}
                  height={height}
                  rows={data}
                  columns={columns(width, handleEditClick, handleDeleteClick)}
                  disableColumnFilter
                  disableColumnMenu
                  disableSelectionOnClick
                  hideFooter
                  loading={isLoading}
                  sortingOrder={["asc", "desc"]}
                  components={{
                    ColumnSortedAscendingIcon: ArrowDropDownIcon,
                    ColumnSortedDescendingIcon: ArrowDropUpIcon,
                    NoRowsOverlay: NoRowsOverlay,
                  }}
                />
              </div>
            )}
          </AutoSizer>
        </div>
        <ConfirmationModal
          id="confirmation-modal"
          keepMounted
          open={open}
          handleClose={handleClose}
          onConfirm={onConfirm}
          isLoading={isLoading}
          title="Are you sure you want to delete ?"
        />
      </section>
    </Container>
  );
}
