import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  subHeader: {
    backgroundColor: theme.palette.secondary.main,
    height: "58px",
    width: "100%",
    display: "flex",
    color: theme.palette.common.white,
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  boxLink: {
    fontSize: "14px",
    lineHeight: "16px",
    color: theme.palette.primary.main,
  },
  column: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  columnWrap: {
    display: "grid",
    gridTemplateColumns: "2fr 1fr",
    gridGap: "20px",
    padding: "20px",
  },
  availableRoomContainer: {
    display: "grid",
    gridTemplateColumns: "1fr",
    padding: "20px",
  },
}));

export { useStyles };
