import { Dialog, DialogActions, Grid, Button } from "@material-ui/core";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { ModalType } from "../../../core";
import { closeModal } from "../../../store";
import "./scan-preview-modal.css";
import CloseModalIcon from "../../../assets/icons/closeModalIcon";
import ScannedDocPreview from "../../../assets/images/test.pdf";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function ScanPreviewModal() {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const modal = state.ui.modal[ModalType.MailScanPreviewModal] || false;
  const handleClose = () => {
    dispatch(closeModal(ModalType.MailScanPreviewModal));
  };
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const handleScanDownload = () => {
    var link = document.createElement("a");
    link.href = ScannedDocPreview;
    link.download = "Scanned Document.pdf";
    link.dispatchEvent(new MouseEvent("click"));
  };
  return (
    <>
      <Dialog
        open={modal}
        onClose={handleClose}
        aria-labelledby="junk-dialog-title"
        aria-describedby="junk-dialog-description"
      >
        <div className="ScanPreviewModalTitleDiv">
          <div className="ScanPreviewModalTitleContentDiv">
            <p className="ScanPreviewModalTitle">Scan preview</p>
            <CloseModalIcon
              handleCloseIcon={handleClose}
              className="ScanPreviewModalTitleIcon"
            />
          </div>
        </div>
        <div className="ScanPreviewModalMainContentDiv">
          <Grid sm={12} direction="row" contianer>
            <div className="ScanPreviewModalContentDiv">
              <Grid sm={4}>
                <p className="ScanPreviewModalContent">Document name:</p>
              </Grid>
              <Grid sm={8}>
                <p className="ScanPreviewModalDocumentName">Signature_23.pdf</p>
              </Grid>
            </div>
          </Grid>
          <Grid sm={12} direction="row" contianer>
            <div className="ScanPreviewModalContentDiv">
              <Grid sm={4}>
                <p className="ScanPreviewModalContent">Scanned date:</p>
              </Grid>
              <Grid sm={8}>
                <p className="ScanPreviewModalDocumentName">10/28/12</p>
              </Grid>
            </div>
          </Grid>
          <Grid sm={12} direction="row" contianer>
            <div className="ScanPreviewModalContentDiv">
              <Grid sm={4}>
                <p className="ScanPreviewModalContent">Pages scanned:</p>
              </Grid>
              <Grid sm={8}>
                <p className="ScanPreviewModalDocumentName">1-5/30</p>
              </Grid>
            </div>
          </Grid>
          <Grid sm={12} direction="row" contianer>
            <div className="ScanPreviewModalContentDiv">
              <Grid sm={4}>
                <p className="ScanPreviewModalContent">Size:</p>
              </Grid>
              <Grid sm={8}>
                <p className="ScanPreviewModalDocumentName">2.8 mb</p>
              </Grid>
            </div>
            <Grid sm={12} direction="row" contianer>
              <Document
                file={{
                  url: ScannedDocPreview,
                }}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNumber} />
              </Document>
              {/* <object
                data="http://www.africau.edu/images/default/sample.pdf"
                type="application/pdf"
                width="100%"
                height="100%"
              >
                <p>
                  Alternative text - include a link{" "}
                  <a href="http://africau.edu/images/default/sample.pdf">
                    to the PDF!
                  </a>
                </p>
              </object> */}
            </Grid>
          </Grid>
          <DialogActions className="junkModalFooter">
            <Button
              variant="primary"
              className="faddressSubmitButton"
              onClick={handleScanDownload}
            >
              <svg
                style={{ marginRight: "10px" }}
                width="15"
                height="16"
                viewBox="0 0 15 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.6525 11.8953L7.12679 15.7189C7.22109 15.8221 7.35708 15.8823 7.50003 15.8823C7.64297 15.8823 7.77896 15.8221 7.87326 15.7189L11.3476 11.8953C11.4756 11.7539 11.5074 11.5541 11.426 11.383C11.3456 11.2128 11.1689 11.1029 10.9743 11.1029L8.98901 11.1029L8.98901 4.88968C8.98901 4.62585 8.76665 4.41174 8.49268 4.41174L6.50737 4.41174C6.2334 4.41174 6.01104 4.62585 6.01104 4.88968L6.01104 11.1029L4.02574 11.1029C3.83118 11.1029 3.65448 11.2119 3.57408 11.383C3.49367 11.5541 3.52345 11.7548 3.6525 11.8953Z"
                  fill="white"
                />
                <path
                  d="M2 5.29408L2 2.11761L13 2.11761L13 5.29408L15 5.29408L15 1.05879C15 0.473258 14.552 -3.62005e-05 14 -3.61522e-05L1 -3.50157e-05C0.447 -3.49674e-05 -4.2145e-07 0.473259 -3.70261e-07 1.05879L0 5.29408L2 5.29408Z"
                  fill="white"
                />
              </svg>
              Download.
            </Button>
            <Button
              className="faddressCancelButton"
              // onClick={(e) => props.handleScanAgain(e)}
              disabled
            >
              Scan again
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
}
