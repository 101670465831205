import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, InputBase, IconButton } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import { debounce } from "lodash";
import { setMMSearchText, searchMailItems } from "../../../../store";
import { useStyles } from "./Search.style";

export default function Search() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const mail = useSelector((state) => state.mail);
  const { search } = mail;

  const setSearchTaxt = debounce(function (text) {
    dispatch(setMMSearchText(text));
  }, 1000);

  const handleSearch = () => dispatch(searchMailItems());

  const searchOnEnter = (e) => {
    if (e.keyCode == 13) {
      handleSearch();
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      sm={6}
      md={6}
      className={classes.searchFieldGrid}
    >
      <div className={classes.searchField}>
        <InputBase
          onChange={(e) => setSearchTaxt(e.target.value)}
          onKeyDown={searchOnEnter}
          className={classes.input}
          placeholder="Search mail"
          value={search}
        />
        <IconButton
          onClick={handleSearch}
          type="submit"
          className={classes.iconButton}
          aria-label="search"
          value={search}
        >
          <SearchIcon stroke={"#2CA4C0"} />
        </IconButton>
      </div>
    </Grid>
  );
}
