import React, { useEffect } from "react";
import { Grid, Link } from "@material-ui/core";
import { useStyles } from "./current-plan-style";
import {
  openModal,
  setCustomerPlanServices,
  setCustomerPlanInfo,
  setCustomerDetailsdAdmin,
  getLoginCustomerId,
  setSelectedCustomer,
  setErrorMessage
} from "../../../store";
import { ModalType } from "../../../core";
import { apiGetCustomerPlanServices } from "../../../apis";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { userHasEditPermission } from "../../../constants/constants";

export default function CurrentPlan() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const customerId = useSelector((state) => getLoginCustomerId(state));
  const [loading, setLoading] = useState(false);

  const planInfo = useSelector((state) => state.admin.customer.planInfo);
  const userType = useSelector((state) => state.auth.data.user_type);

  const handleChangePlan = () => {
    dispatch(setSelectedCustomer({}));
    dispatch(openModal(ModalType.ChangeCustomersPlan));
  };

  const mangeAdditionalService = () => {
    dispatch(openModal(ModalType.BuyAdditionalService));
  };

  useEffect(() => {
    async function fetchAPI() {
      setLoading(true);
      try {
        const res = await apiGetCustomerPlanServices(customerId);
        const { planInfo, planServices, details } = res;
        dispatch(setCustomerPlanServices(planServices));
        dispatch(setCustomerPlanInfo(planInfo || {}));
        dispatch(setCustomerDetailsdAdmin(details));
        setLoading(false);
      }
      catch (error) {
        setLoading(false);
        dispatch(setErrorMessage(error.toString()));
      }
    }
    if (customerId) fetchAPI();
  }, [dispatch, customerId]);

  return (
    <>
      <div className={classes.currentPlanDiv}>
        <table className={classes.currentPlanTable}>
          <tr className={classes.headerMainGrid}>
            <th style={{ padding: "0 0 0 15px" }} className={classes.currentPlanTableHeading}>
              <p style={{ height: 49, fontFamily: "Roboto-Medium" }} className={classes.headerText}>My Current plan</p>
            </th>
            <th>
              {userHasEditPermission(userType) && <Link className={classes.headerLink} onClick={handleChangePlan}>
                Upgrade
              </Link>}
            </th>
          </tr>
          {planInfo.planId ? (
            <>
              <tr>
                <td className={classes.currentPlanTableHeading}>
                  <p className={classes.planHeading}>{planInfo?.planName}</p>
                </td>
                <td>
                  <p
                    className={classes.planPrice}
                  >{`$${planInfo?.planPrice}`}</p>
                </td>
              </tr>
              {/* <tr>
                <td className={classes.currentPlanTableHeading}>
                  <p className={classes.planDuration}>{`Until - ${moment(
                    planInfo?.nextBillingDate
                  ).format("DD MMM YYYY")}`}</p>
                </td>
                <td>
                  <p className={classes.planMonth}>{planInfo?.periodUnit}</p>
                </td>
              </tr> */}
            </>
          ) : (
            <tr>
              <td className={classes.currentPlanTableHeading}>
                <p className={classes.planDuration}>No active plan</p>
              </td>
            </tr>
          )}
        </table>
      </div>

      {/* <div className={classes.AdditionalServicesDiv}>
        <Grid
          sm={12}
          direction="row"
          container
          className={classes.headerMainGrid}
        >
          <Grid sm={6} className={classes.headerTextGrid}>
            <p className={classes.secondHeaderText}>Additional services</p>
          </Grid>
          <Grid sm={6} className={classes.AdditionalHeaderLinkGrid}>
            {userHasEditPermission(userType) && <Link
              className={classes.headerLink}
              onClick={mangeAdditionalService}
            >
              Upgrade
            </Link>}
          </Grid>
        </Grid>
        <div
          className={classes.additionalBodyDiv}
          style={{ background: "white" }}
        >
          <Grid
            sm={12}
            direction="row"
            container
            style={{
              padding: "15px 10px",
              borderBottom: "1px solid #DFDFE4",
            }}
          >
            <Grid sm={8} className={classes.servicesGrid}>
              <p className={classes.servicesHeading}>Fax Message Delivery</p>
            </Grid>
            <Grid sm={4} className={classes.contentSubGrid}>
              <p className={classes.servicesPrice}>$9.99</p>
            </Grid>
          </Grid>
          <Grid
            sm={12}
            direction="row"
            container
            style={{
              padding: "15px 10px",
              borderBottom: "1px solid #DFDFE4",
            }}
          >
            <Grid sm={8} className={classes.servicesGrid}>
              <p className={classes.servicesHeading}>Basic Spanish Coverage</p>
            </Grid>
            <Grid sm={4} className={classes.contentSubGrid}>
              <p className={classes.servicesPrice}>$19.99</p>
            </Grid>
          </Grid>
          <Grid
            sm={12}
            direction="row"
            container
            style={{
              padding: "15px 10px",
              borderBottom: "1px solid #DFDFE4",
            }}
          >
            <Grid sm={8} className={classes.servicesGrid}>
              <p className={classes.servicesHeading}>Premium Spanish Routing</p>
            </Grid>
            <Grid sm={4} className={classes.contentSubGrid}>
              <p className={classes.servicesPrice}>$29.99</p>
            </Grid>
          </Grid>
          <Grid
            sm={12}
            direction="row"
            container
            style={{
              padding: "15px 10px",
            }}
          >
            <Grid sm={8} className={classes.servicesGrid}>
              <p className={classes.servicesHeading}>Internal Outbound</p>
            </Grid>
            <Grid sm={4} className={classes.contentSubGrid}>
              <p className={classes.servicesPrice}>$30.00</p>
            </Grid>
          </Grid>
        </div>
      </div> */}
    </>
  );
}
