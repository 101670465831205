import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUIActionType } from "../../../store";

export default function UIActionTypeHook(action) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setUIActionType(action));
  }, [dispatch, action]);
  return null;
}
