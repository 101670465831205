import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  container: {
    padding: "20px",
  },
  discountDiv: {
    display: "flex",
    justifyContent: "flex-end",
  },
  listContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    gap: "10px",
    marginTop: "15px",
  },
  listItem: {
    display: "flex",
    height: "160px",
    border: "1px solid #CDE0F1",
    background: "#fff",
  },
  imgContainer: {
    flexBasis: "30%",
  },
  detailContainer: {
    flexBasis: "70%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "20px",
  },
  backgroundImg: {
    height: "158px",
    width: "100%",
  },
  link: {
    fontSize: "15px",
    lineHeight: "16px",
    color: "#2CA4C0",
    fontFamily: "Roboto-Medium",
  },
  roomAddress: {
    width: "60%",
  },
  viewDetailBtn: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
}));

export { useStyles };
