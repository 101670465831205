import { UI } from "../../actions";

const messageTypeReducer = (state = "", action) => {
    switch (action.type) {
        case UI.CLEAR_MESSAGE:
            return "";
        case UI.SET_SUCCESS_MESSAGE:
            return "success";
        case UI.SET_ERROR_MESSAGE:
            return "error";
        default:
            return state;
    }
};

export { messageTypeReducer };
