import React from "react";
import { Box, Typography, IconButton, CircularProgress } from "@material-ui/core";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@material-ui/icons/Edit";
import { openModal, getCompanyOfficeInfoAdmin, setUIActionType } from "../../../store";
import { ModalType, UIActionType } from "../../../core";
import { OfficeNameInput } from "../../shared";
import { useStyles } from "./CustomerCompanyInfo.style";
import { useState } from "react";
import { useEffect } from "react";
import FormattedAddress from "../../shared/formatted-address/formatted-address";

export default function CustomerCompanyInfo(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const companyInfo = getCompanyOfficeInfoAdmin(state);
  const { isFromManageCustomerInfoPage } = props;
  const handleEditCompanyAddress = () => {
    if (isFromManageCustomerInfoPage)
      dispatch(setUIActionType(UIActionType.Admin));
    dispatch(openModal(ModalType.EditCompanyAddress));
  };
  const [isLoading, setIsLoading] = useState(false);

  // const formatAddress = () => {
  //   const { address1, address2, city, state, zip, country } = companyInfo;
  //   return (
  //     <Typography variant="subtitle1">
  //       <span>{address1 ? `${address1},` : ""}</span>
  //       <span>{address2 ? ` ${address2},` : ""}</span>
  //       <span>{city ? ` ${city},` : ""}</span>
  //       <span>{state ? ` ${state},` : ""}</span>
  //       <span>{zip ? ` ${zip},` : ""}</span>
  //       <span>{country ? ` ${country}` : ""}</span>
  //     </Typography>
  //   );
  // };
 
  return (
    <Box component="Box" className={classes.box}>
      <Box className={classes.boxHeader}>
        <Typography variant="h6" className={classes.boxHeading}>
          Customer Company Info
        </Typography>
        {isLoading && <Box>
          <CircularProgress size="1.5rem" />
        </Box>}
      </Box>
      <Box className={clsx(classes.boxRow, classes.noBorder)}>
        <Box className={classes.boxContent}>
          <OfficeNameInput hasBackground isFromManageCustomerInfoPage={isFromManageCustomerInfoPage} setIsLoading={setIsLoading} />
          <Box className={clsx(classes.boxContentDataRow, classes.noBorder)}>
            <Box className={classes.dataRow}>
              <Box>
                <Typography variant="body1">Office adress </Typography>
                <Typography variant="subtitle1"><FormattedAddress companyInfo={companyInfo}/></Typography>
              </Box>
            </Box>
            <IconButton
              onClick={handleEditCompanyAddress}
              className={classes.iconButton}
            >
              <EditIcon className={classes.boxContentDataRowEdit} />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
