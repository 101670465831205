import { combineReducers } from "redux";
import { roomRentalsReducer, peopleCap, seeAllRooms, checkAvailability } from "./roomsReducer";

const roomRentalsReducers = combineReducers({
  roomRentals: roomRentalsReducer,
  peopleCap: peopleCap,
  seeAllRooms: seeAllRooms,
  checkAvailability
});

export { roomRentalsReducers };
