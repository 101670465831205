import React from "react";
import { Popover, Box } from "@material-ui/core";
import moment from "moment";
import { DateRangePicker } from "react-date-range";
import {
  DateRangeSharp as DateRangeSharpIcon,
  ArrowDropDownSharp as ArrowDropDownSharpIcon,
} from "@material-ui/icons";

import useStyles from "./styles";

export default function RangeDatePicker(props) {
  const classes = useStyles();
  const { onChange } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = React.useState([
    {
      startDate: moment().startOf('month').toDate(),
      endDate: moment().endOf('month').toDate(),
      key: "selection",
    },
  ]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnChange = (selection) => {
    setState([selection]);
    if (selection.startDate !== selection.endDate) {
      onChange &&
        onChange({
          startDate: selection.startDate,
          endDate: selection.endDate,
        });
      handleClose();
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "date-range-popover" : undefined;

  return (
    <>
      <Box
        aria-describedby={id}
        onClick={handleClick}
        className={classes.transactionDate}
      >
        <DateRangeSharpIcon color="primary" />
        <ArrowDropDownSharpIcon className={classes.arrowIcon} />
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <DateRangePicker
          onChange={(item) => handleOnChange(item.selection)}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={1}
          ranges={state}
          direction="vertical"
          scroll={{ enabled: true }}
        />
      </Popover>
    </>
  );
}
