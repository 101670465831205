import { combineReducers } from "redux";
import modalReducer from "./modal";
import { masterDataReducer } from "./master-data";
import { messageReducer } from "./message";
import { messageTypeReducer } from "./message-type";
import { actionTypeReducer } from "./action-type";
import { chargebeeStatusReducer } from "./chargebee-status";

const uiReducers = combineReducers({
  modal: modalReducer,
  master: masterDataReducer,
  message: messageReducer,
  messageType: messageTypeReducer,
  actionType: actionTypeReducer,
  isChargebeeLoaded: chargebeeStatusReducer,
});

export { uiReducers };
