import { makeStyles } from "@material-ui/core";
import fgtPwdBackground from "../../assets/images/signup-background.png"
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    fgtPwd: {
        display: 'grid',
        'grid-template-columns': '1fr 1fr',
        height: '100vh'
    },
    fgtPwdLeft: {
        padding: '10% 0',
        textAlign: 'center'
    },
    fgtPwdLeftContainer: {
        maxWidth: '345px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        alignItems: 'center'
    },
    fgtPwdLeftBtn: {
        padding: 14,
        backgroundColor: '#102f51',
        borderRadius: 2,
        color: '#fff',
        marginTop: '10px',
        fontSize: '17px',
        lineHeight: '20px',
        letterSpacing: 1,
        "&:hover": {
            backgroundColor: "#2CA4C0"
        }
    },
    fgtPwdLeftH4: {
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '130%',
        letterSpacing: '0.02em',
        color: '#242223',
        fontFamily: 'Roboto-Medium'
    },
    fgtPwdLeftBody: {
        fontSize: '16px',
        lineHeight: '20px',
        color: '#494949',
    },
    fgtPwdFormWrap: {
        marginTop: '60px',
        width: '100%'
    },
    fgtPwdLogo: {
        width: '201px'
    },
    fgtPwdLogoImg: {
        maxWidth: '100%'
    },
    fgtPwdForm: {
        marginTop: '44px',
        position: 'relative'
    },
    fgtPwdInput: {
        height: '48px'
    },
    fgtPwdTransfromEmail: {
        animation: 'transformEmail 1s'
    },
    fgtPwdTransformPassword: {
        animation: 'transformPassword 1s'
    },
    fgtPwdTransformButton: {
        animation: 'transformButton 0.5s'
    },
    fgtPwdTransformRightButton: {
        animation: 'transformRightButton 0.5s'
    },
    fgtPwdBackToLogin: {
        fontSize: '15px',
        lineHeight: '40px',
        color: '#494949',
        textDecoration: 'none',
        marginTop: 'auto'
    },
    fgtPwdRight: {
        background: `url(${fgtPwdBackground}) left top`,
        backgroundSize: 'cover',
        position: 'relative'
    },
    fgtPwdRightBgLayer: {
        background: '#102f51',
        opacity: 0.8,
        height: '100%'
    },
    fgtPwdRightBox: {
        background: '#102f51',
        border: '2px solid #8eeaff',
        padding: '33px 49px 51px',
        position: 'absolute',
        bottom: '25%',
        width: '425px',
        left: '82px',
        opacity: 0.8
    },
    fgtPwdRightBoxH4: {
        fontSize: '16px',
        lineHeight: '19px',
        color: '#8eeaff',
        fontWeight: 'normal'
    },
    fgtPwdRightBoxH3: {
        fontSize: '26px',
        lineHeight: '30px',
        letterSpacing: '0.01em',
        color: '#fff'
    },
    fgtPwdTrialBtn: {
        background: '#8eeaff',
        borderRadius: '2px',
        padding: '15px',
        fontSize: '18px',
        lineHeight: '21px',
        letterSpacing: '0.01em',
        color: '#102f51',
        position: 'absolute',
        bottom: '-25px',
        left: '36px',
        width: '235px',
        "&:hover": {
            backgroundColor: "#8eeaff"
        }
    },
    fgtPwdNote: {
        fontStyle: 'italic',
        fontSize: '14px',
        lineHeight: '16px',
        color: '#494949'
    },
    fgtPwdStartNow: {
        background: theme.palette.primary.main,
        borderRadius: '2px',
        padding: '17px',
        fontWeight: 'bold',
        fontSize: '15px',
        lineHeight: '18px',
        letterSpacing: '0.01em',
        color: '#fff',
        position: 'absolute',
        bottom: '5%',
        transform: 'translateX(-50%)',
        left: '59%',
        width: '300px'
    },
    fgtPwdProfileField: {
        position: 'relative',
        marginBottom: '22px'
    },
    fgtPwdProfileFieldIcon: {
        position: 'absolute',
        right: '20px',
        top: '20px'
    },
    loader: {
        marginTop: "10px"
    }
}));


export { useStyles }