import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "sans-serif",
    color: "#242223",
  },
  paper: {
    width: 800,
    background: "#fff",
    position: "relative",
    maxHeight: "95vh",
    overflow: "auto",
  },
  modalHeader: {
    padding: "10px 15px",
    background: "#F1F9FE",
    borderBottom: "1px solid #CDE0F1",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  modalHeading: {
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "18px",
    color: "#242223",
    fontFamily: "Roboto-Medium",
  },
  closeButton: {
    color: theme.palette.grey[500],
    padding: 0,
  },
  modalBody: {
    padding: "20px 15px",
  },
}));

export { useStyles };
