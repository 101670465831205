import { http, handleResponse } from "../core";
import { URL } from "./_urls.js";

const apiFetchTransactions = async (startDate, endDate, customerId) => {
  return handleResponse(
    await http.get(URL.FETCH_TRANSACTIONS(startDate, endDate, customerId))
  );
};

const apiFetchInvoiceUrl = async (invoiceId) => {
  return handleResponse(await http.get(URL.FETCH_INVOICE_URL(invoiceId)));
};

export { apiFetchTransactions, apiFetchInvoiceUrl };
