import { http, handleResponse } from "../core";
import { URL } from "./_urls.js";

const apiLoginUser = async (data) => {
  return handleResponse(await http.post(URL.LOGIN_API(), data));
};

const apiSignupUser = async (data) => {
  return handleResponse(await http.post(URL.SIGNUP_API(), data));
};

export { apiLoginUser, apiSignupUser };
