/* eslint-disable no-use-before-define */
import React from "react";
import {
  Popper,
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  ArrowDropDownOutlined as ArrowDropDownOutlinedIcon,
  ArrowDropUpOutlined as ArrowDropUpOutlinedIcon,
  Close as CloseIcon,
  Search as SearchIcon,
} from "@material-ui/icons";
import clsx from "clsx";
import { useStyles } from "./BaseSearchDropdown.style";

export default function BaseSearchDropdown({
  options,
  multiple,
  value,
  onChange,
  label,
  className,
  id: elementId,
  onInputChange,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget || event.target);
  };

  const handleClose = (_, reason) => {
    if (reason === "toggleInput") {
      return;
    }
    if (reason !== "select-option" && reason !== "remove-option")
      onChange(value);
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? elementId : undefined;

  const renderSelectedValue = (item) => (
    <div key={item[label]} className={classes.selectDropdown}>
      <span>{item[label] || ""}</span>
      <CloseIcon fontSize="small" className={classes.crossIconLeft} />
    </div>
  );
  return (
    <React.Fragment>
      <Box
        aria-describedby={id}
        className={clsx(
          className ? className : classes.select,
          open ? classes.activeContainer : ""
        )}
        onClick={handleClick}
      >
        {multiple ? (
          <div>{(value || []).map((item) => renderSelectedValue(item))}</div>
        ) : (
          <span>{value && value[label]}</span>
        )}
        {open ? (
          <ArrowDropUpOutlinedIcon className={classes.downIcon} />
        ) : (
          <ArrowDropDownOutlinedIcon className={classes.downIcon} />
        )}
      </Box>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        className={classes.popper}
        style={{ width: anchorEl?.offsetWidth || 300 }}
      >
        <Autocomplete
          open
          onClose={handleClose}
          multiple={multiple}
          classes={{
            root: classes.root,
            paper: classes.paper,
            listbox: classes.listbox,
            option: classes.option,
            popperDisablePortal: classes.popperDisablePortal,
            inputRoot: classes.inputRoot,
            popupIndicatorOpen: classes.popupIndicatorOpen,
            popper: classes.optionPopper,
          }}
          onInputChange={(_, val, reason) => {
            if (reason === "clear") {
              onChange(val);
              onInputChange && onInputChange(val);
            }
            if (reason === "input" && onInputChange) onInputChange(val);
          }}
          value={value}
          onChange={(_, newValue) => onChange(newValue)}
          disableCloseOnSelect={false}
          disablePortal
          renderTags={() => null}
          noOptionsText="No result found"
          renderOption={(option, { selected }) => (
            <React.Fragment>
              {multiple ? (
                <FormControlLabel
                  control={<Checkbox color="primary" name="checkedA" />}
                  label={option[label]}
                  checked={selected}
                />
              ) : (
                <div>{option[label] || ""}</div>
              )}
            </React.Fragment>
          )}
          popupIcon={<SearchIcon />}
          options={options}
          getOptionLabel={(option) => option[label] || {}}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" fullWidth autoFocus />
          )}
        />
      </Popper>
    </React.Fragment>
  );
}
