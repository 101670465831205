import React from "react";
import {
  Container,
  Grid,
  ListItemText,
  List,
  ListItem,
  Link,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { useHistory } from "react-router";
import { useStyles } from "./office-card-style";

export default function OfficeCard(props) {
  const classes = useStyles();
  let history = useHistory();
  const selectedLocation = useSelector(
    (state) => state.customer.locations.selected
  );

  const handleHeaderLinkClick = () => {
    history.push("/front-desk");
  };

  return (
    <div className={classes.MainDiv}>
      <Grid sm={12} direction="row" container>
        <div className={classes.headerDiv}>
          <Grid
            sm={12}
            direction="row"
            container
            style={{ padding: "12px 15px" }}
          >
            <Grid
              sm={6}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div className={classes.headerText}>
                <p>{props.header}</p>
              </div>
            </Grid>
            <Grid
              sm={6}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Link
                component="button"
                variant="body2"
                color="primary"
                onClick={handleHeaderLinkClick}
              >
                {props.headerLinkText}
              </Link>
            </Grid>
          </Grid>
        </div>
        <Grid sm={12} direction="row" container>
          <Grid sm={12} direction="col" container>
            <div className={classes.bodyImgDiv}>
              <img
                src={
                  selectedLocation.coverUrl
                    ? selectedLocation.coverUrl
                    : props.image
                }
                className={classes.bodyImg}
                alt={selectedLocation.name}
              ></img>
              <div className={classes.bodyDetailsDiv}>
                <Container>
                  <strong className={classes.bodyDetailsheading}>
                    {selectedLocation.name}
                  </strong>

                  <p className={classes.addressHeading}>Your Office Address:</p>
                  <p className={classes.bodyDetailsAddress}>
                    {selectedLocation.address1}
                    {selectedLocation.state
                      ? `, ${selectedLocation.state}`
                      : ""}
                  </p>
                  <p className={classes.addressHeading}>Todays Date: </p>
                  <List className={classes.dateList}>
                    <ListItem className={classes.bodyDetailsDate}>
                      <ListItemText>
                        {selectedLocation.timezone
                          ? moment()
                              .tz(selectedLocation.timezone)
                              .format("MMM DD, YYYY")
                          : ""}
                      </ListItemText>
                      <ListItemText className={classes.timeItem}>
                        {selectedLocation.timezone
                          ? moment()
                              .tz(selectedLocation.timezone)
                              .format("HH:mm A")
                          : ""}
                      </ListItemText>
                    </ListItem>
                  </List>
                </Container>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
