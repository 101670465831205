import React, { Fragment, useEffect } from "react";
import { Box, Typography, Link } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./MyAccount.style";
import { UIActionTypeHook } from "../shared";
import { UIActionType } from "../../core";
import { setAuthData, setErrorMessage, setSelectedCustomer } from "../../store";
import { UserInfo } from "./user-info";
import { TeamMembers } from "../shared/team-members";
import { CompanyInfo } from "./company-info";
import { ForwardingAddresses } from "./forwarding-addresses";
import { ForwardingPhoneNumbers } from "./forwarding-phone-numbers";
import { PaymentInformation } from "./payment-information";
import { FilledUSPSForm } from "./filled-USPS-form";
import { apiFetchUserDetail } from "../../apis";

export default function MyAccount() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth.data.user_id)
  UIActionTypeHook(UIActionType.User);

  useEffect(() => {
    dispatch(setSelectedCustomer({}));
  }, [dispatch]);

  useEffect(() => {
    const loadUserDetails = async () => {
      try {
        const response = await apiFetchUserDetail(userId);
        dispatch(setAuthData(response));
      }
      catch (error) {
        dispatch(setErrorMessage(error.toString()));
      }
    }
    loadUserDetails();
  }, [dispatch, userId]);
  return (
    <Fragment>
      <Box component="div" className={classes.subHeader}>
        <Typography variant="body1">
          You haven’t filled the USPS Form
        </Typography>
        <Link
          component="button"
          variant="inherit"
          color="primary"
          className={classes.boxLink}
        >
          Resend the mail
        </Link>
      </Box>
      <Box className={classes.main}>
        <Box className={classes.columnWrap}>
          {/* Column 1 */}
          <Box className={classes.column}>
            <UserInfo />
            <TeamMembers />
          </Box>

          {/* Column 2 */}
          <Box className={classes.column}>
            <CompanyInfo />
            <PaymentInformation />
            <FilledUSPSForm />
          </Box>

          {/* Column 3 */}
          <Box className={classes.column}>
            <ForwardingAddresses />
            <ForwardingPhoneNumbers />
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
}
