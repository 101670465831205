import { combineReducers } from "redux";
import { forwardingAddressReducer } from "./forwarding-address";
import { forwardingPhoneNumberReducer } from "./forwarding-phone-number";
import { teamMemberReducer } from "./team-members";
import { paymentMethodReducer } from "./payment-method";

const accountReducer = combineReducers({
  forwardingAddress: forwardingAddressReducer,
  forwardingPhoneNumber: forwardingPhoneNumberReducer,
  teamMember: teamMemberReducer,
  paymentMethod: paymentMethodReducer,
});

export { accountReducer };
