import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    "@global": {
      "*": {
        boxSizing: "border-box",
        margin: 0,
        padding: 0,
      },
      html: {
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
        height: "100%",
        width: "100%",
      },
      body: {
        height: "100%",
        width: "100%",
      },
      ".MuiInputBase-root.Mui-disabled": {
        background: "#EFEFEF",
      },
      ".MuiDataGrid-columnHeaderWrapper": {
        background: "#f1f9fe",
      },
      ".MuiDataGrid-columnSeparator": {
        display: "none !important",
      },
      ".MuiDataGrid-overlay": {
        zIndex: 99,
      },
      ".MuiDataGrid-row:nth-child(odd)": {
        backgroundColor: "white",
      },
      ".MuiDataGrid-columnHeader--moving": {
        backgroundColor: "inherit !important",
      },
      ".MuiDataGrid-columnHeaderTitle	": {
        fontFamily: "Roboto-Medium",
        fontSize: "15px",
      },
      ".MuiDataGrid-root .MuiDataGrid-cell,.MuiDataGrid-root .MuiDataGrid-columnHeader":
        {
          cursor: "pointer",
          "&:focus,&:focus-within": {
            outline: "none !important",
          },
        },
      ".MuiDataGrid-row.Mui-selected": {
        backgroundColor: "inherit !important",
        border: "1px solid #2CA4C0",
      },
      "input[type='time']::-webkit-calendar-picker-indicator,input[type='date']::-webkit-calendar-picker-indicator":
        {
          position: "absolute",
          top: 0,
          right: 0,
          width: "100%",
          height: "100%",
          padding: 0,
          color: "transparent",
          background: "transparent",
        },
      "input[type='time'],input[type='date']": {
        position: "relative",
      },
      ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderWidth: 0,
      },
      ".pn-msg": {
        maxWidth: "70%",
        width:"fit-content",
        alignItems:"flex-start",
        // background:"white",
        borderRadius:10,
        marginBottom:5,
        "&:hover":{
          // background:"#f0f3f7",
          background:"transparent"
        }
      },
      ".pn-msg--own": {
        marginLeft: "auto",
        // background:"#f1f9fe",
        // color:"#fff",
        "&:hover":{
          // background:"#f1f9fe"
        },
        "--msg__avatar__margin": "2px 0 0 18px",
        "--msg__content__alignItems": "flex-end",
        "--msg__flexDirection": "row-reverse",
        "--msg__title__flexDirection": "row-reverse",
        "--msg__time__flexDirection": "row-reverse",
        "--msg__content__width": "350px",
      },
      "--msg-list__unread__background": "var(--chat--light__color--2)",
    },
  })
);

const GlobalStyles = () => {
  useStyles();

  return null;
};

export { GlobalStyles };
