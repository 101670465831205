import { ADMIN } from "../../../actions";

const planInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN.CLEAR_ADMIN:
      return {};
    case ADMIN.SET_CUSTOMER_PLAN_INFO:
      return action.planInfo;
    default:
      return state;
  }
};

export { planInfoReducer };
