import { SPACERENTAL } from "../../../actions";

const dataReducer = (state = [], action) => {
  switch (action.type) {
    case SPACERENTAL.RESET_UPCOMING_BOOKINGS:
      return [];
    case SPACERENTAL.SET_UPCOMING_BOOKINGS:
      return action.data;
    default:
      return state;
  }
};

export { dataReducer };
