import { MAILMANAGEMENT } from "../../actions";

const selectedRowIdsReducer = (state = [], action) => {
  switch (action.type) {
    case MAILMANAGEMENT.CLEAR:
      return [];
    case MAILMANAGEMENT.SET_SELECTED_MAIL_ROW_IDS:
      return action.selectedRowIds;
    default:
      return state;
  }
};

export { selectedRowIdsReducer };
