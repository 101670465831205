import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Table,
  Radio,
  FormHelperText,
  FormControl,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import {
  ModalType,
  MailAction,
  ServiceMetaKey,
} from "../../../core";
import {
  closeModal,
  setMailOptions,
  setMailAction,
  setMailItems,
  setMMTotalPages,
  setTotalCount,
  setCustomerMailCounts,
  openModal,
  setErrorMessage,
  getCustomerId,
  getSelectedLocationId,
  loadMailItemsFailed,
} from "../../../store";
import { ForwardingAddressInput } from "../../shared";
import CloseModalIcon from "../../../assets/icons/closeModalIcon";
import JunkModalCautionIcon from "../../../assets/icons/junkModalCoutionIcon";
import "./request-forward-modal.css";
import { apiMailAction } from "../../../apis";

const deliveryOptions = {
  USPS: ServiceMetaKey.Usps,
  FEDEX: ServiceMetaKey.Fedex,
  INTERNATIONAL: ServiceMetaKey.InternationalForward,
};

const shippingOptions = {
  STANDARD: 0,
  NEXTDAY: 1,
  TWODAYS: 2,
  THREEDAYS: 3,
};

export default function RequestForwardModal() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const modal = useSelector(
    (state) => state.ui.modal[ModalType.MailRequestForwardModal] || false
  );
  const options = useSelector((state) => state.mail.options);
  const planServices = useSelector((state) =>
    state.admin.customer.planServices
  );
  const [option, setOption] = useState(deliveryOptions.USPS);
  const [shippingOption, setShippingOption] = useState(
    shippingOptions.STANDARD
  );
  const [error, setError] = useState("");
  const activeTab = state.mail.activeTab;
  const limit = state.mail.limit;
  const ids = state.mail.selectedRowIds;
  const modalSelected = state.ui.modal;
  const selectedRow = state.mail.selectedRow;
  const isBulkOperation = state.mail.isBulkOperation;
  const customerId = useSelector((state) => getCustomerId(state));
  const locationId = useSelector((state) => getSelectedLocationId(state));
  const mailAccountId = state.auth.data.mail_account_id;
  const isCancelRequestModal =
    modalSelected[ModalType.CancelMailRequestModal] || false;
  const isForwardingModal =
    modalSelected[ModalType.MailRequestForwardModal] || false;

  const clearError = () => setError("");
  const handleClose = () => {
    dispatch(closeModal(ModalType.MailRequestForwardModal));
    clearError();
  };
  // const handleTeamMemberChange = (id) => {
  //   clearError();
  // };

  const handleAddressChange = (id) => {
    clearError();
    dispatch(setMailOptions({ ...options, forward_address_id: id }));
  };

  const handleDeliveryOptionChange = (row) => {
    clearError();
    dispatch(setMailOptions({ ...options, ...row }));
  };

  const handleShippingOption = (value) => {
    clearError();
    setShippingOption(Number(value));
    dispatch(setMailOptions({ ...options, delivery_days: value }));
  };

  const handleConfirm = async () => {
    if (options.forward_address_id) {
      dispatch(setMailAction(MailAction.Forward));
      try {
        const data = {
          ids: isBulkOperation ? ids : [selectedRow.id],
          mailAction: MailAction.Forward,
          enabled: !isCancelRequestModal,
          customerPlanServiceId: options.customerPlanServiceId || 0,
          price: options.forward_price || 0,
          params: { page: 1, status: activeTab, limit: limit - ids.length },
          ...(isForwardingModal && {
            forward_address_id: options.forward_address_id,
            ...(options.delivery_days > 0 && {
              delivery_days: options.delivery_days,
            }),
          }),
        };
        const response = await apiMailAction(
          customerId,
          mailAccountId,
          locationId,
          data
        );
        const { mail_items, total_pages, total } = response;
        dispatch(setMailItems(mail_items));
        dispatch(setMMTotalPages(total_pages));
        dispatch(setTotalCount(total));
        dispatch(setCustomerMailCounts(total));
        if (!isCancelRequestModal)
          dispatch(openModal(ModalType.MailSuccessActionModal));
      } catch (error) {
        dispatch(setErrorMessage(error.toString()));
        dispatch(loadMailItemsFailed());
      }
      handleClose();
    } else setError("Select forwarding address");
  };

  const getDeliveryInfo = (metaKey) => {
    const { servicePrice, serviceQty, customQty, usedQty } =
      (planServices || []).find((plan) => plan.serviceKey === metaKey) || {};
    let totalQuantities = customQty ? customQty : serviceQty;
    if ((usedQty || 0) < totalQuantities)
      return `Free (${usedQty || 0}/${totalQuantities} Left)`;
    else return `$${servicePrice} + postage`;
  };

  const getServicePrice = (metaKey) => {
    const { servicePrice, serviceQty, customQty, usedQty, id } =
      (planServices || []).find((plan) => plan.serviceKey === metaKey) || {};
    let totalQuantities = customQty ? customQty : serviceQty;
    if ((usedQty || 0) < totalQuantities)
      return { servicePrice: 0, customerPlanServiceId: id };
    else return { servicePrice, customerPlanServiceId: id };
  };

  return (
    <Dialog
      maxWidth="lg"
      open={modal}
      disableEnforceFocus
      onClose={handleClose}
      aria-labelledby="junk-dialog-title"
      aria-describedby="junk-dialog-description"
    >
      <div className="reqForwardModalDialog">
        <DialogTitle id="junk-dialog-title" className="reqForwardModalHeader">
          <Grid container sm={12} direction="row">
            <Grid sm={6}>
              <p className="reqForwardModalTitle"> Forwarding options</p>
            </Grid>
            <Grid sm={6}>
              <CloseModalIcon
                handleCloseIcon={handleClose}
                className={"reqForwardModalCloseIcon"}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="junk-dialog-description">
            <div className="reqForwardModalContainer">
              <Grid sm={12} direction="row">
                {/* <Grid sm={12}>
                  <div className="reqForwardModalLabel">
                    <label>Forward to</label>
                  </div>
                  <ForwardingToInput onChange={handleTeamMemberChange} />
                </Grid> */}
                <Grid sm={12} className="forwardingAddressGrid">
                  <div className="reqForwardModalLabel">
                    <label>Forwarding address:</label>
                  </div>
                  <ForwardingAddressInput onChange={handleAddressChange} />
                  <FormHelperText error={Boolean(error)}>
                    {error}
                  </FormHelperText>
                </Grid>
                <Grid sm={12} className="forwardingAddressGrid">
                  <hr className="divider" />
                  <h3 className="deliveryOptionsDropdown">
                    Choose a delivery option:
                  </h3>
                </Grid>
                <Grid>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="shippingOptions"
                      name="shippingOptions"
                      value={shippingOption}
                      onChange={(event) =>
                        handleShippingOption(event.target.value)
                      }
                    >
                      <FormControlLabel
                        value={shippingOptions.STANDARD}
                        control={<Radio color="primary" />}
                        label="Standard Shipping"
                      />
                      <FormControlLabel
                        value={shippingOptions.NEXTDAY}
                        control={<Radio color="primary" />}
                        label="Next day delivery"
                      />
                      <FormControlLabel
                        value={shippingOptions.TWODAYS}
                        control={<Radio color="primary" />}
                        label="2 days delivery"
                      />
                      <FormControlLabel
                        value={shippingOptions.THREEDAYS}
                        control={<Radio color="primary" />}
                        label="3 days delivery"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid sm={12} className="forwardingAddressGridTable">
                  <Table size="sm" className="desliveryOptionsTable">
                    <thead className="deliveryOptionsHeading">
                      <tr>
                        <th className="deliveryOptionsHeadingRow">Delivery</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody className="deliveryOptionsTableBody">
                      <tr>
                        <td style={{ paddingTop: "10px" }}>
                          <Radio
                            color="primary"
                            name="radio-button-delivery-price"
                            checked={option === deliveryOptions.USPS}
                            onChange={() => {
                              const service = getServicePrice(
                                deliveryOptions.USPS
                              );
                              setOption(deliveryOptions.USPS);
                              handleDeliveryOptionChange({
                                forward_price: service.servicePrice,
                                customerPlanServiceId:
                                  service.customerPlanServiceId,
                                international_forwarding: false,
                              });
                            }}
                          />
                          USPS Inclusive forward
                        </td>
                        <td>{getDeliveryInfo(deliveryOptions.USPS)}</td>
                      </tr>
                      <tr>
                        <td>
                          <Radio
                            color="primary"
                            name="radio-button-delivery-price"
                            checked={option === deliveryOptions.FEDEX}
                            onChange={() => {
                              const service = getServicePrice(
                                deliveryOptions.FEDEX
                              );
                              setOption(deliveryOptions.FEDEX);
                              handleDeliveryOptionChange({
                                forward_price: service.servicePrice,
                                customerPlanServiceId:
                                  service.customerPlanServiceId,
                                international_forwarding: false,
                              });
                            }}
                          />
                          Fed-ex overnight forward
                        </td>
                        <td>{getDeliveryInfo(deliveryOptions.FEDEX)}</td>
                      </tr>
                      <tr>
                        <td>
                          <Radio
                            color="primary"
                            name="radio-button-delivery-price"
                            checked={option === deliveryOptions.INTERNATIONAL}
                            onChange={() => {
                              const service = getServicePrice(
                                deliveryOptions.INTERNATIONAL
                              );
                              setOption(deliveryOptions.INTERNATIONAL);
                              handleDeliveryOptionChange({
                                forward_price: service.servicePrice,
                                customerPlanServiceId:
                                  service.customerPlanServiceId,
                                international_forwarding: false,
                              });
                            }}
                          />
                          International forward
                        </td>
                        <td>
                          {getDeliveryInfo(deliveryOptions.INTERNATIONAL)}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Grid>
                <Grid sm={12} className="deliveryOptionsTotalGrid">
                  <div className="deliveryOptionsTotal">
                    <h4>TOTAL</h4>
                    <h2 className="deliveryOptionsTotalValue">{`$${options.forward_price} + postage`}</h2>
                  </div>
                </Grid>
              </Grid>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="junkModalFooter">
          <Grid direction="row" container sm={12}>
            <Grid sm={6} className="reqForwardModalCautionCol">
              <JunkModalCautionIcon />
              <p className="reqForwardModalCautionText">
                {`Your card will be charged for $${options.forward_price}.`}
              </p>
            </Grid>
            <Grid sm={6} className="reqForwardModalCautionCol" spacing={3}>
              <Button
                variant="primary"
                className="reqForwardModalButton"
                onClick={handleConfirm}
              >
                Request forwarding
              </Button>
              <Button
                className="reqForwardModalCancelButton"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </div>
    </Dialog>
  );
}
