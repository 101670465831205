import { MAILMANAGEMENT } from "../../actions";
const initialValue = {
  forward_price: 0,
  international_forwarding: false,
};
const optionsReducer = (state = initialValue, action) => {
  switch (action.type) {
    case MAILMANAGEMENT.CLEAR:
      return initialValue;
    case MAILMANAGEMENT.SET_MAIL_OPTIONS:
      return action.options;
    default:
      return state;
  }
};

export { optionsReducer };
