import { combineReducers } from "redux";
import { dataReducer } from "./data";
import { selectedReducer } from "./selected";
import { operatingModeReducer } from "./operating-mode";

const forwardingPhoneNumberReducer = combineReducers({
    data: dataReducer,
    selected: selectedReducer,
    operatingMode: operatingModeReducer
});

export { forwardingPhoneNumberReducer };
