import React from 'react'
import { Modal, Fade, Backdrop, Button, Typography, OutlinedInput } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { closeModal } from '../../../store';
import { ModalType } from '../../../core';
import clsx from 'clsx';
import { useStyles } from './EditVirtualPhoneNumber.style';
import IconButton from '@material-ui/core/IconButton';
import {
    HighlightOff as HighlightOffIcon
} from '@material-ui/icons';


export default function EditVirtualPhoneNumber() {
    const state = useSelector(state => state);
    const dispatch = useDispatch();
    const classes = useStyles();
    const modal = state.ui.modal[ModalType.EditVirtualPhoneNumber] || false;
    const selectedVirtualPhoneNumber = state.phoneServices.liveAnsweringService.virtualPhoneNumbers.selectedVirtualPhoneNumber;
    const handleClose = () => {
        dispatch(closeModal(ModalType.EditVirtualPhoneNumber));
    }
    return (
        <Modal
            aria-labelledby="add-update-directory-modal-title"
            aria-describedby="add-update-directory-modal-description"
            open={modal}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            className={classes.modal}
            BackdropProps={{
                timeout: 500
            }}
        >
            <Fade in={modal}>
                <div className={classes.paper}>
                    <div className={classes.modalHeader}>
                        <Typography variant="h6" className={classes.modalHeading}>
                            Edit virtual phone number
                        </Typography>
                        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                            <HighlightOffIcon />
                        </IconButton>
                    </div>
                    <div className={classes.modalBody}>
                        <Typography variant="h5" className={classes.modalSubhead}>
                            Number’s owner
                        </Typography>
                        <OutlinedInput
                            type="text"
                            className={classes.input}
                            fullWidth
                            placeholder="e.g. Law office of Ana Jones"
                            defaultValue={selectedVirtualPhoneNumber?.fullName}
                        />
                        <Typography className={classes.modalSubhead} variant="h5">
                            Virtual phone number
                        </Typography>
                        <OutlinedInput
                            type="text"
                            className={clsx(classes.input, classes.lastChild)}
                            fullWidth
                            placeholder="e.g. (406) 759-9126"
                            defaultValue={selectedVirtualPhoneNumber?.virtualPhone}
                        />
                    </div>
                    <div className={classes.modalFooter}>
                        <Button className={clsx(classes.footerButton, classes.footerPrimaryBtn)} variant="contained" color="primary" onClick={handleClose}>
                            Save changes
                        </Button>
                        <Button className={classes.footerButton} variant="outlined" onClick={handleClose}>Cancel</Button>
                    </div>
                </div>
            </Fade>
        </Modal>
    )
}
