/* eslint-disable */
import jwt_decode from "jwt-decode";
import { BehaviorSubject } from "rxjs";
const tokenKey = "token";
export const runFuncs = (fns) => (args) =>
  fns && fns.forEach((fn) => fn & fn(args));

const tokenSubject = new BehaviorSubject(localStorage.getItem(tokenKey));

const logout = () => {
  localStorage.removeItem(tokenKey);
  tokenSubject.next(null);
};

const setToken = (token) => {
  localStorage.setItem(tokenKey, token);
  tokenSubject.next(token);
};

const decodedToken = () => {
  return tokenSubject.value
    ? jwt_decode(tokenSubject.value)
    : {
        user_id: "",
        customer_id: "",
        email: "",
        iat: 0,
        exp: 0,
      };
};

const expireAt = () => new Date(decodedToken()?.exp * 1000);

const isExpired = () => new Date() > expireAt();

export const isAuthenticated = () => !isExpired();

export const authHeader = () => {
  const token = tokenSubject.value;
  return token ? { headers: { Authorization: token } } : {};
};

export const withAuthHeader = () => {
  const token = tokenSubject.value;
  return token ? { Authorization: token } : {};
};

export const authHeaderSparemail = () => {
  const token = tokenSubject.value;
  return token
    ? {
        headers: {
          Authorization:
            "eyJhbGciOiJIUzI1NiJ9.eyJpZCI6MTI4MjIsImV4cGlyZXMiOjE2MzM1MDMyODh9.EdxT-xpb0WEG40AYpUNpkG90-oC_q6kURoEI-TXtJXo",
        },
      }
    : {};
};

export const authenticationService = {
  setToken,
  logout,
  authHeader,
  withAuthHeader,
  authHeaderSparemail,
  isAuthenticated,
  tokenSubject,
  tokenObservable: tokenSubject.asObservable(),
  get token() {
    return tokenSubject.value;
  },
};
