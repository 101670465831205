import { makeStyles } from "@material-ui/core";
import currentPlanBackground from "../../../assets/images/currentPlanBackground.jpg"
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "sans-serif",
    color: "#242223",
  },
  paper: {
    width: 800,
    background: "#fff",
    position: "relative",
    maxHeight: "95vh",
    overflow: "auto",
  },
  modalHeader: {
    padding: "10px 15px",
    background: "#F1F9FE",
    borderBottom: "1px solid #CDE0F1",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  modalHeading: {
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "18px",
    color: "#242223",
    fontFamily: "Roboto-Medium",
  },
  modalContentTitle: {
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#242223",
    fontFamily: "Roboto-Medium",
    marginBottom: 20,
  },
  closeButton: {
    color: theme.palette.grey[500],
    padding: 0,
  },
  modalBody: {
    padding: "20px 15px",
  },
  select: {
    display: "flex",
    alignItems: "center",
    margin: "10px 0 20px",
    flex: "1",
    border: "1px solid #CDE0F1",
    padding: "5px 10px",
    width: "70%",
  },
  downIcon: {
    marginLeft: "auto",
    color: "#CDE0F1",
  },
  modalFooter: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "10px 15px",
    marginBottom: 15,
  },
  footerButton: {
    flex: "1",
    maxWidth: "48%",
    border: "2px solid #2CA4C0",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    padding: "6px 15px",
  },
  footerPrimaryBtn: {
    background: "linear-gradient(90deg, #409A95 17.74%, #34CEC6 100%)",
    color: "#fff",
  },
  cardSectionHeader: {
    background: "#CDE0F1",
    borderBottom: "1px solid #CDE0F1",
    alignItems: 'center',
    padding: '10px 15px',
    minHeight: '40px',
    display: 'grid',
    gridTemplateColumns: '3fr 1fr 1fr'
  },
  cardSectionContent: {
    padding: '10px 15px',
    display: 'grid',
    alignItems: 'center',
    minHeight: '40px',
    gridTemplateColumns: '3fr 1fr 1fr'
  },
  highlightRow: {
    background: '#F7F6F7'
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    background: 'transparent'
  },
  editIcon: {
    fontSize: '0.875rem'
  },
  editIconContainer: {
    marginLeft: '15px',
    height: '14px',
    width: '14px'
  },
  cardSection: {
    border: '1px solid #CDE0F1'
  },
  spacing: {
    marginTop: '20px'
  },
  imgSection: {
    color: "#fff",
    height: 93,
    background: `linear-gradient(to right, rgba(21, 38, 81, 0.7), rgba(16, 47, 81, 0.8)), url(${currentPlanBackground})`,
    backgroundSize: 'cover'
  },
  imgSectionContent: {
    color: "#fff",
    padding: "15px",
    fontSize: "14px",
    letterSpacing: "0.01em",
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    height: '100%'
  },
  firmIdentity: {
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '19px',
    letterSpacing: '0.01em'
  },
  boxLeftHeading: {
    fontSize: "16px",
    lineHeight: "19px",
    color: "#242223",
    fontWeight: "600",
    marginBottom: "5px"
  },
  boxPrice: {
    fontSize: "22px",
    lineHeight: "26px",
    color: theme.palette.primary.light,
    fontWeight: "600",
  },
  boxContent: {
    display: 'flex',
    padding: "15px",
    fontSize: "14px",
    color: "#494949",
    letterSpacing: "0.01em",
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    background: 'rgba(242, 246, 250, 0.8)',
    flexDirection: 'column'
  }
}));

export { useStyles };
