import { CONFIG } from "./../config";

export const URL = {
  // Master Api Urls
  LOAD_MASTER_DATA: () => `/v1/master-data`,

  // User Api Urls
  LOGIN_API: () => `/v1/users/login`,
  SIGNUP_API: () => `/v1/users/signup`,
  FETCH_USER_DETAIL: (userId) => `/v1/users/${userId}`,
  UPDATE_USER_API: () => `/v1/users/update-user`,
  CHANGE_PASSWORD_API: () => `/v1/users/change-password`,
  FORGET_PASSWORD_API: () => `/v1/users/forget-password`,
  CP_THROUGH_LINK_API: () => `/v1/users/cp-through-link`,

  // Customer Api Urls
  UPDATE_CUSTOMER_API: () => `/v1/customers/update-customer`,
  FETCH_CF_ADDRESSES_API: (locationId, customerId) =>
    `/v1/customers/cf-addresses?locationId=${locationId}&customerId=${customerId}`,
  ADD_CF_ADDRESSE_API: () => `/v1/customers/add-cf-address`,
  UPDATE_CF_ADDRESSE_API: () => `/v1/customers/update-cf-address`,
  ADD_CT_MEMBERS_API: () => `/v1/customers/add-ct-members`,
  UPDATE_CT_MEMBERS_API: () => `/v1/customers/update-ct-members`,
  FETCH_CUSTOMER_LOCATIONS: (customerId) => `/v1/customers/locations`,
  FETCH_TEAM_MEMBERS: (locationId, customerId, includeLoginUser) =>
    `/v1/customers/ct-members?locationId=${locationId}&customerId=${customerId}&includeLoginUser=${includeLoginUser}`,

  // Mail management Api Urls
  FETCH_ALL_NEW_MAILS: (
    status,
    search,
    page,
    limit,
    accountId,
    locationId
  ) =>
    `/v1/mail/${locationId}/items?page=${page}&limit=${limit}&status=${status}&search=${search}&accountId=${accountId}`,
  MAILS_ACTION_URL: (customerId, accountId, locationId) =>
    `/v1/mail/${locationId}/action?customerId=${customerId}&accountId=${accountId}`,
  FETCH_ALL_CUSTOMERS_MAILS: (search, locationId) =>
    `/v1/mail/customers/${locationId}?search=${search}`,
  FETCH_ALL_COUNTRIES_MAILS: (locationId) => `/v1/mail/${locationId}/countries`,
  FETCH_COUNTRY_DETAIL_MAILS: (id, locationId) =>
    `/v1/mail/${locationId}/countries/${id}`,
  CREATE_ADDRESS_URL: (accountId, locationId) =>
    `/v1/mail/${locationId}/forwardAddress?accountId=${accountId}`,
  UPDATE_ADDRESS_URL: (accountId, locationId) =>
    `/v1/mail/${locationId}/forwardAddress?accountId=${accountId}`,

  // Admin Api Urls
  FETCH_CUSTOMERS: (locationId) =>
    `/v1/admin/getAllCustomers?locationId=${locationId}`,
  FETCH_PLANS: (locationId) =>
    `/v1/billing/getAllPlans?locationId=${locationId}`,
  FETCH_CUSTOMER_PLAN_SERVICES: (customerId) =>
    `/v1/admin/customerPlanServices/${customerId}`,
  UPDATE_CUSTOMER_PLAN_SERVICE: () => `/v1/admin/updateCustomerPlanService`,
  FETCH_ROOM_DETAIL_BY_ID: (roomId) =>
    `/v1/admin/getRoomDetailById?roomId=${roomId}`,
  FETCH_ALL_ROOMS: (locationId) =>
    `/v1/admin/getAllRooms?locationId=${locationId}`,
  FETCH_ALL_CHAT_USERS: (locationId) =>
    `/v1/admin/getAllChatUsers?locationId=${locationId}`,
  FETCH_ALL_LOCATIONS: () => `/v1/admin/getAllLocations`,
  FETCH_LOCATION_DETAIL_BY_ID: (locationId) =>
    `/v1/admin/getLocationDetailById?locationId=${locationId}`,
  ADD_ROOM: () => `/v1/admin/addRoom`,
  UPDATE_ROOM: () => `/v1/admin/updateRoom`,
  ADD_LOCATION: () => `/v1/admin/addLocation`,
  UPDATE_LOCATION: () => `/v1/admin/updateLocation`,
  LINK_ACCOUNT: () => `/v1/admin/linkAccount`,
  CREATE_NEW_CUSTOMER: (locationId) =>
    `/v1/admin/${locationId}/createNewCustomer`,
  GET_CUSTOMER_DETAIL: (customerId) =>
    `/v1/admin/getCustomerById?customerId=${customerId}`,
  DELETE_ROOM: (roomId) => `/v1/admin/deleteRoom/${roomId}`,
  DELETE_CUSTOMER: (customerId) => `/v1/admin/deleteCustomer/${customerId}`,

  // Spacerental Api Urls
  FETCH_AVAILABLE_ROOMS: (offset, limit) =>
    `/v1/spaceRental/getAvailableRooms?limit=${limit || 10}&offset=${
      offset || 0
    }`,
  FETCH_UPCOMING_BOOKINGS: (customerId) =>
    `/v1/spaceRental/getUpcomingBookings?customerId=${customerId}`,
  CHECK_ROOM_AVAILABILITY: (
    startDate,
    endDate,
    locationId,
    roomTypeId,
    includeTimeRange
  ) =>
    `/v1/spaceRental/checkRoomAvailability?locationId=${locationId}&roomTypeId=${roomTypeId}&startDate=${startDate}&endDate=${endDate}&includeTimeRange=${includeTimeRange}`,
  BOOK_ROOM: () => `/v1/spaceRental/confirmBooking`,
  FETCH_ROOM_DETAIL: (startDate, endDate, roomId) =>
    `/v1/spaceRental/getRoomDetail?roomId=${roomId}&startDate=${startDate}&endDate=${endDate}`,

  // Billing Api Urls
  FETCH_PAYMENT_METHODS: (customerId) =>
    `/v1/billing/getAllCards?customerId=${customerId}`,
  ADD_CUSTOMER_NEW_CARD: () => `/v1/billing/addCard`,
  ADD_CUSTOMER_PLAN: () => `/v1/billing/changePlan`,
  UPDATE_CUSTOMER_PLAN: () => `/v1/billing/updatePlan`,

  // Transactions Api Urls
  FETCH_TRANSACTIONS: (startDate, endDate, customerId) =>
    `/v1/transaction/getAll?startDate=${startDate}&endDate=${endDate}&customerId=${customerId}`,
  FETCH_INVOICE_URL: (invoiceId) =>
    `/v1/transaction/downloadInvoice?invoiceId=${invoiceId}`,

  // Chargebee Api Urls
  FETCH_CHARGEBEE_CUSTOMERS: (email) =>
    `/v1/chargebee/customers?email=${email}`,

  // Mock Apis Urls
  FETCH_DIRECTORY: () =>
    CONFIG.MOCK ? "http://dev.firmvo.com/mock/directory-data.json" : ``,
  FETCH_VIRTUAL_NUMBERS: () =>
    CONFIG.MOCK ? "http://dev.firmvo.com/mock/virtual-numbers-data.json" : ``,
  FETCH_USER_FORWARDING_PHONE_NUMBERS: () =>
    CONFIG.MOCK
      ? "http://dev.firmvo.com/mock/forwarding-phone-numbers-data.json"
      : ``,
  VALIDATE_MAIL_ACCOUNT: () => `/v1/admin/validateMailAccount`,
  UPDATE_LOCATION_MAPPING: () => `/v1/admin/updateLocationMapping`,
};
