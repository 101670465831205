import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  FormHelperText,
  CircularProgress,
  Box,
} from "@material-ui/core";
import { useFormik } from "formik";
import {
  apiLinkAccount,
  getAllCustomersMails,
  apiFetchChargebeeCustomers,
} from "../../../../apis";
import {
  setErrorMessage,
  setSuccessMessage,
  getSelectedLocationId,
} from "../../../../store";
import { BaseSearchDropdown } from "../../../shared";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useStyles } from "./styles";
import { debounce } from "lodash";

export default function LinkAccount() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const locationId = useSelector((state) => getSelectedLocationId(state));
  const [search, setSearch] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [customers, setCustomers] = useState([]);
  const [chargebeeCustomers, setChargebeeCustomers] = useState([]);
  const [selected, setSelected] = useState({});
  const [selectedChargebeeCustomer, setChargebeeSelected] = useState({});
  const [loading, setLoading] = useState(false);
  let { push } = useHistory();

  let params = useParams();

  useEffect(() => {
    const fetchCustomerDetail = async () => {
      setLoading(true);
      try {
        const response = await getAllCustomersMails(search, locationId);
        setCustomers(response.customers);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        dispatch(setErrorMessage(error.toString()));
      }
    };

    if (params.customerId && locationId) fetchCustomerDetail();
  }, [dispatch, params.customerId, search, locationId]);

  useEffect(() => {
    const fetchCustomerDetail = async () => {
      setLoading(true);
      try {
        const response = await apiFetchChargebeeCustomers(searchEmail);
        setChargebeeCustomers(response);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        dispatch(setErrorMessage(error.toString()));
      }
    };

    if (params.customerId) fetchCustomerDetail();
  }, [params.customerId, searchEmail]);

  const formik = useFormik({
    initialValues: {
      spheremailCustomerId: selected?.id,
      chargebeeCustomerId: selectedChargebeeCustomer?.id,
      customerId: params.customerId,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await apiLinkAccount(values);
        setLoading(false);
        dispatch(setSuccessMessage("Account linked successfully"));
      } catch (error) {
        setLoading(false);
        dispatch(setErrorMessage(error.toString()));
      }
    },
  });

  const handleCancelClick = () => {
    push("/admin/customers");
  };

  const onInputChange = debounce(function (val) {
    setSearch(val);
  }, 1000);

  const onChargebeeInputChange = debounce(function (val) {
    setSearchEmail(val);
  }, 1000);

  return (
    <section className={classes.section}>
      <form className={classes.root} onSubmit={formik.handleSubmit}>
        <Typography className={classes.heading} variant="h3">
          Spheremail Account Details
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Typography variant="h5" className={classes.label}>
              Customer
            </Typography>
            <BaseSearchDropdown
              id="state-search-dropdown"
              options={customers}
              value={selected}
              onChange={setSelected}
              onInputChange={onInputChange}
              label="user_name"
              className={classes.locationSelector}
            />
            <FormHelperText error={Boolean(formik.errors.login)}>
              {formik.errors.login}
            </FormHelperText>
          </Grid>
        </Grid>
        <Typography className={classes.heading} variant="h3">
          Chargebee Details
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Typography variant="h5" className={classes.label}>
              Chargebee Customer
            </Typography>
            <BaseSearchDropdown
              id="state-search-dropdown"
              options={chargebeeCustomers}
              value={selectedChargebeeCustomer}
              onChange={setChargebeeSelected}
              onInputChange={onChargebeeInputChange}
              label="first_name"
              className={classes.locationSelector}
            />
            <FormHelperText error={Boolean(formik.errors.chargebeeCustomerId)}>
              {formik.errors.chargebeeCustomerId}
            </FormHelperText>
          </Grid>
        </Grid>
        {loading && (
          <Box className={classes.loader}>
            <CircularProgress size="1.9rem" />
          </Box>
        )}
        <Grid className={classes.btnContainer} container spacing={3}>
          <Grid item xs={1}>
            <Button
              className={clsx(classes.footerButton, classes.footerPrimaryBtn)}
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
            >
              Update
            </Button>
          </Grid>
          <Grid item xs={1}>
            <Button
              className={classes.footerButton}
              variant="outlined"
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </section>
  );
}
