import React, { useState } from "react";
import {
  Modal,
  Fade,
  Backdrop,
  Button,
  Typography,
  OutlinedInput,
  IconButton,
  MenuItem,
  Box,
  Avatar,
  Link,
  FormHelperText
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import { isEmpty } from "lodash";
import { useFormik } from "formik";
import * as yup from "yup";
import S3 from "react-aws-s3";
import {
  closeModal,
  getUserTypes,
  getSelectedLocationId,
  addUpdateCTMembersRecord,
  setErrorMessage,
  setSuccessMessage,
  getCustomerId,
} from "../../../store";
import { ModalType } from "../../../core";
import { AWSCONFIG } from "../../../config";
import clsx from "clsx";
import { useStyles } from "./AddUpdateTeamMembers.style";
import { HighlightOff as HighlightOffIcon } from "@material-ui/icons";
import { BaseDropdown } from "../../shared";
import { apiAddCTMembers, apiUpdateCTMembers } from "../../../apis";
import ImageIcon from "../../../assets/images/imageIcon.jpg";
import { userHasEditPermission } from "../../../constants/constants";
const ReactS3Client = new S3(AWSCONFIG);

const validationSchema = yup.object({
  fullName: yup.string().required("Required"),
  title: yup.string().required("Required"),
  userEmail: yup.string().required("Required").email("Please enter a valid email address"),
  userTypeId: yup
    .number()
    .moreThan(0, "Select a type of user")
    .required("Required"),
});

export default function AddUpdateTeamMembers() {
  const initialValues = {
    fullName: "",
    title: "",
    userEmail: "",
    phone: "",
    userTypeId: 0,
    imageUrl: "",
    id: 0,
  };
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const userTypes = getUserTypes(state);
  const customerId = getCustomerId(state);
  const modal = state.ui.modal[ModalType.AddUpdateTeamMembers] || false;
  const selected = state.account.teamMember.selected;
  const userType = state.auth.data.user_type;
  const isNew = isEmpty(selected);
  const successMessage = isNew ? "Team member added" : "Team member updated";
  const [profilePic, setProfilePic] = useState({});
  const [file, setFile] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length) {
        const file = acceptedFiles[0];
        setFile(file);
        setProfilePic({ ...file, preview: URL.createObjectURL(file) });
      }
    },
  });

  const handleClose = () => {
    formik.resetForm();
    setIsLoading(false);
    setProfilePic({});
    setFile({});
    dispatch(closeModal(ModalType.AddUpdateTeamMembers));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: isNew ? initialValues : selected,
    validationSchema,
    onSubmit: async (values) => {
      const { id } = values;
      const finishLoading = () => formik.setSubmitting(false);
      try {
        let imageUrl = "";
        formik.setFieldError("imageUrl", '');
        setIsLoading(true);
        if (!isEmpty(file)) {
          const s3Response = await ReactS3Client.uploadFile(
            file,
            `${values.user_email?.replace(/[^\w\s]/gi, "")}_${Math.random()}`
          );
          imageUrl = s3Response.location;
        }
        if (!imageUrl && (!selected || !selected.imageUrl)) {
          formik.setFieldError("imageUrl", 'Please select a image');
          setIsLoading(false);
          return
        }
        const request = {
          ...values,
          locationId: getSelectedLocationId(state),
          customerId: customerId,
          imageUrl: imageUrl ? imageUrl : selected && selected.imageUrl ? selected.imageUrl : '',
        };
        const res = id
          ? await apiUpdateCTMembers(request)
          : await apiAddCTMembers(request);
        dispatch(setSuccessMessage(successMessage));
        dispatch(addUpdateCTMembersRecord(res));
        handleClose();
      } catch (error) {
        setIsLoading(false);
        dispatch(setErrorMessage(error.toString()));
      }
      finishLoading();
    },
  });

  return (
    <Modal
      aria-labelledby="add-update-team-members-modal-title"
      aria-describedby="add-update-team-members-modal-description"
      open={modal}
      onClose={handleClose}
      closeAfterTransition
      disableEnforceFocus
      BackdropComponent={Backdrop}
      className={classes.modal}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modal}>
        <form
          onSubmit={formik.handleSubmit}
          autoComplete="off"
          className={classes.paper}
        >
          <div className={classes.modalHeader}>
            <Typography variant="h6" className={classes.modalHeading}>
              {isNew ? "New team member" : selected?.name || selected?.fullName}
            </Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <HighlightOffIcon />
            </IconButton>
          </div>
          <div className={classes.modalBody}>
            <Box
              {...getRootProps({ className: "dropzone" })}
              className={classes.imageContainer}
            >
              <input {...getInputProps()} />
              <Avatar
                alt="img icon"
                src={
                  profilePic && profilePic.preview ? profilePic.preview : selected?.imageUrl ? selected.imageUrl : ImageIcon
                }
                className={classes.large}
              />
              {userHasEditPermission(userType) && <Link
                component="button"
                type="button"
                variant="inherit"
                className={classes.boxLink}
                onClick={open}
              >
                {(profilePic.preview || selected?.imageUrl) ? "Upload new image" : "Upload an image"}
              </Link>}
            </Box>
            <FormHelperText
              error={Boolean(formik.errors.imageUrl)}
            >
              {formik.errors.imageUrl}
            </FormHelperText>
            <Typography variant="h5" className={classes.modalSubhead}>
              Full name
            </Typography>
            <OutlinedInput
              type="text"
              fullWidth
              name="fullName"
              value={formik.values.fullName}
              onChange={formik.handleChange}
              error={formik.touched.fullName && Boolean(formik.errors.fullName)}
            />
            <FormHelperText
              error={formik.touched.fullName && Boolean(formik.errors.fullName)}
            >
              {formik.touched.fullName && formik.errors.fullName}
            </FormHelperText>
            <Typography className={classes.modalSubhead} variant="h5">
              Title
            </Typography>
            <OutlinedInput
              type="text"
              fullWidth
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
              error={formik.touched.title && Boolean(formik.errors.title)}
            />
            <FormHelperText
              error={formik.touched.title && Boolean(formik.errors.title)}
            >
              {formik.touched.title && formik.errors.title}
            </FormHelperText>
            <Typography className={classes.modalSubhead} variant="h5">
              Email
            </Typography>
            <OutlinedInput
              type="text"
              fullWidth
              name="userEmail"
              value={formik.values.userEmail}
              onChange={formik.handleChange}
              error={
                formik.touched.userEmail && Boolean(formik.errors.userEmail)
              }
            />
            <FormHelperText
              error={
                formik.touched.userEmail && Boolean(formik.errors.userEmail)
              }
            >
              {formik.touched.userEmail && formik.errors.userEmail}
            </FormHelperText>
            <Typography className={classes.modalSubhead} variant="h5">
              Type of user
            </Typography>
            <BaseDropdown
              name="userTypeId"
              value={formik.values.userTypeId}
              onChange={formik.handleChange}
              error={
                formik.touched.userTypeId && Boolean(formik.errors.userTypeId)
              }
              disabled={!userHasEditPermission(userType)}
            >
              <MenuItem value={0} key={0}>
                Select user type
              </MenuItem>
              {userTypes.map((userType) => (
                <MenuItem value={userType.id} key={userType.id}>
                  {userType.name}
                </MenuItem>
              ))}
            </BaseDropdown>
            <FormHelperText
              error={
                formik.touched.userTypeId && Boolean(formik.errors.userTypeId)
              }
            >
              {formik.touched.userTypeId && formik.errors.userTypeId}
            </FormHelperText>
          </div>
          <div className={classes.modalFooter}>
            {isNew && <Button
              className={clsx(classes.footerButton, classes.footerPrimaryBtn)}
              disabled={formik.isSubmitting || isLoading}
              variant="contained"
              color="primary"
              type="submit"
            >
              Add
            </Button>}
            {(!isNew && userHasEditPermission(userType)) &&
              <Button
                className={clsx(classes.footerButton, classes.footerPrimaryBtn)}
                disabled={formik.isSubmitting || isLoading}
                variant="contained"
                color="primary"
                type="submit"
              >
                Save changes
              </Button>}
            {userHasEditPermission(userType) && <Button
              className={classes.footerButton}
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>}
          </div>
        </form>
      </Fade>
    </Modal >
  );
}
