import { MailStatus } from "../../../core";
import { newColumns, pendingColumns, processedColumns } from "./columns";

export default function SelectedColumn(
  width,
  activeTab,
  handleMailAction,
  handleCancelRequest
) {
  const columns = {
    [MailStatus.New]: newColumns(width, handleMailAction),
    [MailStatus.Pending]: pendingColumns(width, handleCancelRequest),
    [MailStatus.Processed]: processedColumns(width),
  };
  return columns[activeTab];
}
