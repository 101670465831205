import React, { Fragment } from "react";
import { Box,Container } from "@material-ui/core";
import { CheckAvailability } from "./check-availability";
import { UpcomingBookings } from "./upcoming-bookings";
import { AvailableRooms } from "./available-rooms";
import { useStyles } from "./SpaceRental.style";

export default function SpaceRental() {
  const classes = useStyles();
  
  return (
    <Container style={{maxWidth:1111}}>
      <Box className={classes.columnWrap}>
        <Box className={classes.column}>
          <CheckAvailability />
        </Box>
        <Box className={classes.column}>
          <UpcomingBookings />
        </Box>
      </Box>
      <Box className={classes.availableRoomContainer}>
        <AvailableRooms />
      </Box>
    </Container>
  );
}
