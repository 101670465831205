import { http, handleResponse } from "../core";
import { URL } from "./_urls.js";

const apiFetchMasterData = async () => {
  return handleResponse(await http.get(URL.LOAD_MASTER_DATA()));
};

const apiFetchCountries = async () => {
  return handleResponse(await http.get(URL.LOAD_COUNTRIES_DATA()));
};

const apiFetchCities = async (stateName) => {
  return handleResponse(await http.get(URL.LOAD_CITIES_DATA(stateName)));
};

export {
  apiFetchMasterData,
  apiFetchCountries,
  apiFetchCities,
};
