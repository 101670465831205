import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
   loader:{
    display:"flex",
    justifyContent:"center",
    paddingTop:"20px"
   }
}));

export { useStyles };
