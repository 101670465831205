export const props = {
    MuiButtonBase: {
        disableRipple: true
    },
    MuiOutlinedInput: {
        root: {
            notched: false
        }
    },
    MuiTypography: {
        variantMapping: {
            subtitle1: 'p',
            subtitle2: 'p',
        },
    }
};
