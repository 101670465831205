import { SPACERENTAL } from "../../../actions";

const loadingReducer = (state = false, action) => {
  switch (action.type) {
    case SPACERENTAL.SET_FILTERED_ROOMS:
      return false;
    case SPACERENTAL.CHECK_AVAIABLITY_INIT:
      return true;
    default:
      return state;
  }
};

export { loadingReducer };
