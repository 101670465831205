import React, { useEffect } from "react";
import findIndex from "lodash/findIndex";
import { Box, IconButton, Typography, Badge } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import {
  ArrowLeft as ArrowLeftIcon,
  ArrowRight as ArrowRightIcon,
} from "@material-ui/icons";
import {
  getLoginCustomerId,
  getSelectedLocationId,
  setCustomerLocations,
  setCustomerMailCounts,
  setErrorMessage,
  setSelectedCustomerLocation,
} from "../../../../store";
import { useStyles } from "./Locations.style";
import { apiFetchCustomerLocations, getAllNewMails } from "../../../../apis";
import { isUserSuperAdmin } from "../../../../constants/constants";

export default function Locations() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const customerId = useSelector((state) => getLoginCustomerId(state));
  const locationId = useSelector((state) => getSelectedLocationId(state));
  const mailAccountId = useSelector((state) => state.auth.data.mail_account_id);
  const locations = useSelector((state) => state.customer.locations);
  const isSuperAdmin = useSelector((state) =>
    isUserSuperAdmin(state.auth.data.user_type)
  );
  const { data, selected, mailCount } = locations;

  useEffect(() => {
    const loadCustomerLocations = async () => {
      const response = await apiFetchCustomerLocations(customerId);
      const selected = response.length ? response[0] : {};
      dispatch(setCustomerLocations(response));
      dispatch(setSelectedCustomerLocation(selected));
    };
    if (customerId) loadCustomerLocations();
  }, [customerId, dispatch]);

  useEffect(() => {
    const loadMailItems = async () => {
      try {
        const { mail_items, total } = await getAllNewMails(
          "new",
          "",
          1,
          1,
          mailAccountId,
          locationId
        );
        if (mail_items) {
          dispatch(setCustomerMailCounts(total));
        }
      } catch (error) {
        dispatch(setErrorMessage(error.toString()));
      }
    };
    if ((isSuperAdmin || mailAccountId) && locationId) loadMailItems();
  }, [dispatch, customerId, mailAccountId, locationId, isSuperAdmin]);

  const currentIndex = findIndex(data, selected);

  const handleNextClick = () => {
    if (data?.length > currentIndex + 1) {
      dispatch(setSelectedCustomerLocation(data[currentIndex + 1]));
    }
  };

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      dispatch(setSelectedCustomerLocation(data[currentIndex - 1]));
    }
  };
  return (
    <Box className={classes.stepperDiv}>
      {isSuperAdmin && (
        <IconButton onClick={handlePrevClick}>
          <ArrowLeftIcon fontSize="large" />
        </IconButton>
      )}
      <Typography>{selected?.name}</Typography>
      <Badge
        badgeContent={mailCount?.new || 0}
        color="primary"
        className={classes.badge}
      ></Badge>
      {isSuperAdmin && (
        <IconButton onClick={handleNextClick}>
          <ArrowRightIcon fontSize="large" />
        </IconButton>
      )}
    </Box>
  );
}
