import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Pagination as MaterialPagination } from "@material-ui/lab";
import { setMMCurrentPage } from "../../../store";

export default function Pagination() {
  const dispatch = useDispatch();
  const mail = useSelector((state) => state.mail);
  const { totalPages, currentPage } = mail;
  const handlePageChange = (page) => {
    dispatch(setMMCurrentPage(page));
  };
  return (
    <MaterialPagination
      count={totalPages}
      page={currentPage}
      onChange={(_, page) => handlePageChange(page)}
      showFirstButton
      showLastButton
    />
  );
}
