import React, { useState } from "react";
import { useFormik, FormikProvider } from "formik";
import {
  Grid,
  Typography,
  OutlinedInput,
  Button,
  FormHelperText,
  MenuItem,
} from "@material-ui/core";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { BaseDropdown, CountryInput, StateInput } from "../../../shared";
import { apiCreateNewCustomer } from "../../../../apis";
import {
  getSelectedLocationId,
  setErrorMessage,
  setSuccessMessage,
} from "../../../../store";
import { useStyles } from "./styles";
import PhoneInput from "react-phone-input-2";
import * as yup from "yup";

export default function AddCustomer() {
  const dispatch = useDispatch();
  const initialValues = {
    location: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    companyName: "",
    userName: "",
    country: "",
    state: "",
    city: "",
    zip: "",
  };

  const validationSchema = yup.object({
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    email: yup
      .string()
      .required("Required")
      .email("Please enter a valid email address"),
    phone: yup.string().required("Required"),
    address: yup.string().required("Required"),
    city: yup.string().required("Required"),
    state: yup.string().required("Required"),
    companyName: yup.string().required("Required"),
    userName: yup
      .string()
      .min(6, "Username is too short (minimum is 6 characters)")
      .required("Required"),
    country: yup.string().required("Required"),
    zip: yup.string().required("Required"),
  });
  const classes = useStyles();
  let { push } = useHistory();
  const [loading, setLoading] = useState(false);
  const locations = useSelector((state) => state.customer.locations.data || []);
  const selectedLocationId = useSelector((state) =>
    getSelectedLocationId(state)
  );

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        await apiCreateNewCustomer(selectedLocationId, {
          ...values,
          location: formik.values.location
            ? formik.values.location
            : selectedLocationId,
        });
        dispatch(setSuccessMessage("Customer added successfully"));
        setLoading(false);
        push("/admin/customers");
      } catch (error) {
        if (error?.toString()?.includes("Error422") !== -1) {
          let errorString = error.toString();
          let errors = errorString.split(",");
          const userNameError = errors[1];
          const emailError = errors[2];
          const zipCodeError = error[3];
          if (userNameError) formik.setFieldError("userName", userNameError);
          if (emailError) formik.setFieldError("email", emailError);
          if (zipCodeError) formik.setFieldError("zip", zipCodeError);
        } else dispatch(setErrorMessage(error.toString()));
        setLoading(false);
      }
    },
  });

  const handleCancelClick = () => {
    push("/admin/customers");
  };

  const handleCountryChange = (country) => {
    formik.setFieldValue("country", country?.id);
    formik.setFieldValue("state", "");
  };

  const handleStateChange = (state) => {
    formik.setFieldValue("state", state?.id);
  };

  const handlePhoneChange = (phone) => {
    formik.setFieldValue("phone", phone);
  };

  return (
    <section className={classes.section}>
      <FormikProvider value={formik}>
        <form className={classes.root} onSubmit={formik.handleSubmit}>
          <Typography className={classes.heading} variant="h3">
            Basic Details
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Typography variant="h5" className={classes.label}>
                Location
              </Typography>
              <BaseDropdown
                name="location"
                value={formik.values.location}
                onChange={formik.handleChange}
                error={
                  formik.touched.location && Boolean(formik.errors.location)
                }
              >
                {locations.map((location) => (
                  <MenuItem value={location.id} key={location.id}>
                    {location.name}
                  </MenuItem>
                ))}
              </BaseDropdown>
              <FormHelperText
                className={classes.error}
                error={
                  formik.touched.location && Boolean(formik.errors.location)
                }
              >
                {formik.touched.location && formik.errors.location}
              </FormHelperText>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Typography variant="h5" className={classes.label}>
                First Name
              </Typography>
              <OutlinedInput
                type="text"
                fullWidth
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.firstName)}
              />
              <FormHelperText
                className={classes.error}
                error={Boolean(formik.errors.firstName)}
              >
                {formik.touched.firstName && formik.errors.firstName}
              </FormHelperText>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h5" className={classes.label}>
                Last Name
              </Typography>
              <OutlinedInput
                type="text"
                fullWidth
                value={formik.values.lastName}
                name="lastName"
                onChange={formik.handleChange}
                error={Boolean(formik.errors.lastName)}
              />
              <FormHelperText
                className={classes.error}
                error={Boolean(formik.errors.lastName)}
              >
                {formik.touched.lastName && formik.errors.lastName}
              </FormHelperText>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Typography variant="h5" className={classes.label}>
                Email
              </Typography>
              <OutlinedInput
                type="text"
                fullWidth
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.email)}
              />
              <FormHelperText
                className={classes.error}
                error={Boolean(formik.errors.email)}
              >
                {formik.errors.email}
              </FormHelperText>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h5" className={classes.label}>
                Phone
              </Typography>
              <PhoneInput
                value={formik.values.phone}
                onChange={(phone) => handlePhoneChange(phone)}
                inputStyle={{
                  width: "inherit",
                  border: "1px solid #cde0f1",
                  height: "40px",
                }}
              />
              <FormHelperText
                className={classes.error}
                error={Boolean(formik.errors.phone)}
              >
                {formik.errors.phone}
              </FormHelperText>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Typography variant="h5" className={classes.label}>
                Address
              </Typography>
              <OutlinedInput
                type="text"
                fullWidth
                name="address"
                value={formik.values.address}
                onChange={formik.handleChange}
                rows={2}
                error={Boolean(formik.errors.address)}
                multiline
              />
              <FormHelperText
                className={classes.error}
                error={Boolean(formik.errors.address)}
              >
                {formik.errors.address}
              </FormHelperText>
            </Grid>
          </Grid>
          <Typography className={classes.heading} variant="h3">
            Spheremail Details
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Typography variant="h5" className={classes.label}>
                Company Name
              </Typography>
              <OutlinedInput
                type="text"
                fullWidth
                name="companyName"
                value={formik.values.companyName}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.companyName)}
              />
              <FormHelperText
                className={classes.error}
                error={Boolean(formik.errors.companyName)}
              >
                {formik.errors.companyName}
              </FormHelperText>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h5" className={classes.label}>
                Username
              </Typography>
              <OutlinedInput
                type="text"
                fullWidth
                name="userName"
                value={formik.values.userName}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.userName)}
              />
              <FormHelperText
                className={classes.error}
                error={Boolean(formik.errors.userName)}
              >
                {formik.errors.userName}
              </FormHelperText>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Typography variant="h5" className={classes.label}>
                Country
              </Typography>
              <CountryInput
                value={formik.values.country}
                onChange={handleCountryChange}
                error={Boolean(formik.errors.country)}
              />
              <FormHelperText
                className={classes.error}
                error={Boolean(formik.errors.country)}
              >
                {formik.errors.country}
              </FormHelperText>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h5" className={classes.label}>
                State/province
              </Typography>
              <StateInput
                country={formik.values.country}
                value={formik.values.state}
                onChange={handleStateChange}
                error={Boolean(formik.errors.state)}
              />
              <FormHelperText
                className={classes.error}
                error={Boolean(formik.errors.state)}
              >
                {formik.touched.state && formik.errors.state}
              </FormHelperText>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Typography variant="h5" className={classes.label}>
                City
              </Typography>
              <OutlinedInput
                type="text"
                fullWidth
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.city)}
              />
              <FormHelperText
                className={classes.error}
                error={Boolean(formik.errors.city)}
              >
                {formik.errors.city}
              </FormHelperText>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h5" className={classes.label}>
                Zipcode
              </Typography>
              <OutlinedInput
                type="text"
                fullWidth
                name="zip"
                value={formik.values.zip}
                onChange={formik.handleChange}
                error={formik.touched.zip && Boolean(formik.errors.zip)}
              />
              <FormHelperText
                className={classes.error}
                error={formik.touched.zip && Boolean(formik.errors.zip)}
              >
                {formik.touched.zip && formik.errors.zip}
              </FormHelperText>
            </Grid>
          </Grid>

          <Grid className={classes.btnContainer} container spacing={3}>
            <Grid item xs={1}>
              <Button
                className={clsx(classes.footerButton, classes.footerPrimaryBtn)}
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                Add
              </Button>
            </Grid>
            <Grid item xs={1}>
              <Button
                className={classes.footerButton}
                variant="outlined"
                onClick={handleCancelClick}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </section>
  );
}
