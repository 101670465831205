export const CONFIG = {
  MOCK: true,
  API_URL: process.env.REACT_APP_API_URL,
};

export const AWSCONFIG = {
  bucketName: "dev-firmvo",
  dirName: "user-files",
  region: "us-east-1",
  accessKeyId: "AKIAQF4HS2HVVGZ2PTVL",
  secretAccessKey: "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",
  s3Url: "https://dev-firmvo.s3.amazonaws.com",
};

export const AWSLOCATIONCONFIG = {
  bucketName: "dev-firmvo",
  dirName: "locations",
  region: "us-east-1",
  accessKeyId: "AKIAQF4HS2HVVGZ2PTVL",
  secretAccessKey: "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",
  s3Url: "https://dev-firmvo.s3.amazonaws.com",
};

export const AWSROOMSCONFIG = {
  bucketName: "dev-firmvo",
  dirName: "rooms",
  region: "us-east-1",
  accessKeyId: "AKIAQF4HS2HVVGZ2PTVL",
  secretAccessKey: "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",
  s3Url: "https://dev-firmvo.s3.amazonaws.com",
};
