import React, { useEffect } from "react";
import { Box, Typography, Link, CircularProgress } from "@material-ui/core";
import clsx from "clsx";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";
import {
  openModal,
  setErrorMessage,
  setCustomerPlanServices,
  setCustomerPlanInfo,
  setSelectedCustomer,
  setCustomerDetailsdAdmin,
  setSuccessMessage,
} from "../../../store";
import { ModalType } from "../../../core";
import { apiUpdateCPServices, apiGetCustomerPlanServices } from "../../../apis";
import { QuickEditInput } from "../../shared";
import { useStyles } from "./CustomerCurrentPlan.style";
import { useState } from "react";

export default function CustomerCurrentPlan() {
  const classes = useStyles();
  const dispatch = useDispatch();
  let params = useParams();
  const customer = useSelector((state) => state.admin.customer);
  const { planServices, planInfo } = customer;
  const [isLoading, setIsLoading] = useState(false);
  const handleChangePlan = () => {
    dispatch(openModal(ModalType.ChangeCustomersPlan));
  };

  useEffect(() => {
    async function fetchAPI() {
      setIsLoading(true);
      try {
        const res = await apiGetCustomerPlanServices(params.customerId);
        const { planInfo, planServices, details } = res;
        setIsLoading(false);
        dispatch(setCustomerPlanServices(planServices));
        dispatch(setCustomerPlanInfo(planInfo || {}));
        dispatch(setSelectedCustomer(details));
        dispatch(setCustomerDetailsdAdmin(details));
      }
      catch (error) {
        dispatch(setErrorMessage(error));
        setIsLoading(false);
      }
    }
    if (params.customerId) fetchAPI();
  }, [dispatch, params.customerId]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { planServices },
    onSubmit: async (values) => {
      try {
        const { field, planServices } = values;
        const [_, index] = field.split(".");
        const row = planServices[index];
        setIsLoading(true);
        await apiUpdateCPServices(row);
        setIsLoading(false);
        dispatch(setCustomerPlanServices(planServices));
        dispatch(setSuccessMessage("Customer Service updated"));
      } catch (error) {
        dispatch(setErrorMessage(error.toString()));
        setIsLoading(false);
      }
    },
  });

  const handleEditClick = (field) => {
    formik.setFieldValue("field", field);
  };

  return (
    <Box component="div" className={classes.box}>
      <Box className={classes.boxHeader}>
        <Typography variant="h6" className={classes.boxHeading}>
          Customers Current plan
        </Typography>
        <Link
          component="button"
          variant="inherit"
          color="inherit"
          className={classes.boxLink}
          onClick={handleChangePlan}
        >
          Change
        </Link>
      </Box>
      <Box className={classes.imgSection}>
        {planInfo?.planId ? (
          <Box className={classes.imgSectionContent}>
            <Box>
              <Typography className={classes.firmIdentity}>
                {planInfo?.planName}
              </Typography>
              <Typography>{`Until - ${moment(planInfo?.nextBillingDate).format(
                "DD MMM YYYY"
              )}`}</Typography>
            </Box>
            <Box>
              <Typography variant="h4" className={classes.boxPrice}>
                {`$${planInfo?.planPrice}`}
              </Typography>
              <Typography>{planInfo?.periodUnit}</Typography>
            </Box>
          </Box>
        ) :
          (<Box className={classes.imgSectionContent}>
            <Typography className={classes.firmIdentity}>No current plan</Typography>
          </Box>)}
      </Box>
      {isLoading && <Box className={classes.loader}>
        <CircularProgress size="1.9rem" /></Box>}
      {!!formik.values.planServices.length && (
        <Box className={classes.boxContent}>
          <Box className={clsx(classes.cardSection, classes.spacing)}>
            <Box className={classes.cardSectionHeader}>
              <Typography variant="subtitle1">Service</Typography>
              <Typography variant="subtitle1">Default</Typography>
              <Typography variant="subtitle1">Override</Typography>
            </Box>
            <Box>
              {formik.values.planServices.map((plan, index) => (
                <Box
                  key={plan?.id}
                  className={clsx({
                    [classes.cardSectionContent]: true,
                    [classes.highlightRow]: index % 2,
                  })}
                >
                  <Typography variant="subtitle1">
                    {plan?.serviceName}
                  </Typography>
                  <Typography variant="body1">{plan?.serviceQty}</Typography>
                  <QuickEditInput
                    name={`planServices.${index}.customQty`}
                    value={formik.values.planServices[index].customQty}
                    valueProps={{ color: "error" }}
                    className={classes.actionContainer}
                    handleEditClick={handleEditClick}
                    onSave={formik.handleSubmit}
                    onChange={formik.handleChange}
                    isEditable={true}
                    type="number"
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
