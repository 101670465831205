import { ADMIN } from "../../../actions";

const planInfoDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN.CLEAR_ADMIN:
      return {};
    case ADMIN.SET_CUSTOMER_PLAN_INFO_DETAIL:
      return action.planInfoDetail;
    default:
      return state;
  }
};

export { planInfoDetailReducer };
