import React, { useState } from 'react'
import { Modal, Fade, Box, Backdrop, Typography, Link, FormControlLabel, Checkbox, Button } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { closeModal } from '../../../store';
import { ModalType } from '../../../core';
import IconButton from '@material-ui/core/IconButton';
import { useStyles } from './ManageYourAvailability.style';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Add as AddIcon } from '@material-ui/icons';
import { AvailabilitySectionType } from '../../../core';
import { GeneralHours } from './general-hours';
import { DailyAvailability } from './daily-availability';
import { SpecialDates } from './special-dates';

export default function ManageYourAvailability() {
    const state = useSelector(state => state);
    const classes = useStyles();
    const dispatch = useDispatch();
    const modal = state.ui.modal[ModalType.ManageYourAvailability] || false;
    const [activeSectionType, setActiveSectionType] = useState(AvailabilitySectionType.GeneralHours);
    const handleClose = () => {
        dispatch(closeModal(ModalType.ManageYourAvailability));
    }
    return (
        <Modal
            aria-labelledby="manage-your-availability-modal-title"
            aria-describedby="manage-your-availability-modal-description"
            open={modal}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            className={classes.modal}
            BackdropProps={{
                timeout: 500
            }}
        >
            <Fade in={modal}>
                <div className={classes.paper}>
                    <div className={classes.modalHeader}>
                        <Typography variant="h6" className={classes.modalHeading}>
                            Your availability
                        </Typography>
                        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                            <HighlightOffIcon />
                        </IconButton>
                    </div>
                    <div className={classes.modalBody}>
                        {/* General hours */}
                        <FormControlLabel
                            control={<Checkbox color="primary" name="checkedA" />}
                            label="General hours"
                            checked={activeSectionType === AvailabilitySectionType.GeneralHours}
                            onChange={() => setActiveSectionType(AvailabilitySectionType.GeneralHours)}
                        />
                        {activeSectionType === AvailabilitySectionType.GeneralHours && <GeneralHours />}

                        {/* Daily Availability */}
                        <FormControlLabel
                            control={<Checkbox color="primary" name="checkedA" />}
                            label="Daily Availability"
                            className={classes.checkbox}
                            checked={activeSectionType === AvailabilitySectionType.DailyAvailability}
                            onChange={() => setActiveSectionType(AvailabilitySectionType.DailyAvailability)}
                        />
                        {activeSectionType === AvailabilitySectionType.DailyAvailability && <DailyAvailability />}

                        {/* Special Dates */}
                        <div className={classes.specialDates}>
                            <div className={classes.specialTitle}>
                                <FormControlLabel
                                    control={<Checkbox color="primary" name="checkedA" />}
                                    label="Special Dates"
                                    className={classes.checkbox}
                                    checked={activeSectionType === AvailabilitySectionType.SpecialDates}
                                    onChange={() => setActiveSectionType(AvailabilitySectionType.SpecialDates)}
                                />
                                {activeSectionType === AvailabilitySectionType.SpecialDates && <Link
                                    component="button"
                                    variant="inherit"
                                    className={classes.addSpecialDates}
                                >
                                    <AddIcon className={classes.AddIcon} />
                                    Add New
                                </Link>}
                            </div>
                            {activeSectionType === AvailabilitySectionType.SpecialDates && <SpecialDates />}

                        </div>
                    </div>
                    <div className={classes.modalFooter}>
                        <Button className={clsx(classes.footerButton, classes.footerPrimaryBtn)} variant="contained" color="primary">
                            Save Availibilty
                        </Button>
                        <Button className={classes.footerButton} variant="outlined" onClick={handleClose}>Cancel</Button>
                    </div>
                </div>
            </Fade>
        </Modal>
    )
}
