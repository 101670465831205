import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        width: 600,
        background: '#fff',
        position: 'relative',
        maxHeight: "95vh",
        overflow: "auto"
    },
    modalHeader: {
        padding: '10px 15px',
        background: "#F1F9FE",
        borderBottom: "1px solid #CDE0F1",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    modalHeading: {
        fontWeight: "500",
        fontSize: "15px",
        lineHeight: "18px",
        color: "#242223",
        fontFamily: 'Roboto-Medium'
    },
    closeButton: {
        color: theme.palette.grey[500],
        padding: 0
    },
    modalBody: {
        padding: '20px 20px',

    },
    modalFooter: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "15px 15px"
    },
    footerButton: {
        flex: "1",
        maxWidth: "48%",
        border: "2px solid #2CA4C0",
        fontWeight: "bold",
        padding: "6px 15px"
    },
    footerPrimaryBtn: {
        backgroundColor: "#2CA4C0",
        color: "#fff"
    },
    modalSubhead: {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "18px",
        color: "#242223",
        fontFamily: 'Roboto-Medium',
        margin: '10px 0 5px'
    },
    inputRow: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    inputContent: {
        width: '45%'
    }
}));


export { useStyles }