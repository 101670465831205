import { combineReducers } from "redux";
import { dataReducer } from "./data";
import { selectedReducer } from "./selected";
import { detailReducer } from "./detail";

const bookingsReducer = combineReducers({
  data: dataReducer,
  selected: selectedReducer,
  detail: detailReducer,
});

export { bookingsReducer };
