import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  noRowOverlay: {
    top: "40px",
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    position: "absolute",
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(250, 250, 250, 0.38)",
    flexDirection: "column",
  },
  section: {
    padding: 20,
  },
  addButtonContainer: {
    paddingBottom: 20,
    justifyContent: "flex-end",
    display: "flex",
  },
}));

export { useStyles };
