import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    subHeader: {
        backgroundColor: theme.palette.secondary.main,
        height: '58px',
        width: '100%',
        display: 'flex',
        color: theme.palette.common.white,
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6)
    },
    main: {
        padding: "33px 24px",
    },
    boxLink: {
        fontSize: "14px",
        lineHeight: "16px",
        color: theme.palette.primary.main
    },
    boxButton: {
        display: 'flex',
        background: "#102F51",
        padding: "15px",
        fontSize: "15px",
        color: "#8EEAFF",
        border: "1px solid #CDE0F1",
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between'
    },
    boxButtonIcon: {
        width: "16px"
    }
}));


export { useStyles }