import { MYACCOUNT } from "../../../actions";

const mailCountReducer = (state = {}, action) => {
    switch (action.type) {
        case MYACCOUNT.CLEAR_ACCOUNT:
            return {};
        case MYACCOUNT.SET_CUSTOMER_MAILS_COUNT:
            return action.count;
        default:
            return state;
    }
};

export { mailCountReducer };
