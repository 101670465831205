import React, { useEffect, useState } from "react";
import {
  Modal,
  Fade,
  Backdrop,
  Typography,
  Radio,
  Button,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControlLabel,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { useParams } from "react-router-dom";
import {
  closeModal,
  getCustomerId,
  setCustomerPlanServices,
  setCustomerPlanInfo,
  setCustomerDetailsdAdmin,
  setErrorMessage,
  setPlans,
  openModal,
  setCustomerPlanInfoDetail,
  getSelectedLocationId,
} from "../../../store";
import { ModalType } from "../../../core";
import { apiAddNewPlan, apiFetchPlans, apiUpdatePlan } from "../../../apis";
import clsx from "clsx";
import { useStyles } from "./ChangeCustomersPlan.style";
import IconButton from "@material-ui/core/IconButton";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import { HighlightOff as HighlightOffIcon } from "@material-ui/icons";

export default function ChangeCustomersPlan() {
  let params = useParams();
  const modal = useSelector(
    (state) => state.ui.modal[ModalType.ChangeCustomersPlan] || false
  );
  const { planInfo, plans } = useSelector((state) => state.admin.customer);
  const customerId = useSelector((state) =>
    params.customerId ? params.customerId : getCustomerId(state)
  );
  const selectedLocationId = useSelector((state) =>
    getSelectedLocationId(state)
  );
  const [selectedPlan, setSelectedPlan] = useState({});
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const classes = useStyles();
  const handleClose = () => {
    setSelectedPlan({});
    dispatch(closeModal(ModalType.ChangeCustomersPlan));
  };

  const onViewClick = (planDetail) => {
    dispatch(setCustomerPlanInfoDetail(planDetail));
    dispatch(openModal(ModalType.PlanDetailsModal));
  };

  useEffect(() => {
    const loadAllPlans = async () => {
      try {
        const plans = await apiFetchPlans(selectedLocationId);
        dispatch(setPlans(plans));
      } catch (error) {
        dispatch(setErrorMessage(error.toString()));
      }
    };
    if (selectedLocationId) loadAllPlans();
  }, [dispatch, selectedLocationId]);

  const handleChangePlan = async () => {
    let response;
    if (!isEmpty(selectedPlan)) {
      try {
        setLoading(true);
        response = planInfo.planId
          ? await apiUpdatePlan({
              chargebeePlanId: selectedPlan.id,
              customerId,
            })
          : await apiAddNewPlan({
              chargebeePlanId: selectedPlan.id,
              customerId,
            });
        setLoading(false);
        dispatch(setCustomerPlanServices(response.planServices));
        dispatch(setCustomerPlanInfo(response.planInfo || {}));
        dispatch(setCustomerDetailsdAdmin(response.details));
        handleClose();
      } catch (error) {
        setLoading(false);
        dispatch(setErrorMessage(error.toString()));
      }
    }
  };

  return (
    <Modal
      aria-labelledby="buy-minutes-modal-title"
      aria-describedby="buy-minutes-modal-description"
      open={modal}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      className={classes.modal}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modal}>
        <div className={classes.paper}>
          <div className={classes.modalHeader}>
            <Typography variant="h6" className={classes.modalHeading}>
              Change customers plan
            </Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <HighlightOffIcon />
            </IconButton>
          </div>
          <div className={classes.modalBody}>
            <div className={classes.chooseAvailable}>
              <Typography variant="h5" className={classes.modalContentTitle}>
                Choose a plan:
              </Typography>
              <TableContainer
                className={classes.tableContainer}
                component={Paper}
              >
                <Table className={classes.table} aria-label="simple table">
                  <TableHead className={classes.tableThead}>
                    <TableRow>
                      <TableCell
                        className={clsx(
                          classes.tableTh,
                          classes.checkboxTableCell
                        )}
                      ></TableCell>
                      <TableCell className={classes.tableTh}>Plan</TableCell>
                      <TableCell className={classes.tableTh}>
                        Monthly Price
                      </TableCell>
                      <TableCell className={classes.tableTh}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {plans.map((plan) => (
                      <TableRow
                        className={clsx(
                          classes.tableRow,
                          plan.id === planInfo.planId && classes.selectedRow
                        )}
                        key={plan.id}
                      >
                        <TableCell
                          scope="row"
                          className={clsx(classes.checkboxTableCell)}
                        >
                          <FormControlLabel
                            classes={{
                              root: classes.formControlRoot,
                            }}
                            control={
                              <Radio
                                color="primary"
                                name="checkedA"
                                className={classes.checkbox}
                                onChange={() => setSelectedPlan(plan)}
                                checked={
                                  plan.id === selectedPlan.id ||
                                  plan.id === planInfo.planId
                                }
                              />
                            }
                          />
                        </TableCell>
                        <TableCell
                          className={clsx(
                            classes.tableTd,
                            plan.id === planInfo.planId &&
                              classes.selectedRowText
                          )}
                          scope="row"
                        >
                          <RemoveRedEyeIcon
                            className={classes.viewIcon}
                            onClick={() => onViewClick(plan)}
                          ></RemoveRedEyeIcon>
                          {plan.planName}
                        </TableCell>
                        <TableCell
                          className={clsx(
                            classes.tableTd,
                            plan.id === planInfo.planId &&
                              classes.selectedRowText
                          )}
                        >{`$${plan.price}/${plan.periodUnit}`}</TableCell>
                        <TableCell
                          align="right"
                          className={clsx(
                            classes.tableTd,
                            plan.id !== planInfo.planId &&
                              classes.tableTdCancel,
                            plan.id === planInfo.planId &&
                              classes.tableTdChangePlan
                          )}
                        >
                          {/* {plan.id === planInfo.planId &&
                            `Expires - ${moment(
                              planInfo?.nextBillingDate
                            ).format("DD MMM YYYY")}`} */}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box className={classes.total}>
                <Typography variant="h5" className={classes.totalText}>
                  TOTAL
                </Typography>
                <Typography variant="h5" className={classes.totalPrice}>
                  {selectedPlan.price && `$${selectedPlan.price}`}
                </Typography>
              </Box>
            </div>
          </div>
          <div className={classes.modalFooter}>
            <div className={classes.modalFooterRight}>
              <Button
                className={clsx(classes.footerButton, classes.footerPrimaryBtn)}
                variant="contained"
                color="primary"
                onClick={handleChangePlan}
                disabled={isLoading}
              >
                CHANGE PLAN
              </Button>
              <Button
                className={classes.footerButton}
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
