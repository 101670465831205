import axios from "axios";
import { CONFIG } from "../config";
import { withAuthHeader } from "./funcs";
const httpClient = axios.create({
  baseURL: CONFIG.API_URL,
});

httpClient.interceptors.request.use(
  function (config) {
    config.headers = withAuthHeader({});
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

httpClient.interceptors.response.use(
  async function (response) {
    return response;
  },
  async function (error) {
    return Promise.resolve(error.response);
  }
);

export default httpClient;
