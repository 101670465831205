import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    select: {
        padding: "8px 8px",
        border: "2px solid #CDE0F1",
        // paddingRight: 35,
        display: "flex",
        alignItems: "center",
        // flexWrap: "wrap",
        minHeight: '40px',
        justifyContent : 'space-between'
    },
    selectDropdown: {
        background: "#F1F9FE",
        padding: '5px 8px',
        fontSize: 12,
        lineHeight: "18px",
        display: "inline-flex",
        alignItems: "center",
        marginLeft: 10
    },
    crossIconLeft: {
        marginLeft: 5,
        width: 14
    },
    crossIconRight: {
        marginRight: 5,
        width: 14
    },
    downIcon: {
        position: "relative",
        // right: 15,
        // width: 'inherit'
    },
    tag: {
        marginTop: 3,
        height: 20,
        padding: '.15em 4px',
        fontWeight: 600,
        lineHeight: '15px',
        borderRadius: 2,
    },
    root: {
        width: '95%',
        fontSize: 13,
        display: 'flex',
        justifyContent: 'center',
        margin: '8px'
    },
    activeContainer: {
        border: `2px solid ${theme.palette.primary.main} !important`
    },
    popper: {
        border: `2px solid ${theme.palette.primary.main}`,
        boxShadow: '0 3px 12px rgba(27,31,35,.15)',
        width: 300,
        zIndex: theme.zIndex.modal + 1,
        fontSize: 13,
        color: '#586069',
        backgroundColor: '#f6f8fa',
    },
    header: {
        borderBottom: '1px solid #e1e4e8',
        padding: '8px 10px',
        fontWeight: 600,
    },
    listbox: {
        padding: 0,
    },
    paper: {
        boxShadow: 'none',
        margin: 0,
        color: '#586069',
        fontSize: 13,
        borderRadius: 0
    },
    option: {
        minHeight: '40px',
        alignItems: 'center',
        padding: '0px 8px',
        fontSize : '14px',
        '&[aria-selected="true"]': {
            backgroundColor: '#F1F9FE',
        },
        '&[data-focus="true"]': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    popperDisablePortal: {
        position: 'relative',
    },
    inputRoot: {
        '&[class*="MuiOutlinedInput-root"]': {
            padding: 0
        }
    },
    popupIndicatorOpen: {
        transform: 'rotate(0deg)'
    },
    optionPopper: {
        width: '100% !important'
    },
    iconSelected: {
        width: 17,
        height: 17,
        marginRight: 5,
        marginLeft: -2,
    },
    color: {
        width: 14,
        height: 14,
        flexShrink: 0,
        borderRadius: 3,
        marginRight: 8,
        marginTop: 2,
    },
    text: {
        flexGrow: 1,
    },
    close: {
        opacity: 0.6,
        width: 18,
        height: 18,
    },
}));

export { useStyles }