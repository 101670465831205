import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    box: {
        background: "#FFFFFF",
        border: "1px solid #CDE0F1",
        marginTop: '30px'
    },
    boxRow: {
        borderBottom: "1px solid #DFDFE4",
    },
    noBorder: {
        border: "none"
    },
    boxHeader: {
        display: 'flex',
        background: "#F1F9FE",
        borderBottom: "1px solid #CDE0F1",
        boxSizing: "border- box",
        padding: "15px",
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    boxHeading: {
        fontWeight: "500",
        fontSize: "15px",
        lineHeight: "18px",
        color: "#242223",
        fontFamily: 'Roboto-Medium'
    },
    boxLink: {
        fontSize: "14px",
        lineHeight: "16px",
        color: theme.palette.primary.main
    },
    boxContent: {
        display: 'flex',
        fontSize: "14px",
        color: "#494949",
        letterSpacing: "0.01em",
    },
    boxContentRight: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        flexWrap: 'wrap'
    },
    boxContentHeading: {
        marginBottom: "5px",
        color: "#242223",
        fontWeight: "500",
        fontSize: "15px",
        lineHeight: "18px",
        fontFamily: 'Roboto-Medium'
    },
    boxContentDataRow: {
        background: '#FFFFFF',
        display: 'flex',
        marginTop: "0",
        padding: "10px 15px",
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
    },
    evenBoxContentDataRow: {
        background: '#F7F6F7',
        display: 'flex',
        marginTop: "0",
        padding: "10px 15px",
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
    },
    boxContentLink: {
        marginTop: "15px",
        fontSize: "14px",
        lineHeight: "16px",
        color: theme.palette.primary.main,
        display: 'flex',
        alignItems: 'center',
    },
    AddIcon: {
        width: "14px",
        marginRight: "5px"
    },
    iconButton: {
        height: '14px',
        width: '14px'
    },
    boxContentDataRowEdit: {
        color: theme.palette.primary.main,
        width: "14px",
    },
    teamRow: {
        display: 'flex',
        alignItems: 'center'
    },
    teamRowContent: {
        marginLeft: '20px'
    },
    mailIcon: {
        marginRight: '10px'
    }
}));


export { useStyles }