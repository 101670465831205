import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Layout } from "./Layout";
import {
  SignIn,
  SignUp,
  ForgetPassword,
  ChangePasswordThroughLink,
} from "../components";
import { GuestRoute } from "./GuestRoute";
import { PrivateRoute } from "./PrivateRoute";
import { apiFetchMasterData } from "../apis";
import { setMasterData } from "../store";

const Routes = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    async function fetchAPI() {
      const res = await apiFetchMasterData();
      dispatch(setMasterData(res));
    }
    fetchAPI();
  }, [dispatch]);
  return (
    <Switch>
      <GuestRoute path="/sign-in" exact component={SignIn} />
      <GuestRoute path="/sign-up" exact component={SignUp} />
      <GuestRoute path="/forget-password" exact component={ForgetPassword} />
      <GuestRoute
        path="/change-password"
        exact
        component={ChangePasswordThroughLink}
      />
      <PrivateRoute path="/" component={Layout} />
    </Switch>
  );
};

export default Routes;
