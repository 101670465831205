export const ModalType = {
  BuyPhoneNumber: "BuyPhoneNumber",
  AddUpdateDirectory: "AddUpdateDirectory",
  BuyAdditionalService: "BuyAdditionalService",
  ManageYourAvailability: "manageYourAvailability",
  ManageAnsweringScript: "ManageAnsweringScript",
  AddNoteAgent: "AddNoteAgent",
  BuyMinutes: "BuyMinutes",
  ChangePassword: "ChangePassword",
  AddForwardingNumber: "AddForwardingNumber",
  AddForwardingAddress: "AddForwardingAddress",
  EditCompanyAddress: "EditCompanyAddress",
  AddUpdateTeamMembers: "AddUpdateTeamMembers",
  ManagePaymentInformation: "ManagePaymentInformation",
  ChangeCustomersPlan: "ChangeCustomersPlan",
  EditVirtualPhoneNumber: "EditVirtualPhoneNumber",
  MailRequestForwardModal: "MailRequestForwardModal",
  MailRequestHoldModal: "MailRequestHoldModal",
  MailRequestPhotoModal: "MailRequestPhotoModal",
  MailRequestScanModal: "MailRequestScanModal",
  MailScanPreviewModal: "MailScanPreviewModal",
  ShredMailModal: "ShredMailModal",
  MailSuccessActionModal: "MailSuccessActionModal",
  MailJunkModal: "MailJunkModal",
  CancelMailRequestModal: "CancelMailRequestModal",
  RoomAvailabilityResultModal: "RoomAvailabilityResultModal",
  RoomBookingSuccessModal: "RoomBookingSuccessModal",
  PlanDetailsModal: "PlanDetailsModal",
  ImageSliderModal: "ImageSliderModal"
};

export const AvailabilitySectionType = {
  GeneralHours: "generalHours",
  DailyAvailability: "dailyAvailability",
  SpecialDates: "specialDates",
};

export const OperatingMode = {
  New: "new",
  Edit: "edit",
};

export const UIActionType = {
  User: "user",
  Admin: "admin",
};

export const ScreenHeading = {
  "/": "Welcome",
  "/mail-management": "Mail Management",
  "/space-rentals": "Space Rental",
  "/phone-services": "Phone Services",
  "/payments": "Payments",
  "/reports": "Reports",
  "/admin/dashboard": "Admin Internal",
  "/admin/manage": "Manage Customer",
  "/my-account": "My Account",
  "/admin/customers": "Customers",
  "/admin/customers/add": "Add Customer",
  "/admin/rooms": "Rooms",
  "/admin/rooms/add": "Add Room",
  "/admin/link-account/": "Link Account",
  "/admin/locations": "Locations",
  "/admin/locations/add": "Add Location",
  "/front-desk": "Front Desk",
  "/admin/support": "Support",
  "/admin/map-location": "Map Location"
};

export const MailStatus = {
  New: "new",
  Pending: "pending",
  Processed: "processed",
};

export const MailAction = {
  Junk: "junk",
  Picture: "picture",
  PickUp: "pick_up",
  MarkPickUp: "mark_as_pick_up",
  Scan: "scan",
  Shred: "shred",
  Forward: "forward",
  New: "new",
};

export const ServiceType = {
  Mail: "mail",
  Phone: "phone",
  Room: "room",
};

export const ServiceMetaKey = {
  email: "email",
  Usps: "usps",
  Fedex: "fedex",
  InternationalForward: "internationalForward",
  Scans: "scans",
  Photo: "photo",
  PhoneNumber: "phoneNumber",
  LiveAnswering: "liveAnswering",
  EFax: "eFax",
  SmallRoom: "smallRoom",
  LargeRoom: "largeRoom",
  RoomDiscount: "roomDiscount",
  CreditOtherLocation: "creditOtherLocation",
};

export const UserRoles = {
  Admin: 'admin',
  Regular_User: 'regular user',
  Editor: 'editor',
  Viewer: 'viewer',
  SuperAdmin: 'super admin'
}
