import { combineReducers } from "redux";
import { directoryReducer } from "./directory";
import { virtualPhoneNumbersReducer } from "./virtual-phone-number";

const liveAnsweringServiceReducer = combineReducers({
    directory: directoryReducer,
    virtualPhoneNumbers: virtualPhoneNumbersReducer
});

export { liveAnsweringServiceReducer };
