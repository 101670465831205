import { SPACERENTAL } from "./action-types";

const clearSpaceRental = () => ({
  type: SPACERENTAL.CLEAR,
});

const resetAvailableRooms = () => ({
  type: SPACERENTAL.RESET_AVAILABLE_ROOMS,
});

const resetSpaceRentalFilter = () => ({
  type: SPACERENTAL.RESET_AVAILABLE_ROOMS_FILTER,
});

// const loadAvailableRooms = () => ({
//   type: SPACERENTAL.LOAD_AVAILABLE_ROOMS,
// });

const setAvailableRooms = (data) => ({
  type: SPACERENTAL.SET_AVAILABLE_ROOMS,
  data,
});

const setAvailableRoomsFilter = (filter) => ({
  type: SPACERENTAL.SET_AVAILABLE_ROOMS_FILTER,
  filter,
});

const setSelectedAvailableRoom = (selected) => ({
  type: SPACERENTAL.SET_ACTIVE_AVAILABLE_ROOM,
  selected,
});

const setSelectedUpcomingBooking = (selected) => ({
  type: SPACERENTAL.SET_ACTIVE_UPCOMING_BOOKINGS,
  selected,
});

// const loadUpcomingBookings = () => ({
//   type: SPACERENTAL.LOAD_UPCOMING_BOOKINGS,
// });

const setUpcomingBookings = (data) => ({
  type: SPACERENTAL.SET_UPCOMING_BOOKINGS,
  data,
});

// const roomBookingInit = () => ({
//   type: SPACERENTAL.ROOM_BOOKING_INIT,
// });

// const loadFilteredRoom = () => ({
//   type: SPACERENTAL.LOAD_FILTERED_ROOM,
// });

const setFilteredRooms = (data) => ({
  type: SPACERENTAL.SET_FILTERED_ROOMS,
  data,
});

const setBookingDetail = (detail) => ({
  type: SPACERENTAL.SET_BOOKING_DETAIL,
  detail,
});

const clearActiveAvailableRoom = () => ({
  type: SPACERENTAL.RESET_ACTIVE_AVAILABLE_ROOM,
});

const checkAvailabilityInit = () => ({
  type: SPACERENTAL.CHECK_AVAIABLITY_INIT,
})

export {
  clearSpaceRental,
  resetAvailableRooms,
  resetSpaceRentalFilter,
  setAvailableRooms,
  setAvailableRoomsFilter,
  setSelectedAvailableRoom,
  setSelectedUpcomingBooking,
  setUpcomingBookings,
  // loadUpcomingBookings,
  // loadAvailableRooms,
  // roomBookingInit,
  // loadFilteredRoom,
  setFilteredRooms,
  setBookingDetail,
  clearActiveAvailableRoom,
  checkAvailabilityInit
};
