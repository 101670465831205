import React from "react";
import { Grid } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { openModal } from "../../../store";
import { ModalType } from "../../../core";
import MoveToJunkIcon from "../../../assets/icons/moveToJunkIcon";
import RequestForwardingIcon from "../../../assets/icons/requestForwardingIcon";
import RequestScanIcon from "../../../assets/icons/requestScanIcon";
import ShredMailIcon from "../../../assets/icons/shredMailIcon";
import HoldForPickupIcon from "../../../assets/icons/holdForPickupIcon";
import RequestPhotoIcon from "../../../assets/icons/requestPhotoIcon";
import { userHasEditPermission } from "../../../constants/constants";

export default function MailActions() {
  const mail = useSelector((state) => state.mail);
  const dispatch = useDispatch();
  const { selectedRowIds } = mail;
  const isActive = Boolean(selectedRowIds?.length);
  const userType = useSelector((state) => state.auth.data.user_type);

  const handleActionClick = (modal) => dispatch(openModal(modal));

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      sm={12}
      spacing={3}
      className="mailActionsGrid"
    >
      <Grid item sm={4} md={4} lg={2} xs={12} className="mailActionsButtonGrid">
        <button
          className={`formButton ${isActive ? "activeFormButton" : ""}`}
          disabled={!isActive || !userHasEditPermission(userType)}
          onClick={() => handleActionClick(ModalType.MailJunkModal)}
        >
          <MoveToJunkIcon {...(isActive ? { className: "activeDelete" } : {})} />
          Move to junk
        </button>
      </Grid>
      <Grid item sm={4} md={4} lg={2} xs={12} className="mailActionsButtonGrid">
        <button
          className={`formButton ${isActive ? "activeFormButton" : ""}`}
          disabled={!isActive || !userHasEditPermission(userType)}
          onClick={() => handleActionClick(ModalType.MailRequestForwardModal)}
        >
          <RequestForwardingIcon
            {...(isActive ? { className: "active" } : {})}
          />
          Request forwarding
        </button>
      </Grid>
      <Grid item sm={4} md={4} lg={2} xs={12} className="mailActionsButtonGrid">
        <button
          className={`formButton ${isActive ? "activeFormButton" : ""}`}
             disabled={!isActive || !userHasEditPermission(userType)}
          onClick={() => handleActionClick(ModalType.MailRequestScanModal)}
        >
          <RequestScanIcon {...(isActive ? { className: "active" } : {})} />
          Request a scan
        </button>
      </Grid>
      <Grid item sm={4} md={4} lg={2} xs={12} className="mailActionsButtonGrid">
        <button
          className={`formButton ${isActive ? "activeFormButton" : ""}`}
             disabled={!isActive || !userHasEditPermission(userType)}
          onClick={() => handleActionClick(ModalType.ShredMailModal)}
        >
          <ShredMailIcon {...(isActive ? { className: "active" } : {})} />
          Shred the mail
        </button>
      </Grid>
      <Grid item sm={4} md={4} lg={2} xs={12} className="mailActionsButtonGrid">
        <button
          className={`formButton ${isActive ? "activeFormButton" : ""}`}
             disabled={!isActive || !userHasEditPermission(userType)}
          onClick={() => handleActionClick(ModalType.MailRequestHoldModal)}
        >
          <HoldForPickupIcon {...(isActive ? { className: "active" } : {})} />
          Hold for pickup
        </button>
      </Grid>
      <Grid item sm={4} md={4} lg={2} xs={12} className="mailActionsButtonGrid">
        <button
          className={`formButton ${isActive ? "activeFormButton" : ""}`}
             disabled={!isActive || !userHasEditPermission(userType)}
          onClick={() => handleActionClick(ModalType.MailRequestPhotoModal)}
        >
          <RequestPhotoIcon {...(isActive ? { className: "active" } : {})} />
          Request a photo
        </button>
      </Grid>
    </Grid>
  );
}
