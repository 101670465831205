import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  section: {
    padding: "20px 20px 0px 20px",
    height: "calc(100vh - 70px)",
    flexGrow: 1,
    overflowY: "auto",
  },
  box: {
    background: "#FFFFFF",
    border: "1px solid #CDE0F1",
  },
  main: {
    padding: "33px 24px",
  },
  label: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#242223",
    fontFamily: "Roboto-Medium",
    paddingBottom: "10px",
  },
  footerButton: {
    flex: "1",
    maxWidth: "48%",
    border: "2px solid #2CA4C0",
    padding: "6px 15px",
    fontFamily: "Roboto-Medium",
  },
  footerPrimaryBtn: {
    backgroundColor: "#2CA4C0",
    color: "#fff",
  },
  btnContainer: {
    marginTop: 30,
  },
  loginInput: {
    height: "48px",
  },
  root: {
    padding: 20,
    background: "white",
  },
}));
export { useStyles };
