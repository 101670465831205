import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  reportsContainer: {
    marginTop: "50px",
    marginLeft: "10px",
  },
  mailGraphDiv: {
    position: "relative",
    height: "440px",
    background: "#FFFFFF",
    border: "1px solid #CDE0F1",
    boxSizing: "border-box",
  },
  mailGraphHeader: {
    background: "#F1F9FE",
    border: "1px solid #CDE0F1",
    boxSizing: "border-box",
    height: "50px",
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  mailGraphHeaderText: {
    paddingLeft: "30px",
    fontWeight: "500",
    fontSize: "15px",
    letterSpacing: "0.01em",
    color: "#242223",
    fontFamily: "'Roboto-Medium'",
  },
  graphInnerDiv: {
    position: "absolute",
    width: "68.77px",
    height: "68.77px",
    background: "#FFFFFF",
    boxShadow: "0px 10px 20px rgb(0 0 0 / 5%)",
    borderRadius: "37px",
    marginLeft: "40%",
    marginTop: "25%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  callingGraphInnerDiv: {
    position: "absolute",
    width: "68.77px",
    height: "68.77px",
    background: "#FFFFFF",
    boxShadow: "0px 10px 20px rgb(0 0 0 / 5%)",
    borderRadius: "37px",
    marginLeft: "39%",
    marginTop: "25%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  graphInnerTotal: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    textAlign: "center",
    color: "#242223",
  },
  mailGraphListDiv: {
    height: "26px",
    width: "100%",
    background: "#F2F6FA",
    display: "flex",
    alignItems: "center",
    padding:"4px 8px"
  },
  mailGraphOddListDiv: {
    display: "flex",
    height: "26px",
    width: "100%",
    background: "white",
    alignItems: "center",
    padding:"4px 8px"
  },
  mailGraphOddListValue: {
    fontSize: "14px",
    color: "#242223",
    fontFamily: "'Roboto-Medium'",
  },
  spaceGraphTitle: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    color: "#494949",
  },
  spaceGraphMeterDiv: {
    display: "flex",
    justifyContent: "space-between",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "13px",
    alignItems: "flex-end",
    color: "#494949",
  },
  spaceGraphTeamHeading: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.01em",
    color: "#242223",
  },
  cleHoursDiv: {
    height: "170px",
    background: "white",
    border: "1px solid #CDE0F1",
  },
  clehoursContainer: {
    display: "flex",
    padding: "40px",
  },
  clehourspercentGrid: {
    justifyContent: "flex-start",
    display: "flex",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "28px",
    lineHeight: "25px",
    color: "#2CA4C0",
  },
  clehoursLimitGrid: {
    display: "flex",
    justifyContent: "flex-end",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "25px",
    color: "#494949",
  },
  cleHoursProgress: {
    width: "100%",
  },
  cleHoursWatched: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "26px",
    display: "flex",
    alignItems: "center",
    color: "#494949",
  },
  forwardingMailProgress: {
    width: "100%",
  },
  spaceUsageProgress: {
    width: "100%",
  },
}));

export { useStyles };
