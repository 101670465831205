import { MAILMANAGEMENT } from "../../actions";

const selectedRowReducer = (state = {}, action) => {
  switch (action.type) {
    case MAILMANAGEMENT.CLEAR:
      return {};
    case MAILMANAGEMENT.SET_MAIL_ITEM:
      return action.selectedRow;
    default:
      return state;
  }
};

export { selectedRowReducer };
