import { ADMIN } from "./action-types";

const setSelectedCustomer = (selected) => ({
  type: ADMIN.SET_SELECTED_CUSTOMER,
  selected,
});

const setCustomerPlanServices = (planServices) => ({
  type: ADMIN.SET_CUSTOMER_PLAN_SERVICES,
  planServices,
});

const setCustomerPlanInfo = (planInfo) => ({
  type: ADMIN.SET_CUSTOMER_PLAN_INFO,
  planInfo,
});

const setCustomerPlanInfoDetail = (planInfoDetail) => ({
  type: ADMIN.SET_CUSTOMER_PLAN_INFO_DETAIL,
  planInfoDetail,
});

const setCustomerDetailsdAdmin = (details) => ({
  type: ADMIN.SET_CUSTOMER_DETAIL_ADMIN,
  details,
});

const setPlans = (plans) => ({
  type: ADMIN.SET_PLANS,
  plans,
});

// const loadPlans = () => ({
//   type: ADMIN.LOAD_PLANS,
// });

// const loadCustomerPlansServices = () => ({
//   type: ADMIN.LOAD_CUSTOMER_PLAN_SERVICES,
// });

const setRooms = (rooms) => ({
  type: ADMIN.SET_ROOMS,
  rooms,
});

// const loadRooms = () => ({
//   type: ADMIN.LOAD_ROOMS,
// });

const setCustomers = (customers) => ({
  type: ADMIN.SET_CUSTOMERS,
  customers,
});

// const loadCustomers = () => ({
//   type: ADMIN.LOAD_CUSTOMERS,
// });

const setLocations = (locations) => ({
  type: ADMIN.SET_LOCATIONS,
  locations,
});

export {
  setSelectedCustomer,
  setCustomerPlanServices,
  setCustomerPlanInfo,
  setCustomerPlanInfoDetail,
  setCustomerDetailsdAdmin,
  setPlans,
  // loadPlans,
  // loadCustomerPlansServices,
  setRooms,
  // loadRooms,
  setCustomers,
  // loadCustomers,
  setLocations,
};
