import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles(() => ({
  mainContainer: {
    display: "flex",
    marginBottom: "20px",
    minHeight: "242px"
  },
  leftSection: {
    width: "20%",
    border: "1px solid #CDE0F1",
    background: "#F1F9FE",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  rightSection: {
    border: "1px solid #CDE0F1",
    width: "80%",
    padding: "20px",
    background: "#fff",
    display: "flex",
    justifyContent: "flex-start",
    gridGap: "10px",
  },
  heading: {
    fontFamily: "Roboto-Bold",
    color: "#242223",
  },
  roomContainer: {
    flexBasis: "33%",
    display: "flex",
    position: "relative",
  },
  backgroundImg: {
    width: "100%",
    height: "200px",
    objectFit: "cover"
  },
  bookingsContentDiv: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    background:
      "linear-gradient(180deg, rgba(38, 64, 92, 0.1) 0%, rgba(36, 62, 90, 0.5) 49.6%, #233D59 100%)",
    opacity: "0.85",
    color: "white",
    padding: "20px",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  link: {
    fontSize: "15px",
    lineHeight: "16px",
    color: "#8EEAFF",
    fontFamily: "Roboto-Medium",
  },
  priceDiv: {
    height: "32px",
    width: "72px",
    background: "linear-gradient(90deg, #409A95 -17.74%, #34CEC6 100%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "flex-end",
  },
  loader: {
    display: "flex",
  justifyContent:"center"
  }
}));

export { useStyles };
