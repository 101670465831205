import { TRANSACTION } from "../../actions";

const dateRangeReducer = (state = {}, action) => {
  switch (action.type) {
    case TRANSACTION.LOAD_ALL_TRANSACTIONS:
      return action.dateRange;
    default:
      return state;
  }
};

export { dateRangeReducer };
