import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  InputLabel,
  Box,
  Grid,
  Typography,
  Button,
  InputAdornment,
  OutlinedInput,
  FormHelperText,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import clsx from "clsx";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { apiSignupUser } from "../../apis";
import { authenticationService } from "../../core";
import { BaseDropdown } from "../shared";
import { setErrorMessage } from "../../store";
import Logo from "../../assets/images/logo.jpg";
import { useStyles } from "./SignUp.style";
import { useState } from "react";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";

const validationSchema = yup.object({
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  email: yup.string().required("Required").email("Enter a valid email address"),
  location: yup.string().required("Required"),
  phone: yup
    .string()
    .required("Required")
    .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, "Enter a valid phone number"),
});

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  location: "",
  phone: "",
};

export default function SignUp() {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const locations = useSelector((state) => state.ui.master.locations || []);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (authenticationService.isAuthenticated()) {
      history.push("/");
    }
  });

  const handleStartNowClick = () => {
    history.push("/");
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const finishLoading = () => formik.setSubmitting(false);
      try {
        setLoading(true);
        await apiSignupUser(values);
        setLoading(false);
        handleStartNowClick();
      } catch (error) {
        dispatch(setErrorMessage(error.toString()));
        setLoading(false);
      }
      finishLoading();
    },
  });

  if (authenticationService.isAuthenticated()) return null;

  return (
    <form
      onSubmit={formik.handleSubmit}
      autoComplete="off"
      className={classes.signup}
    >
      <Grid className={classes.signupLeft}>
        <Grid className={classes.signupLeftContainer}>
          <Box component="div" className={classes.signupLogo}>
            <img className={classes.signupLogoImg} src={Logo} alt="logo" />
          </Box>
          <Box fullWidth className={classes.signupFormWrap}>
            <Typography variant="h4" className={classes.signupLeftH4}>
              Start 15 days free trial!
            </Typography>
            <Typography variant="body1" className={classes.signupLeftBody}>
              Please enter your details
            </Typography>
            <Box component="div" className={classes.signupForm}>
              <Box className={classes.signupFormGroup}>
                <Box component="div" className={classes.signupProfileField}>
                  <InputLabel
                    htmlFor="firstName"
                    className={classes.signupFormLabel}
                  >
                    First Name
                  </InputLabel>
                  <OutlinedInput
                    id="signup-First"
                    type="text"
                    name="firstName"
                    fullWidth
                    classes={{
                      root: classes.signupInput,
                    }}
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                  />
                  <FormHelperText
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                  >
                    {formik.touched.firstName && formik.errors.firstName}
                  </FormHelperText>
                </Box>
                <Box className={classes.signupProfileField}>
                  <InputLabel
                    htmlFor="lastName"
                    className={classes.signupFormLabel}
                  >
                    Last Name
                  </InputLabel>
                  <OutlinedInput
                    id="signup-Last"
                    type="text"
                    name="lastName"
                    fullWidth
                    classes={{
                      root: classes.signupInput,
                    }}
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                  />
                </Box>
              </Box>
              <Box
                className={clsx(
                  classes.signupProfileField,
                  classes.signupEmailField
                )}
              >
                <InputLabel htmlFor="" className={classes.signupFormLabel}>
                  Email
                </InputLabel>
                <OutlinedInput
                  id="signup-Email"
                  type="text"
                  name="email"
                  fullWidth
                  classes={{
                    root: classes.signupInput,
                  }}
                  startAdornment={
                    <InputAdornment
                      position="start"
                      className={classes.startAdornment}
                    >
                      <MailOutlinedIcon
                        style={{ fontSize: 18, color: "#8eeaff" }}
                      />
                    </InputAdornment>
                  }
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                />
                <FormHelperText
                  error={formik.touched.email && Boolean(formik.errors.email)}
                >
                  {formik.touched.email && formik.errors.email}
                </FormHelperText>
              </Box>
              <Box className={classes.signupFormGroup}>
                <Box component="div" className={classes.signupProfileField}>
                  <InputLabel htmlFor="" className={classes.signupFormLabel}>
                    Location
                  </InputLabel>
                  <BaseDropdown
                    name="location"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.startAdornment}
                        >
                          <LocationOnOutlinedIcon
                            style={{ fontSize: 18, color: "#8eeaff" }}
                          />
                        </InputAdornment>
                      ),
                      className: classes.signupInput,
                    }}
                    className={classes.signupInput}
                    value={formik.values.location}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.location && Boolean(formik.errors.location)
                    }
                  >
                    {locations.map((location) => (
                      <MenuItem value={location.id} key={location.id}>
                        {location.name}
                      </MenuItem>
                    ))}
                  </BaseDropdown>
                  <FormHelperText
                    error={
                      formik.touched.location && Boolean(formik.errors.location)
                    }
                  >
                    {formik.touched.location && formik.errors.location}
                  </FormHelperText>
                </Box>
                <Box className={classes.signupProfileField}>
                  <InputLabel htmlFor="" className={classes.signupFormLabel}>
                    Phone number
                  </InputLabel>
                  <OutlinedInput
                    id="signup-Phone"
                    type="text"
                    name="phone"
                    fullWidth
                    classes={{
                      root: classes.signupInput,
                    }}
                    startAdornment={
                      <InputAdornment
                        position="start"
                        className={classes.startAdornment}
                      >
                        <PhoneOutlinedIcon
                          style={{ fontSize: 18, color: "#8eeaff" }}
                        />
                      </InputAdornment>
                    }
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    inputProps={{ maxLength: 15 }}
                  />
                  <FormHelperText
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                  >
                    {formik.touched.phone && formik.errors.phone}
                  </FormHelperText>
                </Box>
              </Box>
            </Box>
          </Box>
          {loading && (
            <Box>
              <CircularProgress size="1.9rem" />
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid className={classes.signupRight}>
        <Box component="div" className={classes.signupRightBgLayer}></Box>
        <Box component="div" className={classes.signupRightBox}>
          <Typography variant="h4" className={classes.signupRightBoxH4}>
            Don't have an account yet? Call us:
          </Typography>
          <Typography variant="h3" className={classes.signupRightBoxH3}>
            (877) 603-1962
          </Typography>
        </Box>
      </Grid>
      <Button
        variant="contained"
        color="inherit"
        type="submit"
        disabled={formik.isSubmitting || loading}
        className={classes.signupStartNow}
      >
        START NOW
      </Button>
    </form>
  );
}
