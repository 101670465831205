import { http, handleResponse } from "../core";
import { URL } from "./_urls.js";

const getAllNewMails = async (
  status,
  search,
  page,
  limit,
  accountId,
  locationId
) => {
  return handleResponse(
    await http.get(
      URL.FETCH_ALL_NEW_MAILS(
        status,
        search,
        page,
        limit,
        accountId,
        locationId
      )
    )
  );
};

const apiMailAction = async (customerId, accountId, locationId, data) => {
  return handleResponse(
    await http.post(
      URL.MAILS_ACTION_URL(customerId, accountId, locationId),
      data
    )
  );
};

const getAllCustomersMails = async (search, locationId) => {
  return handleResponse(
    await http.get(URL.FETCH_ALL_CUSTOMERS_MAILS(search, locationId))
  );
};

const getAllCountriesSpheremail = async (locationId) => {
  return handleResponse(
    await http.get(URL.FETCH_ALL_COUNTRIES_MAILS(locationId))
  );
};

const getCountryDetailSpheremail = async (id, locationId) => {
  return handleResponse(
    await http.get(URL.FETCH_COUNTRY_DETAIL_MAILS(id, locationId))
  );
};

export {
  getAllNewMails,
  apiMailAction,
  getAllCustomersMails,
  getAllCountriesSpheremail,
  getCountryDetailSpheremail,
};
