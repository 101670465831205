import React, { useState } from "react";
import {
  Typography,
  InputLabel,
  Box,
  Link,
  InputAdornment,
  MenuItem,
  ButtonBase,
  Button,
  CircularProgress,
} from "@material-ui/core";
import {
  AccessTimeSharp as AccessTimeOutlinedIcon,
  LocationOnOutlined as LocationOnOutlinedIcon,
  CalendarTodayOutlined as CalendarTodayOutlinedIcon,
  PeopleOutline as PeopleOutlineIcon,
} from "@material-ui/icons";
import { DatePicker } from "@material-ui/pickers";
import { useSelector, useDispatch } from "react-redux";
import { BaseDropdown } from "../../shared";
import { DualToneCheckboxIcon } from "../../../icons";
import {
  openModal,
  setAvailableRoomsFilter,
  setErrorMessage,
  clearSpaceRental,
  setFilteredRooms,
} from "../../../store";
import { useStyles } from "./CheckAvailability.style";
import { ModalType } from "../../../core";
import { apiCheckRoomAvailability } from "../../../apis";
import { getFormatedDate } from "../../../utils";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";

export default function CheckAvailability() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [error, setError] = useState();
  const { ui, spaceRental, customer } = useSelector((state) => state);
  const { roomTypes } = ui.master;
  const locations = customer.locations.data;
  const { filter } = spaceRental.availableRooms;
  const [loading, setLoading] = useState(false);

  const handleCheckavailibility = async () => {
    const { date } = filter;
    if (date) {
      if (filter.fromTime && !filter.endTime) {
        setError("Select end time");
      } else if (!filter.fromTime && filter.endTime) {
        setError("Select from time");
      } else if (
        filter.fromTime &&
        filter.endTime &&
        filter.fromTime > filter.endTime
      )
        setError("End time must be greater then start time");
      else {
        setLoading(true);
        try {
          const { date, fromTime, endTime, locationId, roomTypeId } = filter;
          dispatch(clearSpaceRental());
          const rooms = await apiCheckRoomAvailability(
            new Date(getFormatedDate(date, fromTime && fromTime._d ? fromTime._d : null)).toISOString(),
            new Date(getFormatedDate(date, endTime && endTime._d ? endTime._d : null)).toISOString(),
            locationId || 0,
            roomTypeId || 0,
            !!(fromTime && endTime)
          );
          setLoading(false);
          dispatch(setFilteredRooms(rooms));
          dispatch(openModal(ModalType.RoomAvailabilityResultModal));
        } catch (error) {
          dispatch(setErrorMessage(error.toString()));
          setLoading(false);
        }
      }
    } else {
      setError("Select date");
    }
  };

  const handleFilterChange = (key, value) => {
    setError("");
    dispatch(setAvailableRoomsFilter({ ...filter, [key]: value }));
  };

  return (
    <>
      <Box component="div" className={classes.box}>
        <Box className={classes.boxHeader}>
          <Typography variant="h6" className={classes.boxHeading}>
            Your availibility
          </Typography>
          <Link
            component="button"
            variant="inherit"
            color="inherit"
            className={classes.boxLink}
          >
            View all
          </Link>
        </Box>
        <Box className={classes.boxContent}>
          <Box className={classes.leftSection}>
            <Box className={classes.dateRowContainer}>
              <Box>
                <InputLabel className={classes.labelItem}>
                  Select a date
                </InputLabel>
                <Box className={classes.timeContainer}>
                  <Box className={classes.dateFieldLogo}>
                    <CalendarTodayOutlinedIcon />
                  </Box>
                  <DatePicker
                    fullWidth
                    disablePast
                    value={filter?.date || null}
                    onChange={(value) => {
                      handleFilterChange("date", value);
                    }}
                    className="datepicket-availablity"
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    emptyLabel="mm/dd/yyyy"
                  />
                </Box>
                <Typography variant="body2" color="error">
                  {error}
                </Typography>
              </Box>
              <Box>
                <InputLabel htmlFor="" className={classes.labelItem}>
                  Location
                </InputLabel>
                <BaseDropdown
                  name="locationId"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className={classes.startAdornment}
                      >
                        <LocationOnOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={filter?.locationId || "NA"}
                  onChange={(event) =>
                    handleFilterChange(event.target.name, event.target.value)
                  }
                >
                  <MenuItem value="NA">Select Location</MenuItem>
                  {(locations || []).map((location) => (
                    <MenuItem value={location.id} key={location.id}>
                      {location.name}
                    </MenuItem>
                  ))}
                </BaseDropdown>
              </Box>
            </Box>
            <Box className={classes.timeRowContainer}>
              <Box>
                <Box className={classes.labelContainer}>
                  <InputLabel className={classes.labelItem}>From</InputLabel>
                  <InputLabel className={classes.labelItem}>Till</InputLabel>
                </Box>
                <Box className={classes.timeContainer}>
                  <Box className={classes.dateFieldLogo}>
                    <AccessTimeOutlinedIcon />
                  </Box>
                  {/* <TimePicker
                    fullWidth
                    value={filter?.fromTime || null}
                    onChange={(value) => handleFilterChange("fromTime", value)}
                    className={classes.InputRoot}
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    minutesStep={30}
                  /> */}
                  <TimePicker
                    showSecond={false}
                    defaultValue={filter?.fromTime || null}
                    className={classes.InputRoot}
                    onChange={(value) =>
                      handleFilterChange(
                        "fromTime",
                        value ? value : null
                      )
                    }
                    use12Hours
                    inputReadOnly
                    minuteStep={30}
                  />
                  <TimePicker
                    showSecond={false}
                    defaultValue={filter?.endTime || null}
                    className={classes.InputRoot}
                    onChange={(value) =>
                      handleFilterChange(
                        "endTime",
                        value ? value : null
                      )
                    }
                    use12Hours
                    inputReadOnly
                    minuteStep={30}
                  />
                  {/* <TimePicker
                    fullWidth
                    value={filter?.endTime || null}
                    onChange={(value) => handleFilterChange("endTime", value)}
                    className={classes.InputRoot}
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    minutesStep={30}
                  /> */}
                </Box>
              </Box>
              {/* <RadioGroup className={classes.radioGroup}>
                <FormControlLabel
                  className="availableDetailsSection"
                  control={
                    <Radio
                      value="fullDay"
                      name="price"
                      color="primary"
                      size="small"
                    />
                  }
                  label="Whole day"
                />
                <FormControlLabel
                  className="availableDetailsSection"
                  control={
                    <Radio
                      value="halfDay"
                      name="price"
                      color="primary"
                      size="small"
                    />
                  }
                  label="Half Day"
                />
              </RadioGroup> */}
            </Box>
            <Box className={classes.peopleContainer}>
              <Box>
                <InputLabel className={classes.labelItem}>
                  Number of people
                </InputLabel>
                <Box className={classes.timeContainer}>
                  <Box className={classes.dateFieldLogo}>
                    <PeopleOutlineIcon />
                  </Box>
                  {(roomTypes || []).map((roomType) => (
                    <ButtonBase
                      key={roomType.id}
                      className={
                        filter.roomTypeId === roomType.id
                          ? classes.activePeopleButton
                          : classes.peopleButton
                      }
                      onClick={() =>
                        handleFilterChange("roomTypeId", roomType.id)
                      }
                    >
                      {roomType.name}
                    </ButtonBase>
                  ))}
                </Box>
              </Box>
            </Box>
            {loading && (
              <Box className={classes.loader}>
                <CircularProgress size="1.7rem" />
              </Box>
            )}
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.checkAvailabilityButton}
              onClick={handleCheckavailibility}
              disabled={loading}
            >
              Check availibility
            </Button>
          </Box>
          <Box className={classes.rightSection}>
            <Box className={classes.serviceRow}>
              <Typography variant="h3">All of our rooms include</Typography>
            </Box>
            <Box className={classes.serviceRow}>
              <DualToneCheckboxIcon />
              <Typography variant="body2">Staffed Reception</Typography>
            </Box>
            <Box className={classes.serviceRow}>
              <DualToneCheckboxIcon />
              <Typography variant="body2">Legal Environment</Typography>
            </Box>
            <Box className={classes.serviceRow}>
              <DualToneCheckboxIcon />
              <Typography variant="body2">
                State of the Art Technology
              </Typography>
            </Box>
            <Box className={classes.serviceRow}>
              <DualToneCheckboxIcon />
              <Typography variant="body2">Fresh Coffee</Typography>
            </Box>
            <Box className={classes.serviceRow}>
              <DualToneCheckboxIcon />
              <Typography variant="body2">High Speed WIFI</Typography>
            </Box>
            <Box className={classes.serviceRow}>
              <DualToneCheckboxIcon />
              <Typography variant="body2">City Views</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
