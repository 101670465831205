import React from "react";
import { Typography, Grid, ButtonBase } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { MailStatus } from "../../../../core";
import { setActiveTab } from "../../../../store";
import { useStyles } from "./Tabs.style";

export default function Tabs() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const mail = useSelector((state) => state.mail);
  const { total, activeTab } = mail;

  const handleTabClick = (activeTab) => {
    dispatch(setActiveTab(activeTab));
  };
  return (
    <Grid
      className={classes.subHeadings}
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      sm={6}
      md={4}
    >
      <ButtonBase onClick={() => handleTabClick(MailStatus.New)}>
        <Typography
          className={`navLink ${
            activeTab === MailStatus.New ? "activeTab" : ""
          }`}
        >
          New ({total?.new || 0})
        </Typography>
      </ButtonBase>
      <ButtonBase onClick={() => handleTabClick(MailStatus.Pending)}>
        <Typography
          className={`navLink ${
            activeTab === MailStatus.Pending ? "activeTab" : ""
          }`}
        >
          Pending ({total?.pending || 0})
        </Typography>
      </ButtonBase>
      <ButtonBase onClick={() => handleTabClick(MailStatus.Processed)}>
        <Typography
          className={`navLink ${
            activeTab === MailStatus.Processed ? "activeTab" : ""
          }`}
        >
          Processed ({total?.processed || 0})
        </Typography>
      </ButtonBase>
    </Grid>
  );
}
