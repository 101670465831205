import { TRANSACTION } from "../../actions";

const paymentTransactionReducer = (state = [], action) => {
  switch (action.type) {
    case TRANSACTION.CLEAR_TRANSACTION:
      return [];
    case TRANSACTION.SET_PAYMENT_TRANSACTION:
      return action.data;
    default:
      return state;
  }
};

export { paymentTransactionReducer };
