import React, { useState } from "react";
import {
  Modal,
  Fade,
  Box,
  Backdrop,
  Typography,
  Button,
  OutlinedInput,
  IconButton,
  FormControlLabel,
  Radio,
  FormHelperText
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import { addUpdatePaymentMethod, closeModal, getCustomerId, setErrorMessage, setPaymentMethods, setSelectedPaymentMethod, setSuccessMessage } from "../../../store";
import { ModalType } from "../../../core";
import { apiAddNewCard, apiFetchPaymentMethods } from "../../../apis";
import {
  HighlightOff as HighlightOffIcon,
  Help as HelpIcon,
} from "@material-ui/icons";
import { Formik, Field } from "formik";
import { usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";
import { useStyles } from "./ManagePaymentInformation.style";
import CardBrand from "../../../assets/images/cardBrand.png";
// import Paypal from "../../../assets/images/paypalimage.png";

const paymentModeEnum = {
  Card: "card",
  Paypal: "paypal",
};

export default function ManagePaymentInformation() {
  const state = useSelector((state) => state);
  const classes = useStyles();
  const dispatch = useDispatch();
  const customerId = useSelector((state) => getCustomerId(state));
  const modal = state.ui.modal[ModalType.ManagePaymentInformation] || false;
  const [paymentMode, setPaymentMode] = useState(paymentModeEnum.Card);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    dispatch(closeModal(ModalType.ManagePaymentInformation));
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const response = await apiAddNewCard({ ...data, customerId });
      dispatch(addUpdatePaymentMethod(response));
      setIsLoading(false);
      dispatch(setSuccessMessage('Card added successfully'));
      const methods = await apiFetchPaymentMethods(customerId);
      dispatch(setPaymentMethods(methods));
      dispatch(setSelectedPaymentMethod(methods.length ? methods[0] : {}));
      // dispatch(loadPaymentMethods());
      handleClose();
    } catch (error) {
      setIsLoading(false);
      dispatch(setErrorMessage(error.toString()));
    }
  };

  const {
    meta,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs();

  return (
    <Modal
      aria-labelledby="add-forwarding-address-modal-title"
      aria-describedby="add-forwarding-address-modal-description"
      open={modal}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      className={classes.modal}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modal}>
        <Formik
          initialValues={{
            cardNumber: "",
            expiryDate: "",
            cvc: "",
          }}
          onSubmit={onSubmit}
          validate={(values) => {
            let errors = {};
            if (!values.firstName) {
              errors.firstName = "Enter first name";
            }
            if (!values.lastName) {
              errors.lastName = "Enter last name";
            }
            if (meta.erroredInputs.cardNumber) {
              errors.cardNumber = meta.erroredInputs.cardNumber;
            }
            if (meta.erroredInputs.expiryDate) {
              errors.expiryDate = meta.erroredInputs.expiryDate;
            }
            if (meta.erroredInputs.cvc) {
              errors.cvc = meta.erroredInputs.cvc;
            }
            return errors;
          }}
        >
          {({ handleSubmit, isSubmitting, handleChange, values, errors, touched }) => (
            <form
              onSubmit={handleSubmit}
              autoComplete="off"
              className={classes.paper}
            >
              <Box className={classes.header}>
                <Typography variant="h6" className={classes.modalHeading}>
                  Manage payment information
                </Typography>
                <IconButton
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={handleClose}
                >
                  <HighlightOffIcon />
                </IconButton>
              </Box>
              <Box className={classes.modalBody}>
                <Box className={classes.cardSection}>
                  <Box
                    className={clsx(
                      classes.cardSectionHeader,
                      paymentMode === paymentModeEnum.Card
                        ? classes.activeHeader
                        : ""
                    )}
                  >
                    <FormControlLabel
                      control={<Radio color="primary" name="checkedA" />}
                      label="Credit card / debit card"
                      checked={paymentMode === paymentModeEnum.Card}
                      onChange={() => setPaymentMode(paymentModeEnum.Card)}
                    />
                    <img
                      alt="card brand"
                      src={CardBrand}
                      className={classes.brandImage}
                    />
                  </Box>

                  <Box className={classes.cardSectionContent}>
                    <Box className={classes.inputRow}>
                      <Box className={classes.inputContent}>
                        <Typography
                          variant="h5"
                          className={classes.modalSubhead}
                        >
                          First name
                        </Typography>
                        <OutlinedInput
                          type="text"
                          fullWidth
                          name="firstName"
                          value={values.firstName}
                          onChange={handleChange}
                        />
                        <FormHelperText error={Boolean(errors.firstName)}>
                          {errors.firstName && errors.firstName}
                        </FormHelperText>
                      </Box>
                      <Box className={classes.inputContent}>
                        <Typography
                          variant="h5"
                          className={classes.modalSubhead}
                        >
                          Last name
                        </Typography>
                        <OutlinedInput
                          type="text"
                          fullWidth
                          name="lastName"
                          value={values.lastName}
                          onChange={handleChange}
                        />
                        <FormHelperText error={Boolean(errors.lastName)}>
                          {errors.lastName && errors.lastName}
                        </FormHelperText>
                      </Box>
                    </Box>
                    <Typography variant="h5" className={classes.modalSubhead}>
                      Card number
                    </Typography>
                    <Box className={classes.cardContainer}>
                      <Field name="cardNumber">
                        {({ field }) => (
                          <input
                            type="text"
                            placeholder="xxxx xxxx xxxx xxxx"
                            className={classes.cardNumberField}
                            {...getCardNumberProps({
                              onBlur: field.onBlur,
                              onChange: field.onChange,
                            })}
                          />
                        )}
                      </Field>
                      <Box className={classes.cardIcon}>
                        <svg {...getCardImageProps({ images })} />
                      </Box>
                    </Box>
                    <FormHelperText error={touched.cardNumber && Boolean(errors.cardNumber)}>
                      {touched.cardNumber && errors.cardNumber}
                    </FormHelperText>
                    <Box className={classes.inputRow}>
                      <Box className={classes.inputContent}>
                        <Typography
                          variant="h5"
                          className={classes.modalSubhead}
                        >
                          Expiry date (mm/yy)
                        </Typography>
                        <Field name="expiryDate">
                          {({ field }) => (
                            <input
                              type="text"
                              placeholder="MM/DD"
                              label="Expiry date"
                              inputRef={getExpiryDateProps().ref}
                              className={classes.cbField}
                              {...getExpiryDateProps({
                                onBlur: field.onBlur,
                                onChange: field.onChange,
                              })}
                            />
                          )}
                        </Field>
                        <FormHelperText error={touched.expiryDate && Boolean(errors.expiryDate)}>
                          {touched.expiryDate && errors.expiryDate}
                        </FormHelperText>
                      </Box>
                      <Box className={classes.inputContent}>
                        <Typography
                          variant="h5"
                          className={classes.modalSubhead}
                        >
                          CVC <HelpIcon color="primary" fontSize="inherit" />
                        </Typography>
                        <Field name="cvc">
                          {({ field }) => (
                            <input
                              type="text"
                              placeholder="123"
                              label="CVC"
                              inputRef={getCVCProps().ref}
                              className={classes.cbField}
                              {...getCVCProps({
                                onBlur: field.onBlur,
                                onChange: field.onChange,
                              })}
                            />
                          )}
                        </Field>
                        <FormHelperText error={touched.cvc && Boolean(errors.cvc)}>
                          {touched.cvc && errors.cvc}
                        </FormHelperText>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {/* <Box className={clsx(classes.cardSection, classes.spacing)}>
              <Box
                className={clsx(
                  classes.cardSectionHeader,
                  paymentMode === paymentModeEnum.Paypal
                    ? classes.activeHeader
                    : ""
                )}
              >
                <FormControlLabel
                  control={<Radio color="primary" name="checkedA" />}
                  label="Paypal"
                  checked={paymentMode === paymentModeEnum.Paypal}
                  onChange={() => setPaymentMode(paymentModeEnum.Paypal)}
                />
                <img
                  alt="card brand"
                  src={Paypal}
                  className={classes.brandImage}
                />
              </Box>
            </Box> */}
              </Box>
              <Box className={classes.modalFooter}>
                <Button
                  className={clsx(
                    classes.footerButton,
                    classes.footerPrimaryBtn
                  )}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting || isLoading}
                >
                  Save Changes
                </Button>
                <Button
                  className={classes.footerButton}
                  variant="outlined"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Fade>
    </Modal>
  );
}
