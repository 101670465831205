import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { AutoSizer } from "react-virtualized";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Button, Box,Container, IconButton } from "@material-ui/core";
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  VisibilityOutlined as VisibilityIcon,
  Link as LinkIcon,
  DeleteOutline as DeleteOutlineIcon,
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import {
  setCustomers,
  setSelectedCustomer,
  setCustomerDetailsdAdmin,
  setErrorMessage,
  getSelectedLocationId,
} from "../../../../store";
import { apiDeleteCustomer, apiGetCustomers } from "../../../../apis";
import { useStyles } from "./styles";
import { ConfirmationModal } from "../../../modal-popups";

export const columns = (width, handleActionClick, handleDeleteClick) => [
  {
    field: "customerName",
    headerName: "Customer Name",
    width: width * 0.2,
  },
  {
    field: "customerEmail",
    headerName: "Email",
    width: width * 0.2,
  },
  {
    field: "officeName",
    headerName: "Office Name",
    width: width * 0.2,
  },
  {
    field: "address1",
    headerName: "Address",
    width: width * 0.2,
  },

  {
    field: "id",
    headerName: "Action",
    width: width * 0.2,
    hideSortIcons: true,
    renderCell: (param) => {
      return (
        <Box
          style={{
            display: "flex",
            // justifyContent: "space-between",
            width: "100%",
          }}
        >
          <IconButton
            color="primary"
            onClick={() => handleActionClick(param.row, "view")}
          >
            <VisibilityIcon style={{ height: "20px" }} />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => handleActionClick(param.row, "link")}
          >
            <LinkIcon />
          </IconButton>
          <IconButton
            style={{ marginRight: "20px", color: "red" }}
            onClick={() => handleDeleteClick(param.row)}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Box>
      );
    },
  },
];

const NoRowsOverlay = () => {
  const classes = useStyles();
  return (
    <div className={classes.noRowOverlay}>
      <Typography variant="subtitle1">Nothing here yet</Typography>
      <Typography variant="body2">{`Customers are displayed here. `}</Typography>
    </div>
  );
};

export default function CustomerList() {
  const classes = useStyles();
  const data = useSelector((state) => state.admin.customer.data);
  const selectedLocationId = useSelector((state) =>
    getSelectedLocationId(state)
  );
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  let { push } = useHistory();
  const [open, setIsOpen] = useState(false);
  const [selected, setIsSelected] = useState({});

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        setLoading(true);
        const customers = await apiGetCustomers(selectedLocationId);
        dispatch(setCustomers(customers));
        setLoading(false);
      } catch (error) {
        setLoading(false);
        dispatch(setErrorMessage(error.toString()));
      }
    };
    if (selectedLocationId) fetchCustomers();
  }, [dispatch, selectedLocationId]);

  const handleActionClick = (row, action) => {
    dispatch(setSelectedCustomer(row));
    dispatch(setCustomerDetailsdAdmin(row));
    if (action === "view") push(`/admin/manage-customer/${row.id}`);
    if (action === "link") push(`/admin/link-account/${row.id}`);
  };

  const handleDeleteClick = async (row) => {
    setIsSelected(row);
    setIsOpen(true);
  };

  const onConfirm = async () => {
    setLoading(true);
    try {
      await apiDeleteCustomer(selected.id);
      const customers = await apiGetCustomers(selectedLocationId);
      dispatch(setCustomers(customers));
      setLoading(false);
      setIsOpen(false);
      setIsSelected({});
    } catch (error) {
      dispatch(setErrorMessage(error.toString()));
      setLoading(false);
    }
  };

  const handleAddClick = () => {
    push("/admin/customers/add");
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Container style={{maxWidth:1111}}>
      <section className={classes.section}>
        <div className={classes.addButtonContainer}>
          <Button
            variant="outlined"
            color="primary"
            style={{ height: "30px" }}
            onClick={handleAddClick}
          >
            Add new customer
          </Button>
        </div>
        <div style={{ height: "calc(100vh - 80px)" }}>
          <AutoSizer  style={{width:"100%"}}>
            {({ height, width }) => (
              <div style={{ height, width : "100%", minWidth:"100%" }}>
                <DataGrid
                  headerHeight={40}
                  rowHeight={40}
                  height={height}
                  rows={data}
                  columns={columns(width, handleActionClick, handleDeleteClick)}
                  disableColumnFilter
                  disableColumnMenu
                  disableSelectionOnClick
                  hideFooter
                  loading={isLoading}
                  sortingOrder={["asc", "desc"]}
                  components={{
                    ColumnSortedAscendingIcon: ArrowDropDownIcon,
                    ColumnSortedDescendingIcon: ArrowDropUpIcon,
                    NoRowsOverlay: NoRowsOverlay,
                  }}
                />
              </div>
            )}
          </AutoSizer>
        </div>
        <ConfirmationModal
          id="confirmation-modal"
          keepMounted
          open={open}
          isLoading={isLoading}
          handleClose={handleClose}
          onConfirm={onConfirm}
          title="Are you sure you want to delete ?"
        />
      </section>
    </Container>
  );
}
