import { SPACERENTAL } from "../../../actions";

const dataReducer = (state = [], action) => {
  switch (action.type) {
    case SPACERENTAL.RESET_AVAILABLE_ROOMS:
      return [];
    case SPACERENTAL.SET_AVAILABLE_ROOMS:
      return action.data;
    default:
      return state;
  }
};

export { dataReducer };
