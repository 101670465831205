import { MAILMANAGEMENT } from "../../actions";

const loadingReducer = (state = false, action) => {
  switch (action.type) {
    case MAILMANAGEMENT.CLEAR:
    case MAILMANAGEMENT.SET_MAIL_ITEMS:
    case MAILMANAGEMENT.LOAD_MAIL_ITEMS_FAILED:
      return false;
    case MAILMANAGEMENT.LOAD_MAIL_ITEMS:
    case MAILMANAGEMENT.SET_MAIL_ACTION:
      return true;
    default:
      return state;
  }
};

export { loadingReducer };
