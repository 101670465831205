import { ADMIN } from "../../../actions";

const planServicesReducer = (state = [], action) => {
  switch (action.type) {
    case ADMIN.CLEAR_ADMIN:
      return [];
    case ADMIN.SET_CUSTOMER_PLAN_SERVICES:
      return action.planServices;
    default:
      return state;
  }
};

export { planServicesReducer };
