import { combineReducers } from "redux";
import { dataReducer } from "./data";
import { selectedReducer } from "./selected";
import { filterReducer } from "./filter";
import { resultsReducer } from "./results";
import { loadingReducer } from "./loading";

const availableRoomsReducer = combineReducers({
  data: dataReducer,
  selected: selectedReducer,
  filter: filterReducer,
  results: resultsReducer,
  isLoading: loadingReducer
});

export { availableRoomsReducer };
