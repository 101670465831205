import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    needCustomizePlan: {
        background: '#102F51',
        padding: '20px 15px',
        color: '#fff',
        fontFamily: 'Roboto-Medium',
        marginTop : '20px'
    },
    boxHeading: {
        fontWeight: "500",
        fontSize: "15px",
        lineHeight: "18px",
        color: "#8EEAFF",
        marginBottom: '5px'
    },
    callText: {
        fontWeight: "bold",
        fontSize: "24px",
        lineHeight: "28px",
        letterSpacing: "0.01em"
    }
}));


export { useStyles }