import { MAILMANAGEMENT } from "../../actions";

const totalPagesReducer = (state = 0, action) => {
  switch (action.type) {
    case MAILMANAGEMENT.CLEAR:
      return 0;
    case MAILMANAGEMENT.SET_TOTAL_PAGES:
      return action.totalPages;
    default:
      return state;
  }
};

export { totalPagesReducer };
