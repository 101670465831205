import React from "react";
import {
  Modal,
  Fade,
  Backdrop,
  Typography,
  Checkbox,
  Button,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import {
  HighlightOff as HighlightOffIcon,
  ArrowDropDownOutlined as ArrowDropDownOutlinedIcon,
  ReportProblem as ReportProblemIcon,
} from "@material-ui/icons";
import clsx from "clsx";
import { AreaInput } from "../../../shared";
import { useStyles } from "./buy-phone-number-modal-style";

export default function BuyPhoneNumberModal(props) {
  const classes = useStyles();
  const handleClose = () => {
    props.handleClose();
  };
  return (
    <Modal
      aria-labelledby="buy-phone-number-modal-title"
      aria-describedby="buy-phone-number-modal-description"
      open={props.show}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      className={classes.modal}
      BackdropProps={{
        timeout: 500,
      }}
      disableEnforceFocus
    >
      <Fade in={props.show}>
        <div className={classes.paper}>
          <div className={classes.modalHeader}>
            <Typography variant="h6" className={classes.modalHeading}>
              Buy a Phone Number
            </Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <HighlightOffIcon />
            </IconButton>
          </div>
          <div className={classes.modalBody}>
            <Typography variant="h6" className={classes.modalHeading}>
              Forward to:
            </Typography>
            <AreaInput className={classes.select} />
            <div className={classes.chooseAvailable}>
              <Typography variant="h5" className={classes.modalContentTitle}>
                Choose an Available Number:
              </Typography>
              <TableContainer
                className={classes.tableContainer}
                component={Paper}
              >
                <Table className={classes.table} aria-label="simple table">
                  <TableHead className={classes.tableThead}>
                    <TableRow>
                      <TableCell
                        className={clsx(
                          classes.tableTh,
                          classes.checkboxTableCell
                        )}
                      ></TableCell>
                      <TableCell className={classes.tableTh}>
                        Delivery
                      </TableCell>
                      <TableCell className={classes.tableTh}>Type</TableCell>
                      <TableCell className={classes.tableTh}>
                        Monthly price
                      </TableCell>
                      <TableCell className={classes.tableTh}>
                        This month*
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow className={classes.tableRow}>
                      <TableCell
                        scope="row"
                        className={clsx(classes.checkboxTableCell)}
                      >
                        <Checkbox
                          color="primary"
                          name="checkedA"
                          className={classes.checkbox}
                        />
                      </TableCell>
                      <TableCell
                        className={clsx(classes.deliveryTd, classes.tableTd)}
                        scope="row"
                      >
                        (212) 345-3456
                      </TableCell>
                      <TableCell className={classes.tableTd}>Basic</TableCell>
                      <TableCell className={classes.tableTd}>$16.50</TableCell>
                      <TableCell className={classes.tableTd}>$5.50</TableCell>
                    </TableRow>
                    <TableRow
                      className={clsx(classes.tableRow, classes.tableEvenRow)}
                    >
                      <TableCell
                        scope="row"
                        className={clsx(classes.checkboxTableCell)}
                      >
                        <Checkbox
                          color="primary"
                          name="checkedA"
                          className={classes.checkbox}
                        />
                      </TableCell>
                      <TableCell
                        className={clsx(classes.deliveryTd, classes.tableTd)}
                        scope="row"
                      >
                        (212) 345-3456
                      </TableCell>
                      <TableCell className={classes.tableTd}>Premium</TableCell>
                      <TableCell className={classes.tableTd}>$16.50</TableCell>
                      <TableCell className={classes.tableTd}>$14.50</TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                      <TableCell
                        scope="row"
                        className={clsx(classes.checkboxTableCell)}
                      >
                        <Checkbox
                          color="primary"
                          name="checkedA"
                          className={classes.checkbox}
                        />
                      </TableCell>
                      <TableCell
                        className={clsx(classes.deliveryTd, classes.tableTd)}
                        scope="row"
                      >
                        (212) 345-3456
                      </TableCell>
                      <TableCell className={classes.tableTd}>Premium</TableCell>
                      <TableCell className={classes.tableTd}>$36.50</TableCell>
                      <TableCell className={classes.tableTd}>$15.50</TableCell>
                    </TableRow>
                    <TableRow
                      className={clsx(classes.tableRow, classes.tableEvenRow)}
                    >
                      <TableCell
                        scope="row"
                        className={clsx(classes.checkboxTableCell)}
                      >
                        <Checkbox
                          color="primary"
                          name="checkedA"
                          className={classes.checkbox}
                        />
                      </TableCell>
                      <TableCell
                        className={clsx(classes.deliveryTd, classes.tableTd)}
                        scope="row"
                      >
                        (212) 345-3456
                      </TableCell>
                      <TableCell className={classes.tableTd}>Basic</TableCell>
                      <TableCell className={classes.tableTd}>$16.50</TableCell>
                      <TableCell className={classes.tableTd}>$5.50</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Box className={classes.total}>
                <Typography variant="h5" className={classes.totalText}>
                  TOTAL
                </Typography>
                <Typography variant="h5" className={classes.totalPrice}>
                  $169
                </Typography>
              </Box>
              <Typography className={classes.italicText} variant="body1">
                *15 days left
              </Typography>
            </div>
          </div>
          <div className={classes.modalFooter}>
            <div className={classes.modalFooterLeft}>
              <ReportProblemIcon className={classes.reportProblemIcon} />
              <Typography
                variant="body2"
                className={classes.modalFooterLeftBody}
              >
                Your card will be immediately charged for{" "}
                <b>$20.00 Next month your account will renew for $45.00</b>
              </Typography>
            </div>
            <div className={classes.modalFooterRight}>
              <Button
                className={clsx(classes.footerButton, classes.footerPrimaryBtn)}
                variant="contained"
                color="primary"
              >
                BUY
              </Button>
              <Button
                className={classes.footerButton}
                variant="outlined"
                onClick={props.handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
