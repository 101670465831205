import { combineReducers } from "redux";
import { dataReducer } from "./data";
import { selectedReducer } from "./selected";
import { mailCountReducer } from "./mail-count";
const locationsReducer = combineReducers({
    data: dataReducer,
    selected: selectedReducer,
    mailCount: mailCountReducer
});

export { locationsReducer };
