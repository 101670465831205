import { createSelector } from "reselect";

const customerDetails = (state) => state.admin.customer.details;
const selectedCustomer = (state) => state.admin.customer.selected;

const getCompanyOfficeInfoAdmin = createSelector(
  [customerDetails],
  (customerDetails) => {
    return {
      officeName: customerDetails.officeName || "",
      address1: customerDetails.address1 || "",
      address2: customerDetails.address2 || "",
      city: customerDetails.city || "",
      state: customerDetails.state || "",
      zip: customerDetails.zip || "",
      country: customerDetails.country || "",
    };
  }
);

const getCustomerInfoAdmin = createSelector(
  [customerDetails, selectedCustomer],
  (customerDetails, selected) => {
    return {
      id: selected?.id,
      customerName: customerDetails.customerName || "",
      phone: customerDetails.phone || "",
      mobile: customerDetails.mobile || "",
      customerEmail: customerDetails.customerEmail || "",
      officeName: customerDetails.officeName || "",
      address1: customerDetails.address1 || "",
      address2: customerDetails.address2 || "",
      city: customerDetails.city || "",
      state: customerDetails.state || "",
      zip: customerDetails.zip || "",
      country: customerDetails.country || ""
    };
  }
);

export { getCompanyOfficeInfoAdmin, getCustomerInfoAdmin };
