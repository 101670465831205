import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    box: {
        background: '#F1F9FE',
        padding: '20px 15px',
        fontFamily: 'Roboto-Medium',
        marginTop: '30px',
        border: "1px solid #CDE0F1",
    },
    boxContent: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    boxLink: {
        fontSize: "14px",
        lineHeight: "16px",
        color: theme.palette.primary.main,
    },
    infoMessage: {
        fontFamily: 'Roboto-Italic'
    }
}));


export { useStyles }