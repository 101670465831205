import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    box: {
        background: "#FFFFFF",
        border: "1px solid #CDE0F1",
    },
    boxRow: {
        borderBottom: "1px solid #DFDFE4",
    },
    noBorder: {
        border: "none !important"
    },
    boxLink: {
        fontSize: "14px",
        lineHeight: "16px",
        color: theme.palette.primary.main
    },
    boxHeader: {
        display: 'flex',
        background: "#F1F9FE",
        borderBottom: "1px solid #CDE0F1",
        boxSizing: "border- box",
        padding: "15px",
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    boxSubHeader: {
        display: 'flex',
        background: "rgba(241, 249, 254, 0.5)",
        borderBottom: "1px solid #CDE0F1",
        boxSizing: "border- box",
        padding: "3px 10px",
        alignItems: 'center',
    },
    boxHeading: {
        fontWeight: "500",
        fontSize: "15px",
        lineHeight: "18px",
        color: "#242223",
        fontFamily: 'Roboto-Medium'
    },
    boxContent: {
        display: 'flex',
        padding: "15px",
        fontSize: "14px",
        color: "#494949",
        letterSpacing: "0.01em",
        flexWrap: 'wrap',
        width: '100%'
    },
    boxContentDataRow: {
        display: 'flex',
        marginTop: "0",
        background: '#FFFFFF',
        padding: "10px 0",
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: "1px solid #DFDFE4",
        width: '100%'
    },
    evenBoxContentDataRow: {
        background: '#F7F6F7',
        display: 'flex',
        marginTop: "0",
        padding: "10px 0",
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: "1px solid #DFDFE4",
        width: '100%'
    },
    boxContentHeading: {
        marginBottom: "5px",
        color: "#242223",
        fontWeight: "500",
        fontSize: "15px",
        lineHeight: "18px",
        fontFamily: 'Roboto-Medium'
    },
    boxContentTimes: {
        display: 'flex',
        margin: "5px 0",
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
    },
    boxContentDataRowEdit: {
        color: theme.palette.primary.main,
        width: "14px",
        marginRight: "15px"
    },
    dataRow: {
        display: 'flex',
    },
    imageContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    imageContent: {
        marginLeft: '20px'
    },
    leftRightIcon: {
        color: '#91AAC0'
    },
    iconButton: {
        padding: 0
    }
}));


export { useStyles }