import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    box: {
        background: "#FFFFFF",
        border: "1px solid #CDE0F1",
    },
    boxRow: {
        borderBottom: "1px solid #DFDFE4"
    },
    noBorder: {
        border: "none"
    },
    boxHeader: {
        display: 'flex',
        background: "#F1F9FE",
        borderBottom: "1px solid #CDE0F1",
        boxSizing: "border- box",
        padding: "15px",
        alignItems: 'center',
        justifyContent : 'space-between'
    },
    boxHeading: {
        fontWeight: "500",
        fontSize: "15px",
        lineHeight: "18px",
        color: "#242223",
        fontFamily : 'Roboto-Medium'
    },
}));


export { useStyles }