import { combineReducers } from "redux";
import { customerReducer } from "./customer";
import { roomReducer } from "./room";
import { locationReducer } from "./location";

const adminReducer = combineReducers({
  customer: customerReducer,
  room: roomReducer,
  location: locationReducer,
});

export { adminReducer };
