import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { ModalType, MailAction } from "../../../core";
import {
  closeModal,
  getCustomerId,
  openModal,
  setCustomerMailCounts,
  setErrorMessage,
  setMailAction,
  setMailItems,
  setMailOptions,
  setMMTotalPages,
  setTotalCount,
  getSelectedLocationId,
  loadMailItemsFailed,
} from "../../../store";
import CloseModalIcon from "../../../assets/icons/closeModalIcon";
import "./request-hold-modal.css";
import { apiMailAction } from "../../../apis";
export default function RequestHoldModal() {
  const state = useSelector((state) => state);
  const locationId = useSelector((state) => getSelectedLocationId(state));
  const dispatch = useDispatch();
  const modal = state.ui.modal[ModalType.MailRequestHoldModal] || false;
  const activeTab = state.mail.activeTab;
  const limit = state.mail.limit;
  const ids = state.mail.selectedRowIds;
  const modalSelected = state.ui.modal;
  const selectedRow = state.mail.selectedRow;
  const isBulkOperation = state.mail.isBulkOperation;
  const customerId = useSelector((state) => getCustomerId(state));
  const mailAccountId = state.auth.data.mail_account_id;
  const isCancelRequestModal =
    modalSelected[ModalType.CancelMailRequestModal] || false;
  const isForwardingModal =
    modalSelected[ModalType.MailRequestForwardModal] || false;
  const options = useSelector((state) => state.mail.options);
  const handleClose = () => {
    dispatch(closeModal(ModalType.MailRequestHoldModal));
  };
  const handleConfirm = async () => {
    dispatch(setMailOptions({}));
    dispatch(setMailAction(MailAction.PickUp));
    try {
      const data = {
        ids: isBulkOperation ? ids : [selectedRow.id],
        mailAction: MailAction.PickUp,
        enabled: !isCancelRequestModal,
        customerPlanServiceId: options.customerPlanServiceId || 0,
        price: options.forward_price || 0,
        params: { page: 1, status: activeTab, limit: limit - ids.length },
        ...(isForwardingModal && {
          forward_address_id: options.forward_address_id,
          ...(options.delivery_days > 0 && {
            delivery_days: options.delivery_days,
          }),
        }),
      };
      const response = await apiMailAction(
        customerId,
        mailAccountId,
        locationId,
        data
      );
      const { mail_items, total_pages, total } = response;
      dispatch(setMailItems(mail_items));
      dispatch(setMMTotalPages(total_pages));
      dispatch(setTotalCount(total));
      dispatch(setCustomerMailCounts(total));
      if (!isCancelRequestModal)
        dispatch(openModal(ModalType.MailSuccessActionModal));
    } catch (error) {
      dispatch(setErrorMessage(error.toString()));
      dispatch(loadMailItemsFailed());
    }
    handleClose();
  };

  return (
    <>
      <Dialog
        open={modal}
        onClose={handleClose}
        aria-labelledby="junk-dialog-title"
        aria-describedby="junk-dialog-description"
      >
        <div className="requestHoldMailDialog">
          <DialogTitle
            id="junk-dialog-title"
            className="junkModalHeader requestScanHeader"
          >
            <Grid container sm={12} direction="row">
              <Grid sm={9}>
                <p className="pickupHoldModalTitle">
                  Request a hold for pickup
                </p>
              </Grid>
              <Grid sm={3}>
                <CloseModalIcon
                  className="requestScanTitleIcon requestHoldTitleIcon"
                  handleCloseIcon={handleClose}
                />
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="junk-dialog-description">
              <p className="junkModalText">
                You are about to request a <b>hold for pickup.</b>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="junkModalFooter">
            <Button
              variant="primary"
              className="requestHoldButton"
              onClick={handleConfirm}
            >
              Request hold
            </Button>
            <Button className="requestHoldCancelButton" onClick={handleClose}>
              Cancel
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
}
