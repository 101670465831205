import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: "sans-serif",
        color: "#242223"
    },
    paper: {
        width: 800,
        background: '#fff',
        position: 'relative',
        maxHeight: "95vh",
        overflow: "auto"
    },
    modalHeader: {
        padding: '10px 15px',
        background: "#F1F9FE",
        borderBottom: "1px solid #CDE0F1",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    modalHeading: {
        fontWeight: "500",
        fontSize: "15px",
        lineHeight: "18px",
        color: "#242223",
        fontFamily: 'Roboto-Medium'
    },
    modalContentTitle: {
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "18px",
        color: "#242223",
        fontFamily: 'Roboto-Medium',
        marginBottom: 20
    },
    closeButton: {
        color: theme.palette.grey[500],
        padding: 0
    },
    modalBody: {
        padding: '20px 15px',
    },
    select: {
        display: 'flex',
        alignItems: 'center',
        margin: "10px 0 20px",
        flex: "1",
        border: "1px solid #CDE0F1",
        padding: "5px 10px",
        width: "70%"
    },
    downIcon: {
        marginLeft: "auto",
        color: "#CDE0F1"
    },
    modalFooter: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 15px",
        marginBottom: 15
    },
    footerButton: {
        flex: "1",
        maxWidth: "48%",
        border: "2px solid #2CA4C0",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        padding: "6px 15px"
    },
    footerPrimaryBtn: {
        background: "linear-gradient(90deg, #409A95 17.74%, #34CEC6 100%)",
        color: "#fff",
    },
    tableThead: {
        background: "#F1F9FE",
        border: "1px solid #CDE0F1"
    },
    tableTh: {
        fontSize: 15,
        letterSpacing: "0.01em",
        fontWeight: "500",
        fontFamily: 'Roboto-Medium'
    },
    tableTd: {
        fontSize: 14,
        fontFamily: 'Roboto-Medium'
    },
    deliveryTd: {
        fontWeight: "bold",
    },
    tableTdChangePlan: {
        color: theme.palette.primary.light,
        fontFamily: 'Roboto-Light'
    },
    checkbox: {
        padding: 0
    },
    tableRow: {
        background: "#F7F7F7",
        borderLeft: "2px solid #CDE0F1",
        borderRight: "2px solid #CDE0F1",
    },
    tableEvenRow: {
        border: "2px solid #2CA4C0"
    },
    selectedRow: {
        background: theme.palette.secondary.main
    },
    selectedRowText: {
        color: theme.palette.common.white
    },
    tableContainer: {
        overflow: "initial",
        boxShadow: "none"
    },
    checkboxTableCell: {
        width: 20,
        padding: "8px 8px"
    },
    total: {
        margin: "15px 0",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '4px 15px',
        width: "100%",
        border: "2px solid #8EEAFF"
    },
    totalText: {
        fontSize: 15,
        letterSpacing: "0.01em",
        fontWeight: "600"
    },
    totalPrice: {
        fontSize: 21,
        fontWeight: "700"
    },
    italicText: {
        fontStyle: "italic",
        fontWeight: "normal",
        fontSize: 12
    },
    reportProblemIcon: {
        marginRight: 10,
        color: "#D95767"
    },
    modalFooterLeft: {
        display: "flex",
        alignItems: "center",
        maxWidth: "55%",
        flex: "1"
    },
    modalFooterLeftBody: {
        fontSize: '12px',
        fontFamily: 'Roboto-Italic'
    },
    modalFooterRight: {
        maxWidth: "40%",
        flex: "1",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    formControlRoot: {
        marginLeft: 0
    }
}));


export { useStyles }