import { SPACERENTAL } from "../../../actions";

const detailReducer = (state = {}, action) => {
  switch (action.type) {
    case SPACERENTAL.CLEAR:
      return {};
    case SPACERENTAL.SET_BOOKING_DETAIL:
      return action.detail;
    default:
      return state;
  }
};

export { detailReducer };
