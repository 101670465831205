import React, { Fragment, useState } from 'react'
import { Modal, Fade, Backdrop, Button, Typography, OutlinedInput, IconButton, Box, FormControlLabel, Checkbox, InputAdornment, TextField } from '@material-ui/core';
import {
    HighlightOff as HighlightOffIcon,
    AccessTime as AccessTimeIcon
} from '@material-ui/icons';
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { closeModal } from '../../../store';
import { ModalType } from '../../../core';
import { useStyles } from './AddNoteAgent.style';

const defaultValue = { note: "", startDate: null, endDate: null, startTime: null, endTime: null };
export default function AddNoteAgent() {
    const state = useSelector(state => state);
    const classes = useStyles();
    const dispatch = useDispatch();
    const modal = state.ui.modal[ModalType.AddNoteAgent] || false;
    const [hasTimeFrame, setHasTimeFrame] = useState(false);
    const handleClose = () => {
        dispatch(closeModal(ModalType.AddNoteAgent));
    }
    const [values, setValues] = useState(defaultValue);
    return (
        <Modal
            aria-labelledby="add-note-agent-modal-title"
            aria-describedby="add-note-agent-modal-description"
            open={modal}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            className={classes.modal}
            BackdropProps={{
                timeout: 500
            }}
        >
            <Fade in={modal}>
                <div className={classes.paper}>
                    <div className={classes.modalHeader}>
                        <Typography variant="h6" className={classes.modalHeading}>
                            Add a note for the agent
                        </Typography>
                        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                            <HighlightOffIcon />
                        </IconButton>
                    </div>
                    <div className={classes.modalBody}>
                        <Typography variant="h5" className={classes.modalSubhead}>
                            Your note (max 100 words)
                        </Typography>
                        <OutlinedInput
                            type="text"
                            className={classes.input}
                            fullWidth
                            multiline
                            rows={3}
                            value={values.note}
                            onChange={(e) => setValues({ ...values, note: e.target.value })}
                        />
                        <FormControlLabel
                            control={<Checkbox color="primary" name="checkedA" />}
                            label="There is a time frame for this note"
                            checked={hasTimeFrame}
                            onChange={() => setHasTimeFrame(!hasTimeFrame)}
                        />
                        {
                            hasTimeFrame && (
                                <Fragment>
                                    <div className={classes.selectDaysRow}>
                                        <div className={classes.selectColumn}>
                                            <Typography className={classes.selectLabel} variant='subtitle1'>From</Typography>
                                            <KeyboardDatePicker
                                                value={values.startDate}
                                                onChange={(value) => setValues({ ...values, startDate: value })}
                                                className={classes.InputRoot}
                                                autoOk
                                                variant="inline"
                                                inputVariant="outlined"
                                                format="MM/dd/yyyy"
                                                InputAdornmentProps={{ position: "start" }}
                                            />
                                        </div>
                                        <div className={classes.selectColumn}>
                                            <Typography className={classes.selectLabel} variant='subtitle1'>to</Typography>
                                            <KeyboardDatePicker
                                                value={values.endDate}
                                                onChange={(value) => setValues({ ...values, endDate: value })}
                                                className={classes.InputRoot}
                                                autoOk
                                                variant="inline"
                                                inputVariant="outlined"
                                                format="MM/dd/yyyy"
                                                InputAdornmentProps={{ position: "start" }}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.selectDaysRow}>
                                        <div className={classes.selectColumn}>
                                            <Typography className={classes.selectLabel} variant='subtitle1'>From</Typography>
                                            <KeyboardTimePicker
                                                value={values.startTime}
                                                onChange={(value) => setValues({ ...values, startTime: value })}
                                                className={classes.InputRoot}
                                                autoOk
                                                variant="inline"
                                                inputVariant="outlined"
                                                keyboardIcon={<AccessTimeIcon />}
                                                InputAdornmentProps={{ position: "start" }}
                                            />
                                        </div>
                                        <div className={classes.selectColumn}>
                                            <Typography className={classes.selectLabel} variant='subtitle1'>to</Typography>
                                            <KeyboardTimePicker
                                                value={values.endTime}
                                                onChange={(value) => setValues({ ...values, endTime: value })}
                                                className={classes.InputRoot}
                                                autoOk
                                                variant="inline"
                                                inputVariant="outlined"
                                                keyboardIcon={<AccessTimeIcon />}
                                                InputAdornmentProps={{ position: "start" }}
                                            />
                                        </div>
                                    </div>
                                </Fragment>
                            )
                        }
                    </div>
                    <div className={classes.modalFooter}>
                        <Button className={clsx(classes.footerButton, classes.footerPrimaryBtn)} variant="contained" color="primary" onClick={handleClose}>
                            Save the note
                        </Button>
                        <Button className={classes.footerButton} variant="outlined" onClick={handleClose}>Cancel</Button>
                    </div>
                </div>
            </Fade>
        </Modal >
    )
}
