import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    selectDaysRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: "wrap",
        marginBottom: 10
    },
    selectLabel: {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "18px",
        color: "#242223",
        fontFamily: 'Roboto-Medium'
    },
    select: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 10,
        flex: "1",
        border: "1px solid #CDE0F1",
        padding: "5px 10px",
    },
    calendraIcon: {
        marginRight: 10,
        color: "#2CA4C0"
    },
    downIcon: {
        marginLeft: "auto",
        color: "#CDE0F1"
    },
    selectColumn: {
        flex: "1",
        maxWidth: "48%",
        minWidth: "48%",
        display: 'flex',
        alignItems: 'center',
        marginBottom: 10,
        justifyContent: 'space-between'
    },
    specialDatesRow: {
        borderBottom: "2px dashed lightgrey",
        padding: "10px 0",
        margin: "10px 0"
    },
    lastChild: {
        border: "none",
        marginBottom: 0,
        paddingBottom: 0
    },
    picker: {
        width: '80%'
    }
}));


export { useStyles }