import React, { useEffect } from "react";
import { Box, Typography, Link, CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  openModal,
  getSelectedLocationName,
  getSelectedLocationId,
  setAvailableRoomsFilter,
  setSelectedAvailableRoom,
  clearSpaceRental,
  setFilteredRooms,
  setErrorMessage,
  setAvailableRooms,
} from "../../../store";
import { ModalType } from "../../../core";
import { useStyles } from "./AvailableRooms.style";
import Logo from "../../../assets/images/logo.jpg";
import {
  apiCheckRoomAvailability,
  apiFetchAvailableRooms,
} from "../../../apis";
import { getFormatedDate } from "../../../utils";
import { useState } from "react";

export default function AvailableRooms() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { spaceRental } = useSelector((state) => state);
  const { data } = useSelector((state) => state.spaceRental.availableRooms);
  const { filter } = spaceRental.availableRooms;
  const locationName = useSelector((state) => getSelectedLocationName(state));
  const locationId = useSelector((state) => getSelectedLocationId(state));
  const [loading, setLoading] = useState(false);

  const seeAllRooms = async () => {
    setLoading(true);
    try {
      const currentDate = moment(new Date());
      const fromTime = null;
      const endTime = null;
      const { roomTypeId } = filter;
      dispatch(
        setAvailableRoomsFilter({
          date: currentDate,
          fromTime,
          endTime,
          locationId,
        })
      );
      dispatch(clearSpaceRental());
      const rooms = await apiCheckRoomAvailability(
        new Date(getFormatedDate(currentDate, fromTime)).toISOString(),
        new Date(getFormatedDate(currentDate, endTime)).toISOString(),
        locationId || 0,
        roomTypeId || 0,
        !!(fromTime && endTime)
      );
      setLoading(false);
      dispatch(setFilteredRooms(rooms));
      dispatch(openModal(ModalType.RoomAvailabilityResultModal));
    } catch (error) {
      setLoading(false);
      dispatch(setErrorMessage(error.toString()));
    }
  };

  const bookItNow = (room) => {
    dispatch(setSelectedAvailableRoom(room));
    dispatch(
      setAvailableRoomsFilter({
        date: moment(new Date()).format("YYYY-MM-DD"),
      })
    );
    dispatch(openModal(ModalType.RoomAvailabilityResultModal));
  };

  useEffect(() => {
    async function loadAllRooms() {
      try {
        setLoading(true);
        const response = await apiFetchAvailableRooms(0, 4);
        dispatch(setAvailableRooms(response.data));
        setLoading(false);
      } catch (error) {
        dispatch(setErrorMessage(error.toString()));
        setLoading(false);
      }
    }
    loadAllRooms();
  }, [dispatch]);
  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.leftSection}>
        <Box>
          <Typography variant="h2" className={classes.heading}>
            Rooms Available today
          </Typography>
          <Typography variant="h3">{locationName}</Typography>
        </Box>
        {loading && <Box>
          <CircularProgress size="1.9rem" />
        </Box>}
        <Link
          component="button"
          variant="inherit"
          color="primary"
          onClick={seeAllRooms}
          disabled={loading}
        >
          See all
        </Link>
      </Box>
      <Box className={classes.rightSection}>
        {data.map((room) => (
          <Box className={classes.roomContainer} key={room.id}>
            <img
              className={classes.backgroundImg}
              src={room?.url ? room.url : Logo}
              alt="hotdesk"
            />
            <Box className={classes.bookingsContentDiv}>
              <Box className={classes.priceDiv}>
                <Typography variant="h3">{`${room.hourlyPrice}$/h`}</Typography>
              </Box>
              <Box>
                <Typography variant="h2" className={classes.roomTitle}>
                  {room.name}
                </Typography>
                <Typography variant="h3" className={classes.roomTitle}>
                  {room.location}
                </Typography>
              </Box>
              <Link
                component="button"
                variant="inherit"
                color="inherit"
                className={classes.link}
                onClick={() => bookItNow(room)}
              >
                Book it now
              </Link>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
