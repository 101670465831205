import React from "react";
export default function JunkModalCautionIcon(props) {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 12.88C16 13.244 15.8682 13.5217 15.6047 13.713C15.3411 13.9043 15.0177 14 14.6343 14H1.36568C0.98233 14 0.65888 13.902 0.395328 13.706C0.131776 13.51 0 13.2347 0 12.88C0 12.6 0.0862534 12.3153 0.25876 12.026L6.88589 0.77C7.20216 0.256667 7.57592 0 8.00719 0C8.43845 0 8.80264 0.256667 9.09973 0.77L15.7412 12.04C15.9137 12.3387 16 12.6187 16 12.88ZM8.9991 5.726V3.682H7.0009V5.726C7.0009 5.85667 7.01048 5.98033 7.02965 6.097C7.04882 6.21367 7.07517 6.34433 7.10872 6.489C7.14226 6.63367 7.16861 6.75733 7.18778 6.86L7.56155 9.128H8.4097L8.79784 6.86C8.81701 6.76667 8.84576 6.64533 8.8841 6.496C8.92243 6.34667 8.95118 6.21367 8.97035 6.097C8.98952 5.98033 8.9991 5.85667 8.9991 5.726ZM8.9991 11.144C8.9991 10.8733 8.90087 10.6447 8.7044 10.458C8.50794 10.2713 8.27074 10.178 7.99281 10.178C7.72447 10.178 7.49206 10.2713 7.2956 10.458C7.09913 10.6447 7.0009 10.8733 7.0009 11.144C7.0009 11.4147 7.09913 11.6457 7.2956 11.837C7.49206 12.0283 7.72447 12.124 7.99281 12.124C8.27074 12.124 8.50794 12.0283 8.7044 11.837C8.90087 11.6457 8.9991 11.4147 8.9991 11.144Z"
        fill="#D95767"
      />
    </svg>
  );
}
