import React from "react";
import {
  Modal,
  Fade,
  Backdrop,
  Typography,
  Box,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import {
  closeModal
} from "../../../store";
import { ModalType } from "../../../core";
import { useStyles } from "./styles";
import IconButton from "@material-ui/core/IconButton";
import { HighlightOff as HighlightOffIcon } from "@material-ui/icons";
import clsx from "clsx";

export default function PlanDetailsModal() {
  const modal = useSelector(
    (state) => state.ui.modal[ModalType.PlanDetailsModal] || false
  );
  const customer = useSelector((state) => state.admin.customer);
  const { planInfoDetail } = customer;
  const { planServices } = planInfoDetail;

  const dispatch = useDispatch();

  const classes = useStyles();
  const handleClose = () => {
    dispatch(closeModal(ModalType.PlanDetailsModal));
  };

  return (
    <Modal
      aria-labelledby="buy-minutes-modal-title"
      aria-describedby="buy-minutes-modal-description"
      open={modal}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      className={classes.modal}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modal}>
        <div className={classes.paper}>
          <div className={classes.modalHeader}>
            <Typography variant="h6" className={classes.modalHeading}>
              Plan Details
            </Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <HighlightOffIcon />
            </IconButton>
          </div>
          <div className={classes.modalBody}>
            <Box className={classes.imgSection}>
              {planInfoDetail?.id ? (
                <Box className={classes.imgSectionContent}>
                  <Box>
                    <Typography className={classes.firmIdentity}>
                      {planInfoDetail?.planName}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h4" className={classes.boxPrice}>
                      {`$${planInfoDetail?.price}`}
                    </Typography>
                    <Typography>{planInfoDetail?.periodUnit}</Typography>
                  </Box>
                </Box>
              ) :
                (<Box className={classes.imgSectionContent}>
                  <Typography className={classes.firmIdentity}>No current plan</Typography>
                </Box>)}
            </Box>
            {planServices && !!Object.keys(planServices).length && (
              <Box className={classes.boxContent}>
                <Box className={clsx(classes.cardSection, classes.spacing)}>
                  <Box className={classes.cardSectionHeader}>
                    <Typography variant="subtitle1">Service</Typography>
                    <Typography variant="subtitle1">Default</Typography>
                  </Box>
                  <Box>
                    {Object.entries(planServices).map(([plan, value]) => (
                      <Box
                        key={plan}
                        className={clsx({
                          [classes.cardSectionContent]: true,
                        })}
                      >
                        <Typography variant="subtitle1">
                          {plan}
                        </Typography>
                        <Typography variant="body1">{value.toString()}</Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            )}
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
