import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  main: {
    padding: "33px",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  columnWrap: {
    display: "grid",
    gridTemplateColumns: "1fr 2fr",
    gridGap: "20px",
  }
}));

export { useStyles };
