import React from "react";
import { Box } from "@material-ui/core";
import { Tabs } from "./tabs";
import { Search } from "./search";
import { useStyles } from "./header.style";

export default function Header() {
  const classes = useStyles();
  return (
    <Box component="div" className={classes.subHeader}>
      <Tabs />
      <Search />
    </Box>
  );
}
