import { combineReducers } from "redux";
import { serviceTransactionReducer } from "./serviceTransactions";
import { paymentTransactionReducer } from "./paymentTransactions";
import { dateRangeReducer } from "./dateRange";

const transactionsReducer = combineReducers({
  service: serviceTransactionReducer,
  payment: paymentTransactionReducer,
  dateRange: dateRangeReducer,
});

export { transactionsReducer };
