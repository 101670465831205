import React from "react";
import {
  Modal,
  Fade,
  Box,
  Backdrop,
  Typography,
  Button,
  OutlinedInput,
  FormHelperText,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import { useFormik } from "formik";
import * as yup from "yup";
import { apiUpdateCustomer } from "../../../apis";
import {
  closeModal,
  getCompanyInfo,
  setAuthData,
  setErrorMessage,
  setSuccessMessage,
  getCompanyOfficeInfoAdmin,
  setCustomerDetailsdAdmin,
  getCustomerId,
} from "../../../store";
import { CountryInput, StateInput } from "../../shared";
import { ModalType, UIActionType } from "../../../core";
import IconButton from "@material-ui/core/IconButton";
import { useStyles } from "./EditCompanyAddress.style";
import { HighlightOff as HighlightOffIcon } from "@material-ui/icons";
import { useState } from "react";

const validationSchema = yup.object({
  address1: yup.string().required("Required"),
  city: yup.string().required("Required"),
  state: yup.string().required("Required"),
  zip: yup.string().required("Required"),
  country: yup
    .string()
    .max(3, "Country code must be less then 3 digit")
    .required("Required"),
});

export default function EditCompanyAddress() {
  const state = useSelector((state) => state);
  const classes = useStyles();
  const dispatch = useDispatch();
  const modal = state.ui.modal[ModalType.EditCompanyAddress] || false;
  const customerId = getCustomerId(state);
  const [isLoading, setIsLoading] = useState(false);
  const handleClose = () => {
    formik.resetForm();
    dispatch(closeModal(ModalType.EditCompanyAddress));
  };

  const isAdminAction = Boolean(state.ui.actionType === UIActionType.Admin);
  const initialValues = isAdminAction
    ? getCompanyOfficeInfoAdmin(state)
    : getCompanyInfo(state);

  const handleAdminResponse = (res) => {
    const { details } = state.admin.customer;
    dispatch(setCustomerDetailsdAdmin({ ...details, ...res }));
    handleClose();
  };

  const handleUserResponse = (res) => {
    const { data } = state.auth;
    dispatch(setAuthData({ ...data, ...res }));
    handleClose();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const request = { row: values, customerId };
        const res = await apiUpdateCustomer(request);
        isAdminAction ? handleAdminResponse(res) : handleUserResponse(res);
        setIsLoading(false);
        dispatch(setSuccessMessage("Office name updated"));
      } catch (error) {
        setIsLoading(false);
        dispatch(setErrorMessage(error.toString()));
      }
    },
  });

  const handleCountryChange = (country) => {
    formik.setFieldValue("country", country?.id || "");
    formik.setFieldValue("state", '');
  };

  const handleStateChange = (state) => {
    formik.setFieldValue("state", state?.id);
  };

  return (
    <Modal
      aria-labelledby="add-forwarding-address-modal-title"
      aria-describedby="add-forwarding-address-modal-description"
      disableEnforceFocus
      open={modal}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      className={classes.modal}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modal}>
        <Box className={classes.paper}>
          <Box className={classes.modalHeader}>
            <Typography variant="h6" className={classes.modalHeading}>
              Edit company address
            </Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <HighlightOffIcon />
            </IconButton>
          </Box>
          <Box className={classes.modalBody}>
            <Typography variant="h5" className={classes.modalSubhead}>
              Address line1
            </Typography>
            <OutlinedInput
              type="text"
              fullWidth
              name="address1"
              value={formik.values.address1}
              onChange={formik.handleChange}
              error={formik.touched.address1 && Boolean(formik.errors.address1)}
            />
            <FormHelperText
              error={formik.touched.address1 && Boolean(formik.errors.address1)}
            >
              {formik.touched.address1 && formik.errors.address1}
            </FormHelperText>
            <Typography variant="h5" className={classes.modalSubhead}>
              Address line2 (optional)
            </Typography>
            <OutlinedInput
              type="text"
              fullWidth
              name="address2"
              value={formik.values.address2}
              onChange={formik.handleChange}
            />
            <Box className={classes.inputRow}>
              <Box className={classes.inputContent}>
                <Typography variant="h5" className={classes.modalSubhead}>
                  Country
                </Typography>
                <CountryInput
                  value={formik.values.country}
                  onChange={handleCountryChange}
                />
                {/* <OutlinedInput
                  type="text"
                  fullWidth
                  name="country"
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.country && Boolean(formik.errors.country)
                  }
                /> */}
                <FormHelperText
                  error={
                    formik.touched.country && Boolean(formik.errors.country)
                  }
                >
                  {formik.touched.country && formik.errors.country}
                </FormHelperText>
              </Box>
              <Box className={classes.inputContent}>
                <Typography variant="h5" className={classes.modalSubhead}>
                  State/province
                </Typography>
                  <StateInput
                    country={formik.values.country}
                    value={formik.values.state}
                    onChange={handleStateChange}
                  />
                <FormHelperText
                  error={formik.touched.state && Boolean(formik.errors.state)}
                >
                  {formik.touched.state && formik.errors.state}
                </FormHelperText>
              </Box>
            </Box>
            <Box className={classes.inputRow}>
              <Box className={classes.inputContent}>
                <Typography variant="h5" className={classes.modalSubhead}>
                  City
                </Typography>
                <OutlinedInput
                  type="text"
                  fullWidth
                  name="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                />
                <FormHelperText
                  error={formik.touched.city && Boolean(formik.errors.city)}
                >
                  {formik.touched.city && formik.errors.city}
                </FormHelperText>
              </Box>
              <Box className={classes.inputContent}>
                <Typography variant="h5" className={classes.modalSubhead}>
                  ZIP
                </Typography>
                <OutlinedInput
                  type="text"
                  fullWidth
                  name="zip"
                  value={formik.values.zip}
                  onChange={formik.handleChange}
                  error={formik.touched.zip && Boolean(formik.errors.zip)}
                />
                <FormHelperText
                  error={formik.touched.zip && Boolean(formik.errors.zip)}
                >
                  {formik.touched.zip && formik.errors.zip}
                </FormHelperText>
              </Box>
            </Box>
          </Box>
          <Box className={classes.modalFooter}>
            <Button
              className={clsx(classes.footerButton, classes.footerPrimaryBtn)}
              disabled={formik.isSubmitting || isLoading}
              variant="contained"
              color="primary"
              onClick={formik.handleSubmit}
            >
              Save Changes
            </Button>
            <Button
              className={classes.footerButton}
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
