import { makeStyles } from "@material-ui/core";
import mask from '../../../assets/images/mask.png';


const useStyles = makeStyles((theme) => ({
    box: {
        background: "#FFFFFF",
        border: "1px solid #CDE0F1",
    },
    boxHeader: {
        display: 'flex',
        background: "#F1F9FE",
        borderBottom: "1px solid #CDE0F1",
        boxSizing: "border- box",
        padding: "15px",
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    boxHeading: {
        fontWeight: "500",
        fontSize: "15px",
        lineHeight: "18px",
        color: "#242223",
        fontFamily: 'Roboto-Medium'
    },
    boxLeftHeading: {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#242223",
        fontWeight: "600",
        marginBottom: "5px"
    },
    boxLink: {
        fontSize: "14px",
        lineHeight: "16px",
        color: "#2CA4C0"
    },
    boxContent: {
        display: 'flex',
        padding: "15px",
        fontSize: "14px",
        color: "#494949",
        letterSpacing: "0.01em",
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    imgSectionContent: {
        color: "#fff",
        padding: "15px",
        fontSize: "14px",
        letterSpacing: "0.01em",
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    imgSectionH2: {
        fontWeight: "bold",
        fontSize: "31px",
        lineHeight: "36px",
        margin: "5px 0 20px"
    },
    boxContentRight: {
        marginLeft: "10px",
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    boxPrice: {
        fontSize: "21px",
        lineHeight: "25px",
        color: theme.palette.primary.main,
        marginBottom: "5px",
        fontWeight: 600
    },
    myCurrentSpan: {
        color: "#D95767"
    },
    imgSection: {
        height: "195px",
        color: "#fff",
        paddingTop: "56px",
        background: `linear-gradient(to right, rgba(21, 38, 81, 1), rgba(21, 38, 81, 0.21)), url(${mask})`
    },
}));


export { useStyles }