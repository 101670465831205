import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  MainDiv: {
    width: "100%",
    height: "409.17px",
    border: "1px solid #CDE0F1",
    background: "white",
  },
  MainHeadingDiv: {
    height: "48px",
    background: "#F1F9FE",
    borderBottom: "1px solid #CDE0F1",
    width: "100%",
  },
  MainHeadingText: {
    marginLeft: "20px",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.01em",
    color: "#242223",
    fontFamily: "'Roboto-Medium'",
  },
  forwardingMainDiv: {
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "cover !important",
    width: "100%",
    height: "172.95px",
  },
  forwardingContentDiv: {
    padding: "45px 35px",
  },
  forwardingConten: {
    fontStyle: "normal",
    fontSize: "21px",
    color: "#FFFFFF",
    fontFamily: "'Roboto-Bold'",
  },
  forwardingAddress: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    letterSpacing: "0.01em",
    color: "#FFFFFF",
  },
  forwardingAddressLink: {
    fontStyle: "normal",
    fontSize: "16px",
    textDecorationLine: "underline",
    color: "#8EEAFF",
  },
  forwardingAddressIcon: {
    marginLeft: "5%",
    marginTop: "3px",
  },
  counterDiv: {
    width: "149.33px",
    height: "140.21px",
    background: "#F7F6F7",
    marginTop: "15%",
    marginLeft: "9px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  counterNum: {
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "40px",
    display: "flex",
    letterSpacing: "0.01em",
    color: "#242223",
  },
  counterStatus: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    display: "flex",
    letterSpacing: "0.01em",
    color: "#494949",
  },
  NumListGrid: {
    background: "#F7F6F7",
    // width: "465px",
    height: "48px",
    marginTop: "5%",
  },
  NumListIcon: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
  },
  NumListNum: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    letterSpacing: "0.01em",
    color: "#494949",
  },
  NumListNumber: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "0.01em",
    color: "#494949",
  },
  MainHeadingLinkGrid: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "12px",
    alignItems: "center",
  },
  MainHeadingLink: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    textAlign: "right",
    letterSpacing: "0.01em",
    color: "#2CA4C0",
    cursor: "pointer",
  },
}));

export { useStyles };
