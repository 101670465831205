import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  container: {
    padding: "20px",
  },
  imgContainer: {
    flexBasis: "30%",
  },
  detailContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingBottom: "20px",
  },
  subDetail1: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "flex-end",
  },
  subDetail2: {
    display: "flex",
    justifyContent: "space-around",
    flexBasis: "50%",
  },
  subDetail3: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  typography1: {
    padding: "20px 0",
    fontFamily: "Roboto-Bold",
  },
  typography2: {
    fontFamily: "Roboto-Bold",
    paddingBottom: "20px",
  },
  backgroundImg: {
    height: "196px",
    width: "100%",
  },
  serviceRow: {
    display: "flex",
    flexBasis: "50%",
    padding: "5px 0",
  },
  serviceContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  btnContainer: {
    display: "flex",
  },
  btn1: {
    flexBasis: "50%",
    fontFamily: "Roboto-Bold",
    height: "40px",
    background: "#F0F0F0",
    display: "flex",
    alignItems: "center",
    paddingLeft: "20px",
  },
  btn2: {
    flexBasis: "50%",
    background: "linear-gradient(90deg, #409A95 -17.74%, #34CEC6 100%)",
    color: "#fff",
    fontFamily: "Roboto-Bold",
    height: "40px",
  },
  roomImageContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gridGap: "10px",
    marginTop: "-45px",
    width: "95%",
  },
  roomImage: {
    width: "95px",
    height: "75px",
    border: "2px solid #FFFFFF",
  },
  labelContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  labelItem: {
    flexBasis: "40%",
    marginBottom: "5px",
    fontFamily: "Roboto-Medium",
    color: "#000",
  },
  dateFieldLogo: {
    background: "aliceblue",
    display: "flex",
    height: 40,
    border: "1px solid #CDE0F1",
    alignItems: "center",
    padding: "0 8px",
  },
  timeContainer: {
    display: "flex",
  },
  errorContainer: {
    marginBottom: "20px",
  },
  loader: {
    display:"flex",
    justifyContent:"center"
  }
}));

export { useStyles };
