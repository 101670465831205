import React from "react";
import { saveAs } from "file-saver";
import moment from "moment";
import { capitalize } from "lodash";
import { IconButton, LinearProgress, Typography, Box, Tooltip } from "@material-ui/core";
import { CancelOutlined as CancelOutlinedIcon } from "@material-ui/icons";
import { ModalType } from "../../../core";
import MoveToJunkIcon from "../../../assets/icons/moveToJunkIcon";
import ShredMailIcon from "../../../assets/icons/shredMailIcon";
import ForwardedMailActionIcon from "../../../assets/icons/forwardedMailActionIcon";
import ScannedPreviewMailActionIcon from "../../../assets/icons/scannedPreviewMailActionIcon";
import PhotographedMailActionIcon from "../../../assets/icons/photographedMailActionIcon";

export const newColumns = (width, handleMailAction) => [
  {
    field: "mail_number",
    headerName: "Box",
    width: width * 0.1,
  },
  {
    field: "sender",
    headerName: "Sender",
    width: width * 0.15,
  },
  {
    field: "addressed_to",
    headerName: "Addressed to",
    width: width * 0.15,
  },
  {
    field: "type",
    headerName: "Type",
    width: width * 0.14,
    valueFormatter: (params) => capitalize(params.value),
  },
  {
    field: "received_at",
    headerName: "Arrival Date",
    width: width * 0.15,
    valueFormatter: (params) => {
      const date = new Date(params.value);
      return moment(date).format("MM/DD/YYYY hh:mm A");
    },
  },
  {
    field: "tracking",
    headerName: "Number",
    width: width * 0.15,
  },
  {
    field: "id",
    headerName: "Action",
    width: width * 0.1,
    hideSortIcons: true,
    renderCell: (param) => {
      return (
        <>
          <Tooltip title="Shred Email" placement="start-top">
            <IconButton
              style={{ overflow: 'visible' }}
              onClick={() =>
                handleMailAction(ModalType.ShredMailModal, param.row)
              }
            >
              <ShredMailIcon className="active" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" placement="start-top">
            <IconButton
              onClick={() => handleMailAction(ModalType.MailJunkModal, param.row)}
            >
              <MoveToJunkIcon className="activeDelete" />
            </IconButton>
          </Tooltip>
        </>
      );
    },
  },
];

export const pendingColumns = (width, handleCancelRequest) => [
  {
    field: "mail_number",
    headerName: "Box",
    width: width * 0.1,
  },
  {
    field: "sender",
    headerName: "Sender",
    width: width * 0.2,
  },
  {
    field: "addressed_to",
    headerName: "Addressed to",
    width: width * 0.15,
  },
  {
    field: "type",
    headerName: "Type",
    width: width * 0.14,
    valueFormatter: (params) => capitalize(params.value),
  },
  {
    field: "received_at",
    headerName: "Requested date",
    width: width * 0.15,
    valueFormatter: (params) => {
      const { update_date, create_date, received_at } = params.row;
      return moment(update_date || create_date || received_at).format(
        "MM/DD/YYYY hh:mm A"
      );
    },
  },
  {
    field: "status_label",
    headerName: "Status",
    width: width * 0.25,
    renderCell: (param) => {
      const { update_date, create_date, received_at } = param.row;
      const latestDate = update_date || create_date || received_at;
      const currentDate = moment(new Date());
      const hourDiff = moment.duration(currentDate.diff(latestDate)).asHours();
      const initialProgress = 0;
      return (
        <>
          <Box
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box style={{ width: "70%" }}>
              <Typography variant="subtitle1">{param.value}</Typography>
              <LinearProgress
                variant="determinate"
                value={initialProgress}
              />
            </Box>
            <Tooltip title="Cancel" placement="start-top">
              <IconButton
                onClick={() =>
                  handleCancelRequest(ModalType.CancelMailRequestModal, param.row)
                }
              >
                <CancelOutlinedIcon color="primary" />
              </IconButton>
            </Tooltip>
          </Box>
        </>
      );
    },
  },
];

export const processedColumns = (width) => [
  {
    field: "mail_number",
    headerName: "Box",
    width: width * 0.1,
  },
  {
    field: "sender",
    headerName: "Sender",
    width: width * 0.2,
  },
  {
    field: "addressed_to",
    headerName: "Addressed to",
    width: width * 0.2,
  },
  {
    field: "type",
    headerName: "Type",
    width: width * 0.14,
    valueFormatter: (params) => capitalize(params.value),
  },
  {
    field: "received_at",
    headerName: "Processed date",
    width: width * 0.15,
    valueFormatter: (params) => {
      const date = new Date(params.value);
      return moment(date).format("MM/DD/YYYY hh:mm A");
    },
  },
  {
    field: "status_label",
    headerName: "Status",
    width: width * 0.2,
    renderCell: (param) => {
      const { row } = param;
      const saveFile = () => {
        saveAs(row.scan_url, `${row.type}_${row.id}.pdf`);
      };
      const renderIcon = () => {
        if (row.is_scan_complete) return <ScannedPreviewMailActionIcon />;
        else if (row.is_forward_complete) return <ForwardedMailActionIcon />;
        else if (row.picture_added_at) return <PhotographedMailActionIcon />;
        else return null;
      };
      const renderTooltip = () => {
        if (row.is_scan_complete) return 'Scan Completed /Preview';
        else if (row.is_forward_complete) return 'Forwarded';
        else if (row.picture_added_at) return 'Picture Added';
        else return '';
      };
      return (
        <>
          <Box
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box style={{ width: "70%", alignSelf: "center" }}>
              <Typography variant="body2">{param.value}</Typography>
            </Box>
            <Tooltip title={renderTooltip()}>
              <IconButton
                onClick={() => {
                  row.is_scan_complete && saveFile();
                }}
              >
                {renderIcon()}
              </IconButton>
            </Tooltip>
          </Box>
        </>
      );
    },
  },
];
