import { MYACCOUNT } from "../../../actions";

const dataReducer = (state = [], action) => {
    switch (action.type) {
        case MYACCOUNT.CLEAR_ACCOUNT:
            return [];
        case MYACCOUNT.SET_FORWARDING_ADDRESS:
            return action.data;
        case MYACCOUNT.ADD_UPDATE_FORWARDING_ADDRESS: {
            const records = state;
            const record = action.record;
            const index = state.findIndex(x => x.id === record.id);
            if (index < 0)
                records.push(record);
            else
                records.splice(index, 1, record);

            return records;
        }
        default:
            return state;
    }
};

export { dataReducer };
