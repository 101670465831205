import { MYACCOUNT } from "./action-types";

const clearAccount = () => ({
  type: MYACCOUNT.CLEAR_ACCOUNT,
});

// const loadForwardingAddresses = () => ({
//   type: MYACCOUNT.LOAD_FORWARDING_ADDRESS,
// });

// const loadUserDetail = () => ({
//   type: MYACCOUNT.LOAD_USER_DETAILS,
// });

const setForwardingAddressData = (data) => ({
  type: MYACCOUNT.SET_FORWARDING_ADDRESS,
  data,
});

const addUpdateCFAddressRecord = (record) => ({
  type: MYACCOUNT.ADD_UPDATE_FORWARDING_ADDRESS,
  record,
});

const setSelectedForwardingAddress = (selected) => ({
  type: MYACCOUNT.SET_SELECTED_FORWARDING_ADDRESS,
  selected,
});

const loadForwardingPhoneNumbers = () => ({
  type: MYACCOUNT.LOAD_FORWARDING_PHONE_NUMBERS,
});

const setForwardingPhoneNumbersData = (data) => ({
  type: MYACCOUNT.SET_FORWARDING_PHONE_NUMBERS,
  data,
});

const setSelectedForwardingPhoneNumber = (selectedForwardingPhoneNumber) => ({
  type: MYACCOUNT.SET_SELECTED_FORWARDING_PHONE_NUMBER,
  selectedForwardingPhoneNumber,
});

const setForwardingPhoneNumberOperatingMode = (operatingMode) => ({
  type: MYACCOUNT.SET_FORWARDING_PHONE_NUMBER_OPERATING_MODE,
  operatingMode,
});

const setForwardingAddressOperatingMode = (operatingMode) => ({
  type: MYACCOUNT.SET_FORWARDING_ADDRESS_OPERATING_MODE,
  operatingMode,
});

// const loadCustomerLocations = () => ({
//   type: MYACCOUNT.LOAD_CUSTOMER_LOCATIONS,
// });

const setCustomerLocations = (data) => ({
  type: MYACCOUNT.SET_CUSTOMER_LOCATIONS,
  data,
});

const setSelectedCustomerLocation = (selected) => ({
  type: MYACCOUNT.SET_SELECTED_CUSTOMER_LOCATION,
  selected,
});

// const loadTeamMembers = () => ({
//   type: MYACCOUNT.LOAD_TEAM_MEMBERS,
// });

const setTeamMembers = (data) => ({
  type: MYACCOUNT.SET_TEAM_MEMBERS,
  data,
});

const addUpdateCTMembersRecord = (record) => ({
  type: MYACCOUNT.ADD_UPDATE_CT_MEMBERS,
  record,
});

const setSelectedTeamMember = (selected) => ({
  type: MYACCOUNT.SET_SELECTED_TEAM_MEMBER,
  selected,
});

const setCustomerMailCounts = (count) => ({
  type: MYACCOUNT.SET_CUSTOMER_MAILS_COUNT,
  count,
});

// const loadPaymentMethods = () => ({
//   type: MYACCOUNT.LOAD_PAYMENT_METHODS,
// });

const setPaymentMethods = (data) => ({
  type: MYACCOUNT.SET_PAYMENT_METHODS,
  data,
});

const addUpdatePaymentMethod = (record) => ({
  type: MYACCOUNT.ADD_UPDATE_PAYMENT_METHOD,
  record,
});

const setSelectedPaymentMethod = (selected) => ({
  type: MYACCOUNT.SET_SELECTED_PAYMENT_METHOD,
  selected,
});

export {
  setForwardingAddressData,
  clearAccount,
  // loadUserDetail,
  // loadForwardingAddresses,
  setSelectedForwardingAddress,
  loadForwardingPhoneNumbers,
  setForwardingPhoneNumbersData,
  setSelectedForwardingPhoneNumber,
  setForwardingPhoneNumberOperatingMode,
  setForwardingAddressOperatingMode,
  // loadCustomerLocations,
  setCustomerLocations,
  setSelectedCustomerLocation,
  // loadTeamMembers,
  setTeamMembers,
  setSelectedTeamMember,
  addUpdateCFAddressRecord,
  addUpdateCTMembersRecord,
  setCustomerMailCounts,
  // loadPaymentMethods,
  setPaymentMethods,
  addUpdatePaymentMethod,
  setSelectedPaymentMethod
};
