import { TRANSACTION } from "./action-types";

const loadAllTransactions = (dateRange) => ({
  type: TRANSACTION.LOAD_ALL_TRANSACTIONS,
  dateRange,
});

const setPaymentTransactions = (data) => ({
  type: TRANSACTION.SET_PAYMENT_TRANSACTION,
  data,
});

export { loadAllTransactions, setPaymentTransactions };
