import React, { useState } from 'react'
import { Typography, FormControlLabel, Checkbox, MenuItem } from '@material-ui/core';
import clsx from 'clsx';
import {
    AccessTime as AccessTimeIcon
} from '@material-ui/icons';
import { KeyboardTimePicker } from "@material-ui/pickers";
import { BaseDropdown } from '../../../shared';
import { useStyles } from './DailyAvailability.style';

const defaultValues = {
    monday: { selected: false, startDate: null, endDate: null },
    tuesday: { selected: false, startDate: null, endDate: null },
    wednesday: { selected: false, startDate: null, endDate: null },
    thursday: { selected: false, startDate: null, endDate: null },
    friday: { selected: false, startDate: null, endDate: null },
    saturday: { selected: false, startDate: null, endDate: null },
    sunday: { selected: false, startDate: null, endDate: null },
}

export default function DailyAvailability() {
    const classes = useStyles();
    const [values, setValues] = useState(defaultValues);

    return (
        <div className={classes.dailyAvailablity}>
            <div className={clsx(classes.selectDaysRow, classes.firstRow)}>
                <div className={classes.selectColumn}>
                    <FormControlLabel
                        control={<Checkbox color="primary" name="checkedA" />}
                        label="Monday"
                        className={classes.checkbox}
                        checked={values?.monday?.selected}
                        onChange={() => setValues({ ...values, monday: { ...values.monday, selected: !values?.monday?.selected } })}
                    />
                </div>
                <div className={clsx(classes.selectColumn, classes.selectColumnRight)}>
                    <KeyboardTimePicker
                        value={values?.monday?.startDate}
                        onChange={(value) => setValues({ ...values, monday: { ...values.monday, startDate: value } })}
                        className={classes.timePicker}
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        keyboardIcon={<AccessTimeIcon />}
                        InputAdornmentProps={{ position: "start" }}
                    />
                    <Typography className={classes.selectLabel} variant='span'>Until</Typography>
                    <KeyboardTimePicker
                        value={values?.monday?.endDate}
                        onChange={(value) => setValues({ ...values, monday: { ...values.monday, endDate: value } })}
                        className={classes.timePicker}
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        keyboardIcon={<AccessTimeIcon />}
                        InputAdornmentProps={{ position: "start" }}
                    />
                </div>
            </div>
            <div className={classes.selectDaysRow}>
                <div className={classes.selectColumn}>
                    <FormControlLabel
                        control={<Checkbox color="primary" name="checkedA" />}
                        label="Tuesday"
                        className={classes.checkbox}
                        checked={values?.tuesday?.selected}
                        onChange={() => setValues({ ...values, tuesday: { ...values.tuesday, selected: !values?.tuesday?.selected } })}
                    />

                </div>
                <div className={clsx(classes.selectColumn, classes.selectColumnRight)}>
                    <KeyboardTimePicker
                        value={values?.tuesday?.startDate}
                        onChange={(value) => setValues({ ...values, tuesday: { ...values.tuesday, startDate: value } })}
                        className={classes.timePicker}
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        keyboardIcon={<AccessTimeIcon />}
                        InputAdornmentProps={{ position: "start" }}
                    />
                    <Typography className={classes.selectLabel} variant='span'>Until</Typography>
                    <KeyboardTimePicker
                        value={values?.tuesday?.endDate}
                        onChange={(value) => setValues({ ...values, tuesday: { ...values.tuesday, endDate: value } })}
                        className={classes.timePicker}
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        keyboardIcon={<AccessTimeIcon />}
                        InputAdornmentProps={{ position: "start" }}
                    />
                </div>
            </div>
            <div className={classes.selectDaysRow}>
                <div className={classes.selectColumn}>
                    <FormControlLabel
                        control={<Checkbox color="primary" name="checkedA" />}
                        label="Wednesday"
                        className={classes.checkbox}
                        checked={values?.wednesday?.selected}
                        onChange={() => setValues({ ...values, wednesday: { ...values.wednesday, selected: !values?.wednesday?.selected } })}
                    />
                </div>
                <div className={clsx(classes.selectColumn, classes.selectColumnRight)}>
                    <KeyboardTimePicker
                        value={values?.wednesday?.startDate}
                        onChange={(value) => setValues({ ...values, wednesday: { ...values.wednesday, startDate: value } })}
                        className={classes.timePicker}
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        keyboardIcon={<AccessTimeIcon />}
                        InputAdornmentProps={{ position: "start" }}
                    />
                    <Typography className={classes.selectLabel} variant='span'>Until</Typography>
                    <KeyboardTimePicker
                        value={values?.wednesday?.endDate}
                        onChange={(value) => setValues({ ...values, wednesday: { ...values.wednesday, endDate: value } })}
                        className={classes.timePicker}
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        keyboardIcon={<AccessTimeIcon />}
                        InputAdornmentProps={{ position: "start" }}
                    />
                </div>
            </div>
            <div className={classes.selectDaysRow}>
                <div className={classes.selectColumn}>
                    <FormControlLabel
                        control={<Checkbox color="primary" name="checkedA" />}
                        label="Thursday"
                        className={classes.checkbox}
                        checked={values?.thursday?.selected}
                        onChange={() => setValues({ ...values, thursday: { ...values.thursday, selected: !values?.thursday?.selected } })}
                    />

                </div>
                <div className={clsx(classes.selectColumn, classes.selectColumnRight)}>
                    <KeyboardTimePicker
                        value={values?.thursday?.startDate}
                        onChange={(value) => setValues({ ...values, thursday: { ...values.thursday, startDate: value } })}
                        className={classes.timePicker}
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        keyboardIcon={<AccessTimeIcon />}
                        InputAdornmentProps={{ position: "start" }}
                    />
                    <Typography className={classes.selectLabel} variant='span'>Until</Typography>
                    <KeyboardTimePicker
                        value={values?.thursday?.endDate}
                        onChange={(value) => setValues({ ...values, thursday: { ...values.thursday, endDate: value } })}
                        className={classes.timePicker}
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        keyboardIcon={<AccessTimeIcon />}
                        InputAdornmentProps={{ position: "start" }}
                    />
                </div>
            </div>
            <div className={classes.selectDaysRow}>
                <div className={classes.selectColumn}>
                    <FormControlLabel
                        control={<Checkbox color="primary" name="checkedA" />}
                        label="Friday"
                        className={classes.checkbox}
                        checked={values?.friday?.selected}
                        onChange={() => setValues({ ...values, friday: { ...values.friday, selected: !values?.friday?.selected } })}
                    />
                </div>
                <div className={clsx(classes.selectColumn, classes.selectColumnRight)}>
                    <KeyboardTimePicker
                        value={values?.friday?.startDate}
                        onChange={(value) => setValues({ ...values, friday: { ...values.friday, startDate: value } })}
                        className={classes.timePicker}
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        keyboardIcon={<AccessTimeIcon />}
                        InputAdornmentProps={{ position: "start" }}
                    />
                    <Typography className={classes.selectLabel} variant='span'>Until</Typography>
                    <KeyboardTimePicker
                        value={values?.friday?.endDate}
                        onChange={(value) => setValues({ ...values, friday: { ...values.friday, endDate: value } })}
                        className={classes.timePicker}
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        keyboardIcon={<AccessTimeIcon />}
                        InputAdornmentProps={{ position: "start" }}
                    />
                </div>
            </div>
            <div className={classes.selectDaysRow}>
                <div className={classes.selectColumn}>
                    <FormControlLabel
                        control={<Checkbox color="primary" name="checkedA" />}
                        label="Saturday"
                        className={classes.checkbox}
                    />
                </div>
                <div className={clsx(classes.selectColumn, classes.selectColumnRight)}>
                    <KeyboardTimePicker
                        value={null}
                        className={classes.closedTimePicker}
                        autoOk
                        disabled
                        variant="inline"
                        inputVariant="outlined"
                        keyboardIcon={<AccessTimeIcon className={classes.disabledIcon} />}
                        InputAdornmentProps={{ position: "start" }}
                    />
                </div>
            </div>
            <div className={classes.selectDaysRow}>
                <div className={classes.selectColumn}>
                    <FormControlLabel
                        control={<Checkbox color="primary" name="checkedA" />}
                        label="Sunday"
                        className={classes.checkbox}
                    />
                </div>
                <div className={clsx(classes.selectColumn, classes.selectColumnRight)}>
                    <KeyboardTimePicker
                        value={null}
                        className={classes.closedTimePicker}
                        autoOk
                        disabled
                        variant="inline"
                        inputVariant="outlined"
                        keyboardIcon={<AccessTimeIcon className={classes.disabledIcon} />}
                        InputAdornmentProps={{ position: "start" }}
                    />
                </div>
            </div>
        </div>
    )
}
