import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    column: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    columnWrap: {
        display: "grid",
        gridTemplateColumns: "3fr 2fr",
        gridGap: "20px"
    }
}));


export { useStyles }