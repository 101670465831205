import React, { useEffect } from "react";
import { Container, Grid } from "@material-ui/core";
import clsx from "clsx";
import { OfficeCard } from "./office-card";
import { CurrentPlan } from "./current-plan";
import { MailManagement } from "./my-mail-managment";
import { MyCommunications } from "./my-communications";
import { TeamMembers } from "../shared/team-members";
import { ForwardingAddressModal } from "../shared/modals/forwarding-address-modal";
import { BuyPhoneNumberModal } from "../shared/modals/buy-phone-number-modal";
import NewYorkImg from "../../assets/images/new-york.jpg";
import { UpcomingBookings } from "../space-rental/upcoming-bookings";
import { useStyles } from "./Dashboard.style";
import { setSelectedCustomer } from "../../store";
import { useDispatch } from "react-redux";

export default function Dashboard() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isForwardAddressModalOpen, setForwardAddressModal] =
    React.useState(false);
  const [isBuyPhoneNumberModalOpen, setBuyPhoneNumberModal] =
    React.useState(false);
  const handleForwardModalAddress = () => {
    setForwardAddressModal(!isForwardAddressModalOpen);
  };

  useEffect(() => {
    dispatch(setSelectedCustomer({}));
  }, [dispatch]);

  const handleBuyPhoneNumberModal = () => {
    setBuyPhoneNumberModal(!isBuyPhoneNumberModalOpen);
  };
  return (
    <>
      <ForwardingAddressModal
        show={isForwardAddressModalOpen}
        handleClose={handleForwardModalAddress}
      />
      <BuyPhoneNumberModal
        show={isBuyPhoneNumberModalOpen}
        handleClose={handleBuyPhoneNumberModal}
      />
      <Container style={{ maxWidth: 1111 }}>
        <Grid
          sm={12}
          direction="row"
          container
          className={classes.dashboardMainGrid}
          spacing={3}
        >
          <Grid sm={12} md={6} lg={4} direction="col" item>
            <OfficeCard
              header={"Satellite Office"}
              headerLinkText={"Contact front desk"}
              image={NewYorkImg}
              heading={" New York - Federal Plaza"}
              address={"305 Broadway, New York"}
              street={"NY 10007 - 7th Floor"}
              date={"July 29, 2020"}
              time={"10:15 am"}
            />
          </Grid>
          <Grid sm={12} md={6} lg={4} item>
            <UpcomingBookings showInDashboard />
          </Grid>
          <Grid
            style={{ display: "flex", flexDirection: "column" }}
            justifyContent="space-between"
            sm={12}
            md={12}
            lg={4}
            direction="col"
            item
          >
            <CurrentPlan />
          </Grid>
        </Grid>
        <Grid
          sm={12}
          direction="row"
          container
          className={classes.dashboardMainGrid}
          spacing={3}
        >
          <Grid
            sm={12}
            md={6}
            direction="col"
            className={classes.dashboardSecondMainGrid}
          >
            <MailManagement
              handleForwardAddressModal={handleForwardModalAddress}
            />
          </Grid>
          <Grid
            sm={12}
            md={6}
            direction="col"
            className={clsx(
              classes.dashboardSecondMainGrid,
              classes.teamMember
            )}
          >
            {/* <MyCommunications
              handleBuyPhoneNumberModal={handleBuyPhoneNumberModal}
            /> */}
            <TeamMembers rootClass={classes.root} />
          </Grid>
        </Grid>

        {/* <Grid
          sm={12}
          direction="row"
          container
          className={classes.dashboardMainGrid}
          spacing={3}
        >
          <Grid
            sm={12}
            md={6}
            direction="col"
            className={clsx(
              classes.dashboardSecondMainGrid,
              classes.teamMember
            )}
          >
            <TeamMembers rootClass={classes.root} />
          </Grid>
        </Grid> */}
      </Container>
    </>
  );
}
