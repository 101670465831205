import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "none",
  },
  dashboardContent: {
    marginLeft: "250px",
    marginTop: "40px",
    height: "100%",
    overflow: "hidden",
    padding: "20px",
    minHeight: "80vh",
  },
  dashboardMainGrid: {
    margin: 0,
  },
  dashboardSecondMainGrid: {
    marginTop: "12px",
    marginBottom: "12px",
    padding: "0 12px 12px",
  },
  teamMember: {
    marginTop: "0px",
  },
}));

export { useStyles };
