import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
    background: "white",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  section: {
    padding: "20px 20px 0px 20px",
    height: "calc(100vh - 70px)",
    flexGrow: 1,
    overflowY: "auto",
  },
  label: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#242223",
    fontFamily: "Roboto-Medium",
    paddingBottom: "10px",
  },
  footerButton: {
    flex: "1",
    maxWidth: "48%",
    border: "2px solid #2CA4C0",
    padding: "6px 15px",
    fontFamily: "Roboto-Medium",
  },
  footerPrimaryBtn: {
    backgroundColor: "#2CA4C0",
    color: "#fff",
  },
  btnContainer: {
    marginTop: 30,
  },
  heading: {
    padding: "20px 0",
  },
  image: {
    height: "300px",
    width: "200px",
  },
  imageContainer: {
    display: "flex",
    flexDirection: "column",
  },
  boxLink: {
    width: "200px",
    paddingTop: "20px",
  },
  loader: {
    display: "flex",
    paddingLeft: "70px",
    marginTop: "10px"
  }
}));

export { useStyles };
