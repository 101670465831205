import React from "react";
import { Box,Container, Paper, Tabs, Tab } from "@material-ui/core";
import {
  ForwardingAddresses,
  ForwardingPhoneNumbers,
} from "../../../my-account";
import { CustomerInfo } from "../../customer-info";
import { CustomerCompanyInfo } from "../../customer-company-info";
import { CustomerCurrentPlan } from "../../customer-current-plan";
import { TeamMembers } from "../../../shared";
import { useStyles } from "./ManageCustomer.style";

export default function ManageCustomer() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Container style={{maxWidth:1111}} className={classes.main}>
      <Paper className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Basic Details" />
          <Tab label="Manage customer" />
        </Tabs>
      </Paper>

      {value === 0 && (
        <Box className={classes.basicDetailColumnWrap}>
          {/* Column 1 */}
          <Box className={classes.column}>
            {/* <LocationSelector /> */}
            <CustomerInfo />
            <CustomerCompanyInfo isFromManageCustomerInfoPage={true}/>
          </Box>

          {/* Column 2 */}
          <Box className={classes.column}>
            <CustomerCurrentPlan/>
          </Box>
        </Box>
      )}
      {value === 1 && (
        <Box className={classes.columnWrap}>
          {/* Column 1 */}
          <Box className={classes.column}>
            <TeamMembers rootClass={classes.box} />
          </Box>
          {/* Column 2 */}
          <Box className={classes.column}>
            <ForwardingAddresses />
          </Box>

          {/* Column 3 */}
          <Box className={classes.column}>
            <ForwardingPhoneNumbers rootClass={classes.box} />
          </Box>
        </Box>
      )}
    </Container>
  );
}
