import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CustomRouter } from "./CustomRouter";
import Modal from "./Modal";
import { NavBar, SideBar } from "../components/shared";
import { useDispatch } from "react-redux";
import { getCustomerId, setErrorMessage, setPaymentMethods, setSelectedPaymentMethod } from "../store";
import { authenticationService } from "../core";
import { apiFetchPaymentMethods } from "../apis";
import { useSelector } from "react-redux";
const useStyles = makeStyles({
  dashboardContent: {
    marginLeft: "238px",
    minHeight: "calc(100vh - 70px)",
    height: "auto",
    backgroundColor: "#F7F6F7",
   "& .MuiDataGrid-root .MuiDataGrid-columnHeaderDraggableContainer":{
      padding: 0
    }
  },
});
export function Layout() {
  const classes = useStyles();
  const customerId = useSelector((state) => getCustomerId(state));
  const dispatch = useDispatch();
  const isAuthenticated = authenticationService.isAuthenticated();
  useEffect(() => {
    const getPaymentMethods = async () => {
      try {
        const methods = await apiFetchPaymentMethods(customerId);
        dispatch(setPaymentMethods(methods));
        dispatch(setSelectedPaymentMethod(methods.length ? methods[0] : {}));
      }
      catch (error) {
        dispatch(setErrorMessage(error.toString()));
      }
    }
    // dispatch(loadPaymentMethods());
    if (isAuthenticated) getPaymentMethods();
  }, [dispatch, isAuthenticated,customerId]);
  return (
    <>
      <NavBar />
      <SideBar />
      <Modal />
      <div className={classes.dashboardContent}>
        <CustomRouter />
      </div>
    </>
  );
}
