import { SPACERENTAL } from "../../../actions";

const filterReducer = (state = {}, action) => {
  switch (action.type) {
    case SPACERENTAL.RESET_AVAILABLE_ROOMS_FILTER:
      return {};
    case SPACERENTAL.SET_AVAILABLE_ROOMS_FILTER:
      return action.filter;
    default:
      return state;
  }
};

export { filterReducer };
