import { Box, CircularProgress, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { apiGetLocationDetailById } from "../../../../apis";
import { setErrorMessage } from "../../../../store";
import { AddLocation } from "../add-location";
import { useStyles } from "./styles";

export default function EditLocation() {
  const classes = useStyles();
  const [locationDetail, setLocationDetail] = useState();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  let params = useParams();
  useEffect(() => {
    const fetchLocationDetail = async () => {
      try {
        const response = await apiGetLocationDetailById(params.locationId);
        setLocationDetail(response);
        setLoading(false);
      }
      catch (error) {
        setLoading(false);
        dispatch(setErrorMessage(error.toString()));
      }
    };

    if (params.locationId) fetchLocationDetail();
  }, [params.locationId]);

  return loading ? (
    <Box className={classes.loader}><CircularProgress  size="3.5rem"/></Box>
  ) : (
    <AddLocation locationDetail={locationDetail} />
  );
}
