import React from "react";
export default function RequestScanIcon(props) {
  return (
    <svg
      className={`${props.className} iconSvg`}
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.9281 10.152V11.1531H0.199951H0.0999512V11.2531V14.1827C0.0999512 15.8534 1.45898 17.2124 3.12964 17.2124H20.2H20.3V17.1124V10.152C20.3 9.00561 19.6648 7.97038 18.6428 7.45106L6.0721 1.06325C5.73438 0.891633 5.32147 1.02629 5.14985 1.36402C4.97822 1.70175 5.11288 2.11466 5.45062 2.28629L18.0213 8.67408C18.5808 8.9584 18.9281 9.52442 18.9281 10.152ZM18.9281 15.8406H3.12964C2.21561 15.8406 1.47183 15.0968 1.47183 14.1827V12.5249H18.9281V15.8406Z"
        fill="#B8BFC5"
        stroke="#A19E9E"
        stroke-width="0.2"
      />
    </svg>
  );
}
