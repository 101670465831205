import React, { useState } from "react";
import { BaseSearchDropdown } from "..";

const options = [
  {
    name: "New York (212)",
  },
  {
    name: "Los Angeles (323)",
  },
  {
    name: "Boston (324)",
  },
  {
    name: "Dallas (321)",
  },
  {
    name: "Florida (432)",
  },
  {
    name: "Washington (123)",
  },
];

export default function AreaInput(props) {
  const [value, setValue] = useState({});
  return (
    <BaseSearchDropdown
      options={options}
      value={value}
      onChange={setValue}
      label="name"
      {...props}
    />
  );
}
