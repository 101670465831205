import { makeStyles } from "@material-ui/core";
import backgroundImage from "../../../assets/images/currentPlan.png";

const useStyles = makeStyles((theme) => ({
  box: {
    border: "1px solid #CDE0F1",
    fontFamily: " Roboto-Light",
    height: "100%",
    background: "rgba(242, 246, 250, 0.8)",
  },
  boxHeader: {
    display: "flex",
    background: "#F1F9FE",
    borderBottom: "1px solid #CDE0F1",
    boxSizing: "border- box",
    padding: "15px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  boxHeading: {
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "18px",
    color: "#242223",
    fontFamily: "Roboto-Medium",
  },
  boxLeftHeading: {
    fontSize: "16px",
    lineHeight: "19px",
    color: "#242223",
    fontWeight: "600",
    marginBottom: "5px",
  },
  boxLink: {
    fontSize: "14px",
    lineHeight: "16px",
    color: "#2CA4C0",
  },
  boxContent: {
    display: "flex",
    padding: "15px",
    fontSize: "14px",
    color: "#494949",
    letterSpacing: "0.01em",
    flexWrap: "wrap",
    justifyContent: "space-between",
    background: "rgba(242, 246, 250, 0.8)",
    flexDirection: "column",
  },
  imgSectionContent: {
    color: "#fff",
    padding: "15px",
    fontSize: "14px",
    letterSpacing: "0.01em",
    flexWrap: "wrap",
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
  boxPrice: {
    fontSize: "22px",
    lineHeight: "26px",
    color: theme.palette.primary.light,
    fontWeight: 600,
  },
  myCurrentSpan: {
    color: "#D95767",
  },
  imgSection: {
    color: "#fff",
    height: 93,
    background: `linear-gradient(to right, rgba(21, 38, 81, 0.7), rgba(16, 47, 81, 0.8)), url(${backgroundImage})`,
    backgroundSize: "cover",
  },
  firmIdentity: {
    fontWeight: "bold",
    fontSize: 16,
    lineHeight: "19px",
    letterSpacing: "0.01em",
  },
  list: {
    width: "100%",
  },
  listItem: {
    padding: "10px 16px",
    justifyContent: "space-between",
  },
  bulletIcon: {
    fontSize: "0.8em",
    marginRight: 15,
    color: "#2CA4C0",
  },
}));

export { useStyles };
