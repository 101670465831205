import React from "react";
import {
  Modal,
  Fade,
  Backdrop,
  Button,
  Typography,
  OutlinedInput,
  IconButton,
  Box,
  InputAdornment,
  Divider,
  FormHelperText,
  CircularProgress,
} from "@material-ui/core";
import { HighlightOff as HighlightOffIcon } from "@material-ui/icons";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  VisibilityOutlined as VisibilityOutlinedIcon,
  VisibilityOffOutlined as VisibilityOffOutlinedIcon,
} from "@material-ui/icons";
import { apiChangePassword } from "../../../apis";
import { closeModal, setErrorMessage, setSuccessMessage } from "../../../store";
import { ModalType } from "../../../core";
import { useStyles } from "./ChangePassword.style";
import { useState } from "react";

const validationSchema = yup.object({
  oldPassword: yup
    .string("Enter old Password")
    .min(8, "Old password must be more then 8 digit")
    .required("Old password is required"),
  newPassword: yup
    .string("Enter old Password")
    .min(8, "New password must be more then 8 digit")
    .required("New password is required"),
  confirmPassword: yup
    .string("Enter old Password")
    .required("New password is required")
    .test("match", "Confirm password do not match", function (confirmPassword) {
      return confirmPassword === this.parent.newPassword;
    }),
});

export default function ChangePassword() {
  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    oldPasswordVisible: false,
    newPasswordVisible: false,
    confirmPasswordVisible: false,
  };
  const state = useSelector((state) => state);
  const classes = useStyles();
  const dispatch = useDispatch();
  const modal = state.ui.modal[ModalType.ChangePassword] || false;

  const inputType = (isVisible) => (isVisible ? "text" : "password");
  const [isLoading, setIsloading] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        const userId = state.auth.data?.user_id;
        const { oldPassword, newPassword } = values;
        setIsloading(true);
        await apiChangePassword({ oldPassword, newPassword, userId });
        setIsloading(false);
        dispatch(setSuccessMessage("Password changed successfully"));
        handleClose();
      } catch (error) {
        setIsloading(false);
        dispatch(setErrorMessage(error.toString()));
      }
    },
  });

  const handleClose = () => {
    formik.resetForm();
    dispatch(closeModal(ModalType.ChangePassword));
  };

  return (
    <Modal
      aria-labelledby="add-note-agent-modal-title"
      aria-describedby="add-note-agent-modal-description"
      open={modal}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      className={classes.modal}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modal}>
        <form
          onSubmit={formik.handleSubmit}
          autoComplete="off"
          className={classes.paper}
        >
          <Box className={classes.modalHeader}>
            <Typography variant="h6" className={classes.modalHeading}>
              Change the password
            </Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <HighlightOffIcon />
            </IconButton>
          </Box>
          <Box className={classes.modalBody}>
            <Typography variant="h5" className={classes.modalSubhead}>
              Old Password
            </Typography>
            <OutlinedInput
              id="old-password"
              type={inputType(formik.values.oldPasswordVisible)}
              fullWidth
              classes={{
                root: classes.input,
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      formik.setFieldValue(
                        "oldPasswordVisible",
                        !formik.values.oldPasswordVisible
                      )
                    }
                  >
                    {formik.values.oldPasswordVisible ? (
                      <VisibilityOffOutlinedIcon color="primary" />
                    ) : (
                      <VisibilityOutlinedIcon color="primary" />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              name="oldPassword"
              value={formik.values.oldPassword}
              onChange={formik.handleChange}
            />
            <FormHelperText
              error={
                formik.touched.oldPassword && Boolean(formik.errors.oldPassword)
              }
            >
              {formik.touched.oldPassword && formik.errors.oldPassword}
            </FormHelperText>
            <Divider className={classes.divider} />
            <Typography variant="h5" className={classes.modalSubhead}>
              New password
            </Typography>
            <OutlinedInput
              id="new-password"
              type={inputType(formik.values.newPasswordVisible)}
              fullWidth
              classes={{
                root: classes.input,
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      formik.setFieldValue(
                        "newPasswordVisible",
                        !formik.values.newPasswordVisible
                      )
                    }
                  >
                    {formik.values.newPasswordVisible ? (
                      <VisibilityOffOutlinedIcon color="primary" />
                    ) : (
                      <VisibilityOutlinedIcon color="primary" />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              name="newPassword"
              value={formik.values.newPassword}
              onChange={formik.handleChange}
            />
            <FormHelperText
              error={
                formik.touched.newPassword && Boolean(formik.errors.newPassword)
              }
            >
              {formik.touched.newPassword && formik.errors.newPassword}
            </FormHelperText>
            <Typography variant="h5" className={classes.modalSubhead}>
              Confirm password
            </Typography>
            <OutlinedInput
              id="confirm-password"
              type={inputType(formik.values.confirmPasswordVisible)}
              fullWidth
              classes={{
                root: classes.input,
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      formik.setFieldValue(
                        "confirmPasswordVisible",
                        !formik.values.confirmPasswordVisible
                      )
                    }
                  >
                    {formik.values.confirmPasswordVisible ? (
                      <VisibilityOffOutlinedIcon color="primary" />
                    ) : (
                      <VisibilityOutlinedIcon color="primary" />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              name="confirmPassword"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
            />
            <FormHelperText
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
            >
              {formik.touched.confirmPassword && formik.errors.confirmPassword}
            </FormHelperText>
            <FormHelperText error={Boolean(formik.errors?.apiError)}>
              {formik.errors?.apiError}
            </FormHelperText>
          </Box>
          {isLoading && <Box className={classes.loader}><CircularProgress /></Box>}
          <Box className={classes.modalFooter}>
            <Button
              className={clsx(classes.footerButton, classes.footerPrimaryBtn)}
              variant="contained"
              color="primary"
              type="submit"
              disabled={isLoading}
            >
              Save changes
            </Button>
            <Button
              className={classes.footerButton}
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Fade>
    </Modal>
  );
}
