import React, { useCallback } from "react";
import {
  Modal,
  Slide,
  Backdrop,
  Typography,
  Box,
  IconButton,
} from "@material-ui/core";
import {
  HighlightOff as HighlightOffIcon,
  CalendarToday as CalendarTodayIcon,
  Schedule as ScheduleIcon,
  PeopleOutline as PeopleOutlineIcon,
} from "@material-ui/icons";
import { Back } from "../../../icons";
import { isEmpty } from "lodash";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { RoomListing } from "./room-listing";
import { SelectedRoom } from "./selected-room";
import { closeModal, clearActiveAvailableRoom } from "../../../store";
import { ModalType } from "../../../core";
import { useStyles } from "./RoomAvailabilityResultModal.style";

export default function RoomAvailabilityResultModal() {
  const modal = useSelector(
    (state) => state.ui.modal[ModalType.RoomAvailabilityResultModal] || false
  );
  const { results, selected, filter } = useSelector(
    (state) => state.spaceRental.availableRooms
  );
  const roomTypes = useSelector((state) => state.ui.master.roomTypes);
  const locations = useSelector((state) => state.customer.locations.data);

  const dispatch = useDispatch();
  const classes = useStyles();
  const handleClose = () => {
    dispatch(closeModal(ModalType.RoomAvailabilityResultModal));
  };

  const getPeoplePreference = useCallback(
    () =>
      filter?.roomTypeId
        ? roomTypes.find((x) => x.id === filter?.roomTypeId)?.name
        : "any",
    [filter?.roomTypeId, roomTypes]
  );

  const getLocationName = useCallback(
    () =>
      filter?.locationId
        ? locations.find((x) => x.id === filter?.locationId)?.name
        : "any",
    [filter?.locationId, locations]
  );

  const handleBack = () => dispatch(clearActiveAvailableRoom());

  return (
    <Modal
      aria-labelledby="manage-answering-script-modal-title"
      aria-describedby="manage-answering-script-modal-description"
      open={modal}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      className={classes.modal}
      BackdropProps={{
        timeout: 500,
      }}
      disableEnforceFocus
    >
      <Slide direction="left" in={modal}>
        <Box className={classes.paper}>
          <Box className={classes.modalHeader}>
            <Typography variant="h6" className={classes.modalHeading}>
              {isEmpty(selected) ? (
                `Available rooms in ${getLocationName()} (${results.length})`
              ) : (
                <>
                  <IconButton aria-label="back" onClick={handleBack}>
                    <Back />
                  </IconButton>
                  {`${selected.name} (${getLocationName()})`}
                </>
              )}
            </Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <HighlightOffIcon />
            </IconButton>
          </Box>
          <Box className={classes.modalBody}>
            <Box className={classes.boxSubHeader}>
              <Typography>
                <CalendarTodayIcon fontSize="small" color="primary" />
                {moment(filter.date).format("DD MMM YYYY")}
              </Typography>
              <Typography>
                <ScheduleIcon fontSize="small" color="primary" />
                {filter.fromTime
                  ? `${moment(filter.fromTime, ["HH:mm"]).format(
                      "h:mm A"
                    )} - ${moment(filter.endTime, ["HH:mm"]).format("h:mm A")}`
                  : " any"}
              </Typography>
              <Typography>
                <PeopleOutlineIcon fontSize="medium" color="primary" />{" "}
                {`${getPeoplePreference()} people`}
              </Typography>
            </Box>
            {isEmpty(selected) ? (
              <RoomListing data={results} />
            ) : (
              <SelectedRoom />
            )}
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
}
