import { MAILMANAGEMENT } from "./action-types";

const clearMailManagement = () => ({
  type: MAILMANAGEMENT.CLEAR,
});

const setActiveTab = (activeTab) => ({
  type: MAILMANAGEMENT.SET_ACTIVE_TAB,
  activeTab,
});

const setMMCurrentPage = (currentPage) => ({
  type: MAILMANAGEMENT.SET_CURRENT_PAGE,
  currentPage,
});

const setMMTotalPages = (totalPages) => ({
  type: MAILMANAGEMENT.SET_TOTAL_PAGES,
  totalPages,
});

const setMMSearchText = (search) => ({
  type: MAILMANAGEMENT.SET_SEARCH_TEXT,
  search,
});

const searchMailItems = () => ({
  type: MAILMANAGEMENT.SEARCH_MAIL_ITEMS,
});

const setMailItems = (data) => ({
  type: MAILMANAGEMENT.SET_MAIL_ITEMS,
  data,
});

const setMailItem = (selectedRow) => ({
  type: MAILMANAGEMENT.SET_MAIL_ITEM,
  selectedRow,
});

const setSelectedMailRowIds = (selectedRowIds) => ({
  type: MAILMANAGEMENT.SET_SELECTED_MAIL_ROW_IDS,
  selectedRowIds,
});

const setMailLoading = () => ({
  type: MAILMANAGEMENT.LOAD_MAIL_ITEMS,
});

const setTotalCount = (total) => ({
  type: MAILMANAGEMENT.SET_TOTAL,
  total,
});

const setMailAction = (mailAction) => ({
  type: MAILMANAGEMENT.SET_MAIL_ACTION,
  mailAction,
});

const setMailOptions = (options) => ({
  type: MAILMANAGEMENT.SET_MAIL_OPTIONS,
  options,
});

const loadMailItemsFailed = () => ({
  type : MAILMANAGEMENT.LOAD_MAIL_ITEMS_FAILED
})

export {
  clearMailManagement,
  setMMCurrentPage,
  setMMTotalPages,
  setMailItems,
  setSelectedMailRowIds,
  setMailLoading,
  setTotalCount,
  setActiveTab,
  setMMSearchText,
  searchMailItems,
  setMailAction,
  setMailItem,
  setMailOptions,
  loadMailItemsFailed
};
