import { makeStyles } from "@material-ui/core";
import signupBackground from "../../assets/images/signup-background.png"
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    signup: {
        display: 'grid',
        'grid-template-columns': '1fr 1fr',
        height: '100vh',
    },
    signupLeft: {
        padding: '10% 0',
        textAlign: 'center'
    },
    signupLeftContainer: {
        maxWidth: '505px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    signupLeftBtn: {
        padding: 14,
        backgroundColor: '#102f51',
        borderRadius: 2,
        color: '#fff',
        marginTop: '10px',
        fontSize: '17px',
        lineHeight: '20px',
        letterSpacing: 1,
        "&:hover": {
            backgroundColor: "#102f51"
        }
    },
    signupLeftH4: {
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '130%',
        letterSpacing: '0.02em',
        color: '#242223',
        fontFamily: 'Roboto-Medium'
    },
    signupLeftBody: {
        fontSize: '16px',
        lineHeight: '20px',
        color: '#494949',
    },
    signupFormWrap: {
        marginTop: '60px',
        width: '100%'
    },
    signupLogo: {
        width: '201px'
    },
    signupLogoImg: {
        maxWidth: '100%'
    },
    signupForm: {
        marginTop: '44px',
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        textAlign: 'left'
    },
    signupInput: {
        height: '48px'
    },
    signupTransfromEmail: {
        animation: 'transformEmail 1s'
    },
    signupTransformPassword: {
        animation: 'transformPassword 1s'
    },
    signupTransformButton: {
        animation: 'transformButton 0.5s'
    },
    signupTransformRightButton: {
        animation: 'transformRightButton 0.5s'
    },
    signupBackToLogin: {
        fontSize: '15px',
        lineHeight: '40px',
        color: '#494949',
        textDecoration: 'none',
        marginTop: 'auto'
    },
    signupRight: {
        background: `url(${signupBackground}) left top`,
        backgroundSize: 'cover',
        position: 'relative'
    },
    signupRightBgLayer: {
        background: '#102f51',
        opacity: 0.8,
        height: '100%'
    },
    signupRightBox: {
        background: '#102f51',
        border: '2px solid #8eeaff',
        padding: '33px 49px 51px',
        position: 'absolute',
        bottom: '25%',
        width: '425px',
        left: '82px',
        opacity: 0.8
    },
    signupRightBoxH4: {
        fontSize: '16px',
        lineHeight: '19px',
        color: '#8eeaff',
        fontWeight: 'normal'
    },
    signupRightBoxH3: {
        fontSize: '26px',
        lineHeight: '30px',
        letterSpacing: '0.01em',
        color: '#fff'
    },
    signupTrialBtn: {
        background: '#8eeaff',
        borderRadius: '2px',
        padding: '15px',
        fontSize: '18px',
        lineHeight: '21px',
        letterSpacing: '0.01em',
        color: '#102f51',
        position: 'absolute',
        bottom: '-25px',
        left: '36px',
        width: '235px',
        "&:hover": {
            backgroundColor: "#8eeaff"
        }
    },
    signupNote: {
        fontStyle: 'italic',
        fontSize: '14px',
        lineHeight: '16px',
        color: '#494949'
    },
    signupStartNow: {
        background: theme.palette.primary.main,
        borderRadius: '2px',
        padding: '17px',
        fontWeight: 'bold',
        fontSize: '15px',
        lineHeight: '18px',
        letterSpacing: '0.01em',
        color: '#fff',
        position: 'absolute',
        bottom: '5%',
        transform: 'translateX(-50%)',
        left: '59%',
        width: '300px',
        "&:hover": {
            backgroundColor: theme.palette.primary.main
        }
    },
    signupFormGroup: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    },
    signupFormLabel: {
        marginBottom: '5px',
        display: 'block',
        fontFamily: 'Roboto-Medium',
        color: 'inherit',
        fontWeight: 400
    },
    signupProfileField: {
        position: 'relative',
        marginBottom: '22px',
        maxWidth: '45%',
        flex: '1 1',
    },
    signupEmailField: {
        width: '100%',
        maxWidth: '100%',
    },
    signupProfileFieldIcon: {
        position: 'absolute',
        right: '20px',
        top: '20px'
    },
    startAdornment: {
        backgroundColor: '#F1F9FE',
        height: '100%',
        maxHeight: '100%',
        padding: '0 8px'
    }

}));


export { useStyles }