import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    boxContent: {
        display: 'flex',
        padding: "15px",
        fontSize: "14px",
        color: "#494949",
        letterSpacing: "0.01em",
    },
    boxContentRight: {
        display: 'flex',
        flex: 1,
        marginLeft: "10px",
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        flexWrap: 'wrap'
    },
    boxContentHeading: {
        marginBottom: "5px",
        color: "#242223",
        fontWeight: "500",
        fontSize: "15px",
        lineHeight: "18px",
        fontFamily: 'Roboto-Medium'
    },
    boxContentDataRow: {
        display: 'flex',
        marginTop: "0",
        borderBottom: "1px solid #DFDFE4",
        padding: "10px 0",
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
    },
    boxContentLink: {
        marginTop: "15px",
        fontSize: "14px",
        lineHeight: "16px",
        color: theme.palette.primary.main,
        display: 'flex',
        alignItems: 'center',
    },
    AddIcon: {
        width: "14px",
        marginRight: "5px"
    },
    boxContentDataRowEdit: {
        color: theme.palette.primary.main,
        width: "14px",
    },
    noBorder: {
        border: "none"
    },
    directoryRow: {
        display: 'flex',
        alignItems: 'center'
    },
    directoryRowContent: {
        marginLeft: '20px'
    }
}));


export { useStyles }