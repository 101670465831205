import { SPACERENTAL } from "../../../actions";

const selectedReducer = (state = {}, action) => {
  switch (action.type) {
    case SPACERENTAL.CLEAR:
    case SPACERENTAL.RESET_AVAILABLE_ROOMS:
    case SPACERENTAL.RESET_ACTIVE_AVAILABLE_ROOM:
      return {};
    case SPACERENTAL.SET_ACTIVE_AVAILABLE_ROOM:
      return action.selected;
    default:
      return state;
  }
};

export { selectedReducer };
