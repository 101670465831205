import React, { Fragment } from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { CustomerInfo } from "../customer-info";
import { CustomerCompanyInfo } from "../customer-company-info";
import { CustomerCurrentPlan } from "../customer-current-plan";
import { useStyles } from "./Dashboard.style";

export default function Dashbaord() {
  const classes = useStyles();
  const selected = useSelector((state) => state.admin.customer.selected);
  return (
    <Fragment>
      {isEmpty(selected) ? (
        <Box className={classes.main}></Box>
      ) : (
        <Box className={classes.main}>
          <Box className={classes.columnWrap}>
            {/* Column 1 */}
            <Box className={classes.column}>
              <CustomerInfo />
              <CustomerCompanyInfo />
            </Box>

            {/* Column 2 */}
            <Box className={classes.column}>
              <CustomerCurrentPlan />
            </Box>
          </Box>
        </Box>
      )}
    </Fragment>
  );
}
