import { http, handleResponse } from "../core";
import { URL } from "./_urls.js";

const apiUpdateUser = async (data) => {
  return handleResponse(await http.post(URL.UPDATE_USER_API(), data));
};

const apiFetchUserDetail = async (userId) => {
  return handleResponse(await http.get(URL.FETCH_USER_DETAIL(userId)));
};

const apiChangePassword = async (data) => {
  return handleResponse(await http.post(URL.CHANGE_PASSWORD_API(), data));
};

const apiFetchUserForwardingPhoneNumber = async () => {
  return handleResponse(
    await http.get(URL.FETCH_USER_FORWARDING_PHONE_NUMBERS())
  );
};

const apiFetchTeamMembers = async (
  locationId,
  customerId,
  includeLoginUser = false
) => {
  return handleResponse(
    await http.get(
      URL.FETCH_TEAM_MEMBERS(locationId, customerId, includeLoginUser)
    )
  );
};

const apiForgetPassword = async (data) => {
  return handleResponse(await http.post(URL.FORGET_PASSWORD_API(), data));
};

const apiCPThroughLink = async (data) => {
  return handleResponse(await http.post(URL.CP_THROUGH_LINK_API(), data));
};

export {
  apiUpdateUser,
  apiFetchUserDetail,
  apiChangePassword,
  apiFetchUserForwardingPhoneNumber,
  apiFetchTeamMembers,
  apiForgetPassword,
  apiCPThroughLink,
};
