import React, { useState } from "react";
import { Box, Typography, Link, Avatar, IconButton, CircularProgress } from "@material-ui/core";
import clsx from "clsx";
import { Edit as EditIcon } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { useFormik } from "formik";
import S3 from "react-aws-s3";
import { apiUpdateUser } from "../../../apis";
import {
  openModal,
  getUserInfoSelector,
  setAuthData,
  setErrorMessage,
  setSuccessMessage,
} from "../../../store";
import { ModalType } from "../../../core";
import { AWSCONFIG } from "../../../config";
import { QuickEditInput } from "../../shared";
import { useStyles } from "./UserInfo.style";
import ImageIcon from "../../../assets/images/imageIcon.jpg";
import { userHasEditPermission } from "../../../constants/constants";
const ReactS3Client = new S3(AWSCONFIG);

export default function UserInfo() {
  const classes = useStyles();
  const state = useSelector((state) => state);
  const initialValues = getUserInfoSelector(state);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const userType = state.auth.data.user_type;

  const handleImageChange = async (acceptedFiles) => {
    if (acceptedFiles.length) {
      const { userId, userEmail } = initialValues;
      const file = acceptedFiles[0];
      try {
        const s3Response = await ReactS3Client.uploadFile(
          file,
          `${userEmail.replace(/[^\w\s]/gi, "")}_${Math.random()}`
        );
        const request = { row: { imageUrl: s3Response.location }, userId };
        setIsLoading(true);
        await handleUpdateUser(request);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        dispatch(setErrorMessage(error.toString()));
      }
    }
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    accept: "image/*",
    onDrop: handleImageChange,
  });

  const openChangePassword = () => {
    dispatch(openModal(ModalType.ChangePassword));
  };

  const handleUpdateUser = async (request) => {
    setIsLoading(true);
    const res = await apiUpdateUser(request);
    setIsLoading(false)
    dispatch(setAuthData(res));
    dispatch(setSuccessMessage("User info updated"));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (values) => {
      try {
        const { field, userId } = values;
        const request = { row: { [field]: values[field] }, userId };
        await handleUpdateUser(request);
      } catch (error) {
        dispatch(setErrorMessage(error.toString()));
      }
    },
  });

  const handleEditClick = (field) => {
    formik.setFieldValue("field", field);
  };

  return (
    <Box component="Box" className={classes.box}>
      <Box className={classes.boxHeader}>
        <Typography variant="h6" className={classes.boxHeading}>
          User Info
        </Typography>
        <Typography variant="inherit" className={classes.boxLink}>
          {formik.values.user_type}
        </Typography>
        {isLoading && <Box>
          <CircularProgress size="1.5rem"></CircularProgress>
        </Box>}
      </Box>
      <Box className={clsx(classes.boxRow, classes.noBorder)}>
        <Box className={classes.boxContent}>
          <Box className={classes.boxContentDataRow}>
            <Box
              {...getRootProps({ className: "dropzone" })}
              className={classes.imageContainer}
            >
              <input {...getInputProps()} />
              <Avatar
                alt="Remy Sharp"
                src={initialValues?.imageUrl || ImageIcon}
              />
              {userHasEditPermission(userType) && <Box className={classes.imageContent}>
                {/* <Typography variant="body1" >Sevahn_Simonain.jpg</Typography> */}
                <Link
                  component="button"
                  variant="inherit"
                  className={classes.boxLink}
                  onClick={open}
                >
                  Upload new image
                </Link>
              </Box>}
            </Box>
          </Box>
          <QuickEditInput
            label="Full name"
            name="fullName"
            value={formik.values.fullName}
            onChange={formik.handleChange}
            className={classes.evenBoxContentDataRow}
            handleEditClick={handleEditClick}
            onSave={formik.handleSubmit}
            isEditable={userHasEditPermission(userType)}
          />
          <QuickEditInput
            label="Position"
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            handleEditClick={handleEditClick}
            onSave={formik.handleSubmit}
            isEditable={userHasEditPermission(userType)}
          />
          <QuickEditInput
            className={classes.evenBoxContentDataRow}
            label="Username"
            name="userName"
            value={formik.values.userName}
            onChange={formik.handleChange}
            handleEditClick={handleEditClick}
            onSave={formik.handleSubmit}
            isEditable={userHasEditPermission(userType)}
          />
          <Box className={clsx(classes.boxContentDataRow)}>
            <Box className={classes.dataRow}>
              <Box>
                <Typography variant="body1">Password</Typography>
                <Typography variant="subtitle1">****************</Typography>
              </Box>
            </Box>
            {userHasEditPermission(userType) && <IconButton
              className={classes.boxContentDataRowEdit}
              onClick={openChangePassword}
            >
              <EditIcon className={classes.editIcon} />
            </IconButton>}
          </Box>
          <QuickEditInput
            label="Email address"
            name="userEmail"
            value={formik.values.userEmail}
            onChange={formik.handleChange}
            className={classes.evenBoxContentDataRow}
            handleEditClick={handleEditClick}
            onSave={formik.handleSubmit}
            isEditable={userHasEditPermission(userType)}
          />
          <QuickEditInput
            label="Phone"
            name="phone"
            value={formik.values.phone}
            onChange={formik.handleChange}
            handleEditClick={handleEditClick}
            onSave={formik.handleSubmit}
            isEditable={userHasEditPermission(userType)}
          />
        </Box>
      </Box>
    </Box >
  );
}
