import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { apiFetchCustomerForwardingAddresses } from "../../../apis";
import { BaseSearchDropdown } from "..";
import { useDispatch } from "react-redux";
import { setErrorMessage } from "../../../store";

export default function ForwardingAddressInput(props) {
  const { auth } = useSelector((state) => state);
  const customerId = auth.data.customer_id;
  const locationId = auth.data.location_id;
  const dispatch = useDispatch();

  const [value, setValue] = useState({});
  const [options, setOptions] = useState([]);

  const formatAddress = (row) => {
    const { address1, address2, city, state, zip, country } = row;
    let address = "";
    address += address1 ? `${address1},` : "";
    address += address2 ? ` ${address2},` : "";
    address += city ? ` ${city},` : "";
    address += state ? ` ${state},` : "";
    address += zip ? ` ${zip},` : "";
    address += country ? ` ${country}` : "";
    return address;
  };

  useEffect(() => {
    async function fetchAPI() {
      try {
        const res = await apiFetchCustomerForwardingAddresses(
          locationId,
          customerId
        );
        const formated = res.map((row) => ({
          name: formatAddress(row),
          id: row.addressId,
        }));
        setOptions(formated);
      } catch (error) {
        dispatch(setErrorMessage(error.toString()));
      }
    }
    if (customerId && locationId) fetchAPI();
  }, [customerId, dispatch, locationId]);

  useEffect(() => {
    const { onChange } = props;
    onChange && onChange(value?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  return (
    <BaseSearchDropdown
      id="forwarding-address-search-dropdown"
      options={options}
      value={value}
      onChange={setValue}
      label="name"
    />
  );
}
