import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  box: {
    background: "#FFFFFF",
    border: "1px solid #CDE0F1",
  },
  boxContainer: {
    background: "#FFFFFF",
    border: "1px solid #CDE0F1",
  },
  boxHeader: {
    display: "flex",
    background: "#F1F9FE",
    borderBottom: "1px solid #CDE0F1",
    boxSizing: "border- box",
    padding: "0 5px 0 15px",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: "49px",
  },
  boxHeading: {
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "18px",
    color: "#242223",
    fontFamily: "Roboto-Medium",
  },
  boxLink: {
    fontSize: "14px",
    lineHeight: "16px",
    color: theme.palette.primary.main,
  },
  link: {
    fontSize: "15px",
    lineHeight: "16px",
    color: "#8EEAFF",
    fontFamily: "Roboto-Medium",
    justifyContent: "left",
    display: "flex"
  },
  navigation: {
    display: "flex",
    alignItems: "center",
  },
  navigationButton: {
    padding: "5px",
  },
  boxContent: {
    display: "flex",
    fontSize: "14px",
    color: "#494949",
    letterSpacing: "0.01em",
    position: "relative",
    minHeight: "350px",
  },
  backgroundImg: {
    width: "100%",
    maxHeight: "350px",
  },
  backgroundImgDashboard: {
    width: "100%",
    height: "375px",
  },
  bookingsContentDiv: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "40%",
    display: "grid",
    background: "#102F51",
    opacity: "0.85",
    color: "white",
    padding: "20px",
  },
  roomInfoContainer: {
    alignSelf: "flex-end",
  },
  roomInfo: {
    color: "#8EEAFF",
  },
  dateTimeContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  loader: {
    paddingLeft: "10px"
  }
}));

export { useStyles };
