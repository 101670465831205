import React, { useEffect, useState } from "react";
import find from "lodash/find";
import { getCountryDetailSpheremail } from "../../../apis";
import { BaseSearchDropdown } from "..";
import { useDispatch, useSelector } from "react-redux";
import { setErrorMessage, getSelectedLocationId } from "../../../store";

export default function StateInput(props) {
  const { value, onChange, country } = props;
  const locationId = useSelector((state) => getSelectedLocationId(state));
  const [options, setOptions] = useState([]);
  const seleced = find(options, { id: value });
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchAPI() {
      try {
        const response = await getCountryDetailSpheremail(country, locationId);
        setOptions(response.states);
      } catch (error) {
        dispatch(setErrorMessage(error.toString()));
      }
    }
    if (country && locationId) fetchAPI();
    else setOptions([]);
  }, [country, dispatch, locationId]);

  return (
    <BaseSearchDropdown
      id="state-search-dropdown"
      options={options}
      value={seleced}
      onChange={onChange}
      label="name"
    />
  );
}
