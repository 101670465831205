import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: "sans-serif",
        color: "#242223",
    },
    paper: {
        width: 524,
        background: '#fff',
        position: 'fixed',
        maxHeight: "100vh",
        overflow: "auto",
        border: "1px solid #CDE0F1",
        right: 0
    },
    modalHeader: {
        padding: '10px 15px',
        background: "#F1F9FE",
        borderBottom: "1px solid #CDE0F1",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    modalHeading: {
        fontWeight: "500",
        fontSize: "15px",
        lineHeight: "18px",
        color: "#242223",
        fontFamily: 'Roboto-Medium'
    },
    modalSubHead: {
        margin: "15px 0"
    },
    closeButton: {
        color: theme.palette.grey[500],
        padding: 0
    },
    modalBody: {
        padding: '20px 15px'
    },
    modalFooter: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 15px",
        marginBottom: 15
    },
    footerButton: {
        flex: "1",
        maxWidth: "48%",
        border: "2px solid #2CA4C0",
        padding: "6px 15px",
        fontFamily: 'Roboto-Medium'
    },
    footerPrimaryBtn: {
        backgroundColor: "#2CA4C0",
        color: "#fff"
    },
    forChangeText: {
        color: "#D95767",
        fontStyle: "italic",
        marginBottom: 24
    },

    lastChild: {
        marginBottom: 0
    },
    boxScriptRow: {
        background: "#F1F9FE",
        padding: 16,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "15px 0"
    },
    editIcon: {
        color: theme.palette.primary.main,
        width: "14px",
        marginLeft: 10
    },
    radioGroup: {
        marginBottom: 15
    },
    radio: {
        padding: "5px 10px",
    },
    dailyAvailablity: {
        margin: "25px 0",
        borderTop: "2px dashed lightgrey",
        borderBottom: "2px dashed lightgrey"
    },
    select: {
        padding: "8px 16px",
        border: "1px solid #CDE0F1",
        paddingRight: 35,
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap"
    },
    selectDropdown: {
        background: "#F1F9FE",
        padding: '5px 8px',
        fontSize: 12,
        lineHeight: "18px",
        display: "inline-flex",
        alignItems: "center",
        marginLeft: 10
    },
    crossIconLeft: {
        marginLeft: 5,
        width: 14
    },
    crossIconRight: {
        marginRight: 5,
        width: 14
    },
    downIcon: {
        position: "absolute",
        right: 20
    },

}));


export { useStyles }