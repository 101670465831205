import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiFetchTeamMembers } from "../../../apis";
import { BaseSearchDropdown } from "..";
import { setErrorMessage } from "../../../store";

export default function ForwardingToInput(props) {
  const { auth } = useSelector((state) => state);
  const customerId = auth.data.customer_id;
  const locationId = auth.data.location_id;
  const dispatch = useDispatch();
  const [value, setValue] = useState({});
  const [options, setOptions] = useState([]);

  useEffect(() => {
    async function fetchAPI() {
      try {
        const res = await apiFetchTeamMembers(locationId, customerId, true);
        const formated = res.map((team) => ({
          name: team.full_name || team.user_email,
          id: team.user_id,
        }));
        setOptions(formated);
      } catch (error) {
        dispatch(setErrorMessage(error.toString()));
      }
    }
    fetchAPI();
  }, []);

  useEffect(() => {
    const { onChange } = props;
    onChange && onChange(value?.id);
  }, [value]);
  return (
    <BaseSearchDropdown
      id="team-members-search-dropdown"
      options={options}
      value={value}
      onChange={setValue}
      label="name"
    />
  );
}
