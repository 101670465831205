import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  chatSection: {
    border: "1px black",
    height: "100%",
    flex: "auto",
    display: "flex",
    flexDirection: "column"
  },
  imageContent: {
    borderRadius: "50%",
    height: "50px",
  },
  boxContent: {
    display: "flex",
    padding: 20,
  },
  userName: {
    padding: "10px",
  },
  subHeader: {
    backgroundColor: theme.palette.secondary.main,
    height: "58px",
    width: "100%",
    display: "flex",
    color: theme.palette.common.white,
    alignItems: "center",
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    justifyContent: "center",
  },
  memberList: {
    minWidth: "250px",
    height: "100%",
    overflow: "auto",
    borderRight: "1px solid grey"
  },
  messageTime: {
    fontSize: "10px",
    paddingLeft: "5px",
  },
  timeBox: {
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  userDetails: {
    display: "flex"
  },
  userSectionName: {
    margin: '7px'
  }
}));
export { useStyles };
