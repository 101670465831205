import React from "react";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Router } from "react-router-dom";
import { Buffer } from "buffer";
import { store, history } from "../store";
import { CustomSnackbar } from "../components";
import { theme, GlobalStyles } from "../theme";
import Routes from "./Routes";
import "../assets/scss/main.scss";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const persistor = persistStore(store);

const App = () => {
  Buffer.from("anything", "base64");
  window.Buffer = window.Buffer || require("buffer").Buffer;
  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <GlobalStyles />
            <CustomSnackbar />
            <Router history={history()}>
              <Routes />
            </Router>
          </PersistGate>
        </Provider>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
};

export default App;
