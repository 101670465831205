import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { authenticationService } from "../core";

export const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const isAuthenticated = authenticationService.isAuthenticated();
  const userType = useSelector((state) => state.auth.data.user_type);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated && roles && roles.length) {
          const isRouteAuthorised = roles.find(role => {
            return role === userType.toLowerCase()
          });
          if (isRouteAuthorised) return <Component {...props} />
          return <Redirect to="/sign-in" />
        }
        else {
          if (!isAuthenticated) return <Redirect to="/sign-in" />;
          return <Component {...props} />;
        }
      }}
    />
  );
};
