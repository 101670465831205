import React, { Fragment } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { FormHelperText } from "@material-ui/core";
import { QuickEditInput } from "../quick-edit-input";
import {
  getCompanyInfo,
  getCompanyOfficeInfoAdmin,
  setSuccessMessage,
  setErrorMessage,
  setAuthData,
  setCustomerDetailsdAdmin,
  getCustomerId,
} from "../../../store";
import { UIActionType } from "../../../core";
import { apiUpdateCustomer } from "../../../apis";
import { useStyles } from "./OfficeNameInput.style";
import { userHasEditPermission } from "../../../constants/constants";

const validationSchema = yup.object({
  officeName: yup.string().required("Required"),
});

export default function OfficeNameInput(props) {
  const { className, hasBackground, isFromManageCustomerInfoPage } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const customerId = getCustomerId(state);
  const isAdminAction = Boolean(state.ui.actionType === UIActionType.Admin);
  const companyInfo = isFromManageCustomerInfoPage ? getCompanyOfficeInfoAdmin(state) : isAdminAction
    ? getCompanyOfficeInfoAdmin(state)
    : getCompanyInfo(state);
  const userType = state.auth.data.user_type;

  const handleAdminResponse = (res) => {
    const { details } = state.admin.customer;
    dispatch(setCustomerDetailsdAdmin({ ...details, ...res }));
  };

  const handleUserResponse = (res) => {
    const { data } = state.auth;
    dispatch(setAuthData({ ...data, ...res }));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { officeName: companyInfo.officeName },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const request = { row: values, customerId };
        if (props.setIsLoading)
          props.setIsLoading(true);
        const res = await apiUpdateCustomer(request);
        isAdminAction ? handleAdminResponse(res) : handleUserResponse(res);
        if (props.setIsLoading)
          props.setIsLoading(false);
        dispatch(setSuccessMessage("Office name updated"));
      } catch (error) {
        if (props.setIsLoading)
          props.setIsLoading(false);
        dispatch(setErrorMessage(error.toString()));
      }
    },
  });
  return (
    <Fragment>
      <QuickEditInput
        label="Office name"
        className={className ? className : classes.boxContentDataRow}
        name="officeName"
        value={formik.values.officeName}
        onChange={formik.handleChange}
        onSave={formik.handleSubmit}
        hasBackground={hasBackground}
        error={formik.touched.officeName && Boolean(formik.errors.officeName)}
        isEditable={isFromManageCustomerInfoPage ? true : userHasEditPermission(userType)}
      />
      {/* <FormHelperText
        error={formik.touched.officeName && Boolean(formik.errors.officeName)}
      >
        {formik.touched.officeName && formik.errors.officeName}
      </FormHelperText> */}
    </Fragment>
  );
}
