import { http, handleResponse } from "../core";
import { URL } from "./_urls.js";

const apiUpdateCustomer = async (data) => {
  return handleResponse(await http.put(URL.UPDATE_CUSTOMER_API(), data));
};

const apiFetchCustomerForwardingAddresses = async (locationId, customerId) => {
  return handleResponse(
    await http.get(URL.FETCH_CF_ADDRESSES_API(locationId, customerId))
  );
};

const apiAddCFAddress = async (mailAccountId, locationId, data) => {
  return handleResponse(
    await http.post(URL.CREATE_ADDRESS_URL(mailAccountId, locationId), data)
  );
};

const apiUpdateCFAddress = async (mailAccountId, locationId, data) => {
  return handleResponse(
    await http.put(URL.UPDATE_ADDRESS_URL(mailAccountId, locationId), data)
  );
};

const apiFetchCustomerLocations = async (customerId) => {
  return handleResponse(
    await http.get(URL.FETCH_CUSTOMER_LOCATIONS(customerId))
  );
};

const apiAddCTMembers = async (data) => {
  return handleResponse(await http.post(URL.ADD_CT_MEMBERS_API(), data));
};

const apiUpdateCTMembers = async (data) => {
  return handleResponse(await http.put(URL.UPDATE_CT_MEMBERS_API(), data));
};

const apiGetCustomers = async (locationId) => {
  return handleResponse(await http.get(URL.FETCH_CUSTOMERS(locationId)));
};

const apiGetCustomerPlanServices = async (customerId) => {
  return handleResponse(
    await http.get(URL.FETCH_CUSTOMER_PLAN_SERVICES(customerId))
  );
};

const apiUpdateCPServices = async (data) => {
  return handleResponse(
    await http.put(URL.UPDATE_CUSTOMER_PLAN_SERVICE(), data)
  );
};

export {
  apiUpdateCustomer,
  apiFetchCustomerForwardingAddresses,
  apiFetchCustomerLocations,
  apiAddCFAddress,
  apiUpdateCFAddress,
  apiAddCTMembers,
  apiUpdateCTMembers,
  apiGetCustomers,
  apiGetCustomerPlanServices,
  apiUpdateCPServices,
};
