import React, { Fragment, useState } from 'react';
import { Typography } from '@material-ui/core';
import {
    AccessTime as AccessTimeIcon,
} from '@material-ui/icons';
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import { useStyles } from './SpecialDates.style';

const defaultValues = [{ startDate: null, endDate: null, startTime: null, endTime: null }, { startDate: null, endDate: null, startTime: null, endTime: null }];

export default function SpecialDates() {
    const classes = useStyles();
    const [values, setValues] = useState(defaultValues);
    return (
        <Fragment>
            {values.map((item, index) =>
                <div key={Math.random()} className={classes.specialDatesRow}>
                    <div className={classes.selectDaysRow}>
                        <div className={classes.selectColumn}>
                            <Typography className={classes.selectLabel} variant='span'>From</Typography>
                            <KeyboardDatePicker
                                value={item.startDate}
                                onChange={(value) => {
                                    const obj = { ...values[index] };
                                    obj.startDate = value;
                                    values.splice(index, 1, obj)
                                    setValues([...values])
                                }}
                                className={classes.picker}
                                autoOk
                                variant="inline"
                                inputVariant="outlined"
                                format="MM/dd/yyyy"
                                InputAdornmentProps={{ position: "start" }}
                            />
                        </div>
                        <div className={classes.selectColumn}>
                            <Typography className={classes.selectLabel} variant='span'>Until</Typography>
                            <KeyboardDatePicker
                                value={item.endDate}
                                onChange={(value) => {
                                    const obj = { ...values[index] };
                                    obj.endDate = value;
                                    values.splice(index, 1, obj)
                                    setValues([...values])
                                }}
                                className={classes.picker}
                                autoOk
                                variant="inline"
                                inputVariant="outlined"
                                format="MM/dd/yyyy"
                                InputAdornmentProps={{ position: "start" }}
                            />
                        </div>
                        <div className={classes.selectColumn}>
                            <Typography className={classes.selectLabel} variant='span'>From</Typography>
                            <KeyboardTimePicker
                                value={item.startTime}
                                onChange={(value) => {
                                    const obj = { ...values[index] };
                                    obj.startTime = value;
                                    values.splice(index, 1, obj)
                                    setValues([...values])
                                }}
                                className={classes.picker}
                                autoOk
                                variant="inline"
                                inputVariant="outlined"
                                keyboardIcon={<AccessTimeIcon />}
                                InputAdornmentProps={{ position: "start" }}
                            />
                        </div>
                        <div className={classes.selectColumn}>
                            <Typography className={classes.selectLabel} variant='span'>Until</Typography>
                            <KeyboardTimePicker
                                value={item.endTime}
                                onChange={(value) => {
                                    const obj = { ...values[index] };
                                    obj.endTime = value;
                                    values.splice(index, 1, obj)
                                    setValues([...values])
                                }}
                                className={classes.picker}
                                autoOk
                                variant="inline"
                                inputVariant="outlined"
                                keyboardIcon={<AccessTimeIcon />}
                                InputAdornmentProps={{ position: "start" }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    )
}
