import React from "react";
import PubNub from "pubnub";
import { PubNubProvider } from "pubnub-react";
import { Chat, MessageInput, MessageList } from "@pubnub/react-chat-components";
import { Box, Container, Typography, Avatar } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useStyles } from "./styles";
import moment from "moment";
import { useEffect, useMemo } from "react";
import ImageIcon from "../../assets/images/imageIcon.jpg";

const theme = "light";

export default function FrontDeskChat() {
  const classes = useStyles();
  const uuid = useSelector((state) => state.auth.data.uuid);
  const user = useSelector((state) => state.auth.data);

  const pubnub = useMemo(
    () =>
      new PubNub({
        publishKey: "pub-c-5ec3e8ea-d4d0-4f31-a1b6-144e5a9aaade",
        subscribeKey: "sub-c-a60b5c44-4550-4bd0-95fd-dfc33861774c",
        userId: uuid,
      }),
    [uuid]
  );

  const beforeSendMessage = (props) => {
    props.sender = {
      imageUrl: user.image_url ? user.image_url : '',
      name: user.full_name ? user.full_name : '',
    };
  }


  const messageView = (props) => {
    const { message, timetoken } = props?.message;
    const { text } = message;
    return (
      <Box>
        <Typography className={classes.timeBox}>
          <Typography className={classes.userDetails}><Avatar
            alt="Remy Sharp"
            src={props?.user?.imageUrl || ImageIcon}
          />
            <Typography className={classes.userSectionName}> {props?.user?.name}</Typography>
          </Typography>
          {text}{" "}
          <span className={classes.messageTime}>
            {moment(new Date(timetoken / 1e4)).format("hh:mm a")}
          </span>
        </Typography>
      </Box>
    );
  };

  useEffect(() => {
    if (uuid) {
      pubnub.subscribe({ channels: [uuid] });
    }
    return () => pubnub.unsubscribeAll();
  }, [pubnub, uuid]);

  return (
    <PubNubProvider client={pubnub}>
      <Container style={{ maxWidth: 1111, height: "calc(100vh - 70px)" }}>
        <Typography className={classes.subHeader}>
          Front Desk Support
        </Typography>
        {uuid ? (
          <Box className={classes.chatContent}>
            <Chat {...{ currentChannel: uuid, theme }}>
              <MessageList
                fetchMessages={100}
                messageRenderer={(props) => messageView(props)}
              />
              <MessageInput onBeforeSend={(props) => beforeSendMessage(props)} senderInfo={true} />
            </Chat>
          </Box>
        ) : null}
      </Container>
    </PubNubProvider>
  );
}
