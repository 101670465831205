import { combineReducers } from "redux";
import { dataReducer } from "./data";
import { currentPageReducer } from "./current-page";
import { totalPagesReducer } from "./total-pages";
import { activeTabReducer } from "./active-tab";
import { searchReducer } from "./search";
import { selectedRowIdsReducer } from "./selected-row-ids";
import { limitReducer } from "./limit";
import { totalReducer } from "./total";
import { loadingReducer } from "./loading";
import { mailActionReducer } from "./mail-action";
import { selectedRowReducer } from "./selected-row";
import { bulkOperationReducer } from "./bulk-operation";
import { optionsReducer } from "./options";

const mailManagementReducer = combineReducers({
  data: dataReducer,
  currentPage: currentPageReducer,
  totalPages: totalPagesReducer,
  activeTab: activeTabReducer,
  search: searchReducer,
  limit: limitReducer,
  total: totalReducer,
  isLoading: loadingReducer,
  selectedRowIds: selectedRowIdsReducer,
  mailAction: mailActionReducer,
  selectedRow: selectedRowReducer,
  isBulkOperation: bulkOperationReducer,
  options: optionsReducer,
});

export { mailManagementReducer };
