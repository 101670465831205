import { combineReducers } from "redux";
import { dataReducer } from "./data";
import { selectedReducer } from "./selected";

const teamMemberReducer = combineReducers({
    data: dataReducer,
    selected: selectedReducer,
});

export { teamMemberReducer };
