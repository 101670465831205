import { MYACCOUNT } from "../../../actions";

const dataReducer = (state = [], action) => {
    switch (action.type) {
        case MYACCOUNT.CLEAR_ACCOUNT:
            return [];
        case MYACCOUNT.SET_CUSTOMER_LOCATIONS:
            return action.data;
        default:
            return state;
    }
};

export { dataReducer };
