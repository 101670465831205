import { createTheme, colors } from '@material-ui/core';
import { typography } from './typography';
import { overrides } from './overrides';
import { props } from './props';
import { GlobalStyles } from './global';

const theme = createTheme({
    palette: {
        primary: {
            light: "#8EEAFF",
            main: "#2CA4C0",
        },
        secondary: {
            main: "#102F51",
        },
        error: {
            main: colors.red.A400,
        },
        background: {
            background: "#009387",
        },
        divider: "#000",
        text: {
            primary: "#2E3438",
        },
    },
    typography,
    overrides,
    props
});

export { theme, GlobalStyles };
