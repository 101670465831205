import React from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomerInfoAdmin,
  setErrorMessage,
  setCustomerDetailsdAdmin,
  setSuccessMessage,
} from "../../../store";
import { apiUpdateCustomer } from "../../../apis";
import { useFormik } from "formik";
import { QuickEditInput } from "../../shared";
import { useStyles } from "./CustomerInfo.style";
import { useState } from "react";
// import EllipseImage from "../../../assets/images/Ellipse.png";

export default function CustomerInfo() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const initialValues = getCustomerInfoAdmin(state);
  const [loading, setLoading] = useState(false);
  // const openChangePassword = () => {
  //   dispatch(openModal(ModalType.ChangePassword));
  // };

  const handleUpdateCustomer = async (request) => {
    setLoading(true);
    try {
      const res = await apiUpdateCustomer(request);
      const { details } = state.admin.customer;
      setLoading(false);
      dispatch(setCustomerDetailsdAdmin({ ...details, ...res }));
      dispatch(setSuccessMessage("Customer info updated"));
    }
    catch (error) {
      setLoading(false);
      dispatch(setErrorMessage(error.toString()));
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (values) => {
      try {
        const { field, id } = values;
        const request = { row: { [field]: values[field] }, customerId: id };
        setLoading(true);
        await handleUpdateCustomer(request);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        dispatch(setErrorMessage(error.toString()));
      }
    },
  });

  const handleEditClick = (field) => {
    formik.setFieldValue("field", field);
  };

  return (
    <Box component="Box" className={classes.box}>
      <Box className={classes.boxHeader}>
        <Typography variant="h6" className={classes.boxHeading}>
          Customer’s info
        </Typography>
        {loading && <Box className={classes.loader}>
          <CircularProgress size="1.5rem"/>
        </Box>}
        {/* <Link component="button" variant="inherit" className={classes.boxLink}>
          ADMIN
        </Link> */}
      </Box>
      <Box className={clsx(classes.boxRow, classes.noBorder)}>
        <Box className={classes.boxContent}>
          {/* <Box className={classes.boxContentDataRow}>
            <Box className={classes.imageContainer}>
              <Avatar alt="Remy Sharp" src={EllipseImage} />
              <Typography variant="body1">Sevahn_Simonain.jpg</Typography>
              <IconButton className={classes.boxContentDataRowEdit}>
                <EditIcon className={classes.editIcon} />
              </IconButton>
            </Box>
          </Box> */}
          <QuickEditInput
            label="Customer name"
            name="customerName"
            value={formik.values.customerName}
            onChange={formik.handleChange}
            className={clsx(classes.boxContentDataRow)}
            handleEditClick={handleEditClick}
            onSave={formik.handleSubmit}
            isEditable = {true}
          />
          <QuickEditInput
            label="Customer Phone"
            name="phone"
            value={formik.values.phone}
            onChange={formik.handleChange}
            className={classes.evenBoxContentDataRow}
            handleEditClick={handleEditClick}
            onSave={formik.handleSubmit}
            isEditable = {true}
          />
          {/* <Box
            className={clsx(
              classes.boxContentDataRow,
              classes.evenBoxContentDataRow
            )}
          >
            <Box className={classes.dataRow}>
              <Box>
                <Typography variant="body1">Password</Typography>
                <Typography variant="subtitle1">****************</Typography>
              </Box>
            </Box>
            <IconButton
              className={classes.boxContentDataRowEdit}
              onClick={openChangePassword}
            >
              <EditIcon className={classes.editIcon} />
            </IconButton>
          </Box> */}
          <QuickEditInput
            label="Customer Email"
            name="customerEmail"
            value={formik.values.customerEmail}
            onChange={formik.handleChange}
            className={clsx(classes.boxContentDataRow)}
            handleEditClick={handleEditClick}
            onSave={formik.handleSubmit}
            isEditable = {true}
          />
          <QuickEditInput
            label="Customer Mobile"
            name="mobile"
            value={formik.values.mobile}
            onChange={formik.handleChange}
            className={classes.evenBoxContentDataRow}
            handleEditClick={handleEditClick}
            onSave={formik.handleSubmit}
            isEditable = {true}
          />
        </Box>
      </Box>
    </Box>
  );
}
