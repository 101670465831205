import React, { Fragment } from "react";
import { Dashbaord } from "./dashboard";
import { ManageMailAccount } from "./manage-mail-account";
import { RoomList, EditRoom, ManageRoom } from "./manage-rooms";
import {
  CustomerList,
  ManageCustomer,
  LinkAccount,
  AddCustomer,
} from "./manage-customer";
import { LocationList, AddLocation, EditLocation } from "./manage-locations";
import { AdminFrontDeskChat } from "./admin-front-desk-chat";
import { PrivateRoute } from "../../root/PrivateRoute";
import { MapLocation } from "./map-location";
import { UserRoles } from "../../core";

export default function AdminInternal() {
  return (
    <Fragment>
      <PrivateRoute path="/admin" roles={[UserRoles.SuperAdmin]} exact component={Dashbaord} />
      <PrivateRoute path="/admin/support" roles={[UserRoles.SuperAdmin]} exact component={AdminFrontDeskChat} />
      <PrivateRoute path="/admin/customers" roles={[UserRoles.SuperAdmin]} exact component={CustomerList} />
      <PrivateRoute path="/admin/customers/add" roles={[UserRoles.SuperAdmin]} exact component={AddCustomer} />
      <PrivateRoute
        path="/admin/link-account/:customerId"
        roles={[UserRoles.SuperAdmin]}
        exact
        component={LinkAccount}
      />
      <PrivateRoute
        path="/admin/manage-customer/:customerId"
        roles={[UserRoles.SuperAdmin]}
        exact
        component={ManageCustomer}
      />
      <PrivateRoute
        path="/admin/mail-accounts"
        roles={[UserRoles.SuperAdmin]}
        exact
        component={ManageMailAccount}
      />
      <PrivateRoute path="/admin/rooms/add" roles={[UserRoles.SuperAdmin]} exact component={ManageRoom} />
      <PrivateRoute
        path="/admin/rooms/:roomId/edit"
        roles={[UserRoles.SuperAdmin]}
        exact
        component={EditRoom}
      />
      <PrivateRoute path="/admin/rooms" roles={[UserRoles.SuperAdmin]} exact component={RoomList} />
      <PrivateRoute path="/admin/locations" roles={[UserRoles.SuperAdmin]} exact component={LocationList} />
      <PrivateRoute path="/admin/locations/add" roles={[UserRoles.SuperAdmin]} exact component={AddLocation} />
      <PrivateRoute
        path="/admin/locations/:locationId/edit"
        roles={[UserRoles.SuperAdmin]}
        exact
        component={EditLocation}
      />
      <PrivateRoute path="/admin/map-location" roles={[UserRoles.SuperAdmin]} exact component={MapLocation} />
    </Fragment>
  );
}
