import React, { Fragment, useEffect } from "react";
import { Box, Container, Link, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getLoginCustomerId, openModal, setCustomerDetailsdAdmin, setCustomerPlanInfo, setCustomerPlanServices, setErrorMessage } from "../../store";
import { ModalType } from "../../core";
import { useStyles } from "./payments.style";
import { MyAddOns } from "./my-add-ons";
import { MyCurrentPlan } from "./my-current-plan";
import { AdditionalMinutes } from "./additional-minutes";
import { Transactions } from "./transactions";
import { NeedCustomizePlan } from "./need-customize-plan";
import { apiGetCustomerPlanServices } from "../../apis";

export default function Payments() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const customerId = useSelector((state) => getLoginCustomerId(state));

  useEffect(() => {
    const loadCustomerPlanServices = async () => {
      try {
        const res = await apiGetCustomerPlanServices(customerId);
        const { planInfo, planServices, details } = res;
        dispatch(setCustomerPlanServices(planServices));
        dispatch(setCustomerPlanInfo(planInfo || {}));
        dispatch(setCustomerDetailsdAdmin(details));
      }
      catch (error) {
        dispatch(setErrorMessage(error.toString()));
      }
    }
    loadCustomerPlanServices();
    // dispatch(loadCustomerPlansServices());
  }, [dispatch, customerId]);

  const handleEditPaymentMethod = () => {
    dispatch(openModal(ModalType.ManagePaymentInformation));
  };

  return (
    <Fragment>
      <Box component="div" className={classes.subHeader}>
        <Typography variant="body2">
          {/* Paying with card ending with - 0008 */}
        </Typography>
        <Link
          component="button"
          variant="inherit"
          color="primary"
          className={classes.boxLink}
          onClick={handleEditPaymentMethod}
        >
          Edit payment method
        </Link>
      </Box>
      <Container  style={{maxWidth:1111}} className={classes.main}>
        <Box className={classes.columnWrap}>
          {/* Column 1 */}
          <Box className={classes.column}>
            <Transactions />
          </Box>

          {/* Column 2 */}
          <Box className={classes.column}>
            <MyCurrentPlan />
          </Box>

          {/* Column 3 */}
          {/* <Box className={classes.column}>
            <MyAddOns />
            <AdditionalMinutes />
            <NeedCustomizePlan />
          </Box> */}
        </Box>
      </Container>
    </Fragment>
  );
}
