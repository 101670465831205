import { UI } from "../../actions";

const chargebeeStatusReducer = (state = false, action) => {
  switch (action.type) {
    case UI.SET_CHARGEBEE_LOADED:
      return action.status;
    default:
      return state;
  }
};

export { chargebeeStatusReducer };
