export const UI = {
  OPEN_MODAL: "OPEN_MODAL",
  CLOSE_MODAL: "CLOSE_MODAL",
  SET_SUCCESS_MESSAGE: "SET_SUCCESS_MESSAGE",
  SET_MASTER_DATA: "SET_MASTER_DATA",
  SET_ERROR_MESSAGE: "SET_ERROR_MESSAGE",
  SET_ACTION_TYPE: "UI_SET_ACTION_TYPE",
  CLEAR_MESSAGE: "CLEAR_MESSAGE",
  SET_CHARGEBEE_LOADED: "SET_CHARGEBEE_LOADED",
};

export const PHONESERVICES = {
  CLEAR_PHONE_SERVICES: "CLEAR_PHONE_SERVICES",
  SET_DIRECTORY_DATA: "SET_DIRECTORY_DATA",
  // LOAD_DIRECTORY_DATA: "LOAD_DIRECTORY_DATA",
  SET_SELECTED_DIRECTORY: "SET_SELECTED_DIRECTORY",
  LOAD_VIRTUAL_PHONE_NUMBERS: "LOAD_VIRTUAL_PHONE_NUMBERS",
  SET_VIRTUAL_PHONE_NUMBERS_DATA: "SET_VIRTUAL_PHONE_NUMBERS_DATA",
  SET_SELECTED_VIRTUAL_PHONE_NUMBER: "SET_SELECTED_VIRTUAL_PHONE_NUMBER",
};

export const MYACCOUNT = {
  CLEAR_ACCOUNT: "CLEAR_ACCOUNT",
  // LOAD_USER_DETAILS: "LOAD_USER_DETAILS",
  // LOAD_FORWARDING_ADDRESS: "LOAD_FORWARDING_ADDRESS",
  SET_FORWARDING_ADDRESS: "SET_FORWARDING_ADDRESS",
  SET_SELECTED_FORWARDING_ADDRESS: "SET_SELECTED_FORWARDING_ADDRESS",
  ADD_UPDATE_FORWARDING_ADDRESS: "ADD_UPDATE_FORWARDING_ADDRESS",
  LOAD_FORWARDING_PHONE_NUMBERS: "LOAD_FORWARDING_PHONE_NUMBERS",
  SET_FORWARDING_PHONE_NUMBERS: "SET_FORWARDING_PHONE_NUMBERS",
  SET_SELECTED_FORWARDING_PHONE_NUMBER: "SET_SELECTED_FORWARDING_PHONE_NUMBER",
  SET_FORWARDING_PHONE_NUMBER_OPERATING_MODE:
    "SET_FORWARDING_PHONE_NUMBER_OPERATING_MODE",
  SET_FORWARDING_ADDRESS_OPERATING_MODE:
    "SET_FORWARDING_ADDRESS_OPERATING_MODE",
  // LOAD_CUSTOMER_LOCATIONS: "LOAD_CUSTOMER_LOCATIONS",
  SET_CUSTOMER_LOCATIONS: "SET_CUSTOMER_LOCATIONS",
  SET_SELECTED_CUSTOMER_LOCATION: "SET_SELECTED_CUSTOMER_LOCATION",
  // LOAD_TEAM_MEMBERS: "LOAD_TEAM_MEMBERS",
  SET_TEAM_MEMBERS: "SET_TEAM_MEMBERS",
  SET_SELECTED_TEAM_MEMBER: "SET_SELECTED_TEAM_MEMBER",
  ADD_UPDATE_CT_MEMBERS: "ADD_UPDATE_CT_MEMBERS",
  SET_CUSTOMER_MAILS_COUNT: "SET_CUSTOMER_MAILS_COUNT",
  // LOAD_PAYMENT_METHODS: "MYACCOUNT:LOAD_PAYMENT_METHODS",
  SET_PAYMENT_METHODS: "MYACCOUNT:SET_PAYMENT_METHOD",
  CLEAR_PAYMENT_METHODS: "MYACCOUNT:CLEAR_PAYMENT_METHOD",
  ADD_UPDATE_PAYMENT_METHOD: "MYACCOUNT:ADD_UPDATE_PAYMENT_METHOD",
  SET_SELECTED_PAYMENT_METHOD: "MYACCOUNT:SET_SELECTED_PAYMENT_METHOD",
};

export const AUTH = {
  SET_TOKEN: "SET_AUTH_TOKEN",
  CLEAR: "CLEAR_AUTH",
  SET_DATA: "SET_AUTH_DATA",
};

export const FORM = {
  SET_LOGIN_FORM: "SET_LOGIN_FORM",
  CLEAR_LOGIN_FORM: "CLEAR_LOGIN_FORM",
};

export const ROOMRENTALS = {
  SET_ROOM_PRICE: "SET_ROOM_PRICE",
  PEOPLE_CAP: "PEOPLE_CAP",
  All_ROOM: "ALL_ROOM",
  CHECK_AVAIABLITY: "CHECK_AVAIABLITY",
};

export const ADMIN = {
  CLEAR_ADMIN: "CLEAR_ADMIN",
  SET_SELECTED_CUSTOMER: "SET_SELECTED_CUSTOMER",
  // LOAD_CUSTOMER_PLAN_SERVICES: "LOAD_CUSTOMER_PLAN_SERVICES",
  SET_CUSTOMER_PLAN_SERVICES: "SET_CUSTOMER_PLAN_SERVICES",
  SET_CUSTOMER_PLAN_INFO: "SET_CUSTOMER_PLAN_INFO",
  SET_CUSTOMER_PLAN_INFO_DETAIL: "SET_CUSTOMER_PLAN_INFO_DETAIL",
  SET_CUSTOMER_DETAIL_ADMIN: "SET_CUSTOMER_DETAIL_ADMIN",
  SET_PLANS: "ADMIN:SET_PLANS",
  // LOAD_PLANS: "ADMIN:LOAD_PLANS",
  // LOAD_ROOMS: "ADMIN:LOAD_ROOMS",
  SET_ROOMS: "ADMIN:SET_ROOMS",
  // LOAD_CUSTOMERS: "ADMIN:LOAD_CUSTOMERS",
  SET_CUSTOMERS: "ADMIN:SET_CUSTOMERS",
  SET_LOCATIONS: "ADMIN:SET_LOCATIONS",
};

export const MAILMANAGEMENT = {
  CLEAR: "CLEAR_MAIL_MANAGEMENT",
  LOAD_MAIL_ITEMS: "MM_LOAD_MAIL_ITEMS",
  SEARCH_MAIL_ITEMS: "MM_SEARCH_MAIL_ITEMS",
  SET_MAIL_ITEMS: "MM_SET_MAIL_ITEMS",
  SET_CURRENT_PAGE: "MM_SET_CURRENT_PAGE",
  SET_TOTAL_PAGES: "MM_SET_TOTAL_PAGES",
  SET_ACTIVE_TAB: "MM_SET_ACTIVE_TAB",
  SET_SEARCH_TEXT: "MM_SET_SEARCH_TEXT",
  SET_MAIL_ACTION: "MM_SET_MAIL_ACTION",
  SET_LIMIT: "MM_SET_LIMIT",
  SET_TOTAL: "MM_SET_TOTAL",
  SET_MAIL_ITEM: "MM_SET_MAIL_ITEM",
  SET_MAIL_OPTIONS: "MM_SET_MAIL_OPTIONS",
  SET_SELECTED_MAIL_ROW_IDS: "MM_SET_SELECTED_MAIL_ROW_IDS",
  LOAD_MAIL_ITEMS_FAILED: 'MM_LOAD_MAIL_ITEMS_FAILED'
};

export const SPACERENTAL = {
  CLEAR: "CLEAR_SPACERENTAL",
  // LOAD_UPCOMING_BOOKINGS: "SPACERENTAL:LOAD_UPCOMING_BOOKINGS",
  SET_UPCOMING_BOOKINGS: "SPACERENTAL:SET_UPCOMING_BOOKINGS",
  SET_ACTIVE_UPCOMING_BOOKINGS: "SPACERENTAL:SET_ACTIVE_UPCOMING_BOOKINGS",
  RESET_UPCOMING_BOOKINGS: "SPACERENTAL:RESET_UPCOMING_BOOKINGS",
  // LOAD_AVAILABLE_ROOMS: "SPACERENTAL:LOAD_AVAILABLE_ROOMS",
  SET_AVAILABLE_ROOMS: "SPACERENTAL:SET_AVAILABLE_ROOMS",
  SET_FILTERED_ROOMS: "SPACERENTAL:SET_FILTERED_ROOMS",
  SET_ACTIVE_AVAILABLE_ROOM: "SPACERENTAL:SET_ACTIVE_AVAILABLE_ROOM",
  RESET_ACTIVE_AVAILABLE_ROOM: "SPACERENTAL:RESET_ACTIVE_AVAILABLE_ROOM",
  RESET_AVAILABLE_ROOMS: "SPACERENTAL:RESET_AVAILABLE_ROOMS",
  RESET_FILTERED_ROOMS: "SPACERENTAL:RESET_FILTERED_ROOMS",
  RESET_AVAILABLE_ROOMS_FILTER: "SPACERENTAL:RESET_AVAILABLE_ROOMS_FILTER",
  SET_AVAILABLE_ROOMS_FILTER: "SPACERENTAL:SET_AVAILABLE_ROOMS_FILTER",
  // ROOM_BOOKING_INIT: "SPACERENTAL:ROOM_BOOKING_INIT",
  // LOAD_FILTERED_ROOM: "SPACERENTAL:LOAD_FILTERED_ROOMS",
  SET_BOOKING_DETAIL: "SPACERENTAL:SET_BOOKING_DETAIL",
  CHECK_AVAIABLITY_INIT: "SPACERENTAL:CHECK_AVAIABLITY_INIT",
};

export const TRANSACTION = {
  CLEAR_TRANSACTION: "CLEAR_TRANSACTION",
  LOAD_ALL_TRANSACTIONS: "LOAD_ALL_TRANSACTIONS",
  SET_PAYMENT_TRANSACTION: "SET_PAYMENT_TRANSACTION",
  SET_SERVICE_TRANSACTION: "SET_SERVICE_TRANSACTION",
};
