import { combineReducers } from "redux";
import { dataReducer } from "./data";
import { selectedVirtualPhoneNumberReducer } from "./selected-virtual-phone-number";

const virtualPhoneNumbersReducer = combineReducers({
    data: dataReducer,
    selectedVirtualPhoneNumber: selectedVirtualPhoneNumberReducer
});

export { virtualPhoneNumbersReducer };
