import React from "react";
import PubNub from "pubnub";
import { PubNubProvider } from "pubnub-react";
import {
  Chat,
  MemberList,
  MessageInput,
  MessageList,
  TypingIndicator
} from "@pubnub/react-chat-components";
import { Box, Container, Typography, Avatar } from "@material-ui/core";
import { useStyles } from "./styles";
import { useState } from "react";
import moment from "moment";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getSelectedLocationId } from "../../../store";
import ImageIcon from "../../../assets/images/imageIcon.jpg";

import { apiFetchAllChatUsers } from "../../../apis";
import { isEmpty } from "lodash";

/* Creates and configures your PubNub instance. Be sure to replace "myPublishKey" and "mySubscribeKey"
  with your own keyset. If you wish, modify the default "myFirstUser" uuid value for the chat user. */
const pubnub = new PubNub({
  publishKey: "pub-c-5ec3e8ea-d4d0-4f31-a1b6-144e5a9aaade",
  subscribeKey: "sub-c-a60b5c44-4550-4bd0-95fd-dfc33861774c",
  userId: "AdminSupportFirmvo",
});

export default function AdminFrontDeskChat() {
  const classes = useStyles();
  const selectedLocationId = useSelector((state) =>
    getSelectedLocationId(state)
  );
  const [loading, setLoading] = useState(false);
  const [memberSelected, setMemberSelected] = useState({});
  const [selectedChannel, setSelectedChannel] = useState("");
  const [users, setUsers] = useState([]);
  const user = useSelector((state) => state.auth.data);

  const onMemberClick = (member) => {
    pubnub.unsubscribe({ channels: [selectedChannel] });
    setMemberSelected(member);
    setSelectedChannel(member?.channel);
    pubnub.subscribe({
      channels: [member?.channel],
    });
  };

  useEffect(() => {
    return () => pubnub.unsubscribeAll();
  }, [selectedChannel]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true);
        const allUsers = await apiFetchAllChatUsers(selectedLocationId);
        const users = (allUsers || []).filter(user => {
          return user.id && !isEmpty(user.uuid)
        });
        setUsers(
          users.map((user) => ({
            name: user.fullName,
            custom: {
              title: user.title,
            },
            email: user.userEmail,
            externalId: null,
            id: user.uuid,
            profileUrl: user.imageUrl,
            channel: user.uuid,
          }))
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    if (selectedLocationId) fetchUsers();
  }, [selectedLocationId]);

  const messageView = (props) => {
    const { message, timetoken } = props?.message;
    const { text } = message;
    return (
      <Box>
        <Typography className={classes.timeBox}>
          <Typography className={classes.userDetails}><Avatar
            alt="Remy Sharp"
            src={props?.user?.imageUrl || ImageIcon}
          />
            <Typography className={classes.userSectionName}> {props?.user?.name}</Typography>
          </Typography>
          {text}{" "}
          <span className={classes.messageTime}>
            {moment(new Date(timetoken / 1e4)).format("hh:mm a")}
          </span>
        </Typography>
      </Box>
    );
  };

  const beforeSendMessage = (props) => {
    props.sender = {
      imageUrl: user.image_url ? user.image_url : '',
      name: user.full_name ? user.full_name : '',
    };
  }

  return (
    <PubNubProvider client={pubnub}>
      <Container style={{ maxWidth: 1111, height: "calc(100vh - 70px)" }}>
        <Typography variant="h3" className={classes.subHeader}>
          Support
        </Typography>
        {users.length ? (
          <Box display="flex" style={{ height: "100%", maxHeight: "calc(100% - 58px)" }}>
            <Box className={classes.memberList}>
              <MemberList
                members={users}
                onMemberClicked={(member) => onMemberClick(member)}
              />
            </Box>
            {memberSelected?.id && memberSelected?.channel && (
              <Box className={classes.chatSection}>
                <Box className={classes.boxContent}>
                  <Avatar
                    alt={memberSelected.name}
                    src={memberSelected.profileUrl}
                  />
                  <Typography className={classes.userName}>
                    {memberSelected.name}
                  </Typography>
                </Box>
                <Chat currentChannel={memberSelected.channel} users={users}>
                  <MessageList
                    fetchMessages={100}
                    messageRenderer={(props) => messageView(props)}
                  >
                    <TypingIndicator showAsMessage={true} />
                  </MessageList>
                  <MessageInput onBeforeSend={(props) => beforeSendMessage(props)} senderInfo={true} />
                </Chat>
              </Box>
            )}
          </Box>
        ) : null}
      </Container>
    </PubNubProvider >
  );
}
