import { MAILMANAGEMENT } from "../../actions";

const bulkOperationReducer = (state = false, action) => {
  switch (action.type) {
    case MAILMANAGEMENT.SET_MAIL_ITEM:
      return false;
    case MAILMANAGEMENT.SET_SELECTED_MAIL_ROW_IDS:
      return true;
    default:
      return state;
  }
};

export { bulkOperationReducer };
