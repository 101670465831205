import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { AutoSizer } from "react-virtualized";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Button, Container } from "@material-ui/core";
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { setErrorMessage, setLocations } from "../../../../store";
import { apiFetchAllLocations } from "../../../../apis";
import { useStyles } from "./styles";

export const columns = (width, handleViewClick) => [
  {
    field: "id",
    headerName: "Id",
    width: width * 0.1,
  },
  {
    field: "name",
    headerName: "Location",
    width: width * 0.15,
  },
  {
    field: "address1",
    headerName: "Address Line1",
    width: width * 0.15,
  },
  {
    field: "address2",
    headerName: "Address Line2",
    width: width * 0.14,
  },
  {
    field: "city",
    headerName: "City",
    width: width * 0.1,
  },
  {
    field: "state",
    headerName: "State",
    width: width * 0.08,
  },
  {
    field: "zip",
    headerName: "Zip",
    width: width * 0.08,
  },
  {
    field: "phone",
    headerName: "Phone",
    width: width * 0.1,
  },
  {
    field: "action",
    headerName: "Action",
    width: width * 0.1,
    hideSortIcons: true,
    renderCell: (param) => {
      return (
        <Button
          variant="outlined"
          color="primary"
          style={{ height: "30px" }}
          onClick={() => handleViewClick(param.row)}
        >
          Edit
        </Button>
      );
    },
  },
];

const NoRowsOverlay = () => {
  const classes = useStyles();
  return (
    <div className={classes.noRowOverlay}>
      <Typography variant="subtitle1">Nothing here yet</Typography>
      <Typography variant="body2">{`Loations are displayed here. `}</Typography>
    </div>
  );
};

export default function LocationList() {
  const classes = useStyles();
  let { push } = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.admin.location.data);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchLocations = async () => {
      setLoading(true);
      try {
        const locations = await apiFetchAllLocations();
        dispatch(setLocations(locations));
        setLoading(false);
      }
      catch (error) {
        setLoading(false);
        dispatch(setErrorMessage(error.toString()));
      }
    };
    fetchLocations();
  }, [dispatch]);

  const handleViewClick = (row) => {
    push(`/admin/locations/${row.id}/edit`);
  };

  const handleAddClick = () => {
    push("/admin/locations/add");
  };

  return (
    <Container style={{ maxWidth: 1111 }}>
      <section className={classes.section}>
        <div className={classes.addButtonContainer}>
          <Button
            variant="outlined"
            color="primary"
            style={{ height: "30px" }}
            onClick={handleAddClick}
          >
            Add new location
          </Button>
        </div>
        <div style={{ height: "calc(100vh - 80px)" }}>
          <AutoSizer style={{ width: "100%" }}>
            {({ height, width }) => (
              <div style={{ height, width: "100%", minWidth: "100%" }}>
                <DataGrid
                  headerHeight={40}
                  rowHeight={40}
                  height={height}
                  rows={data}
                  columns={columns(width, handleViewClick)}
                  disableColumnFilter
                  disableColumnMenu
                  disableSelectionOnClick
                  hideFooter
                  loading={loading}
                  sortingOrder={["asc", "desc"]}
                  components={{
                    ColumnSortedAscendingIcon: ArrowDropDownIcon,
                    ColumnSortedDescendingIcon: ArrowDropUpIcon,
                    NoRowsOverlay: NoRowsOverlay,
                  }}
                />
              </div>
            )}
          </AutoSizer>
        </div>
      </section>
    </Container>
  );
}
