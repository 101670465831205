import { PHONESERVICES } from "../../../../actions";

const selectedVirtualPhoneNumberReducer = (state = {}, action) => {
    switch (action.type) {
        case PHONESERVICES.CLEAR_PHONE_SERVICES:
            return {};
        case PHONESERVICES.SET_SELECTED_VIRTUAL_PHONE_NUMBER:
            return action.selectedVirtualPhoneNumber;
        default:
            return state;
    }
};

export { selectedVirtualPhoneNumberReducer };
