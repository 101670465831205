import { combineReducers } from "redux";
import { selectedReducer } from "./selected";
import { planServicesReducer } from "./plan-services";
import { planInfoReducer } from "./plan-info";
import { detailsReducer } from "./details";
import { plansReducer } from "./plans";
import { dataReducer } from "./data";
import { planInfoDetailReducer } from "./plan-info-detail";

const customerReducer = combineReducers({
  data: dataReducer,
  selected: selectedReducer,
  planServices: planServicesReducer,
  planInfo: planInfoReducer,
  details: detailsReducer,
  plans: plansReducer,
  planInfoDetail: planInfoDetailReducer
});

export { customerReducer };
