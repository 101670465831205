import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { ModalType, MailAction } from "../../../core";
import { closeModal, setMailAction } from "../../../store";
import CloseModalIcon from "../../../assets/icons/closeModalIcon";
import "./cancel-request.css";

export default function CancelRequestModal() {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const modal = state.ui.modal[ModalType.CancelMailRequestModal] || false;

  const handleClose = () => {
    dispatch(closeModal(ModalType.CancelMailRequestModal));
  };

  const getMailAction = () => {
    const row = state.mail.selectedRow;
    if (row.is_scan_requested) return MailAction.Scan;
    else if (row.mark_as_pick_up_requested) return MailAction.MarkPickUp;
    else if (row.is_pick_up) return MailAction.PickUp;
    else if (row.is_forward_requested) return MailAction.Forward;
    else if (row.is_shred_requested) return MailAction.Shred;
    else if (row.is_junk) return MailAction.Junk;
    else if (row.picture_requested_at) return MailAction.Picture;
    else return MailAction.New;
  };

  const handleConfirm = () => {
    dispatch(setMailAction(getMailAction()));
    handleClose();
  };

  return (
    <>
      <Dialog
        open={modal}
        onClose={handleClose}
        aria-labelledby="junk-dialog-title"
        aria-describedby="junk-dialog-description"
      >
        <div className="junk-dialog-title">
          <DialogTitle id="junk-dialog-title" className="junkModalHeader">
            <Grid container sm={12} direction="row">
              <Grid sm={9}>
                <p className="junkModalTitle w-100">Cancel Request</p>
              </Grid>
              <Grid sm={3}>
                <CloseModalIcon
                  handleCloseIcon={handleClose}
                  className="junkDialogCloseIcon"
                />
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="junk-dialog-description">
              <p className="junkModalText">
                Are you sure you want to <b>Cancel</b> the request.
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="junkModalFooter">
            <Button
              variant="primary"
              className="junkModalFooterButton"
              onClick={handleConfirm}
            >
              Yes, move it.
            </Button>
            <Button
              className="junkModalFooterCancelButton"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
}
