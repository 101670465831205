import React from "react";
import {
  AppBar,
  Button,
  ButtonGroup,
  Divider,
  Grid,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { clearAuth, setUIActionType } from "../../../store";
import { authenticationService, UIActionType } from "../../../core";
import { Locations } from "./locations";
import { ScreenHeading } from "../../../core";
import logo from "../../../assets/images/logo.jpg";
import { makeStyles } from "@material-ui/core/styles";
import "./nav-bar.css";
const useStyles = makeStyles({
  image: {
    maxWidth: 100,
    cursor: "pointer",
  },
  divider: {
    marginTop: 9,
    backgroundColor: "lightblue",
    minWidth: 0,
    height: "30px !important",
    width: "1px",
    alignSelf: "auto",
  },
  navButton: {
    textTransform: "capitalize",
    fontSize: 17,
    marginTop: 6,
  },
  navAccountButton: {
    textTransform: "capitalize",
    borderRight: "none !important",
    fontSize: 17,
    marginTop: 6,
  },
  HeadingDiv: {
    display: "flex",
    marginTop: 15,
  },
  HeadingDivText: {
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: "0.02em",
    color: "#242223",
    fontFamily: "Roboto-Bold",
  },
  buttonGroupDiv: {
    display: "flex",
    alignItems: "center",
  },
  stepperDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "8px",
  },
});
export default function NavBar() {
  const dispatch = useDispatch();
  let history = useHistory();
  const classes = useStyles();
  let url = history.location;

  const getUrlHeading = (url) => {
    if (url) {
      if (url.toString().includes("/admin/manage-customer/"))
        return "Customer Details";
      else if (
        url.toString().includes("/admin/rooms/") &&
        url.toString().includes("/edit")
      )
        return "Edit Room Details";
      else if (url.toString().includes("/admin/link-account/"))
        return "Link Account";
      else if (
        url.toString().includes("/admin/locations/") &&
        url.toString().includes("/edit")
      )
        return "Edit Location";
    } else return "";
  };

  const handleMyAccountClick = () => {
    dispatch(setUIActionType(UIActionType.User));
    history.push("/my-account");
  };

  const handleLogout = () => {
    authenticationService.logout();
    dispatch(clearAuth());
    history.push("/sign-in");
  };

  const onIconClick = () => {
    history.push("");
  };

  return (
    <AppBar
      position="sticky"
      color="white"
      style={{ zIndex: 2, height: 70, borderBottom: "5px solid #102F51" }}
    >
      <Toolbar variant="dense">
        <Grid item sm={2} direction="row" container>
          <img
            className={classes.image}
            src={logo}
            alt=""
            onClick={onIconClick}
          />
        </Grid>
        <Grid
          item
          sm={6}
          direction="row"
          container
          className={classes.HeadingDiv}
        >
          <Typography variant="h5" className={classes.HeadingDivText}>
            {ScreenHeading[url.pathname] || getUrlHeading(url.pathname)}
          </Typography>
          {/* <AccountStatus /> */}
        </Grid>
        <Grid item sm={4} container direction="row">
          <Grid item sm={6} container direction="row">
            <Locations />
          </Grid>
          <Grid item sm={6} container direction="row">
            <ButtonGroup
              size="large"
              variant="text"
              color="black"
              aria-label="text primary button group"
              className={classes.buttonGroupDiv}
            >
              <Divider
                className={classes.divider}
                orientation="vertical"
                flexItem
              />
              <Button
                fullWidth
                className={classes.navAccountButton}
                onClick={handleMyAccountClick}
              >
                My account
              </Button>
              <Divider className={classes.divider} orientation="vertical" />
              <Button className={classes.navButton} onClick={handleLogout}>
                Logout
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
