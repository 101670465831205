import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  MainDiv: {
    width: "100%",
    // height: "500px",
    border: "1px solid #CDE0F1",
    boxSizing: "border-box",
  },
  headerDiv: {
    // height: "700px",
    borderBottom: "1px solid #CDE0F1",
    boxSizing: "border-box",
    width: "100%",
    background: "#F1F9FE",
    height:49
  },
  headerText: {
    fontWeight: 500,
    fontSize: "15px",
    lineHeight: "140%",
    letterSpacing: "0.01em",
    color: "#242223",
    fontFamily: "Roboto-Medium",
  },
  headerLink: {
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.01em",
    color: "#2CA4C0",
    textAlign: "right",
  },
  bodyImgDiv: {
    width: "100%",
    display: "block",
    position: "relative",
    // border: "1px solid #CDE0F1",
  },
  bodyImg: {
    width: "100%",
    height: "375px",
    display: "block",
    objectFit: "cover",
  },
  bodyDetailsDiv: {
    width: "100%",
    background: "#102F51",
    opacity: "0.85",
    position: "absolute",
    bottom: 0,
    padding: "20px 5px",
  },
  bodyDetailsheading: {
    fontStyle: "normal",
    fontSize: 21,
    color: "#FFFFFF",
    fontFamily: "Roboto-Bold",
    display: "block",
    margin: "0 0 20px",
  },
  addressHeading: {
    fontSize: 16,
    letterSpacing: "0.01em",
    color: "#FFFFFF",
    fontFamily: "Roboto-Medium",
    lineHeight: 1.5,
  },
  bodyDetailsAddress: {
    fontSize: 14,
    letterSpacing: "0.01em",
    color: "#FFFFFF",
    fontFamily: "Roboto-Regular",
    lineHeight: 1.5,
    display: "block",
    margin: "0 0 20px",
  },
  bodyDetailsDate: {
    fontSize: 16,
    color: "#8EEAFF",
    fontFamily: "Roboto-Regular",
    fontWeight: "bold",
    margin: 0,
    padding: 0,
  },
  timeItem: {
    textAlign: "right",
  },
  dateList : {
    paddingTop : 0
  }
}));

export { useStyles };
