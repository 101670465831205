import React from "react";
import { Switch } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";
import * as Routes from "../components";
import { UserRoles } from "../core";

export const CustomRouter = () => {
  return (
    <Switch>
      <PrivateRoute
        path="/mail-management"
        exact
        component={Routes.MailManagment}
      />
      <PrivateRoute path="/payments" exact component={Routes.Payments} />
      {/* <PrivateRoute
        path="/phone-services"
        exact
        component={Routes.PhoneServices}
      /> */}
      <PrivateRoute path="/my-account" exact component={Routes.MyAccount} />
      <PrivateRoute
        path="/space-rentals"
        exact
        component={Routes.SpaceRental}
      />
      {/* <PrivateRoute path="/reports" exact component={Routes.Reports} /> */}
      <PrivateRoute path="/admin" roles={[UserRoles.SuperAdmin]} component={Routes.AdminInternal} />
      <PrivateRoute path="/front-desk" component={Routes.FrontDeskChat} />
      <PrivateRoute path="/" exact component={Routes.Dashboard} />
    </Switch>
  );
};
