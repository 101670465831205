import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    box: {
        background: "#FFFFFF",
        border: "1px solid #CDE0F1",
        marginTop : '20px'
    },
    boxRow: {
        borderBottom: "1px solid #DFDFE4"
    },
    boxHeader: {
        display: 'flex',
        background: "#F1F9FE",
        borderBottom: "1px solid #CDE0F1",
        boxSizing: "border- box",
        padding: "15px",
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    boxHeading: {
        fontWeight: "500",
        fontSize: "15px",
        lineHeight: "18px",
        color: "#242223",
        fontFamily: 'Roboto-Medium'
    },
    boxLeftHeading: {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#242223",
        fontWeight: "600",
        marginBottom: "5px"
    },
    boxContent: {
        display: 'flex',
        padding: "15px",
        fontSize: "14px",
        color: "#494949",
        letterSpacing: "0.01em",
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    boxContentRight: {
        marginLeft: "10px",
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    boxPrice: {
        fontSize: "21px",
        lineHeight: "25px",
        color: theme.palette.primary.main,
        fontWeight: "600",
        marginBottom: "5px",
        fontWeight: 600
    },
    noBorder: {
        border: "none"
    },
    myCurrentSpan: {
        color: "#D95767"
    },
}));


export { useStyles }