import React, { useEffect } from "react";
import { Box, Typography, IconButton, Link, CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { findIndex, isEmpty } from "lodash";
import moment from "moment";
import { useStyles } from "./UpcomingBookings.style";
import {
  setSelectedUpcomingBooking,
  // loadUpcomingBookings,
  setAvailableRoomsFilter,
  // loadFilteredRoom,
  openModal,
  getSelectedLocationId,
  setUpcomingBookings,
  clearSpaceRental,
  setErrorMessage,
  setFilteredRooms,
  getCustomerId,
} from "../../../store";
import {
  ArrowLeft as ArrowLeftIcon,
  ArrowRight as ArrowRightIcon,
  PeopleOutline as PeopleOutlineIcon,
} from "@material-ui/icons";
import Logo from '../../../assets/images/logo.jpg';
import { ModalType } from "../../../core";
import { apiCheckRoomAvailability, apiFetchUpcomingBookings } from "../../../apis";
import { getFormatedDate } from "../../../utils";
import { useState } from "react";

export default function UpcomingBookings(props) {
  const { spaceRental } = useSelector((state) => state);
  const { filter } = spaceRental.availableRooms;
  const { showInDashboard } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data, selected } = useSelector((state) => state.spaceRental.bookings);
  const locationId = useSelector((state) => getSelectedLocationId(state));
  const customerId = useSelector((state) => getCustomerId(state));

  const currentIndex = findIndex(data, selected);
  const [loading, setLoading] = useState(false);

  const seeAllRooms = async () => {
    try {
      setLoading(true);
      const currentDate = moment(new Date()).format("YYYY-MM-DD");
      const fromTime = null;
      const endTime = null;
      dispatch(
        setAvailableRoomsFilter({
          date: currentDate,
          fromTime,
          endTime,
          locationId,
        })
      );
      dispatch(clearSpaceRental());
      const { roomTypeId } = filter;
      const rooms = await apiCheckRoomAvailability(new Date(getFormatedDate(currentDate, fromTime)).toISOString(),
        new Date(getFormatedDate(currentDate, endTime)).toISOString(),
        locationId || 0,
        roomTypeId || 0,
        !!(fromTime && endTime));
      setLoading(false);
      dispatch(setFilteredRooms(rooms));
      dispatch(openModal(ModalType.RoomAvailabilityResultModal));
    }
    catch (error) {
      dispatch(setErrorMessage(error.toString()));
      setLoading(false);
    }
  };


  const handleNextClick = () => {
    if (data?.length > currentIndex + 1) {
      dispatch(setSelectedUpcomingBooking(data[currentIndex + 1]));
    }
  };

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      dispatch(setSelectedUpcomingBooking(data[currentIndex - 1]));
    }
  };

  useEffect(() => {
    const loadUpcomingBookings = async () => {
      const bookings = await apiFetchUpcomingBookings(customerId);
      dispatch(setUpcomingBookings(bookings));
      if (bookings.length) dispatch(setSelectedUpcomingBooking(bookings[0]));
    }
    loadUpcomingBookings();
    // dispatch(loadUpcomingBookings());
  }, [dispatch, customerId]);

  return (
    <Box
      component="div"
      className={showInDashboard ? classes.boxContainer : classes.box}
    >
      <Box className={classes.boxHeader}>
        <Typography variant="h6" className={classes.boxHeading}>
          Upcoming bookings
        </Typography>
        <Box className={classes.navigation}>
          <IconButton
            className={classes.navigationButton}
            onClick={handlePrevClick}
          >
            <ArrowLeftIcon />
          </IconButton>
          <Typography>{`(${currentIndex + 1}/${data?.length})`}</Typography>
          <IconButton
            className={classes.navigationButton}
            onClick={handleNextClick}
          >
            <ArrowRightIcon />
          </IconButton>
        </Box>
      </Box>
      <Box className={classes.boxContent}>
        <img
          className={
            showInDashboard
              ? classes.backgroundImgDashboard
              : classes.backgroundImg
          }
          src={selected?.coverUrl || Logo}
          alt={selected?.name}
        />
        <Box className={classes.bookingsContentDiv}>
          {isEmpty(selected) ? (
            <>
              <Typography variant="h2" className={classes.roomTitle}>
                No upcoming bookings
              </Typography>
              <Typography variant="body1">Book any of our available room of your next meeting! </Typography>
              <Link
                component="button"
                variant="inherit"
                color="inherit"
                className={classes.link}
                onClick={seeAllRooms}
              >
                View available rooms
                {loading && <Box className={classes.loader}><CircularProgress size="1.7rem" /></Box>}
              </Link>

            </>
          ) : (
            <>
              <Typography variant="h2" className={classes.roomTitle}>
                {selected?.name}
              </Typography>
              <Typography variant="body1">{selected?.location}</Typography>
              <Box className={classes.roomInfoContainer}>
                <Typography variant="body1" className={classes.roomInfo}>
                  <PeopleOutlineIcon /> {`${selected?.maxRange} People`}
                </Typography>
                <Box className={classes.dateTimeContainer}>
                  <Typography variant="body1" className={classes.roomInfo}>
                    {moment(selected?.startDate).format("DD MMM YYYY")}
                  </Typography>
                  <Typography variant="body1" className={classes.roomInfo}>
                    {`${moment(new Date(selected?.startDate), ["HH:mm"]).format(
                      "h:mm A"
                    )} - ${moment(new Date(selected?.endDate), [
                      "HH:mm",
                    ]).format("h:mm A")}`}
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}
