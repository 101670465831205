import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: "sans-serif",
        color: "#242223",
    },
    paper: {
        width: 350,
        background: '#fff',
        position: 'relative',
        maxHeight: "95vh",
        overflow: "auto"
    },
    modalHeader: {
        padding: '10px 15px',
        background: "#F1F9FE",
        borderBottom: "1px solid #CDE0F1",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    modalHeading: {
        fontWeight: "500",
        fontSize: "15px",
        lineHeight: "18px",
        color: "#242223",
        fontFamily: 'Roboto-Medium'
    },
    modalContentTitle: {
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "18px",
        color: "#242223",
        fontFamily: 'Roboto-Medium',
        marginBottom: 20
    },
    closeButton: {
        color: theme.palette.grey[500],
        padding: 0
    },
    modalBody: {
        padding: '20px 15px',
    },
    modalFooter: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "10px 15px",
        marginBottom: 15
    },
    footerButton: {
        flex: "1",
        maxWidth: "48%",
        border: "2px solid #2CA4C0",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        padding: "10px 15px"
    },
    footerPrimaryBtn: {
        background: "linear-gradient(90deg, #409A95 17.74%, #34CEC6 100%)",
        color: "#fff",
    }, modalFooterLeft: {
        display: "flex",
        alignItems: "center",
        maxWidth: "55%",
        flex: "1"
    },
    modalFooterLeftBody: {
        fontSize: '12px',
        fontFamily: 'Roboto-Italic'
    },
    modalFooterRight: {
        flex: "1",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    formControlRoot: {
        marginLeft: 0
    }
}));


export { useStyles }