import { createSelector } from "reselect";

const selectedLocation = (state) => state.customer.locations.selected;
const selectorUserTypes = (state) => state.ui.master.userTypes;
const loginCustomerIdSelector = (state) => state.auth.data.customer_id;
const adminCustomerSelector = (state) => state.admin.customer.selected;

const getSelectedLocationId = createSelector(
  [selectedLocation],
  (selectedLocation) => {
    return selectedLocation?.id || 0;
  }
);

const getSelectedLocationName = createSelector(
  [selectedLocation],
  (selectedLocation) => {
    return selectedLocation?.name || "";
  }
);

const getUserTypes = createSelector([selectorUserTypes], (userTypes) => {
  return userTypes || [];
});

const getCustomerId = createSelector(
  [loginCustomerIdSelector, adminCustomerSelector],
  (loginCustomerId, selectedCustomer) => {
    return selectedCustomer.id ? selectedCustomer.id : loginCustomerId;
  }
);

const getLoginCustomerId = createSelector(
  [loginCustomerIdSelector],
  (loginCustomerId) => {
    return loginCustomerId;
  }
);

export {
  getSelectedLocationId,
  getUserTypes,
  getCustomerId,
  getSelectedLocationName,
  getLoginCustomerId,
};
