import { combineReducers } from "redux";
import { bookingsReducer } from "./bookings";
import { availableRoomsReducer } from "./available-rooms";

const spaceRentalReducer = combineReducers({
  bookings: bookingsReducer,
  availableRooms: availableRoomsReducer,
});

export { spaceRentalReducer };
