import React, { Fragment, useState } from 'react'
import { Typography, MenuItem } from '@material-ui/core';
import { useStyles } from './GeneralHours.style';
import {
    AccessTime as AccessTimeIcon,
    CalendarTodayOutlined as CalendarTodayOutlinedIcon
} from '@material-ui/icons';
import { KeyboardTimePicker } from "@material-ui/pickers";
import { BaseDropdown } from '../../../shared';

export default function GeneralHours() {
    const classes = useStyles();
    const [values, setValues] = useState({ startDate: null, endDate: null });
    return (
        <Fragment>
            <div className={classes.selectDaysRow}>
                <div className={classes.selectColumn}>
                    <Typography className={classes.selectLabel} variant='span'>From</Typography>
                    <BaseDropdown defaultValue={"Select start day"} startIcon={<CalendarTodayOutlinedIcon fontSize="small" />}>
                        <MenuItem value="Select start day">Select start day</MenuItem>
                        <MenuItem value="Monday">Monday</MenuItem>
                        <MenuItem value="Tuesday">Tuesday</MenuItem>
                        <MenuItem value="Wednesday">Wednesday</MenuItem>
                        <MenuItem value="Thursday">Thursday</MenuItem>
                        <MenuItem value="Friday">Friday</MenuItem>
                        <MenuItem value="Saturday">Saturday</MenuItem>
                        <MenuItem value="Sunday">Sunday</MenuItem>
                    </BaseDropdown>
                </div>
                <div className={classes.selectColumn}>
                    <Typography className={classes.selectLabel} variant='span'>Until</Typography>
                    <BaseDropdown defaultValue={"Select end day"} placeholder="Test" startIcon={<CalendarTodayOutlinedIcon fontSize="small" />}>
                        <MenuItem value="Select end day">Select end day</MenuItem>
                        <MenuItem value="Monday">Monday</MenuItem>
                        <MenuItem value="Tuesday">Tuesday</MenuItem>
                        <MenuItem value="Wednesday">Wednesday</MenuItem>
                        <MenuItem value="Thursday">Thursday</MenuItem>
                        <MenuItem value="Friday">Friday</MenuItem>
                        <MenuItem value="Saturday">Saturday</MenuItem>
                        <MenuItem value="Sunday">Sunday</MenuItem>
                    </BaseDropdown>
                </div>
            </div>
            <div className={classes.selectDaysRow}>
                <div className={classes.selectColumn}>
                    <Typography className={classes.selectLabel} variant='span'>From</Typography>
                    <KeyboardTimePicker
                        value={values?.startDate}
                        onChange={(value) => setValues({ ...values, startDate: value })}
                        className={classes.timePicker}
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        keyboardIcon={<AccessTimeIcon color="primary" />}
                        InputAdornmentProps={{ position: "start" }}
                    />
                </div>
                <div className={classes.selectColumn}>
                    <Typography className={classes.selectLabel} variant='span'>Until</Typography>
                    <KeyboardTimePicker
                        value={values?.endDate}
                        onChange={(value) => setValues({ ...values, endDate: value })}
                        className={classes.timePicker}
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        keyboardIcon={<AccessTimeIcon color="primary" />}
                        InputAdornmentProps={{ position: "start" }}
                    />
                </div>
            </div>
        </Fragment>
    )
}
