import { UI } from "../../actions";

const messageReducer = (state = "", action) => {
    switch (action.type) {
        case UI.CLEAR_MESSAGE:
            return "";
        case UI.SET_SUCCESS_MESSAGE:
        case UI.SET_ERROR_MESSAGE:
            return action.message;
        default:
            return state;
    }
};

export { messageReducer };
