import React, { Fragment, useEffect } from "react";
import { Container } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import queryString from "query-string";
import { Pagination } from "./pagination";
import { MailStatus } from "../../core";
import { isUserSuperAdmin } from "../../constants/constants";
import {
  setMailItems,
  setMMTotalPages,
  setCustomerMailCounts,
  setTotalCount,
  setActiveTab,
  getCustomerId,
  setMailLoading,
  loadMailItemsFailed,
  setCustomerPlanServices,
  setCustomerPlanInfo,
  setCustomerDetailsdAdmin,
  setErrorMessage,
  getSelectedLocationId,
} from "../../store";
import { apiGetCustomerPlanServices, getAllNewMails } from "../../apis";
import { MailActions } from "./mail-actions";
import { Table } from "./table";
import { Header } from "./header";
import "./mail-managment.css";

export default function MailManagment() {
  const dispatch = useDispatch();
  const { activeTab, currentPage, search, limit } = useSelector(
    (state) => state.mail
  );
  const customerId = useSelector((state) => getCustomerId(state));
  const locationId = useSelector((state) => getSelectedLocationId(state));
  const mailAccountId = useSelector((state) => state.auth.data.mail_account_id);
  let history = useHistory();
  const status = queryString.parse(history.location.search)?.status;
  const isSuperAdmin = useSelector((state) =>
    isUserSuperAdmin(state.auth.data.user_type)
  );

  useEffect(() => {
    if (status) {
      dispatch(setActiveTab(status));
    }
    const loadCustomerPlanServices = async () => {
      try {
        const res = await apiGetCustomerPlanServices(customerId);
        const { planInfo, planServices, details } = res;
        dispatch(setCustomerPlanServices(planServices));
        dispatch(setCustomerPlanInfo(planInfo || {}));
        dispatch(setCustomerDetailsdAdmin(details));
      } catch (error) {
        dispatch(setErrorMessage(error.toString()));
      }
    };
    loadCustomerPlanServices();
    // dispatch(loadCustomerPlansServices());
  }, [dispatch, status, customerId]);

  useEffect(() => {
    const loadMailItems = async () => {
      try {
        dispatch(setMailLoading());
        const response = await getAllNewMails(
          activeTab,
          search,
          currentPage,
          limit,
          mailAccountId,
          locationId
        );
        const { mail_items, total_pages, total } = response;
        dispatch(setMailItems(mail_items));
        dispatch(setMMTotalPages(total_pages));
        dispatch(setCustomerMailCounts(total));
        dispatch(setTotalCount(total));
      } catch (error) {
        dispatch(loadMailItemsFailed());
      }
    };
    if ((isSuperAdmin || mailAccountId) && locationId) loadMailItems();
  }, [
    activeTab,
    currentPage,
    search,
    limit,
    mailAccountId,
    dispatch,
    locationId,
    isSuperAdmin,
  ]);

  const style =
    ".makeStyles-dashboardContent-1{display:flex;flex-direction:column} .MuiContainer-root{display:flex;flex-direction:column;flex:auto}";

  return (
    <Fragment>
      {<style>{style}</style>}
      <Header />
      <Container
        style={{ maxWidth: 1111 }}
        fullWidth
        className="mailButtonGroup"
      >
        {MailStatus.New === activeTab && <MailActions />}
        <div
          className="table-wrap"
          style={{ minHeight: "442px", width: "100%" }}
        >
          <Table />
        </div>
        <div className="pagination-container">
          <Pagination />
        </div>
      </Container>
    </Fragment>
  );
}
