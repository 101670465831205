import { MAILMANAGEMENT } from "../../actions";

const searchReducer = (state = "", action) => {
  switch (action.type) {
    case MAILMANAGEMENT.CLEAR:
      return "";
    case MAILMANAGEMENT.SET_SEARCH_TEXT:
      return action.search;
    default:
      return state;
  }
};

export { searchReducer };
