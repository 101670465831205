import React from "react";
export default function ScannedPreviewMailActionIcon() {
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.6525 12.3953L7.12679 16.2189C7.22109 16.3221 7.35708 16.3823 7.50003 16.3823C7.64297 16.3823 7.77896 16.3221 7.87326 16.2189L11.3476 12.3953C11.4756 12.2539 11.5074 12.0541 11.426 11.883C11.3456 11.7128 11.1689 11.6029 10.9743 11.6029L8.98901 11.6029L8.98901 5.38968C8.98901 5.12585 8.76665 4.91174 8.49268 4.91174L6.50737 4.91174C6.2334 4.91174 6.01104 5.12585 6.01104 5.38968L6.01104 11.6029L4.02574 11.6029C3.83118 11.6029 3.65448 11.7119 3.57408 11.883C3.49367 12.0541 3.52345 12.2548 3.6525 12.3953Z"
        fill="#2CA4C0"
      />
      <path
        d="M2 5.79419L2 2.61772L13 2.61772L13 5.79419L15 5.79419L15 1.55889C15 0.973365 14.552 0.500071 14 0.500071L1 0.500072C0.447 0.500072 -4.2145e-07 0.973366 -3.70261e-07 1.5589L0 5.79419L2 5.79419Z"
        fill="#2CA4C0"
      />
    </svg>
  );
}
