import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  box: {
    background: "#FFFFFF",
    border: "1px solid #CDE0F1",
  },
  main: {
    padding: "33px 24px",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  columnWrap: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridGap: "20px",
  },
  root: {
    flexGrow: 1,
    marginBottom : 10
  },
  basicDetailColumnWrap: {
    display: "grid",
    gridTemplateColumns: "1fr 2fr",
    gridGap: "20px",
  }
}));

export { useStyles };
