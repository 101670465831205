import { authenticationService } from "./funcs";
import { store, clearAuth, setErrorMessage } from "../store";

function handleErrorResponse(errResponse) {
  if ([401, 403].indexOf(errResponse.status) !== -1) {
    // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
    store.dispatch(clearAuth());
    authenticationService.logout();
    window.location.replace("/sign-in");
  }
  const message =
    typeof errResponse?.data?.data === "string"
      ? errResponse?.data?.data
      : errResponse?.data?.message || "Something went wrong!";
  store.dispatch(setErrorMessage(message));
  return message;
}

const handleResponse = (response) => {
  if (response && response.status >= 400) {
    const error = handleErrorResponse(response);
    throw new Error(error || "Something went wrong!");
  }
  return response?.data || null;
};

function handleAddCustomerErrorResponse(errResponse) {
  if ([401, 403].indexOf(errResponse.status) !== -1) {
    // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
    store.dispatch(clearAuth());
    authenticationService.logout();
    window.location.replace("/sign-in");
  } else if ([422].indexOf(errResponse.status) !== -1) {
    const user_Name_error = errResponse?.data?.data?.user_name
      ? "Username " + errResponse?.data?.data?.user_name
      : "";
    const email_error = errResponse?.data?.data?.email
      ? "Email " + errResponse?.data?.data?.email
      : "";
    const zip_code_error = errResponse?.data?.data?.zip_code
      ? "Zip Code " + errResponse?.data?.data?.zip_code
      : "";
    const error =
      "Error422" +
      "," +
      user_Name_error +
      "," +
      email_error +
      "," +
      zip_code_error;
    return error;
  }
  const message = errResponse?.data?.message || "Something went wrong!";
  store.dispatch(setErrorMessage(message));
  return message;
}

const handleAddCustomerResponse = (response) => {
  if (response && response.status >= 400) {
    const error = handleAddCustomerErrorResponse(response);
    throw new Error(error || "Something went wrong!");
  }
  return response.data || null;
};

export { handleResponse, handleAddCustomerResponse };
