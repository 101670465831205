import { createSelector } from "reselect";

const auth = (state) => state.auth.data;

const getAccountSetupStatus = createSelector(
  [auth],
  (auth) =>
    Boolean(auth.address1) &&
    Boolean(auth.city) &&
    Boolean(auth.state) &&
    Boolean(auth.zip) &&
    Boolean(auth.country) &&
    Boolean(auth.form_1583_filled)
);

const getUserInfoSelector = createSelector([auth], (auth) => {
  return {
    userName: auth.user_name,
    fullName: auth.full_name,
    phone: auth.phone,
    userEmail: auth.user_email,
    userId: auth.user_id,
    title: auth.title,
    userType: auth.user_type,
    imageUrl: auth.image_url,
  };
});

const getCompanyInfo = createSelector([auth], (auth) => {
  return {
    officeName: auth.office_name || "",
    customerName: auth.customer_name || "",
    address1: auth.address1 || "",
    address2: auth.address2 || "",
    city: auth.city || "",
    state: auth.state || "",
    zip: auth.zip || "",
    country: auth.country || "",
  };
});

export { getAccountSetupStatus, getUserInfoSelector, getCompanyInfo };
