import { PHONESERVICES } from "../../../../actions";

const dataReducer = (state = [], action) => {
    switch (action.type) {
        case PHONESERVICES.CLEAR_PHONE_SERVICES:
            return [];
        case PHONESERVICES.SET_DIRECTORY_DATA:
            return action.directory;
        default:
            return state;
    }
};

export { dataReducer };
