import { combineReducers } from "redux";
import { dataReducer } from "./data";
import { selectedReducer } from "./selected";

const paymentMethodReducer = combineReducers({
  data: dataReducer,
  selected: selectedReducer,
});

export { paymentMethodReducer };
