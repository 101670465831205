import { PHONESERVICES } from "../../../../actions";

const selectedDirectoryNumberReducer = (state = {}, action) => {
    switch (action.type) {
        case PHONESERVICES.CLEAR_PHONE_SERVICES:
            return {};
        case PHONESERVICES.SET_SELECTED_DIRECTORY:
            return action.selectedDirectoryNumber;
        default:
            return state;
    }
};

export { selectedDirectoryNumberReducer };
