import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { ModalType } from "../../../core";
import { closeModal } from "../../../store";
import "./success-action-modal.css";
import CloseModalIcon from "../../../assets/icons/closeModalIcon";

export const MailActionStatus = {
  junk: "Moving to Junk",
  picture: "a Photo",
  pick_up: "Hold for Pickup",
  scan: "a Scan",
  shred: "shredding",
  forward: "Forwarding",
};

export default function SuccessActionModal() {
  const state = useSelector((state) => state);
  const mailAction = state.mail.mailAction;
  const dispatch = useDispatch();
  const modal = state.ui.modal[ModalType.MailSuccessActionModal] || false;
  const handleClose = () => {
    dispatch(closeModal(ModalType.MailSuccessActionModal));
  };
  return (
    <Dialog
      open={modal}
      onClose={handleClose}
      aria-labelledby="junk-dialog-title"
      aria-describedby="junk-dialog-description"
    >
      <div className="succModalDiv">
        <DialogTitle id="junk-dialog-title" className="succModalTitleDiv">
          <Grid container sm={12} direction="row">
            <Grid sm={6}>
              <p className="succModalTitle">Success</p>
            </Grid>
            <Grid sm={6}>
              <CloseModalIcon
                handleCloseIcon={handleClose}
                className="succModalIcon"
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className="succModalContent">
          <DialogContentText id="junk-dialog-description">
            <p className="succModalContentText">
              You have sucessfully requested{" "}
              <b>{MailActionStatus[mailAction]}</b> This mail in now moved to
              “Process” and you have a short amount of time to cancel request if
              needed.
            </p>
          </DialogContentText>
        </DialogContent>
      </div>
    </Dialog>
  );
}
