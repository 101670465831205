import { http, handleResponse } from "../core";
import { URL } from "./_urls.js";

const apiFetchUpcomingBookings = async (customerId) => {
  return handleResponse(
    await http.get(URL.FETCH_UPCOMING_BOOKINGS(customerId))
  );
};

const apiFetchAvailableRooms = async (offset, limit) => {
  return handleResponse(
    await http.get(URL.FETCH_AVAILABLE_ROOMS(offset, limit))
  );
};

const apiRoomBooking = async (data) => {
  return handleResponse(await http.post(URL.BOOK_ROOM(), data));
};

const apiCheckRoomAvailability = async (
  startDate,
  endDate,
  locationId,
  roomTypeId,
  includeTimeRange
) => {
  return handleResponse(
    await http.get(
      URL.CHECK_ROOM_AVAILABILITY(
        startDate,
        endDate,
        locationId,
        roomTypeId,
        includeTimeRange
      )
    )
  );
};

const apiFetchRoomDetail = async (startDate, endDate, roomId) => {
  return handleResponse(
    await http.get(URL.FETCH_ROOM_DETAIL(startDate, endDate, roomId))
  );
};

export {
  apiFetchUpcomingBookings,
  apiFetchAvailableRooms,
  apiRoomBooking,
  apiCheckRoomAvailability,
  apiFetchRoomDetail,
};
