import {
  Divider,
  Drawer,
  List,
  makeStyles,
  Toolbar,
  Collapse,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import React, { useEffect } from "react";
import { SidebarNavLink } from "../sidebar-nav-link";
import { useLocation } from "react-router-dom";
import "./side-bar.css";
import { useSelector } from "react-redux";
import { isUserSuperAdmin } from "../../../constants/constants";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: 1,
  },
  drawerPaper: {
    width: drawerWidth,
    zIndex: 1,
    borderRight: "3px solid #f1f9fe !important",
  },
  drawerContainer: {
    overflow: "auto"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  activeClass: {
    backgroundColor: "#102F51",
    color: "white",
    "&:hover,&:focus": {
      backgroundColor: "#102F51 !important",
      color: "white",
      textDecoration: "none",
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));
export default function Sidebar() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { pathname } = useLocation();
  const state = useSelector((state) => state);
  const userType = state.auth.data.user_type;

  useEffect(() => {
    if (pathname.includes("admin")) setOpen(true);
    else setOpen(false);
  }, [pathname]);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <List style={{ paddingTop: 5 }}>
          <SidebarNavLink
            exact
            activeClass={classes.activeClass}
            to={"/"}
            title={"Dashboard"}
          />
          <SidebarNavLink
            exact
            activeClass={classes.activeClass}
            to={"/mail-management"}
            title={"Mail Management"}
          />
          {/* <SidebarNavLink
            exact
            activeClass={classes.activeClass}
            to={"/phone-services"}
            title={"Phone Services"}
          /> */}
          <SidebarNavLink
            exact
            activeClass={classes.activeClass}
            to={"/space-rentals"}
            title={"Space Rental"}
          />
        </List>
        <Divider />
        <List>
          <SidebarNavLink
            exact
            activeClass={classes.activeClass}
            to={"/payments"}
            title={"Payments"}
          />
          {/* <SidebarNavLink
            exact
            activeClass={classes.activeClass}
            to={"/reports"}
            title={"Reports"}
          /> */}
          {isUserSuperAdmin(userType) &&
            <>
              <ListItem button onClick={handleClick}>
                <ListItemText primary="Admin Internal" />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {/* <SidebarNavLink
                exact
                listItemClass={classes.nested}
                activeClass={classes.activeClass}
                to={"/admin"}
                title={"Dashboard"}
              /> */}
                  <SidebarNavLink
                    exact
                    listItemClass={classes.nested}
                    activeClass={classes.activeClass}
                    to={"/admin/customers"}
                    title={"Customers"}
                  />
                  <SidebarNavLink
                    exact
                    listItemClass={classes.nested}
                    activeClass={classes.activeClass}
                    to={"/admin/rooms"}
                    title={"Rooms"}
                  />
                  <SidebarNavLink
                    exact
                    listItemClass={classes.nested}
                    activeClass={classes.activeClass}
                    to={"/admin/locations"}
                    title={"Locations"}
                  />
                  <SidebarNavLink
                    exact
                    listItemClass={classes.nested}
                    activeClass={classes.activeClass}
                    to={"/admin/support"}
                    title={"Support"}
                  />
                  <SidebarNavLink
                    exact
                    listItemClass={classes.nested}
                    activeClass={classes.activeClass}
                    to={"/admin/map-location"}
                    title={"Map Location"}
                  />
                </List>
              </Collapse>
            </>
          }
        </List>
      </div>
    </Drawer>
  );
}
