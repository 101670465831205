import { MAILMANAGEMENT } from "../../actions";

const mailActionReducer = (state = "", action) => {
  switch (action.type) {
    case MAILMANAGEMENT.CLEAR:
      return "";
    case MAILMANAGEMENT.SET_MAIL_ACTION:
      return action.mailAction;
    default:
      return state;
  }
};

export { mailActionReducer };
