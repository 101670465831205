import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  box: {
    background: "#FFFFFF",
    border: "1px solid #CDE0F1",
    marginTop: "30px",
  },
  boxRow: {
    borderBottom: "1px solid #DFDFE4",
  },
  boxButton: {
    display: "flex",
    background: "#102F51",
    padding: "15px",
    fontSize: "15px",
    color: "#8EEAFF",
    border: "1px solid #CDE0F1",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
  },
  noBorder: {
    border: "none",
  },
  boxHeader: {
    display: "flex",
    background: "#F1F9FE",
    borderBottom: "1px solid #CDE0F1",
    boxSizing: "border- box",
    padding: "15px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  boxContentDataRow: {
    marginTop: "0",
    padding: "10px 15px",
    width: "100%",
  },
  boxLink: {
    fontSize: "14px",
    lineHeight: "16px",
    color: theme.palette.primary.main,
  },
}));

export { useStyles };
