/* eslint-disable */
export const overrides = {
  MuiInputBase: {
    formControl: {
      height: "40px",
    },
    root: {
      height: "40px",
    },
    multiline: {
      height: "inherit",
    },
  },
  MuiButton: {
    root: {
      border: "none",
      outline: "none",
      textTransform: "none",
      "-webkit-appearance": "none",
      "&:not(disabled)": {
        cursor: "pointer",
      },
    },
  },
  MuiOutlinedInput: {
    root: {
      borderRadius: 0,
      border: "1px solid #cde0f1",
      "&:hover": {
        border: "1px solid #cde0f1",
        borderColor: "#cde0f1",
      },
    },
    input: {
      padding: "8px",
      "&::placeholder": {
        color: "#000000",
        fontFamily: "Roboto-Light",
      },
    },
    notchedOutline: {
      borderColor: "#cde0f1",
    },
    adornedStart: {
      paddingLeft: 0,
    },
  },
  MuiSwitch: {
    root: {
      width: 48,
      height: 26,
      padding: 0,
      alignItems: "center",
    },
    switchBase: {
      top: "inherit",
      left: "2.5px",
      padding: 1,
      "&$checked": {
        transform: "translateX(22px)",
        color: "#ffffff",
        "& + $track": {
          backgroundColor: "#52d869",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#52d869",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 18,
      height: 18,
      backgroundColor: "#ffffff",
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid grey`,
      backgroundColor: "grey",
      opacity: 1,
    },
    checked: {
      color: "#ffffff",
    },
    focusVisible: {},
  },
  MuiTableCell: {
    root: {
      lineHeight: 0.5,
    },
    head: {
      lineHeight: 0.5,
    },
  },
  PrivateNotchedOutline: {
    root: {
      top: 0,
    },
  },
  MuiPaper: {
    rounded: {
      borderRadius: 0,
    },
    elevation8: {
      boxShadow: "none",
      border: "2px solid #2CA4C0",
    },
  },
  MuiBadge: {
    colorPrimary: {
      color: "#fff",
    },
  },
  PrivateNotchedOutline: {
    root: {
      borderWidth: 0,
    },
  },
  PrivateSwitchBase: {
    root: {
      padding: "0 9px",
    },
  },
};
