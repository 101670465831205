import { http, handleResponse, handleAddCustomerResponse } from "../core";
import { URL } from "./_urls.js";

const apiFetchAllRooms = async (locationId) => {
  return handleResponse(await http.get(URL.FETCH_ALL_ROOMS(locationId)));
};

const apiGetRoomDetailById = async (roomId) => {
  return handleResponse(await http.get(URL.FETCH_ROOM_DETAIL_BY_ID(roomId)));
};

const apiAddNewRoom = async (data) => {
  return handleResponse(await http.post(URL.ADD_ROOM(), data));
};

const apiUpdateRoom = async (data) => {
  return handleResponse(await http.put(URL.UPDATE_ROOM(), data));
};

const apiFetchAllLocations = async () => {
  return handleResponse(await http.get(URL.FETCH_ALL_LOCATIONS()));
};

const apiGetLocationDetailById = async (locationId) => {
  return handleResponse(
    await http.get(URL.FETCH_LOCATION_DETAIL_BY_ID(locationId))
  );
};

const apiAddNewLocation = async (data) => {
  return handleResponse(await http.post(URL.ADD_LOCATION(), data));
};

const apiUpdateLocation = async (data) => {
  return handleResponse(await http.put(URL.UPDATE_LOCATION(), data));
};

const apiLinkAccount = async (data) => {
  return handleResponse(await http.put(URL.LINK_ACCOUNT(), data));
};

const apiGetCustomerDetailById = async (customerId) => {
  return handleResponse(await http.get(URL.GET_CUSTOMER_DETAIL(customerId)));
};

const apiFetchChargebeeCustomers = async (email) => {
  return handleResponse(await http.get(URL.FETCH_CHARGEBEE_CUSTOMERS(email)));
};

const apiCreateNewCustomer = async (locationId, data) => {
  return handleAddCustomerResponse(
    await http.post(URL.CREATE_NEW_CUSTOMER(locationId), data)
  );
};

const apiDeleteRoom = async (roomId) => {
  return handleResponse(await http.delete(URL.DELETE_ROOM(roomId)));
};

const apiDeleteCustomer = async (customerId) => {
  return handleResponse(await http.delete(URL.DELETE_CUSTOMER(customerId)));
};

const apiFetchAllChatUsers = async (locationId) => {
  return handleResponse(await http.get(URL.FETCH_ALL_CHAT_USERS(locationId)));
};

const apiValidateMailAccount = async (data) => {
  return handleResponse(await http.post(URL.VALIDATE_MAIL_ACCOUNT(), data));
};

const apiUpdateLocationMapping = async (data) => {
  return handleResponse(await http.post(URL.UPDATE_LOCATION_MAPPING(), data));
};

export {
  apiFetchAllRooms,
  apiGetRoomDetailById,
  apiAddNewRoom,
  apiUpdateRoom,
  apiFetchAllLocations,
  apiGetLocationDetailById,
  apiAddNewLocation,
  apiUpdateLocation,
  apiLinkAccount,
  apiGetCustomerDetailById,
  apiFetchChargebeeCustomers,
  apiCreateNewCustomer,
  apiDeleteRoom,
  apiDeleteCustomer,
  apiFetchAllChatUsers,
  apiValidateMailAccount,
  apiUpdateLocationMapping,
};
