import React from "react";
import {
  Modal,
  Fade,
  Backdrop,
  Typography,
  Button,
} from "@material-ui/core";
import clsx from "clsx";
import { useStyles } from "./ConfirmationModal.style";
import IconButton from "@material-ui/core/IconButton";
import { HighlightOff as HighlightOffIcon } from "@material-ui/icons";

export default function ConfirmationModal(props) {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="buy-minutes-modal-title"
      aria-describedby="buy-minutes-modal-description"
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      className={classes.modal}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <div className={classes.paper}>
          <div className={classes.modalHeader}>
            <Typography variant="h6" className={classes.modalHeading}>
              Confirmation Modal
            </Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={props.handleClose}
            >
              <HighlightOffIcon />
            </IconButton>
          </div>
          <div className={classes.modalBody}>
            <div className={classes.chooseAvailable}>
              <Typography variant="h5" className={classes.modalContentTitle}>
                {props.title}
              </Typography>
            </div>
          </div>
          <div className={classes.modalFooter}>
            <div className={classes.modalFooterRight}>
              <Button
                className={clsx(classes.footerButton, classes.footerPrimaryBtn)}
                disabled={props.isLoading}
                onClick={props.onConfirm}
                variant="contained"
                color="primary"
              >
                Yes
              </Button>
              <Button
                className={classes.footerButton}
                variant="outlined"
                disabled={props.isLoading}
                onClick={props.handleClose}
              >
                No
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
