import React from 'react';
import { useHistory } from "react-router-dom";
import { Link, Box, Grid, Typography, Button, InputAdornment, OutlinedInput, IconButton, FormHelperText } from '@material-ui/core';
import clsx from 'clsx';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { setErrorMessage, setSuccessMessage } from '../../store';
import { apiCPThroughLink } from '../../apis';
import Logo from '../../assets/images/logo.jpg';
import {
    VisibilityOutlined as VisibilityOutlinedIcon,
    VisibilityOffOutlined as VisibilityOffOutlinedIcon
} from '@material-ui/icons';
import { useStyles } from './ChangePasswordThroughLink.style';

const validationSchema = yup.object({
    newPassword: yup
        .string('Enter old Password')
        .min(8, "new password must be more then 8 digit")
        .required('new password is required'),
    confirmPassword: yup
        .string('Enter old Password')
        .required('confirm password is required')
        .test('match',
            'confirm password do not match',
            function (confirmPassword) {
                return confirmPassword === this.parent.newPassword;
            }),
});

export default function ChangePasswordThroughLink() {
    const initialValues = { newPassword: "", confirmPassword: "", newPasswordVisible: false, confirmPasswordVisible: false };
    let history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();

    const inputType = (isVisible) => isVisible ? "text" : "password";

    const handleBackToLoginClick = () => {
        history.push("/sign-in")
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            const finishLoading = () => formik.setSubmitting(false);
            try {
                const query = new URLSearchParams(history.location.search);
                const token = query.get("token");
                if (token) {
                    await apiCPThroughLink({ ...values, token });
                    dispatch(setSuccessMessage("Password changed"));
                    history.push("/");
                }
                else
                    dispatch(setErrorMessage("Invalid url"));
            } catch (error) {
                dispatch(setErrorMessage(error.toString()));
            }
            finishLoading();
        },
    });

    return (
        <Box component="div" className={classes.fgtPwd}>
            <Grid className={classes.fgtPwdLeft}>
                <Grid className={classes.fgtPwdLeftContainer}>
                    <Box component="div" className={classes.fgtPwdLogo}>
                        <img className={classes.fgtPwdLogoImg} src={Logo} alt="logo" />
                    </Box>
                    <form onSubmit={formik.handleSubmit} autoComplete="off" className={classes.fgtPwdFormWrap}>
                        <Typography variant="h4" className={classes.fgtPwdLeftH4}>Change Password</Typography>
                        <Box component="div" className={classes.fgtPwdForm}>
                            <Box component="div" className={clsx(classes.fgtPwdProfileField, classes.fgtPwdTransformButton)}>
                                <OutlinedInput
                                    id="new-pwd"
                                    type={inputType(formik.values.newPasswordVisible)}
                                    fullWidth
                                    notched={false}
                                    placeholder="New Password"
                                    classes={{
                                        root: classes.fgtPwdInput
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => formik.setFieldValue("newPasswordVisible", !formik.values.newPasswordVisible)}>
                                                {formik.values.newPasswordVisible ? <VisibilityOffOutlinedIcon color="primary" /> : <VisibilityOutlinedIcon color="primary" />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    name="newPassword"
                                    value={formik.values.newPassword}
                                    onChange={formik.handleChange}
                                    error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                                />
                                <FormHelperText error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}>
                                    {formik.touched.newPassword && formik.errors.newPassword}
                                </FormHelperText>
                            </Box>
                            <Box component="div" className={clsx(classes.fgtPwdProfileField, classes.fgtPwdTransformButton)}>
                                <OutlinedInput
                                    id="confirm-pwd"
                                    type={inputType(formik.values.confirmPasswordVisible)}
                                    fullWidth
                                    notched={false}
                                    placeholder="Confirm Password"
                                    classes={{
                                        root: classes.fgtPwdInput
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => formik.setFieldValue("confirmPasswordVisible", !formik.values.confirmPasswordVisible)}>
                                                {formik.values.confirmPasswordVisible ? <VisibilityOffOutlinedIcon color="primary" /> : <VisibilityOutlinedIcon color="primary" />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    name="confirmPassword"
                                    value={formik.values.confirmPassword}
                                    onChange={formik.handleChange}
                                    error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                />
                                <FormHelperText error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}>
                                    {formik.touched.confirmPassword && formik.errors.confirmPassword}
                                </FormHelperText>
                            </Box>
                            <Button variant="contained" color="inherit" type="submit" disabled={formik.isSubmitting} fullWidth className={clsx(classes.fgtPwdLeftBtn, classes.fgtPwdTransformButton)}>
                                Change Password
                            </Button>
                        </Box>
                    </form>
                    <Link
                        component="button"
                        variant="inherit"
                        color="inherit"
                        className={classes.fgtPwdBackToLogin}
                        onClick={handleBackToLoginClick}
                    >
                        Back to Login
                    </Link>
                </Grid>
            </Grid>
            <Grid className={classes.fgtPwdRight}>
                <Box component="div" className={classes.fgtPwdRightBgLayer}>
                </Box>
            </Grid>
        </Box >
    );
}
