import React, { useCallback } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { AutoSizer } from "react-virtualized";
import { useSelector, useDispatch } from "react-redux";
import { Typography } from "@material-ui/core";
import { capitalize } from "lodash";
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
} from "@material-ui/icons";
import { MailStatus } from "../../../core";
import { setSelectedMailRowIds, setMailItem, openModal } from "../../../store";
import SelectedColumn from "./selectedColumn";
import { useStyles } from "./Table.style";

const NoRowsOverlay = () => {
  const classes = useStyles();
  const activeTab = useSelector((state) => state.mail.activeTab);
  return (
    <div className={classes.noRowOverlay}>
      <Typography variant="subtitle1">Nothing here yet</Typography>
      <Typography variant="body2">{`${capitalize(
        activeTab
      )} mail items are displayed here. `}</Typography>
    </div>
  );
};

export default function Table() {
  const mail = useSelector((state) => state.mail);
  const dispatch = useDispatch();
  const { data, isLoading, activeTab } = mail;
  const handleMailAction = useCallback(
    (modal, row) => {
      dispatch(openModal(modal));
      dispatch(setMailItem(row));
    },
    [activeTab]
  );

  const handleCancelRequest = useCallback(
    (modal, row) => {
      dispatch(openModal(modal));
      dispatch(setMailItem(row));
    },
    [activeTab]
  );

  return (
    <AutoSizer style={{ width: "100%" }}>
      {({ height, width }) => (
        <div
          style={{
            height: "100%",
            position: "absolute",
            width: "100%",
            minWidth: "100%",
          }}
        >
          <DataGrid
            headerHeight={40}
            rowHeight={40}
            height={height}
            rows={data}
            columns={SelectedColumn(
              width,
              activeTab,
              handleMailAction,
              handleCancelRequest
            )}
            checkboxSelection={Boolean(MailStatus.New === activeTab)}
            disableColumnFilter
            disableColumnMenu
            disableSelectionOnClick
            hideFooter
            loading={isLoading}
            sortingOrder={["asc", "desc"]}
            onSelectionModelChange={(rowIds) =>
              dispatch(setSelectedMailRowIds(rowIds))
            }
            components={{
              ColumnSortedAscendingIcon: ArrowDropDownIcon,
              ColumnSortedDescendingIcon: ArrowDropUpIcon,
              NoRowsOverlay: NoRowsOverlay,
            }}
          />
        </div>
      )}
    </AutoSizer>
  );
}
