import { ADMIN } from "../../../actions";

const detailsReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN.CLEAR_ADMIN:
      return {};
    case ADMIN.SET_CUSTOMER_DETAIL_ADMIN:
      return action.details;
    default:
      return state;
  }
};

export { detailsReducer };
