import React, { useState } from "react";
import { Box, Typography, Switch, Link } from "@material-ui/core";
import { useStyles } from "./FilledUSPSForm.style";

export default function FilledUSPSForm() {
  const classes = useStyles();
  const [filled, setFilled] = useState(false);

  // const toggleButton = () => setFilled(!filled);

  return (
    <Box component="div" className={classes.box}>
      <Box className={classes.boxContent}>
        <Typography variant="subtitle1">Filled USPS From 1583</Typography>
        <Switch color="primary" checked={filled} />
      </Box>
      {filled ? (
        <Typography className={classes.infoMessage}>
          Successfully filled on 8/10/21{" "}
        </Typography>
      ) : (
        <Typography color="error" className={classes.infoMessage}>
          Check your email for instructions{" "}
        </Typography>
      )}
      <Link
        component="button"
        variant="inherit"
        color="primary"
        className={classes.boxLink}
      >
        {filled ? "View Form" : "Resend the mail"}
      </Link>
    </Box>
  );
}
