import React from "react";
import { ListItem, ListItemText } from "@material-ui/core";
import { NavLink } from "react-router-dom";

export default function SidebarNavLink({
  listItemClass,
  itemTextClass,
  activeClass,
  title,
  to,
  exact,
  isActive,
  ...rest
}) {
  return (
    <ListItem
      className={listItemClass}
      button
      exact={exact}
      component={NavLink}
      to={to}
      title={title}
      {...(activeClass && {
        activeClassName: activeClass,
      })}
      {...(isActive && {
        isActive,
      })}
    >
      {rest.children}
      <ListItemText className={itemTextClass} primary={title} />
    </ListItem>
  );
}
