import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  box: {
    background: "#FFFFFF",
    border: "1px solid #CDE0F1",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  boxRow: {
    // borderBottom: "1px solid #DFDFE4"
  },
  boxHeader: {
    display: "flex",
    background: "#F1F9FE",
    borderBottom: "1px solid #CDE0F1",
    boxSizing: "border- box",
    padding: "0 0 0 10px",
    alignItems: "center",
    justifyContent: "space-between",
    height: "50px",
    width: "100%",
  },
  boxHeading: {
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "18px",
    color: "#242223",
    fontFamily: "Roboto-Medium",
  },
  boxContent: {
    display: "flex",
    padding: "15px",
    fontSize: "14px",
    color: "#494949",
    letterSpacing: "0.01em",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  boxContentDataRow: {
    borderBottom: "1px solid #DFDFE4",
    width: "100%",
    padding: "15px 0",
  },
  noTpadding: {
    paddingTop: 0,
  },
  boxContentHeading: {
    color: "#2CA4C0",
    marginBottom: "10px",
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "18px",
    fontFamily: "Roboto-Medium",
  },
  boxContentTransactions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "5px 0",
  },
  noAlign: {
    alignItems: "flex-start",
  },
  transactionPrice: {
    fontWeight: "bold",
  },
  transactionDate: {
    display: "flex",
    backgroundColor: "#CDE0F1",
    height: "100%",
    alignItems: "center",
    padding: "0 10px",
    width: "50%",
    justifyContent: "space-around",
  },
  noBorder: {
    border: "none",
  },
  total: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px",
    background: "rgba(242, 246, 250, 0.8)",
    borderTop: "1px solid #8EEAFF",
    marginTop: "auto",
  },
  totalNumber: {
    fontSize: "22px",
  },
  arrowIcon: {
    color: "#91AAC0",
    width: "1.5em",
    height: "1.5em",
  },
  dateRangeTypo: {
    fontSize: "14px",
    fontFamily: "Roboto-Light",
    color: "#2CA4C0",
  },
  transactionContainer : {
    flexBasis : "100%"
  }
}));

export { useStyles };
