import React from "react";
import { Box, CircularProgress, Grid } from "@material-ui/core";
import { useStyles } from "./mail-management-style";
import dashboardMailManagementImage from "../../../assets/images/dashboard-mail-managment.png";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { MailStatus, ModalType, OperatingMode } from "../../../core";
import { userHasEditPermission } from "../../../constants/constants";
import { useEffect } from "react";
import { useState } from "react";
import { apiFetchCustomerForwardingAddresses } from "../../../apis";
import { getCustomerId, getSelectedLocationId, openModal, setErrorMessage, setForwardingAddressData, setForwardingAddressOperatingMode, setSelectedForwardingAddress } from "../../../store";

export default function MailManagement(props) {
  const classes = useStyles();
  const state = useSelector((state) => state);
  const mailCount = useSelector((state) => state.customer.locations.mailCount);
  const userType = useSelector((state) => state.auth.data.user_type);
  const [forwardingAddresses, setforwardingAddresses] = useState([]);
  const [loading, setLoading] = useState(false);
  const locationId = getSelectedLocationId(state);
  const customerId = getCustomerId(state);
  const dispatch = useDispatch();
  let history = useHistory();
  const handleMailClick = (status) => {
    history.push(`/mail-management?status=${status}`);
  };

  const handleEditForwardingAddress = () => {
    if (loading)
      return
    dispatch(setForwardingAddressOperatingMode(OperatingMode.Edit));
    dispatch(openModal(ModalType.AddForwardingAddress));
  };

  const handleAddForwardingAddress = () => {
    if (loading)
      return
    history.push("/my-account");
  };

  useEffect(() => {
    const fetchForwardingAddresses = async () => {
      setLoading(true);
      try {
        const response = await apiFetchCustomerForwardingAddresses(
          locationId, customerId,
        );
        const selectedForwardingAddress = response.length
          ? response[0]
          : {};
        setforwardingAddresses(response);
        setLoading(false);
        dispatch(setForwardingAddressData(response));
        dispatch(setSelectedForwardingAddress(selectedForwardingAddress));
      }
      catch (error) {
        setLoading(false);
        dispatch(setErrorMessage(error.toString()));
      }
    };
    if (locationId && customerId) fetchForwardingAddresses();
  }, [dispatch, locationId, customerId]);

  return (
    <>
      <div className={classes.MainDiv}>
        <Grid
          sm={12}
          direction="row"
          container
          className={classes.MainHeadingDiv}
        >
          <p className={classes.MainHeadingText}>My mail management</p>
        </Grid>
        <Grid sm={12} direction="row" container>
          <div
            className={classes.forwardingMainDiv}
            style={{
              background: `url(${dashboardMailManagementImage})`,
            }}
          >
            <div className={classes.forwardingContentDiv}>
              <p className={classes.forwardingConten}>Your forwarding address</p>
              <br />
              {/* <p className={classes.forwardingAddress}>
                1002 Medical Drive, Kentucky
              </p> */}
              <br />
              <br />
              {userHasEditPermission(userType) && <span className="d-flex">
                <p
                  className={classes.forwardingAddressLink}
                  onClick={forwardingAddresses && forwardingAddresses.length ? handleEditForwardingAddress : handleAddForwardingAddress}
                >
                  {forwardingAddresses && forwardingAddresses.length ? 'Edit ' : 'Add '} details
                </p>
                <svg
                  className={classes.forwardingAddressIcon}
                  width="17"
                  height="8"
                  viewBox="0 0 17 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.3536 4.33012C16.5488 4.13485 16.5488 3.81827 16.3536 3.62301L13.1716 0.441029C12.9763 0.245766 12.6597 0.245766 12.4645 0.441029C12.2692 0.636291 12.2692 0.952873 12.4645 1.14814L15.2929 3.97656L12.4645 6.80499C12.2692 7.00025 12.2692 7.31683 12.4645 7.5121C12.6597 7.70736 12.9763 7.70736 13.1716 7.5121L16.3536 4.33012ZM0 4.47656H16V3.47656H0V4.47656Z"
                    fill="#8EEAFF"
                  />
                </svg>
                {loading && <Box className={classes.loader}> <CircularProgress size="1.5rem" /> </Box>}
              </span>}
            </div>
          </div>
        </Grid>
        <Grid
          sm={12}
          direction="row"
          container
          spacing={2}
          className={classes.counterGrid}
        >
          <Grid sm={4} direction="col" item>
            <div
              className={classes.counterDiv}
              onClick={() => handleMailClick(MailStatus.New)}
            >
              <p className={classes.counterNum}>{mailCount?.new || 0}</p>
              <p className={classes.counterStatus}>New</p>
            </div>
          </Grid>
          <Grid sm={4} direction="col" item>
            <div
              className={classes.counterDiv}
              onClick={() => handleMailClick(MailStatus.Pending)}
            >
              <p className={classes.counterNum}>{mailCount?.pending}</p>
              <p className={classes.counterStatus}>Pending</p>
            </div>
          </Grid>
          <Grid sm={4} direction="col" item>
            <div
              className={classes.counterDiv}
              onClick={() => handleMailClick(MailStatus.Processed)}
            >
              <p className={classes.counterNum}>{mailCount?.processed}</p>
              <p className={classes.counterStatus}>Proccessed</p>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
