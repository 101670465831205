import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  box: {
    background: "#FFFFFF",
    border: "1px solid #CDE0F1",
    fontFamily: " Roboto-Light",
    height: "100%",
  },
  boxHeader: {
    display: "flex",
    background: "#F1F9FE",
    borderBottom: "1px solid #CDE0F1",
    boxSizing: "border- box",
    padding: "15px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  boxHeading: {
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "18px",
    color: "#242223",
    fontFamily: "Roboto-Medium",
  },
  boxLink: {
    fontSize: "14px",
    lineHeight: "16px",
    color: "#2CA4C0",
  },
  boxContent: {
    display: "flex",
    padding: "15px",
    fontSize: "14px",
    color: "#494949",
    letterSpacing: "0.01em",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  leftSection: {
    flexBasis: "66%",
  },
  rightSection: {
    flexBasis: "32%",
    borderLeft: "1px solid #DFDFE4",
    flexDirection: "column",
    display: "grid",
  },
  dateRowContainer: {
    marginTop: "10px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: "20px",
  },
  timeRowContainer: {
    marginTop: "20px",
    display: "grid",
    gridTemplateColumns: "2fr 1fr",
    columnGap: "10px",
  },
  peopleContainer: {
    marginTop: "20px",
  },
  labelContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  labelItem: {
    flexBasis: "40%",
    marginBottom: "5px",
    fontFamily: "Roboto-Medium",
    color: "#000",
  },
  timeContainer: {
    display: "flex",
  },
  dateFieldLogo: {
    background: "aliceblue",
    display: "flex",
    height: 40,
    border: "1px solid #CDE0F1",
    alignItems: "center",
    padding: "0 8px",
  },
  radioGroup: {
    marginTop: "18px",
    justifySelf: "center",
  },
  startAdornment: {
    backgroundColor: "#F1F9FE",
    height: "100%",
    maxHeight: "100%",
    padding: "0 8px",
  },
  activePeopleButton: {
    background: "#102F51",
    color: "#ffffff",
    border: "1px solid #CDE0F1",
    height: "40px",
    display: "flex",
    padding: "0 8px",
    alignItems: "center",
    minWidth: "10%",
    fontFamily: "Roboto-Medium",
    "&:hover": {
      backgroundColor: "#102F51",
      color: "#ffffff",
    },
  },
  peopleButton: {
    border: "1px solid #CDE0F1",
    height: "40px",
    display: "flex",
    padding: "0 8px",
    alignItems: "center",
    minWidth: "10%",
    fontFamily: "Roboto-Medium",
    "&:hover": {
      backgroundColor: "#102F51",
      color: "#ffffff",
    },
  },
  checkAvailabilityButton: {
    background: "#2CA4C0",
    color: "white",
    marginTop: "20px",
    fontFamily: "Roboto-Medium",
  },
  serviceRow: {
    display: "flex",
    marginLeft: "20px",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    marginTop:"8px"
  }
}));

export { useStyles };
