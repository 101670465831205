import React from 'react'
import { Modal, Fade, Backdrop, Typography, Radio, Button, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, FormControlLabel } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { closeModal } from '../../../store';
import { ModalType } from '../../../core';
import clsx from 'clsx';
import { useStyles } from './BuyMinutes.style';
import IconButton from '@material-ui/core/IconButton';
import {
    HighlightOff as HighlightOffIcon,
    ReportProblem as ReportProblemIcon
} from '@material-ui/icons';


export default function BuyMinutes() {
    const state = useSelector(state => state);
    const dispatch = useDispatch();
    const classes = useStyles();
    const modal = state.ui.modal[ModalType.BuyMinutes] || false;
    const handleClose = () => {
        dispatch(closeModal(ModalType.BuyMinutes));
    }
    return (
        <Modal
            aria-labelledby="buy-minutes-modal-title"
            aria-describedby="buy-minutes-modal-description"
            open={modal}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            className={classes.modal}
            BackdropProps={{
                timeout: 500
            }}
        >
            <Fade in={modal}>
                <div className={classes.paper}>
                    <div className={classes.modalHeader}>
                        <Typography variant="h6" className={classes.modalHeading}>
                            ChangePlan
                        </Typography>
                        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                            <HighlightOffIcon />
                        </IconButton>
                    </div>
                    <div className={classes.modalBody}>
                        <div className={classes.chooseAvailable}>
                            <Typography variant="h5" className={classes.modalContentTitle}>
                                Choose a package:
                            </Typography>
                            <TableContainer className={classes.tableContainer} component={Paper}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead className={classes.tableThead}>
                                        <TableRow>
                                            <TableCell className={clsx(classes.tableTh, classes.checkboxTableCell)}></TableCell>
                                            <TableCell className={classes.tableTh}>Min</TableCell>
                                            <TableCell className={classes.tableTh}>Plan</TableCell>
                                            <TableCell className={classes.tableTh}>Monthly Price</TableCell>
                                            <TableCell className={classes.tableTh}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow className={clsx(classes.tableRow, classes.selectedRow)}>
                                            <TableCell scope="row" className={clsx(classes.checkboxTableCell)}>
                                                <FormControlLabel
                                                    classes={{
                                                        root: classes.formControlRoot
                                                    }}
                                                    control={<Radio color="primary" name="checkedA" className={classes.checkbox} />}
                                                    checked
                                                />
                                            </TableCell>
                                            <TableCell className={clsx(classes.tableTd, classes.selectedRowText)}>30</TableCell>
                                            <TableCell className={clsx(classes.tableTd, classes.selectedRowText)} scope="row">
                                                Included in Firm Identity Starter
                                            </TableCell>
                                            <TableCell className={clsx(classes.tableTd, classes.selectedRowText)}>$29</TableCell>
                                            <TableCell align="right" className={clsx(classes.tableTd, classes.tableTdChangePlan)}>Change plan</TableCell>
                                        </TableRow>
                                        <TableRow className={classes.tableRow}>
                                            <TableCell scope="row" className={clsx(classes.checkboxTableCell)}>
                                                <FormControlLabel
                                                    classes={{
                                                        root: classes.formControlRoot
                                                    }}
                                                    control={<Radio color="primary" name="checkedA" className={classes.checkbox} />}
                                                />
                                            </TableCell>
                                            <TableCell className={classes.tableTd}>60</TableCell>
                                            <TableCell className={clsx(classes.tableTd)} scope="row">
                                                + 30 min on existing plan
                                            </TableCell>
                                            <TableCell className={classes.tableTd}>$50</TableCell>
                                            <TableCell align="right" className={clsx(classes.tableTd, classes.tableTdCancel)}></TableCell>
                                        </TableRow>
                                        <TableRow className={clsx(classes.tableRow, classes.tableEvenRow)}>
                                            <TableCell scope="row" className={clsx(classes.checkboxTableCell)}>
                                                <FormControlLabel
                                                    classes={{
                                                        root: classes.formControlRoot
                                                    }}
                                                    control={<Radio color="primary" name="checkedA" className={classes.checkbox} />}
                                                    checked
                                                />
                                            </TableCell>
                                            <TableCell className={classes.tableTd}>100</TableCell>
                                            <TableCell className={clsx(classes.deliveryTd, classes.tableTd)} scope="row">
                                                + 70 min on existing plan
                                            </TableCell>
                                            <TableCell className={classes.tableTd}>$129</TableCell>
                                            <TableCell align="right" className={clsx(classes.tableTd, classes.tableTdCancel)}></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box className={classes.total}>
                                <Typography variant="h5" className={classes.totalText}>
                                    TOTAL
                                </Typography>
                                <Typography variant="h5" className={classes.totalPrice}>
                                    $129
                                </Typography>
                            </Box>
                        </div>
                    </div>
                    <div className={classes.modalFooter}>
                        <div className={classes.modalFooterLeft}>
                            <ReportProblemIcon className={classes.reportProblemIcon} />
                            <Typography variant="body2" className={classes.modalFooterLeftBody}>
                                Your card will be immediately charged for <b>$129 Next month your account will renewed with the current update</b>
                            </Typography>
                        </div>
                        <div className={classes.modalFooterRight}>
                            <Button className={clsx(classes.footerButton, classes.footerPrimaryBtn)} variant="contained" color="primary">
                                BUY MINUTES
                            </Button>
                            <Button className={classes.footerButton} variant="outlined" onClick={handleClose}>Cancel</Button>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    )
}
