import { MAILMANAGEMENT } from "../../actions";

const totalReducer = (state = {}, action) => {
    switch (action.type) {
        case MAILMANAGEMENT.CLEAR:
            return {};
        case MAILMANAGEMENT.SET_TOTAL:
            return action.total;
        default:
            return state;
    }
};

export { totalReducer };
