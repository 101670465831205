import React, { useEffect } from 'react';
import { Link, Box, Grid, Typography, Button, InputAdornment, OutlinedInput, FormHelperText, CircularProgress } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import clsx from 'clsx';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Logo from '../../assets/images/logo.jpg';
import arrowIcon from '../../assets/icons/arrow-icon.png';
import { useStyles } from './SignIn.style';
import { setAuthData, setErrorMessage } from "../../store";
import { apiLoginUser } from '../../apis';
import { authenticationService } from '../../core';
import { useState } from 'react';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
const validationSchema = yup.object({
    email: yup.string().required('Required').email("Please enter a valid email address"),
    password: yup.string().min(8, "Password must contain atleast 8 characters").required('Required'),
});

export default function SignIn() {
    let history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (authenticationService.isAuthenticated()) {
            history.push('/');
        }
    })

    const redirect = (pathname) => {
        history.push(pathname)
    }

    const handleForgetPasswordClick = () => {
        history.push("/forget-password")
    }

    const handleFreeTrialClick = () => {
        history.push("/sign-up")
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { email: "", password: "" },
        validationSchema,
        onSubmit: async (values) => {
            const finishLoading = () => formik.setSubmitting(false);
            try {
                setLoading(true);
                const res = await apiLoginUser(values);
                const { token, ...rest } = res;
                authenticationService.setToken(token);
                dispatch(setAuthData(rest));
                setLoading(false);
                Boolean(res.first_login) ? redirect("/my-account") : redirect("/");
            } catch (error) {
                dispatch(setErrorMessage(error.toString()));
                setLoading(false);
            }
            finishLoading();
        },
    });

    if (authenticationService.isAuthenticated())
        return null;

    return (
        <Box component="div" className={classes.login}>
            <Grid className={classes.loginLeft}>
                <Grid className={classes.loginLeftContainer}>
                    <Box component="div" className={classes.loginLogo}>
                        <img className={classes.loginLogoImg} src={Logo} alt="logo" />
                    </Box>
                    <Box fullWidth className={classes.loginFormWrap}>
                        <Typography variant="h4" className={classes.loginLeftH4}>Welcome Back</Typography>
                        <Typography variant="body1" className={classes.loginLeftBody}>
                            Please enter your login details
                        </Typography>
                        <form onSubmit={formik.handleSubmit} autoComplete="off" className={classes.loginForm}>
                            <Box component="div" className={clsx(classes.loginProfileField, classes.loginTransformButton)}>
                                <OutlinedInput
                                    id="login-Email"
                                    type="text"
                                    fullWidth
                                    notched={false}
                                    placeholder="Email"
                                    classes={{
                                        root: classes.loginInput
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <PersonOutlineIcon style={{fontSize:18,color:"#8eeaff"}}/>
                                            {/* <img className="login-icon" src={emailIcon} alt="email-icon" /> */}
                                        </InputAdornment>
                                    }
                                    name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                />
                                <FormHelperText error={formik.touched.email && Boolean(formik.errors.email)}>
                                    {formik.touched.email && formik.errors.email}
                                </FormHelperText>
                            </Box>
                            <Box className={clsx(classes.loginProfileField, classes.loginTransformPassword)}>
                                <OutlinedInput
                                    id="login-password"
                                    type="password"
                                    fullWidth
                                    placeholder="Password"
                                    classes={{
                                        root: classes.loginInput
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <LockOutlinedIcon style={{fontSize:18,color:"#8eeaff"}}/>
                                            {/* <img className="login-icon" src={passwordIcon} alt="password-icon" /> */}
                                        </InputAdornment>
                                    }
                                    name="password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                />
                                <FormHelperText error={formik.touched.password && Boolean(formik.errors.password)}>
                                    {formik.touched.password && formik.errors.password}
                                </FormHelperText>
                                <FormHelperText error={Boolean(formik.errors?.apiError)}>
                                    {formik.errors?.apiError}
                                </FormHelperText>
                            </Box>
                            <Button type="submit" variant="contained" disabled={formik.isSubmitting || loading} color="inherit" fullWidth className={clsx(classes.loginLeftBtn, classes.loginTransformButton)}>Sign in</Button>
                            {loading &&
                                <Box className={classes.loader}>
                                    <CircularProgress size="1.9rem" />
                                </Box>}
                        </form>
                    </Box>
                    <Link
                        component="button"
                        variant="inherit"
                        color="inherit"
                        className={classes.loginFgtPass}
                        onClick={handleForgetPasswordClick}
                    >
                        Forget Password?
                    </Link>
                </Grid>
            </Grid>
            <Grid className={classes.loginRight}>
                <Box component="div" className={classes.loginRightBgLayer}>
                </Box>
                <Box component="div" className={classes.loginRightBox}>
                    <Typography variant="h4" className={classes.loginRightBoxH4}>Don't have an account yet? Call us:</Typography>
                    <Typography variant="h3" className={classes.loginRightBoxH3}>(877) 603-1962</Typography>
                    <Button variant="contained" color="inherit" endIcon={<img src={arrowIcon} alt="arrow" />} onClick={handleFreeTrialClick} className={clsx(classes.loginTrialBtn, classes.loginTransformRightButton)}>Start a free trial </Button>
                </Box>
            </Grid>
        </Box >
    );
}
