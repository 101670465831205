import React from "react";
export default function requestPhotoIcon(props) {
  return (
    <svg
      className={`${props.className} iconSvg`}
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4344 2.89442C16.0951 2.5389 15.6264 2.32883 15.0932 2.32883H12.4106V2.29651C12.4106 1.89251 12.2491 1.50468 11.9743 1.24613C11.6996 0.971411 11.3279 0.809814 10.924 0.809814H6.07605C5.65589 0.809814 5.28422 0.971411 5.00951 1.24613C4.73479 1.52084 4.57319 1.89251 4.57319 2.29651V2.32883H1.90684C1.37357 2.32883 0.904943 2.5389 0.565589 2.89442C0.226236 3.23377 0 3.71856 0 4.23567V12.2832C0 12.8165 0.210076 13.2851 0.565589 13.6245C0.904943 13.9638 1.38973 14.19 1.90684 14.19H15.0932C15.6264 14.19 16.0951 13.98 16.4344 13.6245C16.7738 13.2851 17 12.8003 17 12.2832V4.23567C17 3.7024 16.7899 3.23377 16.4344 2.89442ZM16.1597 12.2832H16.1435C16.1435 12.5741 16.0304 12.8326 15.8365 13.0265C15.6426 13.2205 15.384 13.3336 15.0932 13.3336H1.90684C1.61597 13.3336 1.35741 13.2205 1.1635 13.0265C0.969582 12.8326 0.856464 12.5741 0.856464 12.2832V4.23567C0.856464 3.9448 0.969582 3.68624 1.1635 3.49232C1.35741 3.29841 1.61597 3.18529 1.90684 3.18529H5.02567C5.26806 3.18529 5.46198 2.99137 5.46198 2.74898V2.28035C5.46198 2.10259 5.52662 1.94099 5.63973 1.82788C5.75285 1.71476 5.91445 1.65012 6.09221 1.65012H10.924C11.1017 1.65012 11.2633 1.71476 11.3764 1.82788C11.4895 1.94099 11.5542 2.10259 11.5542 2.28035V2.74898C11.5542 2.99137 11.7481 3.18529 11.9905 3.18529H15.1093C15.4002 3.18529 15.6587 3.29841 15.8527 3.49232C16.0466 3.68624 16.1597 3.9448 16.1597 4.23567V12.2832Z"
        fill="#B8BFC5"
        stroke="#B8BFC5"
        stroke-width="0.2"
      />
      <path
        d="M8.49994 4.28418C7.40108 4.28418 6.39918 4.73665 5.68816 5.44768C4.96097 6.17486 4.52466 7.16061 4.52466 8.25947C4.52466 9.35833 4.97713 10.3602 5.68816 11.0713C6.41534 11.7984 7.40108 12.2348 8.49994 12.2348C9.5988 12.2348 10.6007 11.7823 11.3117 11.0713C12.0389 10.3441 12.4752 9.35833 12.4752 8.25947C12.4752 7.16061 12.0228 6.15871 11.3117 5.44768C10.6007 4.73665 9.5988 4.28418 8.49994 4.28418ZM10.6977 10.4733C10.1321 11.0228 9.35641 11.3783 8.49994 11.3783C7.64348 11.3783 6.86781 11.0228 6.30223 10.4733C5.73664 9.90776 5.39728 9.13209 5.39728 8.27563C5.39728 7.41916 5.7528 6.6435 6.30223 6.07791C6.86781 5.51232 7.64348 5.17296 8.49994 5.17296C9.35641 5.17296 10.1321 5.52848 10.6977 6.07791C11.2633 6.6435 11.6026 7.41916 11.6026 8.27563C11.6188 9.13209 11.2633 9.90776 10.6977 10.4733Z"
        fill="#B8BFC5"
        stroke="#B8BFC5"
        stroke-width="0.2"
      />
      <path
        d="M14.2529 5.85147C14.6902 5.85147 15.0447 5.49696 15.0447 5.05965C15.0447 4.62233 14.6902 4.26782 14.2529 4.26782C13.8156 4.26782 13.4611 4.62233 13.4611 5.05965C13.4611 5.49696 13.8156 5.85147 14.2529 5.85147Z"
        fill="#B8BFC5"
        stroke="#B8BFC5"
        stroke-width="0.2"
      />
    </svg>
  );
}
