import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  boxContentDataRow: {
    display: "flex",
    marginTop: "0",
    padding: "10px 15px",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  boxContentWhiteBackground: {
    display: "flex",
    marginTop: "0",
    background: "#FFFFFF",
    padding: "10px 15px",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  evenBoxContentDataRow: {
    background: "#F7F6F7",
    display: "flex",
    marginTop: "0",
    padding: "10px 15px",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  boxContentHeading: {
    marginBottom: "5px",
    color: "#242223",
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "18px",
    fontFamily: "Roboto-Medium",
  },
  boxContentTimes: {
    display: "flex",
    margin: "5px 0",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  boxContentDataRowEdit: {
    color: theme.palette.primary.main,
  },
  editIcon: {
    fontSize: "0.875rem",
  },
  saveIcon: {
    fontSize: "1.2rem",
  },
  dataRow: {
    display: "flex",
    width: "90%",
  },
  containerRowBased: {
    display: "flex",
    width: "100%"
  },
}));

export { useStyles };
