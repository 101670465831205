import React, { useEffect, useMemo } from "react";
import { Box, Typography, Link, IconButton } from "@material-ui/core";
import {
  ArrowLeft as ArrowLeftIcon,
  ArrowRight as ArrowRightIcon,
} from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, findIndex } from "lodash";
import {
  getCustomerId,
  openModal,
  setErrorMessage,
  setPaymentMethods,
  setSelectedPaymentMethod,
} from "../../../store";
import { ModalType } from "../../../core";
import { useStyles } from "./PaymentInformation.style";
import { apiFetchPaymentMethods } from "../../../apis";

export default function PaymentInformation() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const customerId = useSelector((state) => getCustomerId(state));
  const { data, selected } = useSelector(
    (state) => state.account.paymentMethod
  );

  const hasPaymentMethod = useMemo(() => !isEmpty(selected), [selected]);

  const currentIndex = findIndex(data, selected);

  const handleNextClick = () => {
    if (data?.length > currentIndex + 1) {
      dispatch(setSelectedPaymentMethod(data[currentIndex + 1]));
    }
  };

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      dispatch(setSelectedPaymentMethod(data[currentIndex - 1]));
    }
  };

  const managePaymentInformation = () => {
    dispatch(openModal(ModalType.ManagePaymentInformation));
  };

  useEffect(() => {
    const loadAllPaymentMethods = async () => {
      try {
        const methods = await apiFetchPaymentMethods(customerId);
        dispatch(setPaymentMethods(methods));
        dispatch(setSelectedPaymentMethod(methods.length ? methods[0] : {}));
      }
      catch (error) {
        dispatch(setErrorMessage(error.toString()));
      }
    }
    loadAllPaymentMethods();
  }, [dispatch,customerId]);

  return (
    <Box component="div" className={classes.box}>
      <Box className={classes.boxHeader}>
        <Typography variant="h6" className={classes.boxHeading}>
          Payment information
        </Typography>
        <Link
          component="button"
          variant="inherit"
          color="inherit"
          className={classes.boxLink}
          onClick={managePaymentInformation}
        >
          Manage
        </Link>
      </Box>
      <Box className={classes.boxSubHeader}>
        <IconButton className={classes.iconButton} onClick={handlePrevClick}>
          <ArrowLeftIcon className={classes.leftRightIcon} fontSize="large" />
        </IconButton>
        <Typography>{`(${currentIndex + 1}/${data?.length})`}</Typography>
        <IconButton className={classes.iconButton} onClick={handleNextClick}>
          <ArrowRightIcon className={classes.leftRightIcon} fontSize="large" />
        </IconButton>
      </Box>
      <Box className={`${classes.imgSection}`}>
        <Box className={classes.imgSectionContent}>
          <Box>
            <Typography variant="body1">Card owner:</Typography>
            <Typography variant="subtitle1">
              {hasPaymentMethod
                ? `${selected?.card?.first_name} ${selected?.card?.last_name}`
                : "N/A"}
            </Typography>
          </Box>
          <Box>
            <Typography>Card number:</Typography>
            <Typography>
              {hasPaymentMethod ? `${selected?.card?.masked_number}` : "N/A"}
            </Typography>
          </Box>
          <Box>
            <Typography>Expiry date:</Typography>
            <Typography>
              {hasPaymentMethod
                ? `${selected?.card?.expiry_month}/${selected?.card?.expiry_year}`
                : "N/A"}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
