import React, { useEffect } from "react";
import clsx from "clsx";
import { Box, Typography, Link } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import moment from "moment";
import { RangeDatePicker } from "../../shared";
import { getLoginCustomerId, loadAllTransactions, setErrorMessage, setPaymentTransactions } from "../../../store";
import { apiFetchInvoiceUrl, apiFetchTransactions } from "../../../apis";

import { useStyles } from "./transactions.style";

export default function Transactions() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedDateRange = useSelector((state) => state.transaction.dateRange);
  const paymentTransactions = useSelector((state) => state.transaction.payment);
  const customerId = useSelector((state) => getLoginCustomerId(state));
  const handleDateChange = async (dateRange) => {
    try {
      const { startDate, endDate } = dateRange;
      const response = await apiFetchTransactions(
        startDate,
        endDate,
        customerId);
      const { paymentTransactions } = response;
      dispatch(setPaymentTransactions(paymentTransactions));
      dispatch(loadAllTransactions({ ...dateRange }));
    }
    catch (error) {
      dispatch(setErrorMessage(error.toString()));
    }
  };

  const getTotalAmount = () => {
    let total = 0;
    paymentTransactions.forEach((map) =>
      map.transactions.forEach((transaction) => (total += transaction.amount))
    );
    return total / 100;
  };

  useEffect(() => {
    const loadTransactions = async () => {
      dispatch(
        loadAllTransactions({
          startDate: moment().startOf("month").toDate(),
          endDate: moment().endOf("month").toDate(),
        })
      );
      try {
        const response = await apiFetchTransactions(
          moment().startOf("month").toDate(),
          moment().endOf("month").toDate(),
          customerId
        );
        const { paymentTransactions } = response;
        dispatch(setPaymentTransactions(paymentTransactions));

      }
      catch (error) {
        dispatch(setErrorMessage(error.toString()));
      }
    }
    loadTransactions();
  }, [dispatch, customerId]);

  const handleInvoiceDownload = async (invoiceId) => {
    try {
      const res = await apiFetchInvoiceUrl(invoiceId);
      const newwindow = window.open(res.download_url, '_blank');
      if (newwindow) {
        newwindow.focus();
      }
    }
    catch (error) {
      dispatch(setErrorMessage(error.toString()));
    }
  };

  return (
    <Box component="div" className={classes.box}>
      <Box className={classes.boxHeader}>
        <Box className={classes.transactionContainer}>
          <Typography variant="h4" className={classes.boxHeading}>
            Transactions
          </Typography>
          <Typography variant="body2" className={classes.dateRangeTypo}>
            {!isEmpty(selectedDateRange)
              ? `${moment(selectedDateRange.startDate).format(
                "DD MMM YYYY"
              )} - ${moment(selectedDateRange.endDate).format("DD MMM YYYY")}`
              : ""}
          </Typography>
        </Box>
        <RangeDatePicker onChange={handleDateChange} />
      </Box>
      <Box className={classes.boxRow}>
        <Box className={classes.boxContent}>
          {paymentTransactions.map((payment, index) => (
            <Box key={index} className={classes.boxContentDataRow}>
              <Typography
                variant="subtitle1"
                className={classes.boxContentHeading}
              >
                {payment.date}
              </Typography>
              {payment.transactions.map((transaction) => (
                <Box
                  className={clsx(
                    classes.boxContentTransactions,
                    classes.noAlign
                  )}
                  key={transaction.id}
                >
                  <Typography>
                    <Link
                      component="button"
                      variant="body2"
                      color="primary"
                      onClick={() =>
                        handleInvoiceDownload(
                          transaction.linked_invoices[0].invoice_id
                        )
                      }
                    >
                      {`invoice - ${transaction.linked_invoices[0].invoice_id}`}
                    </Link>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className={classes.transactionPrice}
                  >
                    {`$${transaction.amount / 100}`}
                  </Typography>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </Box>
      <Box className={classes.total}>
        <Typography variant="subtitle2" className={classes.boxHeading}>
          Total
        </Typography>
        <Typography variant="subtitle1" className={classes.totalNumber}>
          {`$${getTotalAmount()}`}
        </Typography>
      </Box>
    </Box>
  );
}
