import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  boxLink: {
    fontSize: "14px",
    lineHeight: "16px",
    color: theme.palette.primary.main,
  },
  subHeadings: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 15px",
  },
  subHeaderTabs: {
    padding: 45,
  },
}));

export { useStyles };
