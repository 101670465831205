import { FORM } from "../../actions";

const loginReducer = (state = {}, action) => {
    switch (action.type) {
        case FORM.CLEAR_LOGIN_FORM:
            return {};
        case FORM.SET_LOGIN_FORM:
            return action.form;
        default:
            return state;
    }
};

export { loginReducer };
