import React, { useEffect, useState } from "react";
import find from "lodash/find";
import { BaseSearchDropdown } from "..";
import { getAllCountriesSpheremail } from "../../../apis";
import { setErrorMessage, getSelectedLocationId } from "../../../store";
import { useDispatch, useSelector } from "react-redux";

export default function CountryInput(props) {
  const { value, onChange, ...rest } = props;
  const locationId = useSelector((state) => getSelectedLocationId(state));
  const [countries, setCountries] = useState([]);
  const dispatch = useDispatch();

  const seleced = find(countries, { id: value });

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await getAllCountriesSpheremail(locationId);
        setCountries(response);
      } catch (error) {
        dispatch(setErrorMessage(error.toString()));
      }
    };
    if (locationId) fetchCountries();
  }, [dispatch, setCountries, locationId]);

  return (
    <BaseSearchDropdown
      id="country-search-dropdown"
      options={countries}
      value={seleced}
      onChange={onChange}
      label="name"
      {...rest}
    />
  );
}
