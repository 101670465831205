import React, { useState } from "react";
import { useFormik, FormikProvider } from "formik";
import {
  Grid,
  Typography,
  OutlinedInput,
  Button,
  FormHelperText,
  Box,
  Link,
  Avatar,
} from "@material-ui/core";
import clsx from "clsx";
import TimezoneSelect, { allTimezones } from "react-timezone-select";
import { useDropzone } from "react-dropzone";
import { useHistory } from "react-router-dom";
import S3 from "react-aws-s3";
import { CountryInput, StateInput } from "../../../shared";
import { apiUpdateLocation, apiAddNewLocation } from "../../../../apis";
import { AWSLOCATIONCONFIG } from "../../../../config";
import ImageIcon from "../../../../assets/images/imageIcon.jpg";
import * as yup from "yup";
import { useStyles } from "./styles";
import PhoneInput from "react-phone-input-2";
import { useDispatch } from "react-redux";
import { setErrorMessage, setSuccessMessage } from "../../../../store";
const ReactS3Client = new S3(AWSLOCATIONCONFIG);

const validationSchema = yup.object({
  name: yup.string().required("Required"),
  address1: yup.string().required("Required"),
  city: yup.string().required("Required"),
  state: yup.string().required("Required"),
  country: yup.string().required("Required"),
  zip: yup.string().required("Required"),
  phone: yup.string().required("Required"),
});

export default function AddLocation(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  let { push } = useHistory();
  const { locationDetail } = props;
  const initialValues = {
    name: "",
    address1: "",
    address2: "",
    country: "",
    state: "",
    city: "",
    zip: "",
    phone: "",
    timeZone: "",
  };

  const formik = useFormik({
    initialValues: locationDetail?.id ? { ...locationDetail } : initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        values?.id
          ? await apiUpdateLocation(values)
          : await apiAddNewLocation(values);
        const message = values?.id
          ? "Location updated successfully"
          : "Location added successfully";
        dispatch(setSuccessMessage(message));
        setLoading(false);
        push("/admin/locations");
      } catch (error) {
        dispatch(setErrorMessage(error.toString()));
        setLoading(false);
      }
    },
  });

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length) {
        setLoading(true);
        const file = acceptedFiles[0];
        ReactS3Client.uploadFile(file, `location_${Math.random()}`)
          .then((data) => {
            formik.setFieldValue("coverUrl", data.location);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      }
    },
  });

  const handleCountryChange = (country) => {
    formik.setFieldValue("country", country?.id || "");
    formik.setFieldValue("state", "");
  };

  const handleStateChange = (state) => {
    formik.setFieldValue("state", state?.id || "");
  };

  const handleTimezoneChange = (tzObject) => {
    formik.setFieldValue("timezone", tzObject?.value);
  };

  const handleCancelClick = () => {
    push("/admin/locations");
  };

  const handlePhoneChange = (phone) => {
    formik.setFieldValue("phone", phone);
  };

  return (
    <section className={classes.section}>
      <FormikProvider value={formik}>
        <form className={classes.root} onSubmit={formik.handleSubmit}>
          <Typography className={classes.heading} variant="h3">
            Location Details
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Typography variant="h5" className={classes.label}>
                Location name
              </Typography>
              <OutlinedInput
                type="text"
                fullWidth
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.name)}
              />
              <FormHelperText
                className={classes.error}
                error={Boolean(formik.errors.name)}
              >
                {formik.touched.name && formik.errors.name}
              </FormHelperText>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Typography variant="h5" className={classes.label}>
                Address Line1
              </Typography>
              <OutlinedInput
                type="text"
                fullWidth
                name="address1"
                value={formik.values.address1}
                onChange={formik.handleChange}
                rows={2}
                error={Boolean(formik.errors.address1)}
                multiline
              />
              <FormHelperText
                className={classes.error}
                error={Boolean(formik.errors.address1)}
              >
                {formik.touched.address1 && formik.errors.address1}
              </FormHelperText>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="h5" className={classes.label}>
                Address Line2
              </Typography>
              <OutlinedInput
                type="text"
                fullWidth
                name="address2"
                value={formik.values.address2}
                onChange={formik.handleChange}
                rows={2}
                multiline
                error={Boolean(formik.errors.address2)}
              />
              <FormHelperText
                className={classes.error}
                error={Boolean(formik.errors.address2)}
              >
                {formik.touched.address2 && formik.errors.address2}
              </FormHelperText>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Typography variant="h5" className={classes.label}>
                Country
              </Typography>
              <CountryInput
                value={formik.values.country}
                onChange={handleCountryChange}
              />
              <FormHelperText
                className={classes.error}
                error={Boolean(formik.errors.country)}
              >
                {formik.touched.country && formik.errors.country}
              </FormHelperText>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h5" className={classes.label}>
                State/province
              </Typography>
              <StateInput
                country={formik.values.country}
                value={formik.values.state}
                onChange={handleStateChange}
                error={Boolean(formik.errors.state)}
              />
              <FormHelperText
                className={classes.error}
                error={Boolean(formik.errors.state)}
              >
                {formik.touched.state && formik.errors.state}
              </FormHelperText>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h5" className={classes.label}>
                City
              </Typography>
              <OutlinedInput
                type="text"
                fullWidth
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.city)}
              />
              <FormHelperText
                className={classes.error}
                error={Boolean(formik.errors.city)}
              >
                {formik.touched.city && formik.errors.city}
              </FormHelperText>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Typography variant="h5" className={classes.label}>
                Zip
              </Typography>
              <OutlinedInput
                type="text"
                fullWidth
                name="zip"
                value={formik.values.zip}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.zip)}
              />
              <FormHelperText
                className={classes.error}
                error={Boolean(formik.errors.zip)}
              >
                {formik.touched.zip && formik.errors.zip}
              </FormHelperText>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h5" className={classes.label}>
                Phone
              </Typography>
              <PhoneInput
                value={formik.values.phone}
                onChange={(phone) => handlePhoneChange(phone)}
              />
              <FormHelperText
                className={classes.error}
                error={Boolean(formik.errors.phone)}
              >
                {formik.touched.phone && formik.errors.phone}
              </FormHelperText>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h5" className={classes.label}>
                Timezone
              </Typography>
              <TimezoneSelect
                value={
                  formik.values.timezone ||
                  Intl.DateTimeFormat().resolvedOptions().timeZone
                }
                onChange={handleTimezoneChange}
                timezones={{
                  ...allTimezones,
                  "America/Lima": "Pittsburgh",
                  "Europe/Berlin": "Frankfurt",
                }}
              />
              <FormHelperText
                className={classes.error}
                error={Boolean(formik.errors.timezone)}
              >
                {formik.touched.timezone && formik.errors.timezone}
              </FormHelperText>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Box
                {...getRootProps({ className: "dropzone" })}
                className={classes.imageContainer}
              >
                <input {...getInputProps()} />
                <Box className={classes.imageContainer}>
                  <img
                    src={formik.values.coverUrl || ImageIcon}
                    alt=""
                    className={classes.image}
                  />
                  <Link
                    component="button"
                    type="button"
                    variant="inherit"
                    className={classes.boxLink}
                    onClick={open}
                  >
                    Upload an image
                  </Link>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid className={classes.btnContainer} container spacing={3}>
            <Grid item xs={1}>
              <Button
                className={clsx(classes.footerButton, classes.footerPrimaryBtn)}
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                {locationDetail?.id ? "Update" : "Add"}
              </Button>
            </Grid>
            <Grid item xs={1}>
              <Button
                className={classes.footerButton}
                variant="outlined"
                onClick={handleCancelClick}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </section>
  );
}
