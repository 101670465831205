import { ADMIN } from "../../../actions";

const dataReducer = (state = [], action) => {
  switch (action.type) {
    case ADMIN.CLEAR_ADMIN:
      return [];
    case ADMIN.SET_ROOMS:
      return action.rooms;
    default:
      return state;
  }
};

export { dataReducer };
