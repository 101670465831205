import React from "react";
import {
  Modal,
  Fade,
  Backdrop,
  Typography,
  IconButton,
} from "@material-ui/core";
import { closeModal } from "../../../store";
import { ModalType } from "../../../core";
import SimpleImageSlider from "react-simple-image-slider";
import { HighlightOff as HighlightOffIcon } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { useStyles } from "./styles";

export default function ImageSlider() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const modal = useSelector(
    (state) => state.ui.modal[ModalType.ImageSliderModal] || false
  );

  const room = useSelector(
    (state) => state.spaceRental.availableRooms?.selected
  );

  const handleClose = () => {
    dispatch(closeModal(ModalType.ImageSliderModal));
  };

  return (
    <Modal
      aria-labelledby="buy-minutes-modal-title"
      aria-describedby="buy-minutes-modal-description"
      open={modal}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      className={classes.modal}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modal}>
        <div className={classes.paper}>
          <div className={classes.modalHeader}>
            <Typography variant="h6" className={classes.modalHeading}>
              Images
            </Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <HighlightOffIcon />
            </IconButton>
          </div>
          <div className={classes.modalBody}>
            <SimpleImageSlider
              width={770}
              height={504}
              images={room?.images || []}
              showBullets={true}
              showNavs={true}
            />
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
