import { TRANSACTION } from "../../actions";

const serviceTransactionReducer = (state = [], action) => {
  switch (action.type) {
    case TRANSACTION.CLEAR_TRANSACTION:
      return [];
    case TRANSACTION.SET_SERVICE_TRANSACTION:
      return action.data;
    default:
      return state;
  }
};

export { serviceTransactionReducer };
