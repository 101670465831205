import React from "react";
export default function ShredMailIcon(props) {
  return (
    <svg
      className={`${props.className}`}
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.31555 9.00008V9.10008H3.41555H12.6463H12.7463V9.00008V5.66675V5.56675H12.6463H9.66939V2.33341V2.23341H9.56939H3.41555H3.31555V2.33341V9.00008ZM1.87708 9.10008H1.97708V9.00008V1.49591C1.97708 1.08519 2.28194 0.766748 2.64324 0.766748H10.2948L14.0848 4.87251V9.00008V9.10008H14.1848H15.6232V10.5667H0.438623V9.10008H1.87708ZM2.54632 12.4334V17.2334H1.20785V12.4334H2.54632ZM14.854 12.4334V17.2334H13.5155V12.4334H14.854ZM10.4386 12.4334H11.7771V17.2334H10.4386V12.4334ZM7.3617 12.4334H8.70016V17.2334H7.3617V12.4334ZM4.28478 12.4334H5.62324V17.2334H4.28478V12.4334Z"
        fill="#B8BFC5"
        stroke="#B8BFC5"
        stroke-width="0.2"
      />
    </svg>
  );
}
