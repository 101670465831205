import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    box: {
        background: "#FFFFFF",
        border: "1px solid #CDE0F1",
    },
    boxRow: {
        borderBottom: "1px solid #DFDFE4",
    },
    noBorder: {
        border: "none"
    },
    boxHeader: {
        display: 'flex',
        background: "#F1F9FE",
        borderBottom: "1px solid #CDE0F1",
        boxSizing: "border- box",
        padding: "15px",
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    boxHeading: {
        fontWeight: "500",
        fontSize: "15px",
        lineHeight: "18px",
        color: "#242223",
        fontFamily: 'Roboto-Medium'
    },
    boxLink: {
        fontSize: "14px",
        lineHeight: "16px",
        color: theme.palette.primary.main,
    },
    boxContent: {
        display: 'flex',
        padding: "15px",
        fontSize: "14px",
        color: "#494949",
        letterSpacing: "0.01em",
        flexWrap: 'wrap'
    },
    boxContentAvailableNow: {
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    boxContentHeading: {
        color: "#242223",
        fontWeight: "500",
        fontSize: "15px",
        lineHeight: "18px",
        fontFamily: 'Roboto-Medium'
    },
    boxContentTimes: {
        display: 'flex',
        margin: "5px 0",
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
    }
}));


export { useStyles }