import { UserRoles } from "../core";

export const token =
  "eyJhbGciOiJIUzI1NiJ9.eyJpZCI6MTI4MjIsImV4cGlyZXMiOjE2MzM1MDMyODh9.EdxT-xpb0WEG40AYpUNpkG90-oC_q6kURoEI-TXtJXo";


export const userHasEditPermission = (userType) => {
  return userType.toLowerCase() === UserRoles.Editor || userType.toLowerCase() === UserRoles.Admin || userType.toLowerCase() === UserRoles.SuperAdmin
}
export const isUserSuperAdmin = (userType) => {
  return userType.toLowerCase() === UserRoles.SuperAdmin
}
