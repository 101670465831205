export const typography = {
    h1: {
        fontWeight: 500,
        fontSize: '2em',
        letterSpacing: '-0.24px'
    },
    h2: {
        fontWeight: 500,
        fontSize: '1.5em',
        letterSpacing: '-0.24px'
    },
    h3: {
        fontWeight: 500,
        fontSize: '1.17em',
        letterSpacing: '-0.06px'
    },
    h4: {
        fontWeight: 500,
        fontSize: '1em',
        letterSpacing: '-0.06px'
    },
    h5: {
        fontWeight: 500,
        fontSize: '0.83em',
        letterSpacing: '-0.05px'
    },
    h6: {
        fontWeight: 500,
        fontSize: '0.75em',
        letterSpacing: '-0.05px'
    },
    subtitle1: {
        fontFamily: 'Roboto-Bold'
    },
    subtitle2: {
        fontFamily: 'Roboto-Medium'
    },
    body1: {
        fontFamily: 'Roboto-Regular'
    },
    body2: {
        fontFamily: 'Roboto-Light'
    },
    overline: {
        fontWeight: 500
    }
};
