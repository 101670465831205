import React, { useEffect } from "react";
import { Box, Typography, Link, IconButton, CircularProgress } from "@material-ui/core";
import clsx from "clsx";
import {
  ArrowLeft as ArrowLeftIcon,
  ArrowRight as ArrowRightIcon,
  Edit as EditIcon,
} from "@material-ui/icons";
import { useParams } from "react-router-dom";
import { findIndex } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./ForwardingAddresses.style";
import {
  openModal,
  // loadForwardingAddresses,
  setSelectedForwardingAddress,
  setForwardingAddressOperatingMode,
  getSelectedLocationId,
  getCustomerId,
  setForwardingAddressData,
  setErrorMessage
} from "../../../store";
import { ModalType, OperatingMode } from "../../../core";
import { apiFetchCustomerForwardingAddresses } from "../../../apis";
import { useState } from "react";
import FormattedAddress from "../../shared/formatted-address/formatted-address";
import { userHasEditPermission } from "../../../constants/constants";

export default function ForwardingAddresses() {
  const classes = useStyles();
  const dispatch = useDispatch();
  let params = useParams();
  const state = useSelector((state) => state);
  const locationId = getSelectedLocationId(state);
  const customerId = getCustomerId(state);
  const { data, selected } = state.account.forwardingAddress;
  const [isLoading, setIsLoading] = useState(false);
  const userType = state.auth.data.user_type;
  const handleAddEditForwardingAddress = (operatingMode) => {
    dispatch(setForwardingAddressOperatingMode(operatingMode));
    dispatch(openModal(ModalType.AddForwardingAddress));
  };

  // useEffect(() => {
  //   const loadForwardingAddress = () => {

  //   }
  //   if (locationId && customerId) loadForwardingAddress();
  // }, [dispatch, locationId, customerId]);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      setIsLoading(true);
      try {
        const response = await apiFetchCustomerForwardingAddresses(
          locationId,
          params.customerId || customerId,
        );
        const selectedForwardingAddress = response.length
          ? response[0]
          : {};
        setIsLoading(false);
        dispatch(setForwardingAddressData(response));
        dispatch(setSelectedForwardingAddress(selectedForwardingAddress));
      }
      catch (error) {
        setIsLoading(false);
        dispatch(setErrorMessage(error.toString()));
      }

    };
    if (locationId && (customerId || params.customerId)) fetchTeamMembers();
  }, [dispatch, locationId, customerId, params.customerId]);

  const currentIndex = findIndex(data, selected);

  const handleNextClick = () => {
    if (data?.length > currentIndex + 1) {
      dispatch(setSelectedForwardingAddress(data[currentIndex + 1]));
    }
  };

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      dispatch(setSelectedForwardingAddress(data[currentIndex - 1]));
    }
  };

  // const formatAddress = () => {
  //   const { address1, address2, city, state, zip, country } = selected;
  //   return (
  //     <Typography variant="subtitle1">
  //       <span>{address1 ? `${address1},` : ""}</span>
  //       <span>{address2 ? ` ${address2},` : ""}</span>
  //       <span>{city ? ` ${city},` : ""}</span>
  //       <span>{state ? ` ${state},` : ""}</span>
  //       <span>{zip ? ` ${zip},` : ""}</span>
  //       <span>{country ? ` ${country}` : ""}</span>
  //     </Typography>
  //   );
  // };

  return (
    <Box component="Box" className={classes.box}>
      <Box className={classes.boxHeader}>
        <Typography variant="h6" className={classes.boxHeading}>
          Forwarding addresses
        </Typography>
        {isLoading && <Box>
          <CircularProgress size="1.5rem" />
        </Box>}
        {userHasEditPermission(userType) && <Link
          component="button"
          variant="inherit"
          className={classes.boxLink}
          onClick={() => handleAddEditForwardingAddress(OperatingMode.New)}
        >
          Add
        </Link>}

      </Box>
      <Box className={classes.boxSubHeader}>
        <IconButton className={classes.iconButton} onClick={handlePrevClick}>
          <ArrowLeftIcon className={classes.leftRightIcon} fontSize="large" />
        </IconButton>
        <Typography>{`(${currentIndex + 1}/${data?.length})`}</Typography>
        <IconButton className={classes.iconButton} onClick={handleNextClick}>
          <ArrowRightIcon className={classes.leftRightIcon} fontSize="large" />
        </IconButton>
      </Box>
      <Box className={clsx(classes.boxRow, classes.noBorder)}>
        <Box className={classes.boxContent}>
          <Box className={classes.boxContentDataRow}>
            <Box className={classes.dataRow}>
              <Box>
                <Typography variant="body1">Office name</Typography>
                <Typography variant="subtitle1">
                  {selected?.addressName || "N/A"}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={clsx(classes.boxContentDataRow, classes.noBorder)}>
            <Box className={classes.dataRow}>
              <Box>
                <Typography variant="body1">Office adress </Typography>
                <FormattedAddress companyInfo={selected} />
              </Box>
            </Box>
            {Boolean(data?.length) && userHasEditPermission(userType) && (
              <IconButton
                className={classes.iconButton}
                onClick={() =>
                  handleAddEditForwardingAddress(OperatingMode.Edit)
                }
              >
                <EditIcon className={classes.boxContentDataRowEdit} />
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
