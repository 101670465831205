import { combineReducers } from "redux";
import { dataReducer } from "./data";
import { selectedDirectoryNumberReducer } from "./selected-directory-number";

const directoryReducer = combineReducers({
    data: dataReducer,
    selectedDirectoryNumber: selectedDirectoryNumberReducer
});

export { directoryReducer };
