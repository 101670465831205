import { Box, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { apiGetRoomDetailById } from "../../../../apis";
import { setErrorMessage } from "../../../../store";
import { ManageRoom } from "../manage-room";
import { useStyles } from "./styles";

export default function EditRoom() {
  const [roomDetail, setRoomDetail] = useState();
  const [loading, setLoading] = useState(true);
  let params = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchRoomDetail = async () => {
      try {
        const response = await apiGetRoomDetailById(params.roomId);
        setRoomDetail(response);
        setLoading(false);
      }
      catch (error) {
        setLoading(false);
        dispatch(setErrorMessage(error?.toString()));
      }

    };

    if (params.roomId) fetchRoomDetail();
  }, [params.roomId]);
  return loading ? (
    <Box className={classes.loader}><CircularProgress  size="3.5rem"/></Box>
  ) : (
    <ManageRoom roomDetail={roomDetail} />
  );
}
