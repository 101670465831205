import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: "sans-serif",
        color: "#242223"
    },
    paper: {
        width: 400,
        background: '#fff',
        position: 'relative',
        maxHeight: "95vh",
        overflow: "auto",
        border: "1px solid #CDE0F1"
    },
    modalHeader: {
        padding: '10px 15px',
        background: "#F1F9FE",
        borderBottom: "1px solid #CDE0F1",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    modalHeading: {
        fontWeight: "500",
        fontSize: "15px",
        lineHeight: "18px",
        color: "#242223",
        fontFamily: 'Roboto-Medium'
    },
    modalSubhead: {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "18px",
        color: "#242223",
        fontFamily: 'Roboto-Medium',
    },
    input: {
        margin: "8px 0 15px",
    },
    closeButton: {
        color: theme.palette.grey[500],
        padding: 0
    },
    modalBody: {
        padding: '20px 15px',
    },
    modalFooter: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 15px",
        marginBottom: 15
    },
    footerButton: {
        flex: "1",
        maxWidth: "48%",
        border: "2px solid #2CA4C0",
        padding: "6px 15px",
        fontFamily: 'Roboto-Medium'
    },
    footerPrimaryBtn: {
        backgroundColor: "#2CA4C0",
        color: "#fff"
    },
    lastChild: {
        marginBottom: 0
    }
}));


export { useStyles }