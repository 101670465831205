import React, { Fragment, cloneElement } from "react";
import clsx from "clsx";
import { TextField } from "@material-ui/core";
import { useStyles } from "./BaseDropdown.style";

export default function BaseDropdown({
  children,
  className,
  startIcon,
  disabled,
  ...rest
}) {
  const classes = useStyles();
  return (
    <Fragment>
      {startIcon &&
        cloneElement(startIcon, {
          className: clsx(
            classes.calendraIcon,
            disabled ? classes.disabledIcon : ""
          ),
        })}
      <TextField
        select
        fullWidth
        disabled={disabled}
        notched={false}
        SelectProps={{
          MenuProps: {
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            getContentAnchorEl: null,
            marginThreshold: 50,
          },
          SelectDisplayProps: {
            style: { paddingLeft: startIcon ? "34px" : '7px'},
          },
        }}
        variant="outlined"
        className={clsx(
          className ? className : "",
          disabled ? classes.disabledSelect : ""
        )}
        {...rest}
      >
        {children}
      </TextField>
    </Fragment>
  );
}
