import React, { Fragment, useMemo } from "react";
import {
  Modal,
  Fade,
  Box,
  Backdrop,
  Typography,
  Button,
  OutlinedInput,
  Divider,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  closeModal,
  addUpdateCFAddressRecord,
  getSelectedLocationId,
  setErrorMessage,
  setSuccessMessage,
  getCustomerId,
} from "../../../store";
import { CountryInput, StateInput } from "../../shared";
import { apiAddCFAddress, apiUpdateCFAddress } from "../../../apis";
import { ModalType, OperatingMode } from "../../../core";
import IconButton from "@material-ui/core/IconButton";
import { useStyles } from "./AddUpdateForwardingAddress.style";
import { HighlightOff as HighlightOffIcon } from "@material-ui/icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useState } from "react";

const validationSchema = yup.object({
  addressName: yup.string().required("Required"),
  address1: yup.string().required("Required"),
  city: yup.string().required("Required"),
  state: yup.string().required("Required"),
  zip: yup.string().required("Required"),
  country: yup
    .string()
    .max(3, "Country must be less the 3 digit")
    .required("Required"),
});

export default function AddUpdateForwardingAddress() {
  const initialValues = useMemo(
    () => ({
      addressName: "",
      address1: "",
      address2: "",
      city: "",
      state: 0,
      zip: "",
      phone: "",
      country: 1,
      defaultAddress: false,
    }),
    []
  );
  const state = useSelector((state) => state);
  const classes = useStyles();
  const dispatch = useDispatch();
  const modal = state.ui.modal[ModalType.AddForwardingAddress] || false;
  const mailAccountId = useSelector((state) => state.auth.data.mail_account_id);
  const locationId = useSelector((state) => getSelectedLocationId(state));
  const [isLoading, setIsLoading] = useState(false);
  const customerId = getCustomerId(state);
  const handleClose = () => {
    formik.resetForm();
    dispatch(closeModal(ModalType.AddForwardingAddress));
  };
  const forwardingAddress = state.account.forwardingAddress;
  const { selected, operatingMode } = forwardingAddress;
  const { updateUser, createdAt, updatedAt, ...rest } = selected;

  const isNew = useMemo(
    () => Boolean(OperatingMode.New === operatingMode),
    [operatingMode]
  );
  const successMessage = isNew
    ? "Forwarding address added"
    : "Forwarding address updated";

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: isNew ? initialValues : rest,
    validationSchema,
    onSubmit: async (values) => {
      const finishLoading = () => formik.setSubmitting(false);
      try {
        const request = {
          ...values,
          locationId,
          customerId,
        };
        setIsLoading(true);
        const res = isNew
          ? await apiAddCFAddress(mailAccountId, locationId, request)
          : await apiUpdateCFAddress(mailAccountId, locationId, request);
        setIsLoading(false);
        dispatch(setSuccessMessage(successMessage));
        dispatch(addUpdateCFAddressRecord(res));
        handleClose();
      } catch (error) {
        dispatch(setErrorMessage(error.toString()));
        setIsLoading(false);
      }
      finishLoading();
    },
  });

  const handleCountryChange = (country) => {
    formik.setFieldValue("country", country?.id);
    formik.setFieldValue("state", "");
  };

  const handleStateChange = (state) => {
    formik.setFieldValue("state", state?.id);
  };

  const handlePhoneChange = (phone) => {
    formik.setFieldValue("phone", phone);
  };

  return (
    <Modal
      aria-labelledby="add-forwarding-address-modal-title"
      aria-describedby="add-forwarding-address-modal-description"
      open={modal}
      onClose={handleClose}
      closeAfterTransition
      disableEnforceFocus
      BackdropComponent={Backdrop}
      className={classes.modal}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modal}>
        <form
          onSubmit={formik.handleSubmit}
          autoComplete="off"
          className={classes.paper}
        >
          <Box className={classes.modalHeader}>
            <Typography variant="h6" className={classes.modalHeading}>
              {isNew ? "New forwarding address" : "Edit forwarding address"}
            </Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <HighlightOffIcon />
            </IconButton>
          </Box>
          <Box className={classes.modalBody}>
            <Fragment>
              <Typography variant="h5" className={classes.modalSubhead}>
                Office name
              </Typography>
              <OutlinedInput
                type="text"
                fullWidth
                name="addressName"
                value={formik.values.addressName}
                onChange={formik.handleChange}
                error={
                  formik.touched.addressName &&
                  Boolean(formik.errors.addressName)
                }
              />
              <FormHelperText
                error={
                  formik.touched.addressName &&
                  Boolean(formik.errors.addressName)
                }
              >
                {formik.touched.addressName && formik.errors.addressName}
              </FormHelperText>
              <FormControlLabel
                checked={formik.values.defaultAddress}
                onChange={() =>
                  formik.setFieldValue(
                    "defaultAddress",
                    !formik.values.defaultAddress
                  )
                }
                control={<Checkbox color="primary" name="checkedA" />}
                label="Use as default address"
              />
              <Divider className={classes.divider} />
            </Fragment>
            <Typography variant="h6" className={classes.modalHeading}>
              Forwarding address :
            </Typography>
            <Typography variant="h5" className={classes.modalSubhead}>
              Address line1
            </Typography>
            <OutlinedInput
              type="text"
              fullWidth
              name="address1"
              value={formik.values.address1}
              onChange={formik.handleChange}
              error={formik.touched.address1 && Boolean(formik.errors.address1)}
            />
            <FormHelperText
              error={formik.touched.address1 && Boolean(formik.errors.address1)}
            >
              {formik.touched.address1 && formik.errors.address1}
            </FormHelperText>
            <Typography variant="h5" className={classes.modalSubhead}>
              Phone
            </Typography>
            <PhoneInput
              value={formik.values.phone}
              onChange={(phone) => handlePhoneChange(phone)}
            />
            <Box className={classes.inputRow}>
              <Box className={classes.inputContent}>
                <Typography variant="h5" className={classes.modalSubhead}>
                  Country
                </Typography>
                <CountryInput
                  value={formik.values.country}
                  onChange={handleCountryChange}
                />
                {/* <OutlinedInput
                  type="text"
                  fullWidth
                  name="country"
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.country && Boolean(formik.errors.country)
                  }
                /> */}
                <FormHelperText
                  error={
                    formik.touched.country && Boolean(formik.errors.country)
                  }
                >
                  {formik.touched.country && formik.errors.country}
                </FormHelperText>
              </Box>
              <Box className={classes.inputContent}>
                <Typography variant="h5" className={classes.modalSubhead}>
                  State/province
                </Typography>
                <StateInput
                  country={formik.values.country}
                  value={formik.values.state}
                  onChange={handleStateChange}
                  error={formik.touched.state && Boolean(formik.errors.state)}
                />
                <FormHelperText
                  error={formik.touched.state && Boolean(formik.errors.state)}
                >
                  {formik.touched.state && formik.errors.state}
                </FormHelperText>
              </Box>
            </Box>
            <Box className={classes.inputRow}>
              <Box className={classes.inputContent}>
                <Typography variant="h5" className={classes.modalSubhead}>
                  City
                </Typography>
                <OutlinedInput
                  type="text"
                  fullWidth
                  name="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                />
                <FormHelperText
                  error={formik.touched.city && Boolean(formik.errors.city)}
                >
                  {formik.touched.city && formik.errors.city}
                </FormHelperText>
              </Box>
              <Box className={classes.inputContent}>
                <Typography variant="h5" className={classes.modalSubhead}>
                  ZIP
                </Typography>
                <OutlinedInput
                  type="text"
                  fullWidth
                  name="zip"
                  value={formik.values.zip}
                  onChange={formik.handleChange}
                  error={formik.touched.zip && Boolean(formik.errors.zip)}
                />
                <FormHelperText
                  error={formik.touched.zip && Boolean(formik.errors.zip)}
                >
                  {formik.touched.zip && formik.errors.zip}
                </FormHelperText>
              </Box>
            </Box>
          </Box>
          <Box className={classes.modalFooter}>
            <Button
              className={clsx(classes.footerButton, classes.footerPrimaryBtn)}
              disabled={formik.isSubmitting || isLoading}
              type="submit"
              variant="contained"
              color="primary"
            >
              {isNew ? "Add" : "Save changes"}
            </Button>
            <Button
              className={classes.footerButton}
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Fade>
    </Modal>
  );
}
