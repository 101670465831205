import { ADMIN } from "../../../actions";

const selectedReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN.CLEAR_ADMIN:
      return {};
    case ADMIN.SET_SELECTED_CUSTOMER:
      return action.selected;
    default:
      return state;
  }
};

export { selectedReducer };
