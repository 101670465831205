import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    boxButton: {
        display: 'flex',
        background: "#102F51",
        padding: "15px",
        fontSize: "15px",
        color: "#8EEAFF",
        border: "1px solid #CDE0F1",
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between'
    },
    boxButtonIcon: {
        width: "16px"
    }
}));


export { useStyles }