import React from "react";
import {
  Grid,
  Container,
  Typography,
  OutlinedInput,
  MenuItem,
  Button,
  FormHelperText,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { useStyles } from "./MapLocation.style";
import { useFormik } from "formik";
import { BaseDropdown } from "../../shared";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useState } from "react";
import {
  apiUpdateLocationMapping,
  apiValidateMailAccount,
} from "../../../apis";
import { setErrorMessage, setSuccessMessage } from "../../../store";

export default function MapLocation() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const locations = useSelector((state) => state.customer.locations.data || []);
  const [isUserValidated, setIsUserValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const validationSchema = yup.object({
    login: yup.string().required("Required"),
    password: yup.string().required("Required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: { login: "", password: "", locationId: "" },
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const data = {
          login: values.login,
          password: values.password,
        };
        const response = await apiValidateMailAccount(data);
        dispatch(setSuccessMessage("Validated successfully"));
        setIsUserValidated(true);
        setLoading(false);
      } catch (error) {
        if (error && error.toString().includes("status code 401"))
          dispatch(setErrorMessage("Invalid Credentials"));
        else dispatch(setErrorMessage(error.toString()));
        setLoading(false);
      }
    },
  });

  const handleDataSubmit = async () => {
    if (!isUserValidated) return;
    if (!formik.values.locationId) {
      dispatch(setErrorMessage("Please select your location"));
      return;
    }
    try {
      setLoading(true);
      await apiUpdateLocationMapping(formik.values);
      dispatch(setSuccessMessage("Updated succesully"));
      setLoading(false);
    } catch (error) {
      dispatch(setErrorMessage(error.toString()));
      setLoading(false);
    }
  };

  const onLoginChange = (event) => {
    formik.setFieldValue("login", event?.target?.value);
    setIsUserValidated(false);
  };

  const onPasswordChange = (event) => {
    formik.setFieldValue("password", event?.target?.value);
    setIsUserValidated(false);
  };

  const onLocationChange = (event) => {
    formik.setFieldValue("locationId", event?.target?.value);
  };

  return (
    <section className={classes.section}>
      <form className={classes.root} onSubmit={formik.handleSubmit} autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Typography variant="h5" className={classes.label}>
              Location
            </Typography>
            <BaseDropdown
              name="location"
              value={formik.values.locationId}
              onChange={onLocationChange}
              error={
                formik.touched.locationId && Boolean(formik.errors.locationId)
              }
            >
              {locations.map((location) => (
                <MenuItem value={location.id} key={location.id}>
                  {location.name}
                </MenuItem>
              ))}
            </BaseDropdown>
            <FormHelperText
              className={classes.error}
              error={
                formik.touched.locationId && Boolean(formik.errors.locationId)
              }
            >
              {formik.touched.locationId && formik.errors.locationId}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Typography variant="h5" className={classes.label}>
              Username
            </Typography>
            <OutlinedInput
              type="text"
              fullWidth
              name="login"
              placeholder="Username"
              value={formik.values.login}
              onChange={onLoginChange}
              classes={{
                root: classes.loginInput,
              }}
              error={Boolean(formik.errors.login)}
            />
            <FormHelperText
              className={classes.error}
              error={formik.touched.login && Boolean(formik.errors.login)}
            >
              {formik.touched.login && formik.errors.login}
            </FormHelperText>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h5" className={classes.label}>
              Password
            </Typography>
            <OutlinedInput
              id="password"
              type="password"
              fullWidth
              placeholder="Password"
              classes={{
                root: classes.loginInput,
              }}
              name="password"
              value={formik.values.password}
              onChange={onPasswordChange}
              error={Boolean(formik.errors.password)}
            />
            <FormHelperText
              className={classes.error}
              error={formik.touched.password && Boolean(formik.errors.password)}
            >
              {formik.touched.password && formik.errors.password}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid className={classes.btnContainer} container spacing={3}>
          <Grid item xs={2}>
            <Button
              className={clsx(classes.footerButton, classes.footerPrimaryBtn)}
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
              fullWidth
            >
              Validate
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              className={clsx(classes.footerButton, classes.footerPrimaryBtn)}
              variant="contained"
              color="primary"
              type="button"
              disabled={!isUserValidated || loading}
              onClick={handleDataSubmit}
            >
              Submit
            </Button>
          </Grid>
          {loading && (
            <Box>
              <CircularProgress />
            </Box>
          )}
        </Grid>
      </form>
    </section>
  );
}
