import { AUTH } from "./action-types";

const setAuthData = (data) => ({
    type: AUTH.SET_DATA,
    data
});

const clearAuth = () => ({
    type: AUTH.CLEAR
});

export { setAuthData, clearAuth }