import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "sans-serif",
    color: "#242223",
  },
  paper: {
    width: 470,
    background: "#fff",
    position: "fixed",
    maxHeight: "100vh",
    overflow: "auto",
    border: "1px solid #CDE0F1",
    right: 0,
    height: "100%",
  },
  modalHeader: {
    padding: "10px 15px",
    background: "#F1F9FE",
    borderBottom: "1px solid #CDE0F1",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  modalHeading: {
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "18px",
    color: "#242223",
    fontFamily: "Roboto-Medium",
  },
  modalSubHead: {
    margin: "15px 0",
  },
  closeButton: {
    color: theme.palette.grey[500],
    padding: 0,
  },
  boxSubHeader: {
    display: "flex",
    background: "rgba(241, 249, 254, 0.5)",
    borderBottom: "1px solid #CDE0F1",
    boxSizing: "border-box",
    padding: "3px 10px",
    alignItems: "center",
    height: "72px",
    justifyContent: "space-around",
  },
}));

export { useStyles };
