import React, { useEffect } from "react";
import { Box, Typography, Link, IconButton } from "@material-ui/core";
import clsx from "clsx";
import {
  ArrowLeft as ArrowLeftIcon,
  ArrowRight as ArrowRightIcon,
  Edit as EditIcon,
} from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { findIndex } from "lodash";
import { useStyles } from "./ForwardingPhoneNumbers.style";
import {
  openModal,
  loadForwardingPhoneNumbers,
  setSelectedForwardingPhoneNumber,
  setForwardingPhoneNumberOperatingMode,
} from "../../../store";
import { ModalType, OperatingMode } from "../../../core";
import { userHasEditPermission } from "../../../constants/constants";

export default function ForwardingPhoneNumbers(props) {
  const { rootClass } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const forwardingPhoneNumber = useSelector(
    (state) => state.account.forwardingPhoneNumber
  );
  const { data, selected } = forwardingPhoneNumber;
  const userType = useSelector(
    (state) => state.auth.data.user_type);

  const handleAddEditPhoneNumber = (operatingMode) => {
    dispatch(setForwardingPhoneNumberOperatingMode(operatingMode));
    dispatch(openModal(ModalType.AddForwardingNumber));
  };

  useEffect(() => {
    dispatch(loadForwardingPhoneNumbers());
  }, [dispatch]);

  const currentIndex = findIndex(data, selected);

  const handleNextClick = () => {
    if (data?.length > currentIndex + 1) {
      dispatch(setSelectedForwardingPhoneNumber(data[currentIndex + 1]));
    }
  };

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      dispatch(setSelectedForwardingPhoneNumber(data[currentIndex - 1]));
    }
  };

  return (
    <Box component="Box" className={rootClass ? rootClass : classes.box}>
      <Box className={classes.boxHeader}>
        <Typography variant="h6" className={classes.boxHeading}>
          Forwarding phone numbers
        </Typography>
        {userHasEditPermission(userType) && <Link
          component="button"
          variant="inherit"
          className={classes.boxLink}
          onClick={() => handleAddEditPhoneNumber(OperatingMode.New)}
        >
          Add
        </Link>}
      </Box>
      <Box className={classes.boxSubHeader}>
        <IconButton className={classes.iconButton} onClick={handlePrevClick}>
          <ArrowLeftIcon className={classes.leftRightIcon} fontSize="large" />
        </IconButton>
        <Typography>{`(${currentIndex + 1}/${forwardingPhoneNumber.data?.length
          })`}</Typography>
        <IconButton className={classes.iconButton} onClick={handleNextClick}>
          <ArrowRightIcon className={classes.leftRightIcon} fontSize="large" />
        </IconButton>
      </Box>
      <Box className={clsx(classes.boxRow, classes.noBorder)}>
        <Box className={classes.boxContent}>
          <Box className={classes.boxContentDataRow}>
            <Box className={classes.dataRow}>
              <Box>
                <Typography variant="body1">Number</Typography>
                <Typography variant="subtitle1">
                  {selected?.forwardingPhoneNumber}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={clsx(classes.boxContentDataRow, classes.noBorder)}>
            <Box className={classes.dataRow}>
              <Box>
                <Typography variant="body1">Forwarding to</Typography>
                <Typography variant="subtitle1">{selected.fullName}</Typography>
              </Box>
            </Box>
            {userHasEditPermission(userType) && <IconButton
              className={classes.iconButton}
              onClick={() => handleAddEditPhoneNumber(OperatingMode.Edit)}
            >
              <EditIcon className={classes.boxContentDataRowEdit} />
            </IconButton>}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
