import React from "react";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useSelector, useDispatch } from "react-redux";
import { clearMessage } from "../../../store";

export default function CustomSnackbar() {
  const dispatch = useDispatch();
  const { message, messageType } = useSelector((state) => state.ui);
  const handleClose = () => {
    dispatch(clearMessage());
  };
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(message)}
      autoHideDuration={1500}
      onClose={handleClose}
    >
      {messageType ? (
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={messageType}
        >
          {message}
        </Alert>
      ) : null}
    </Snackbar>
  );
}
