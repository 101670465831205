import React from 'react';
import { useHistory } from "react-router-dom";
import { Link, Box, Grid, Typography, Button, InputAdornment, OutlinedInput, FormHelperText, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { apiForgetPassword } from '../../apis';
import { setErrorMessage, setSuccessMessage } from '../../store';
import Logo from '../../assets/images/logo.jpg';
import arrowIcon from '../../assets/icons/arrow-icon.png';
import { useStyles } from './ForgetPassword.style';
import { useState } from 'react';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';

const validationSchema = yup.object({
    email: yup.string().required('Required').email('Please enter a valid email')
});

export default function ForgetPassword() {
    const dispatch = useDispatch();
    let history = useHistory();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const handleBackToLoginClick = () => {
        history.push("/sign-in")
    }

    const handleFreeTrialClick = () => {
        history.push("/sign-up")
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { email: "" },
        validationSchema,
        onSubmit: async (values) => {
            const finishLoading = () => formik.setSubmitting(false);
            try {
                setLoading(true);
                await apiForgetPassword(values);
                setLoading(false);
                dispatch(setSuccessMessage("Reset link send to email"));
            } catch (error) {
                dispatch(setErrorMessage(error.toString()));
                setLoading(false);
            }
            finishLoading();
        },
    });

    return (
        <Box component="div" className={classes.fgtPwd}>
            <Grid className={classes.fgtPwdLeft}>
                <Grid className={classes.fgtPwdLeftContainer}>
                    <Box component="div" className={classes.fgtPwdLogo}>
                        <img className={classes.fgtPwdLogoImg} src={Logo} alt="logo" />
                    </Box>
                    <form onSubmit={formik.handleSubmit} autoComplete="off" className={classes.fgtPwdFormWrap}>
                        <Typography variant="h4" className={classes.fgtPwdLeftH4}>Forget Password?</Typography>
                        <Typography variant="body1" className={classes.fgtPwdLeftBody}>
                            Please enter your email address.
                        </Typography>
                        <Box component="div" className={classes.fgtPwdForm}>
                            <Box component="div" className={clsx(classes.fgtPwdProfileField, classes.fgtPwdTransformButton)}>
                                <OutlinedInput
                                    id="fgtPwd-Email"
                                    type="text"
                                    fullWidth
                                    notched={false}
                                    placeholder="Email"
                                    classes={{
                                        root: classes.fgtPwdInput
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <PersonOutlineIcon style={{ fontSize: 18, color: "#8eeaff" }} />
                                        </InputAdornment>
                                    }
                                    name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                />
                                <FormHelperText error={formik.touched.email && Boolean(formik.errors.email)}>
                                    {formik.touched.email && formik.errors.email}
                                </FormHelperText>
                            </Box>
                            <Button variant="contained" color="inherit" disabled={formik.isSubmitting || loading} type="submit" fullWidth className={clsx(classes.fgtPwdLeftBtn, classes.fgtPwdTransformButton)}>Reset Password</Button>
                            {loading && <Box className={classes.loader}>
                                <CircularProgress size="1.9rem" />
                            </Box>}
                        </Box>
                    </form>
                    <Link
                        component="button"
                        variant="inherit"
                        color="inherit"
                        className={classes.fgtPwdBackToLogin}
                        onClick={handleBackToLoginClick}
                    >
                        Back to Login
                    </Link>
                </Grid>
            </Grid>
            <Grid className={classes.fgtPwdRight}>
                <Box component="div" className={classes.fgtPwdRightBgLayer}>
                </Box>
                <Box component="div" className={classes.fgtPwdRightBox}>
                    <Typography variant="h4" className={classes.fgtPwdRightBoxH4}>Don't have an account yet? Call us:</Typography>
                    <Typography variant="h3" className={classes.fgtPwdRightBoxH3}>(877) 603-1962</Typography>
                    <Button variant="contained" color="inherit" endIcon={<img src={arrowIcon} alt="arrow" />} onClick={handleFreeTrialClick} className={clsx(classes.fgtPwdTrialBtn, classes.fgtPwdTransformRightButton)}>Start a free trial </Button>
                </Box>
            </Grid>
        </Box >
    );
}
