import { MYACCOUNT } from "../../../actions";

const operatingModeReducer = (state = {}, action) => {
    switch (action.type) {
        case MYACCOUNT.CLEAR_ACCOUNT:
            return {};
        case MYACCOUNT.SET_FORWARDING_ADDRESS_OPERATING_MODE:
            return action.operatingMode;
        default:
            return state;
    }
};

export { operatingModeReducer };
