import {
  Dialog,
  DialogContent,
  Box,
  DialogTitle,
  Grid,
  Typography,
  Divider,
} from "@material-ui/core";
import {
  CalendarToday as CalendarTodayIcon,
  Schedule as ScheduleIcon,
  PeopleOutline as PeopleOutlineIcon,
} from "@material-ui/icons";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { ModalType } from "../../../core";
import { closeModal } from "../../../store";
import CloseModalIcon from "../../../assets/icons/closeModalIcon";

import { useStyles } from "./RoomBookingModal.style";

export default function RoomBookingModal() {
  const classes = useStyles();
  const modal = useSelector(
    (state) => state.ui.modal[ModalType.RoomBookingSuccessModal] || false
  );
  const detail = useSelector((state) => state.spaceRental.bookings.detail);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeModal(ModalType.RoomBookingSuccessModal));
  };
  return (
    <Dialog
      open={modal}
      onClose={handleClose}
      aria-labelledby="booking-dialog-title"
      aria-describedby="booking-dialog-description"
    >
      <div className={classes.succModalDiv}>
        <DialogTitle
          id="booking-dialog-title"
          className={classes.succModalTitleDiv}
        >
          <Grid container sm={12} direction="row">
            <Grid sm={9}>
              <p className={classes.succModalTitle}>Successfully Booked</p>
            </Grid>
            <Grid sm={3}>
              <CloseModalIcon
                handleCloseIcon={handleClose}
                className={classes.succModalIcon}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.modalContent}>
          <Box>
            <img
              className={classes.backgroundImg}
              src="https://dev-firmvo.s3.amazonaws.com/static-files/hotdesk1_main.png"
              alt="test"
            />
          </Box>
          <Box className={classes.boxSubHeader}>
            <Box paddingBottom={2}>
              <Typography variant="h2" className={classes.typography1}>
                {detail.name}
              </Typography>
              <Typography variant="body1">{detail.location}</Typography>
            </Box>
            <Typography className={classes.info}>
              <CalendarTodayIcon fontSize="small" color="primary" />{" "}
              {moment(detail.startDate).format("DD MMM YYYY")}
            </Typography>
            <Typography className={classes.info}>
              <ScheduleIcon fontSize="small" color="primary" />{" "}
              {`${moment(detail.startDate).format("h:mm A")} - ${moment(
                detail.endDate
              ).format("h:mm A")}`}
            </Typography>
            <Typography className={classes.info}>
              <PeopleOutlineIcon fontSize="medium" color="primary" />{" "}
              {`${
                detail.minRange === detail.maxRange
                  ? `${detail.minRange}`
                  : `${detail.minRange}-${detail.maxRange}`
              } People`}
            </Typography>
            <Divider />
            <Box paddingBottom={1} paddingTop={1}>
              <Typography variant="body1">
                Your card has been charged <b>${detail.bookingPrice}</b>
              </Typography>
            </Box>
          </Box>
        </DialogContent>
      </div>
    </Dialog>
  );
}
