import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  calendraIcon: {
    color: "#2CA4C0",
    position: "relative",
    left: 30,
  },
  disabledIcon: {
    color: "#A19E9E",
    zIndex: 9,
  },
  disabledSelect: {
    background: "#EFEFEF",
  },
  withIcon: {
    marginLeft: "34px",
  },
}));

export { useStyles };
