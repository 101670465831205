import { MAILMANAGEMENT } from "../../actions";

const activeTabReducer = (state = "new", action) => {
  switch (action.type) {
    case MAILMANAGEMENT.CLEAR:
      return "new";
    case MAILMANAGEMENT.SET_ACTIVE_TAB:
      return action.activeTab;
    default:
      return state;
  }
};

export { activeTabReducer };
