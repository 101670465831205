import React from "react";
export default function MoveToJunkIcon(props) {
  return (
    <svg
      className={`${props.className} iconSvg`}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.28247 4.375H14.9781"
        stroke="#B8BFC5"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.08681 4.37492V2.91659C5.08681 2.52981 5.24713 2.15888 5.53252 1.88539C5.8179 1.6119 6.20496 1.45825 6.60855 1.45825H9.65203C10.0556 1.45825 10.4427 1.6119 10.7281 1.88539C11.0134 2.15888 11.1738 2.52981 11.1738 2.91659V4.37492M13.4564 4.37492V14.5833C13.4564 14.97 13.296 15.341 13.0107 15.6144C12.7253 15.8879 12.3382 16.0416 11.9346 16.0416H4.32594C3.92235 16.0416 3.53529 15.8879 3.24991 15.6144C2.96452 15.341 2.8042 14.97 2.8042 14.5833V4.37492H13.4564Z"
        stroke="#B8BFC5"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.60864 8.02075V12.3958"
        stroke="#B8BFC5"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.6521 8.02075V12.3958"
        stroke="#B8BFC5"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
