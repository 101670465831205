import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  subHeader: {
    backgroundColor: theme.palette.secondary.main,
    height: "58px",
    width: "100%",
    display: "flex",
    color: theme.palette.common.white,
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  boxLink: {
    fontSize: "14px",
    lineHeight: "16px",
    color: theme.palette.primary.main,
  },
  subHeadings: {
    marginRight: 100,
    cursor: "pointer",
  },
  subHeaderTabs: {
    padding: 45,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    width: 335,
  },
  iconButton: {
    padding: 10,
  },
  searchField: {
    float: "right",
    background: "white",
  },
  headerGrid: {
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    WebkitTouchCallout: "none",
    WebkitUserSelect: "none",
    KhtmlUserSelect: "none",
    MozUserSelect: "none",
    MsUserSelect: "none",
    userSelect: "none",
  },
  headerLinkSpan: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  headerLinkText: {
    fontSize: "14px",
    letterSpacing: "0.01em",
    textDecorationLine: "underline",
    color: "#8EEAFF",
  },
  headerLinkIcon: {
    marginLeft: "20px",
  },
  customRangeDiv: {
    height: "80%",
    display: "flex",
    marginLeft: "20px",
    justifyContent: "center",
    alignItems: "center",
    background: "white",
    color: "black",
    padding: "10px",
    borderRadius: "5px",
  },
  headerLinkGrid: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

export { useStyles };
