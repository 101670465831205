import { AUTH } from "../../actions";

const dataReducer = (state = {}, action) => {
    switch (action.type) {
        case AUTH.CLEAR:
            return {};
        case AUTH.SET_DATA:
            return action.data;
        default:
            return state;
    }
};

export { dataReducer };
