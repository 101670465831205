import { MAILMANAGEMENT } from "../../actions";

const currentPageReducer = (state = 1, action) => {
  switch (action.type) {
    case MAILMANAGEMENT.CLEAR:
    case MAILMANAGEMENT.SEARCH_MAIL_ITEMS:
    case MAILMANAGEMENT.SET_ACTIVE_TAB:
      return 1;
    case MAILMANAGEMENT.SET_CURRENT_PAGE:
      return action.currentPage;
    default:
      return state;
  }
};

export { currentPageReducer };
