import { MYACCOUNT } from "../../../actions";

const selectedReducer = (state = {}, action) => {
  switch (action.type) {
    case MYACCOUNT.CLEAR_ACCOUNT:
      return {};
    case MYACCOUNT.SET_SELECTED_PAYMENT_METHOD:
      return action.selected;
    case MYACCOUNT.ADD_UPDATE_PAYMENT_METHOD:
      return action.record;
    default:
      return state;
  }
};

export { selectedReducer };
