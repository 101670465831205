import React from "react";

export default function DualToneCheckboxIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" transform="translate(2 2)" fill="#F1F9FE" />
      <path
        d="M10.8218 13.178L8.46425 10.8214L7.28592 11.9997L10.8218 15.5355L16.7134 9.64303L15.5359 8.46387L10.8218 13.178Z"
        fill="#2CA4C0"
      />
    </svg>
  );
}
