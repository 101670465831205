import { http, handleResponse } from "../core";
import { URL } from "./_urls.js";

const apiFetchPaymentMethods = async (customerId) => {
  return handleResponse(await http.get(URL.FETCH_PAYMENT_METHODS(customerId)));
};

const apiAddNewCard = async (data) => {
  return handleResponse(await http.post(URL.ADD_CUSTOMER_NEW_CARD(), data));
};

const apiFetchPlans = async (locationId) => {
  return handleResponse(await http.get(URL.FETCH_PLANS(locationId)));
};

const apiAddNewPlan = async (data) => {
  return handleResponse(await http.post(URL.ADD_CUSTOMER_PLAN(), data));
};

const apiUpdatePlan = async (data) => {
  return handleResponse(await http.post(URL.UPDATE_CUSTOMER_PLAN(), data));
};

export {
  apiFetchPaymentMethods,
  apiAddNewCard,
  apiFetchPlans,
  apiAddNewPlan,
  apiUpdatePlan,
};
