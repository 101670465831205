import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    selectDaysRow: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: "wrap",
        marginBottom: 10
    },
    selectLabel: {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "18px",
        color: "#242223",
        fontFamily: 'Roboto-Medium',
        margin: '10px'
    },
    select: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 10,
        flex: "1",
        border: "1px solid #CDE0F1",
        padding: "5px 10px",
    },
    calendraIcon: {
        marginRight: 10,
        color: "#2CA4C0"
    },
    downIcon: {
        marginLeft: "auto",
        color: "#CDE0F1"
    },
    selectColumn: {
        flex: "1",
        display: 'flex',
        alignItems: 'center',
        marginBottom: 10
    },
    selectColumnLeft: {
        maxWidth: "40%",
        minWidth: "40%",
    },
    selectColumnRight: {
        maxWidth: "60%",
        minWidth: "60%",
    },
    dailyAvailablity: {
        margin: "15px 0",
        borderTop: "2px dashed lightgrey",
        borderBottom: "2px dashed lightgrey"
    },
    firstRow: {
        marginTop: 20
    },
    checkbox: {
        display: "flex"
    },
    disabledIcon: {
        color: '#A19E9E'
    },
    timePicker: {
        width: '82%'
    },
    closedTimePicker: {
        width: "100%",
    }
}));


export { useStyles }