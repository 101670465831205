import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  subHeader: {
    backgroundColor: theme.palette.secondary.main,
    height: "58px",
    width: "100%",
    display: "flex",
    color: theme.palette.common.white,
    alignItems: "center",
    // justifyContent: 'space-between',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    justifyContent: "center",
  },
  chatContent: {
    height: "100%",
    maxHeight: "calc(100% - 58px)",
    display: "flex",
    flexDirection: "column",
  },
  messageTime: {
    fontSize: "10px",
    paddingLeft: "5px",
  },
  userDetails: {
    display: "flex"
  },
  userSectionName: {
    margin: '7px'
  }
}));

export { useStyles };
