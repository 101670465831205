import React, { useState } from "react";
import { Box, Typography, IconButton, OutlinedInput } from "@material-ui/core";
import { Edit as EditIcon, Save as SaveIcon } from "@material-ui/icons";
import { useStyles } from "./QuickEditInput.stle";
import clsx from "clsx";

export default function QuickEditInput({
  label,
  onSave,
  value,
  className,
  handleEditClick,
  hasBackground,
  rowDirection,
  isEditable,
  ...rest
}) {
  const [editable, setEditable] = useState(false);
  const classes = useStyles();
  const { boxContentDataRow, boxContentWhiteBackground } = classes;
  const handleClick = (field) => {
    if (editable) {
      onSave && onSave();
      setEditable(false);
    } else {
      if (handleEditClick) handleEditClick(field);
      setEditable(true);
    }
  };

  const containerClass = () => {
    if (editable && hasBackground) return boxContentWhiteBackground;
    else return className ? className : boxContentDataRow;
  };

  return (
    <Box className={clsx(containerClass())}>
      <Box className={classes.dataRow}>
        {!editable ? (
          <Box className={rowDirection ? classes.containerRowBased : ""}>
            <Typography variant="body1" {...rest.headingProps}>
              {label}
            </Typography>
            <Typography variant="subtitle1" {...rest.valueProps}>
              {value}
            </Typography>
          </Box>
        ) : (
          <OutlinedInput type="text" fullWidth value={value} {...rest} />
        )}
      </Box>
      {isEditable && <IconButton
        className={classes.boxContentDataRowEdit}
        onClick={() => handleClick(rest.name)}
      >
        {editable ? (
          <SaveIcon className={classes.saveIcon} />
        ) : (
          <EditIcon className={classes.editIcon} />
        )}
      </IconButton>
      }
    </Box>
  );
}
