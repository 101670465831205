import React from "react";
import { Box, Typography, Link, List, ListItem } from "@material-ui/core";
import { useStyles } from "./MyCurrentPlan.style";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { useSelector, useDispatch } from "react-redux";
import { openModal, setSelectedCustomer } from "../../../store";
import { ModalType } from "../../../core";
import { userHasEditPermission } from "../../../constants/constants";

export default function MyCurrentPlan() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { planInfo, planServices } = useSelector(
    (state) => state.admin.customer
  );
  const userType = useSelector(
    (state) => state.auth.data.user_type);

  const handleChangePlan = () => {
    dispatch(setSelectedCustomer({}));
    dispatch(openModal(ModalType.ChangeCustomersPlan));
  };

  return (
    <Box component="div" className={classes.box}>
      <Box className={classes.boxHeader}>
        <Typography variant="h6" className={classes.boxHeading}>
          My Current Plan
        </Typography>
        {userHasEditPermission(userType) && <Link
          component="button"
          variant="inherit"
          color="inherit"
          className={classes.boxLink}
          onClick={handleChangePlan}
        >
          Upgrade
        </Link>}
      </Box>
      <Box className={classes.imgSection}>
        <Box className={classes.imgSectionContent}>
          <Typography className={classes.firmIdentity}>
            {planInfo?.planName}
          </Typography>
          <Box>
            <Typography variant="h4" className={classes.boxPrice}>
              {`$${planInfo?.planPrice}`}
            </Typography>
            <Typography>{planInfo?.periodUnit}</Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.boxContent}>
        <Typography variant="h5" className={classes.boxLeftHeading}>
          Plan Includes:
        </Typography>
        <Box>
          <List className={classes.list}>
            {planServices.map((planService) => (
              <ListItem className={classes.listItem}>
                <Box>
                  <FiberManualRecordIcon className={classes.bulletIcon} />
                  {planService.serviceName}
                </Box>
                <Typography variant="body2">
                  {planService.customQty || planService.serviceQty}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Box>
  );
}
