import { UI } from "./action-types";

const openModal = (modal) => ({
  type: UI.OPEN_MODAL,
  modal,
});

const closeModal = (modal) => ({
  type: UI.CLOSE_MODAL,
  modal,
});

const setMasterData = (data) => ({
  type: UI.SET_MASTER_DATA,
  data,
});

const setSuccessMessage = (message) => ({
  type: UI.SET_SUCCESS_MESSAGE,
  message,
});

const setErrorMessage = (message) => ({
  type: UI.SET_ERROR_MESSAGE,
  message,
});

const clearMessage = () => ({
  type: UI.CLEAR_MESSAGE,
});

const setUIActionType = (actionType) => ({
  type: UI.SET_ACTION_TYPE,
  actionType,
});

const setChargebeeLoaded = (status) => ({
  type: UI.SET_CHARGEBEE_LOADED,
  status,
});

export {
  openModal,
  closeModal,
  setMasterData,
  setSuccessMessage,
  setErrorMessage,
  clearMessage,
  setUIActionType,
  setChargebeeLoaded,
};
