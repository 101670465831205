import React from 'react'
import { Modal, Slide, Backdrop, Typography, Button, Box, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { closeModal } from '../../../store';
import { ModalType } from '../../../core';
import clsx from 'clsx';
import { useStyles } from './ManageAnsweringScript.style';
import IconButton from '@material-ui/core/IconButton';
import {
    HighlightOff as HighlightOffIcon,
    Edit as EditIcon
} from '@material-ui/icons';
import { CallTransferedInput } from '../../shared';

export default function ManageAnsweringScript() {
    const state = useSelector(state => state);
    const dispatch = useDispatch();
    const classes = useStyles();

    const modal = state.ui.modal[ModalType.ManageAnsweringScript] || false;
    const handleClose = () => {
        dispatch(closeModal(ModalType.ManageAnsweringScript));
    }
    return (
        <Modal
            aria-labelledby="manage-answering-script-modal-title"
            aria-describedby="manage-answering-script-modal-description"
            open={modal}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            className={classes.modal}
            BackdropProps={{
                timeout: 500
            }}
            disableEnforceFocus
        >
            <Slide direction="left" in={modal}>
                <div className={classes.paper}>
                    <div className={classes.modalHeader}>
                        <Typography variant="h6" className={classes.modalHeading}>
                            Your answering script
                        </Typography>
                        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                            <HighlightOffIcon />
                        </IconButton>
                    </div>
                    <div className={classes.modalBody}>
                        <Typography className={classes.forChangeText}>
                            For changes in script send us a ticket
                        </Typography>
                        <Typography variant="h5" className={clsx(classes.modalHeading, classes.modalSubHead)}>
                            Welcome greeting
                        </Typography>
                        <Box className={classes.boxScriptRow}>
                            <Typography>
                                Thank you for calling <span className={classes.textBold}> The Law Office of Ambre Nerinck-Seltzer.
                                    This is Jane.
                                    How May I Direct Your Call Today?</span>
                            </Typography>
                            <EditIcon className={classes.editIcon} />
                        </Box>
                        <Typography variant="h5" className={clsx(classes.modalHeading, classes.modalSubHead)}>
                            Call type
                        </Typography>
                        <RadioGroup className={classes.radioGroup} aria-label="callType" name="callType">
                            <FormControlLabel value="female" control={<Radio className={classes.radio} color="primary" />} label="Speak to attorney" />
                            <FormControlLabel value="male" control={<Radio className={classes.radio} color="primary" />} label="New potential client" />
                            <FormControlLabel value="other" control={<Radio className={classes.radio} color="primary" />} label="Other" />
                        </RadioGroup>
                        <Box className={classes.boxScriptRow}>
                            <Typography>
                                Whom would you like to speak to?
                            </Typography>
                            <EditIcon className={classes.editIcon} />
                        </Box>
                        <RadioGroup className={classes.radioGroup} aria-label="speakTo" name="speakTo">
                            <FormControlLabel value="female" control={<Radio className={classes.radio} color="primary" />} label="Speak to attorney" />
                            <FormControlLabel value="male" control={<Radio className={classes.radio} color="primary" />} label="New potential client" />
                            <FormControlLabel value="other" control={<Radio className={classes.radio} color="primary" />} label="Other" />
                        </RadioGroup>
                        <Box className={classes.boxScriptRow}>
                            <Typography>
                                Whom would you like to speak to?
                            </Typography>
                            <EditIcon className={classes.editIcon} />
                        </Box>
                        <Box className={classes.boxScriptRow}>
                            <Typography>
                                Whom would you like to speak to?
                            </Typography>
                            <EditIcon className={classes.editIcon} />
                        </Box>
                        <Box className={classes.boxScriptRow}>
                            <Typography>
                                Whom would you like to speak to?
                            </Typography>
                            <EditIcon className={classes.editIcon} />
                        </Box>
                        <div className={classes.dailyAvailablity}></div>
                        <Box className={classes.boxScriptRow}>
                            <Typography>
                                Whom would you like to speak to?
                            </Typography>
                            <EditIcon className={classes.editIcon} />
                        </Box>
                        <Box className={classes.boxScriptRow}>
                            <Typography>
                                Whom would you like to speak to?
                            </Typography>
                            <EditIcon className={classes.editIcon} />
                        </Box>
                        <div className={classes.dailyAvailablity}></div>
                        <Typography variant="h5" className={clsx(classes.modalHeading, classes.modalSubHead)}>
                            Message details and call wil be transfered to:
                        </Typography>
                        <CallTransferedInput />
                    </div>
                    <div className={classes.modalFooter}>
                        <Button className={clsx(classes.footerButton, classes.footerPrimaryBtn)} variant="contained" color="primary" onClick={handleClose}>
                            Save this script
                        </Button>
                        <Button className={classes.footerButton} variant="outlined" onClick={handleClose}>Cancel</Button>
                    </div>
                </div>
            </Slide>
        </Modal >
    )
}
