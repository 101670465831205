import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  transactionDate: {
    display: "flex",
    backgroundColor: "#CDE0F1",
    height: "100%",
    alignItems: "center",
    padding: "0 10px",
    width: "50%",
    justifyContent: "space-around",
    flexBasis : "10%"
  },
  arrowIcon: {
    color: "#91AAC0",
    width: "1.5em",
    height: "1.5em",
  },
}));

export default useStyles;
