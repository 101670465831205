import { makeStyles } from "@material-ui/core";
import loginBackground from "../../assets/images/login-backgroud.png"
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    login: {
        display: 'grid',
        'grid-template-columns': '1fr 1fr',
        height: '100vh'
    },
    loginLeft: {
        padding: '10% 0',
        textAlign: 'center'
    },
    loginLeftContainer: {
        maxWidth: '345px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        alignItems: 'center'
    },
    loginLeftBtn: {
        padding: 14,
        backgroundColor: '#102f51',
        borderRadius: 2,
        color: '#fff',
        marginTop: '10px',
        fontSize: '17px',
        lineHeight: '20px',
        letterSpacing: 1,
        "&:hover": {
            backgroundColor: "#2CA4C0"
        }
    },
    loginLeftH4: {
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '130%',
        letterSpacing: '0.02em',
        color: '#242223',
        fontFamily: 'Roboto-Medium'
    },
    loginLeftBody: {
        fontSize: '16px',
        lineHeight: '20px',
        color: '#494949',
    },
    loginFormWrap: {
        marginTop: '60px',
        width: '100%'
    },
    loginLogo: {
        width: '201px'
    },
    loginLogoImg: {
        maxWidth: '100%'
    },
    loginForm: {
        marginTop: '44px',
        position: 'relative'
    },
    loginInput: {
        height: '48px'
    },
    loginTransfromEmail: {
        animation: 'transformEmail 1s'
    },
    loginTransformPassword: {
        animation: 'transformPassword 1s'
    },
    loginTransformButton: {
        animation: 'transformButton 0.5s'
    },
    loginTransformRightButton: {
        animation: 'transformRightButton 0.5s'
    },
    loginFgtPass: {
        fontSize: '15px',
        lineHeight: '40px',
        color: '#494949',
        textDecoration: 'none',
        marginTop: 'auto'
    },
    loginRight: {
        background: `url(${loginBackground}) left top`,
        backgroundSize: 'cover',
        position: 'relative'
    },
    loginRightBgLayer: {
        background: '#102f51',
        opacity: 0.8,
        height: '100%'
    },
    loginRightBox: {
        background: '#102f51',
        border: '2px solid #8eeaff',
        padding: '33px 49px 51px',
        position: 'absolute',
        bottom: '25%',
        width: '425px',
        left: '82px',
        opacity: 0.8
    },
    loginRightBoxH4: {
        fontSize: '16px',
        lineHeight: '19px',
        color: '#8eeaff',
        fontWeight: 'normal'
    },
    loginRightBoxH3: {
        fontSize: '26px',
        lineHeight: '30px',
        letterSpacing: '0.01em',
        color: '#fff'
    },
    loginTrialBtn: {
        background: '#8eeaff',
        borderRadius: '2px',
        padding: '15px',
        fontSize: '18px',
        lineHeight: '21px',
        letterSpacing: '0.01em',
        color: '#102f51',
        position: 'absolute',
        bottom: '-25px',
        left: '36px',
        width: '235px',
        "&:hover": {
            backgroundColor: "#8eeaff"
        }
    },
    loginNote: {
        fontStyle: 'italic',
        fontSize: '14px',
        lineHeight: '16px',
        color: '#494949'
    },
    loginStartNow: {
        background: theme.palette.primary.main,
        borderRadius: '2px',
        padding: '17px',
        fontWeight: 'bold',
        fontSize: '15px',
        lineHeight: '18px',
        letterSpacing: '0.01em',
        color: '#fff',
        position: 'absolute',
        bottom: '5%',
        transform: 'translateX(-50%)',
        left: '59%',
        width: '300px'
    },
    loginProfileField: {
        position: 'relative',
        marginBottom: '22px'
    },
    loginProfileFieldIcon: {
        position: 'absolute',
        right: '20px',
        top: '20px'
    },
    loader: {
        marginTop: "10px"
    }
}));


export { useStyles }