import { MYACCOUNT } from "../../../actions";

const dataReducer = (state = [], action) => {
  switch (action.type) {
    case MYACCOUNT.CLEAR_ACCOUNT:
    case MYACCOUNT.CLEAR_PAYMENT_METHODS:
      return [];
    case MYACCOUNT.SET_PAYMENT_METHODS:
      return action.data;
    case MYACCOUNT.ADD_UPDATE_PAYMENT_METHOD: {
      const records = state;
      const record = action.record;
      const index = state.findIndex((x) => x.id === record.id);
      if (index < 0) records.push(record);
      else records.splice(index, 1, record);
      return records;
    }
    default:
      return state;
  }
};

export { dataReducer };
