import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  currentPlanDiv: {
    height: "auto",
    background: "#FFFFFF",
    border: "1px solid #CDE0F1",
  },
  currentPlanTableHeading: {
    padding: "20px 27px 20px 15px",
  },
  headerMainGrid: {
    padding: "15px 25px",
    height: "48px",
    background: "#F1F9FE",
    borderBottom: "1px solid #CDE0F1",
    boxSizing: "border-box",
  },
  headerTextGrid: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  AdditionalHeaderLinkGrid: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    // paddingRight: 30,
  },
  headerLinkGrid: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingRight: 40,
  },
  headerText: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.01em",
    color: "#242223",
  },
  headerLink: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    textAlign: "right",
    letterSpacing: "0.01em",
    color: "#2CA4C0",
    cursor: "pointer",
  },
  contentMainGrid: {
    // height: "65%",
    marginTop: "20px",
    marginBottom: "23px",
  },
  planHeading: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    color: "#242223",
  },
  PlanGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  contentSubGrid: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  servicesGrid: {
    display: "flex",
    justifyContent: "flex-start",
  },
  planSubGrid: {
    textAlign: "center",
  },
  planPrice: {
    fontWeight: "bold",
    fontSize: "21px",
    color: "#2CA4C0",
  },
  planMonth: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    letterSpacing: "0.01em",
    color: "#494949",
  },
  AdditionalServicesDiv: {
    marginTop: "30px",
    height: "auto",
    border: "1px solid #CDE0F1",
  },
  secondHeaderText: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.01em",
    color: "#242223",
  },
  additionalBodyDiv: {
    background: "white",
    width: "100%",
    padding:"0 16px"
  },
  servicesHeading: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    letterSpacing: "0.01em",
    color: "#494949",
  },
  servicesPrice: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "right",
    color: "#2CA4C0",
  },
  currentPlanTable: {
    width: "100%",
  },
}));

export { useStyles };
