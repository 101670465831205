import React, { useState } from "react";
import { useFormik, FieldArray, FormikProvider } from "formik";
import {
  Grid,
  Typography,
  OutlinedInput,
  Button,
  FormHelperText,
  MenuItem,
  Box,
  Link,
  CircularProgress,
} from "@material-ui/core";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { useHistory } from "react-router-dom";
import S3 from "react-aws-s3";
import { apiAddNewRoom, apiUpdateRoom } from "../../../../apis";
import { BaseDropdown } from "../../../shared";
import ImageIcon from "../../../../assets/images/imageIcon.jpg";
import { AWSROOMSCONFIG } from "../../../../config";
import * as yup from "yup";
import { useStyles } from "./styles";
import {
  setErrorMessage,
  setSuccessMessage,
  getSelectedLocationId,
} from "../../../../store";
import { useDispatch } from "react-redux";
const ReactS3Client = new S3(AWSROOMSCONFIG);

const validationSchema = yup.object({
  name: yup.string().required("Required"),
  roomInternalId: yup.string().required("Required"),
  hourlyPrice: yup.number().required("Required"),
  location: yup.string().required("Required"),
  description: yup.string().required("Required"),
  amenities: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required("Required"),
      })
    )
    .min(1, "Add minimum one amenity")
    .required("Required"),
});

export default function ManageRoom(props) {
  const classes = useStyles();
  const { roomDetail } = props;
  let { push } = useHistory();
  const [isLoading, setLoading] = useState(false);
  const roomTypes = useSelector((state) => state.ui.master.roomTypes || []);
  const selectedLocationId = useSelector((state) =>
    getSelectedLocationId(state)
  );
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: roomDetail?.id
      ? { ...roomDetail }
      : { amenities: [], images: [] },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const finishLoading = () => formik.setSubmitting(false);
      try {
        setLoading(true);
        const payload = { ...values, locationId: selectedLocationId };
        values?.id
          ? await apiUpdateRoom(payload)
          : await apiAddNewRoom(payload);
        const successMessage = values?.id
          ? "Room updated successfully"
          : "Room added successfully";
        dispatch(setSuccessMessage(successMessage));
        setLoading(false);
        push("/admin/rooms");
      } catch (error) {
        dispatch(setErrorMessage(error.toString()));
        setLoading(false);
      }
      finishLoading();
    },
  });

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length) {
        setLoading(true);
        const file = acceptedFiles[0];
        ReactS3Client.uploadFile(file, `location_${Math.random()}`)
          .then((data) => {
            formik.setFieldValue("url", data.location);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      }
    },
  });

  const handleCancelClick = () => {
    push("/admin/rooms");
  };

  return (
    <section className={classes.section}>
      <FormikProvider value={formik}>
        <form className={classes.root} onSubmit={formik.handleSubmit}>
          <Typography className={classes.heading} variant="h3">
            Basic Details
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Typography variant="h5" className={classes.label}>
                Room Type
              </Typography>
              <BaseDropdown
                name="roomTypeId"
                value={formik.values.roomTypeId}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.roomTypeId)}
              >
                <MenuItem value={0} key={0}>
                  Select room type
                </MenuItem>
                {roomTypes.map((roomType) => (
                  <MenuItem value={roomType.id} key={roomType.id}>
                    {`No of People (${roomType.name})`}
                  </MenuItem>
                ))}
              </BaseDropdown>
              <FormHelperText error={Boolean(formik.errors.roomTypeId)}>
                {formik.errors.roomTypeId}
              </FormHelperText>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h5" className={classes.label}>
                Room name
              </Typography>
              <OutlinedInput
                type="text"
                fullWidth
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              <FormHelperText error={Boolean(formik.errors.name)}>
                {formik.errors.name}
              </FormHelperText>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Typography variant="h5" className={classes.label}>
                Room intenal id
              </Typography>
              <OutlinedInput
                type="text"
                fullWidth
                name="roomInternalId"
                value={formik.values.roomInternalId}
                onChange={formik.handleChange}
              />
              <FormHelperText error={Boolean(formik.errors.roomInternalId)}>
                {formik.errors.roomInternalId}
              </FormHelperText>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h5" className={classes.label}>
                Hourly Price
              </Typography>
              <OutlinedInput
                type="text"
                fullWidth
                name="hourlyPrice"
                value={formik.values.hourlyPrice}
                onChange={formik.handleChange}
              />
              <FormHelperText error={Boolean(formik.errors.hourlyPrice)}>
                {formik.errors.hourlyPrice}
              </FormHelperText>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Typography variant="h5" className={classes.label}>
                Address
              </Typography>
              <OutlinedInput
                type="text"
                fullWidth
                name="location"
                value={formik.values.location}
                onChange={formik.handleChange}
                rows={2}
                multiline
              />
              <FormHelperText error={Boolean(formik.errors.location)}>
                {formik.errors.location}
              </FormHelperText>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="h5" className={classes.label}>
                Description
              </Typography>
              <OutlinedInput
                type="text"
                fullWidth
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                rows={2}
                multiline
              />
              <FormHelperText error={Boolean(formik.errors.description)}>
                {formik.errors.description}
              </FormHelperText>
            </Grid>
          </Grid>
          <Typography className={classes.heading} variant="h3">
            Amenities
          </Typography>

          <FieldArray
            name="amenities"
            render={(arrayHelpers) => (
              <div>
                {formik.values.amenities.map((amenity, index) => (
                  <>
                    <Grid key={index} container spacing={3}>
                      <Grid item xs={4}>
                        <Typography variant="h5" className={classes.label}>
                          Amenity Name
                        </Typography>
                        <OutlinedInput
                          type="text"
                          fullWidth
                          name={`amenities[${index}].name`}
                          value={amenity.name}
                          onChange={formik.handleChange}
                        />
                      </Grid>
                      <Grid item xs={4} className={classes.deleteContainer}>
                        <Button
                          className={classes.footerButton}
                          variant="outlined"
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          Delete
                        </Button>
                      </Grid>
                    </Grid>
                    <FormHelperText
                      error={
                        (formik.touched.amenities || [])[index]?.name &&
                        Boolean((formik.errors.amenities || [])[index]?.name)
                      }
                    >
                      {(formik.touched.amenities || [])[index]?.name &&
                        (formik.errors.amenities || [])[index]?.name}
                    </FormHelperText>
                  </>
                ))}
                <Grid className={classes.deleteContainer} container spacing={3}>
                  <Grid item xs={4}>
                    <Button
                      fullWidth
                      className={classes.footerButton}
                      variant="outlined"
                      onClick={() => arrayHelpers.push({ name: "" })}
                    >
                      Add New Amenity
                    </Button>
                  </Grid>
                </Grid>
              </div>
            )}
          />
          <Typography className={classes.heading} variant="h3">
            Images
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Typography variant="h5" className={classes.label}>
                Cover Image
              </Typography>
              <Box
                {...getRootProps({ className: "dropzone" })}
                className={classes.imageContainer}
              >
                <input {...getInputProps()} />
                <Box className={classes.imageContainer}>
                  <img
                    src={formik.values.url || ImageIcon}
                    alt=""
                    className={classes.coverImage}
                  />
                  <Link
                    component="button"
                    type="button"
                    variant="inherit"
                    className={classes.boxLink}
                    onClick={open}
                  >
                    Upload an image
                  </Link>
                </Box>
              </Box>
            </Grid>
          </Grid>
          {isLoading && (
            <Box className={classes.loader}>
              <CircularProgress />
            </Box>
          )}
          <Grid className={classes.btnContainer} container spacing={3}>
            <Grid item xs={1}>
              <Button
                className={clsx(classes.footerButton, classes.footerPrimaryBtn)}
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading || formik.isSubmitting}
              >
                {roomDetail?.id ? "Update" : "Add"}
              </Button>
            </Grid>
            <Grid item xs={1}>
              <Button
                className={classes.footerButton}
                variant="outlined"
                onClick={handleCancelClick}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </section>
  );
}
