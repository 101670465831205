import { MYACCOUNT } from "../../../actions";

const selectedReducer = (state = {}, action) => {
    switch (action.type) {
        case MYACCOUNT.CLEAR_ACCOUNT:
            return {};
        case MYACCOUNT.SET_SELECTED_CUSTOMER_LOCATION:
            return action.selected;
        default:
            return state;
    }
};

export { selectedReducer };
