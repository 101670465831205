import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Container,
} from "@material-ui/core";
import React from "react";
import "./forwarding-address-modal.css";
export default function ForwardingAddressModal(props) {
  return (
    <>
      <Dialog
        open={props.show}
        onClose={props.handleClose}
        aria-labelledby="junk-dialog-title"
        aria-describedby="junk-dialog-description"
      >
        <div className="forwarding-address-dialog-title">
          <DialogTitle id="junk-dialog-title" className="faddressModalHeader">
            <Grid sm={12} direction="row" container>
              <Grid sm={6}>
                <p className="faddressModalHeaderText">
                  Your forwarding address
                </p>
              </Grid>
              <Grid sm={6} className="faddressModalHeaderIconGrid">
                <svg
                  onClick={props.handleClose}
                  style={{ cursor: "pointer" }}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 0.25C4.624 0.25 0.25 4.624 0.25 10C0.25 15.376 4.624 19.75 10 19.75C15.376 19.75 19.75 15.376 19.75 10C19.75 4.624 15.376 0.25 10 0.25ZM10 1.75C14.5653 1.75 18.25 5.43475 18.25 10C18.25 14.5653 14.5653 18.25 10 18.25C5.43475 18.25 1.75 14.5653 1.75 10C1.75 5.43475 5.43475 1.75 10 1.75ZM7.165 6.085L6.085 7.165L8.923 10L6.0865 12.835L7.1665 13.915L10 11.0778L12.835 13.9127L13.915 12.835L11.0778 10L13.9127 7.165L12.835 6.085L10 8.923L7.165 6.0865V6.085Z"
                    fill="#B8BFC5"
                  />
                </svg>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Container className="faddressContainer">
              <Grid sm={12} direction="row" container>
                <p className="OfficeNameFieldLabel">Office name</p>
              </Grid>
              <Grid
                sm={12}
                direction="row"
                container
                className="OfficeNameFieldInputGrid"
              >
                <input
                  type="text"
                  className="form-control OfficeNameFieldInput"
                  placeholder="New York - Federal Plaza"
                ></input>
              </Grid>
              <Grid
                sm={12}
                direction="row"
                container
                className="OfficeNameFieldInputGrid"
              >
                <hr className="OfficeNameFieldInputDivider" />
              </Grid>
              <Grid
                sm={12}
                direction="row"
                container
                className="OfficeNameFieldInputGrid"
              >
                <p
                  className="OfficeNameFieldLabel"
                  style={{ fontSize: "16px" }}
                >
                  Forwarding adress:
                </p>
              </Grid>
              <Grid
                sm={12}
                direction="row"
                container
                className="fAddressLine1FieldInputGrid"
              >
                <p className="OfficeNameFieldLabel">Address line1</p>
              </Grid>
              <Grid
                sm={12}
                direction="row"
                container
                className="OfficeNameFieldInputGrid"
              >
                <input
                  type="text"
                  className="form-control OfficeNameFieldInput"
                  placeholder="2118 Thornridge Cir. Syracuse, Connecticut 35624"
                ></input>
              </Grid>
              <Grid
                sm={12}
                direction="row"
                container
                className="fAddressLine1FieldInputGrid"
              >
                <p className="OfficeNameFieldLabel">Address line2 (optional)</p>
              </Grid>
              <Grid
                sm={12}
                direction="row"
                container
                className="OfficeNameFieldInputGrid"
              >
                <input
                  type="text"
                  className="form-control OfficeNameFieldInput"
                  placeholder="Enter your secondary adress"
                ></input>
              </Grid>
              <Grid
                sm={12}
                direction="row"
                container
                style={{ marginTop: "10px" }}
              >
                <Grid
                  sm={6}
                  className="OfficeNameFieldInputGrid"
                  style={{ marginTop: "20px" }}
                >
                  <p className="OfficeNameFieldLabel">City</p>
                </Grid>
                <Grid
                  sm={6}
                  className="OfficeNameFieldInputGrid"
                  style={{ marginTop: "20px" }}
                >
                  <p className="OfficeNameFieldLabel">ZIP</p>
                </Grid>
              </Grid>
              <Grid sm={12} direction="row" container>
                <Grid
                  sm={6}
                  className="OfficeNameFieldInputGrid"
                  style={{ marginTop: "5px" }}
                >
                  <div style={{ width: "85%" }}>
                    <input
                      type="text"
                      className="form-control OfficeNameFieldInput"
                      placeholder="Kentucky"
                    ></input>
                  </div>
                </Grid>
                <Grid
                  sm={6}
                  className="OfficeNameFieldInputGrid"
                  style={{ marginTop: "5px" }}
                >
                  <div>
                    <input
                      type="text"
                      className="form-control OfficeNameFieldInput"
                      placeholder="94293"
                    ></input>
                  </div>
                </Grid>
              </Grid>
              <Grid sm={12} direction="row" container>
                <Grid
                  sm={6}
                  className="OfficeNameFieldInputGrid"
                  style={{ marginTop: "20px" }}
                >
                  <p className="OfficeNameFieldLabel">State/Province</p>
                </Grid>
                <Grid
                  sm={6}
                  className="OfficeNameFieldInputGrid"
                  style={{ marginTop: "20px" }}
                >
                  <p className="OfficeNameFieldLabel">Country</p>
                </Grid>
              </Grid>
              <Grid sm={12} direction="row" container>
                <Grid
                  sm={6}
                  className="OfficeNameFieldInputGrid"
                  style={{ marginTop: "5px" }}
                >
                  <div style={{ width: "85%" }}>
                    <input
                      type="text"
                      className="form-control OfficeNameFieldInput"
                      placeholder="California"
                    ></input>
                  </div>
                </Grid>
                <Grid
                  sm={6}
                  className="OfficeNameFieldInputGrid"
                  style={{ marginTop: "5px" }}
                >
                  <div>
                    <input
                      type="text"
                      className="form-control OfficeNameFieldInput"
                      placeholder="United States"
                    ></input>
                  </div>
                </Grid>
              </Grid>
              <Grid
                sm={12}
                direction="row"
                container
                style={{ marginTop: "24px", marginBottom: "20px" }}
              >
                <Grid sm={6}>
                  <Button
                    variant="primary"
                    className="faddressSubmitButton"
                    onClick={props.handleClose}
                  >
                    Save changes
                  </Button>
                </Grid>
                <Grid sm={6}>
                  <Button
                    variant="primary"
                    className="faddressCancelButton"
                    onClick={props.handleClose}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
}
