import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    boxContentDataRow: {
        display: 'flex',
        marginTop: "0",
        padding: "10px 0",
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: "1px solid #DFDFE4",
        width: '100%',
        color: '#ffffff'
    }
}));


export { useStyles }