import React from "react";
export default function RequestForwardingIcon(props) {
  return (
    <svg
      className={`${props.className} iconSvg sss`}
      width="17"
      height="14"
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.65349 11.3477C2.14697 9.92879 2.86421 8.5842 3.86527 7.44012C5.34 5.75472 7.45273 4.47827 10.4486 4.05029L10.8779 3.98896V3.55531V1.20686L15.893 6.22198L10.8779 11.2371V8.79976V8.29976H10.3779C6.68816 8.29976 3.84141 9.26033 1.65349 11.3477Z"
        fill="#B8BFC5"
        stroke="#B8BFC5"
      />
    </svg>
  );
}
