import { combineReducers } from "redux";
import { uiReducers } from "./ui";
import { phoneServicesReducer } from "./phone-services";
import { accountReducer } from "./account";
import { authReducer } from "./auth";
import { formReducer } from "./form";
import { customerReducer } from "./customer";
import { roomRentalsReducers } from "./room-rentals";
import { adminReducer } from "./admin";
import { mailManagementReducer } from "./mail-management";
import { spaceRentalReducer } from "./space-rental";
import { transactionsReducer } from "./transactions";

const rootReducer = combineReducers({
  ui: uiReducers,
  phoneServices: phoneServicesReducer,
  account: accountReducer,
  customer: customerReducer,
  auth: authReducer,
  form: formReducer,
  roomdetails: roomRentalsReducers,
  admin: adminReducer,
  mail: mailManagementReducer,
  spaceRental: spaceRentalReducer,
  transaction: transactionsReducer,
});

export { rootReducer };
