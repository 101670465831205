import { all } from "redux-saga/effects";
//import * as PhoneServiceSagas from "./phone-service-sagas";
// import * as AccountSagas from "./account-sagas";
// import * as CustomerSagas from "./customer-sagas";
// import * as MailManagementSagas from "./mail-management-sagas";
// import * as SpaceRentalSagas from "./space-rental-sagas";
// import * as AdminSagas from "./admin-sagas";
//import * as TransactionSagas from "./transaction-sagas";

export default function* rootSaga() {
  yield all([
    // call(PhoneServiceSagas.watchDirectoryLoad),
   // call(PhoneServiceSagas.watchVirtualPhoneNumbersLoad),
    // call(CustomerSagas.watchForwardingAddressLoad),
    // call(CustomerSagas.watchCustomerLocationsLoad),
    // call(CustomerSagas.watchTeamMemberLoad),
    // call(CustomerSagas.watchCustomerPlanServicesLoad),
    // call(AccountSagas.watchForwardingPhoneNumbersLoad),
    // call(AccountSagas.watchUserDetailLoad),
    // call(AccountSagas.watchPaymentMethodsLoad),
    // call(MailManagementSagas.watchMailAction),
    // call(SpaceRentalSagas.watchUpcomingBookingLoad),
    // call(SpaceRentalSagas.watchAvailableRoomsLoad),
    // call(SpaceRentalSagas.watchRoomBooking),
    // call(SpaceRentalSagas.watchCheckRoomAvailablity),
    // call(AdminSagas.watchPlansLoad),
    // call(TransactionSagas.watchTransactionLoad),
    // call(AdminSagas.watchRoomsLoad),
    // call(AdminSagas.watchCustomersLoad),
  ]);
}
