import React from "react";
import { Box, Typography, ButtonBase } from "@material-ui/core";
import { ArrowRightAlt as ArrowRightAltIcon } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { setSelectedAvailableRoom } from "../../../../store";
import { useStyles } from "./RoomListing.style";
import Logo from '../../../../assets/images/logo.jpg';

export default function RoomListing(props) {
  const dispatch = useDispatch();
  const { data } = props;
  const classes = useStyles();

  const viewRoomDetails = (room) => {
    dispatch(setSelectedAvailableRoom(room));
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.discountDiv}>
        {/* <Typography>Frim starter package: -10% discount included</Typography> */}
      </Box>
      <Box className={classes.listContainer}>
        {data && data.length ? (data || []).map((room, index) => (
          <Box key={room.id || index} className={classes.listItem}>
            <Box className={classes.imgContainer}>
              <img
                className={classes.backgroundImg}
                src={room?.url ? room?.url : Logo}
                alt={room.name}
              />
            </Box>
            <Box className={classes.detailContainer}>
              <Box>
                <Typography variant="subtitle1">{room.name}</Typography>
              </Box>
              <Box className={classes.roomAddress}>
                <Typography variant="body1">{room.location}</Typography>
              </Box>
              <ButtonBase
                className={classes.viewDetailBtn}
                onClick={() => viewRoomDetails(room)}
              >
                <Typography variant="subtitle1" color="primary">
                  View details
                </Typography>
                <ArrowRightAltIcon color="primary" />
              </ButtonBase>
            </Box>
          </Box>
        )) : <Box className={classes.detailContainer}>
          <Typography variant="subtitle1">No rooms available</Typography>
        </Box>}
      </Box>
    </Box>
  );
}
