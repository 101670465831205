import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  subHeader: {
    backgroundColor: theme.palette.secondary.main,
    // height: "58px",
    width: "100%",
    display: "flex",
    color: theme.palette.common.white,
    alignItems: "center",
    justifyContent: "space-between",
    padding: "7px 20px",
  }
}));

export { useStyles };
