import React, { useEffect, useState } from "react";
import find from "lodash/find";
import {
  getAllCountriesSpheremail,
  getCountryDetailSpheremail,
} from "../../../apis";
import { setErrorMessage, getSelectedLocationId } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@material-ui/core";

export default function FormattedAddress(props) {
  const { companyInfo } = props;
  const { address1, address2, city, state, zip, country } = companyInfo;
  const locationId = useSelector((state) => getSelectedLocationId(state));
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [selectedState, setSelectedState] = useState("");

  const formatAddress = () => {
    return (
      <Typography variant="subtitle1">
        <span>{address1 ? `${address1},` : ""}</span>
        <span>{address2 ? ` ${address2},` : ""}</span>
        <span>{city ? ` ${city},` : ""}</span>
        <span>{selectedState ? ` ${selectedState},` : ""}</span>
        <span>{zip ? ` ${zip},` : ""}</span>
        <span>{selectedCountryCode ? ` ${selectedCountryCode}` : ""}</span>
      </Typography>
    );
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await getAllCountriesSpheremail(locationId);
        const selectedCountry = find(response, { id: +country });
        const { code } = selectedCountry;
        setSelectedCountryCode(code);
      } catch (error) {
        dispatch(setErrorMessage(error.toString()));
      }
    };
    if (country && locationId) fetchCountries();
  }, [dispatch, setSelectedCountryCode, setSelectedState, country, locationId]);

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const stateResponse = await getCountryDetailSpheremail(
          country,
          locationId
        );
        const { states } = stateResponse;
        const stateName = find(states, { id: +state });
        const { name } = stateName;
        setSelectedState(name);
      } catch (error) {
        dispatch(setErrorMessage(error.toString()));
      }
    };
    if (state && locationId) fetchStates();
  }, [dispatch, setSelectedState, state, country, locationId]);

  return <Typography> {formatAddress()}</Typography>;
}
