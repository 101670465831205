import React, { useEffect } from "react";
import { Box, Avatar, Typography, Link, IconButton } from "@material-ui/core";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import {
  MailOutline as MailOutlineIcon,
  Edit as EditIcon,
} from "@material-ui/icons";
import {
  openModal,
  setSelectedTeamMember,
  getSelectedLocationId,
  getCustomerId,
  setTeamMembers,
} from "../../../store";
import { useParams } from "react-router-dom";
import { ModalType } from "../../../core";
import { apiFetchTeamMembers } from "../../../apis";
import { useStyles } from "./TeamMembers.style";
import { userHasEditPermission } from "../../../constants/constants";

export default function TeamMembers(props) {
  const { rootClass } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  let params = useParams();
  const state = useSelector((state) => state);
  const locationId = state.auth.data.location_id;
  const customerId = getCustomerId(state);
  const teamMembers = state.account.teamMember.data;
  const userType = state.auth.data.user_type;
  const addEditMember = (teamMember) => {
    dispatch(setSelectedTeamMember(teamMember));
    dispatch(openModal(ModalType.AddUpdateTeamMembers));
  };
  useEffect(() => {
    const fetchTeamMembers = async () => {
      const response = await apiFetchTeamMembers(
        locationId,
        params.customerId || customerId,
        !!params.customerId
      );
      dispatch(setTeamMembers(response));
    };
    if (locationId && (customerId || params.customerId)) fetchTeamMembers();
  }, [dispatch, locationId, customerId, params.customerId]);

  return (
    <Box component="Box" className={rootClass ? rootClass : classes.box}>
      <Box className={classes.boxHeader}>
        <Typography variant="h6" className={classes.boxHeading}>
          Team members
        </Typography>
        {userHasEditPermission(userType) && <Link
          component="button"
          variant="inherit"
          className={classes.boxLink}
          onClick={() => addEditMember({})}
        >
          {`Add a member (${teamMembers.length}/${teamMembers.length})`}
        </Link>}
      </Box>
      <Box className={clsx(classes.boxRow, classes.noBorder)}>
        <Box className={classes.boxContent}>
          <Box className={classes.boxContentRight}>
            {teamMembers.map((item, index) => (
              <Box
                key={item.id}
                className={clsx(
                  index % 2
                    ? classes.evenBoxContentDataRow
                    : classes.boxContentDataRow
                )}
              >
                <Box className={classes.teamRow}>
                  <Avatar alt="Remy Sharp" src={item.imageUrl} />
                  <Box className={classes.teamRowContent}>
                    <Typography variant="subtitle1">
                      {item?.fullName}
                    </Typography>
                    <Typography variant="body2">
                      <MailOutlineIcon
                        color="inherit"
                        fontSize="small"
                        className={classes.mailIcon}
                      />
                      {item?.userEmail}
                    </Typography>
                    <Typography color="primary">{item?.userType}</Typography>
                  </Box>
                </Box>
                <IconButton
                  className={classes.iconButton}
                  onClick={() => addEditMember(item)}
                >
                  <EditIcon className={classes.boxContentDataRowEdit} />
                </IconButton>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
