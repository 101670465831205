import React from "react";

export default function Back() {
  return (
    <svg
      width="17"
      height="12"
      viewBox="0 0 17 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.505026 5.50503C0.231659 5.77839 0.231659 6.22161 0.505026 6.49497L4.9598 10.9497C5.23316 11.2231 5.67638 11.2231 5.94975 10.9497C6.22311 10.6764 6.22311 10.2332 5.94975 9.9598L1.98995 6L5.94975 2.0402C6.22311 1.76684 6.22311 1.32362 5.94975 1.05025C5.67638 0.776886 5.23316 0.776886 4.9598 1.05025L0.505026 5.50503ZM17 5.3L1 5.3L1 6.7L17 6.7L17 5.3Z"
        fill="#2CA4C0"
      />
    </svg>
  );
}
