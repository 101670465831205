import React, { useEffect, useState, useMemo } from "react";
import {
  Box,
  Typography,
  Divider,
  Button,
  InputLabel,
} from "@material-ui/core";
import {
  AccessTimeSharp as AccessTimeOutlinedIcon,
  PeopleOutline as PeopleOutlineIcon,
} from "@material-ui/icons";
// import { TimePicker } from "@material-ui/pickers";
import { useSelector, useDispatch } from "react-redux";
import { DualToneCheckboxIcon } from "../../../../icons";
import {
  apiFetchRoomDetail,
  apiFetchUpcomingBookings,
  apiRoomBooking,
} from "../../../../apis";
import { getFormatedDate } from "../../../../utils";
import { ModalType } from "../../../../core";
import {
  setAvailableRoomsFilter,
  setSelectedAvailableRoom,
  openModal,
  setErrorMessage,
  setUpcomingBookings,
  setSelectedUpcomingBooking,
  setBookingDetail,
  closeModal,
  getCustomerId,
} from "../../../../store";
import { useStyles } from "./SelectedRoom.style";
import Logo from "../../../../assets/images/logo.jpg";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";

export default function SelectedRoom() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [error, setError] = useState();
  const { selected, filter } = useSelector(
    (state) => state.spaceRental.availableRooms
  );
  const customerId = useSelector((state) => getCustomerId(state));
  const hasPaymentMethod = useSelector(
    (state) => !!state.account.paymentMethod.data.length
  );
  const [loading, setLoading] = useState(false);
  const locations = useSelector((state) => state.customer.locations.data);
  const roomTypes = useSelector((state) => state.ui.master.roomTypes);
  const handleBookNow = async () => {
    const { date, fromTime, endTime } = filter;
    if (date && fromTime && endTime) {
      if (fromTime > endTime)
        setError("End time must be greater then start time");
      else {
        if (hasPaymentMethod) {
          try {
            setLoading(true);
            const { date, fromTime, endTime } = filter;
            const bookingDetail = await apiRoomBooking({
              customerId,
              roomId: selected.id,
              startDate: getFormatedDate(
                date,
                fromTime && fromTime._d ? fromTime._d : null
              ),
              endDate: getFormatedDate(
                date,
                endTime && endTime._d ? endTime._d : null
              ),
            });
            dispatch(setBookingDetail(bookingDetail));
            dispatch(closeModal(ModalType.RoomAvailabilityResultModal));
            dispatch(openModal(ModalType.RoomBookingSuccessModal));
            const bookings = await apiFetchUpcomingBookings(customerId);
            dispatch(setUpcomingBookings(bookings));
            if (bookings.length)
              dispatch(setSelectedUpcomingBooking(bookings[0]));
            setLoading(false);
          } catch (error) {
            dispatch(setErrorMessage(error.toString()));
            setLoading(false);
          }
          // dispatch(roomBookingInit());
        } else {
          dispatch(openModal(ModalType.ManagePaymentInformation));
        }
      }
    } else setError("Select Time Slot");
  };

  const peoplePreference = useMemo(
    () =>
      selected?.roomTypeId
        ? roomTypes.find((x) => x.id === selected?.roomTypeId)?.name
        : "any",
    [selected?.roomTypeId, roomTypes]
  );

  const locationName = useMemo(
    () =>
      selected?.locationId
        ? locations.find((x) => x.id === selected?.locationId)?.name
        : "any",
    [selected?.locationId, locations]
  );

  const handleFilterChange = (key, value) => {
    setError("");
    dispatch(setAvailableRoomsFilter({ ...filter, [key]: value }));
  };

  useEffect(() => {
    async function fetchApi() {
      const room = await apiFetchRoomDetail(
        getFormatedDate(filter.date, filter.fromTime),
        getFormatedDate(filter.date, filter.endTime),
        selected.id
      );
      dispatch(setSelectedAvailableRoom(room));
    }
    if (filter.date && filter.fromTime && filter.endTime)
      if (filter.fromTime > filter.endTime)
        setError("End time must be greater then start time");
      else fetchApi();
  }, [filter.date, filter.fromTime, filter.endTime, dispatch, selected.id]);

  const handleImageClick = () => {
    dispatch(openModal(ModalType.ImageSliderModal));
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.imgContainer}>
        <img
          className={classes.backgroundImg}
          src={selected.url || Logo}
          alt={selected.name}
        />
      </Box>
      <Box className={classes.roomImageContainer}>
        {(selected.images || []).map((image) => (
          <img
            className={classes.roomImage}
            src={image.url}
            alt={`room_image_${image.id}`}
            onClick={handleImageClick}
          />
        ))}
      </Box>
      <Box className={classes.detailContainer}>
        <Typography variant="h2" className={classes.typography1}>
          {selected.name}
        </Typography>
        <Box className={classes.subDetail1}>
          <Typography variant="body1">{selected.location}</Typography>
          <Box className={classes.subDetail2}>
            <Box className={classes.subDetail3}>
              <PeopleOutlineIcon fontSize="medium" color="primary" />
              <Typography variant="body1">{locationName}</Typography>
            </Box>
            <Box className={classes.subDetail3}>
              <PeopleOutlineIcon fontSize="medium" color="primary" />
              <Typography variant="body1">{peoplePreference} people</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box className={classes.typography1}>
        <Typography variant="body1">
          Perfect for meeting or as a quiet professional environment where you
          can make telephone calls.
        </Typography>
      </Box>
      <Divider />
      <Box className={classes.typography1}>
        <Typography variant="h3" className={classes.typography2}>
          Included:
        </Typography>
        <Box className={classes.serviceContainer}>
          {(selected.amenities || []).map((amenity) => (
            <Box className={classes.serviceRow}>
              <DualToneCheckboxIcon />
              <Typography variant="body1">{amenity.name}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Box>
        <Box className={classes.labelContainer}>
          <InputLabel className={classes.labelItem}>From</InputLabel>
          <InputLabel className={classes.labelItem}>Till</InputLabel>
        </Box>
        <Box className={classes.timeContainer}>
          <Box className={classes.dateFieldLogo}>
            <AccessTimeOutlinedIcon />
          </Box>
          <TimePicker
            showSecond={false}
            defaultValue={filter?.fromTime || null}
            className={classes.InputRoot}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            onChange={(value) =>
              handleFilterChange("fromTime", value ? value : null)
            }
            use12Hours
            inputReadOnly
            minuteStep={15}
          />
          <TimePicker
            showSecond={false}
            defaultValue={filter?.endTime || null}
            className={classes.InputRoot}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            onChange={(value) =>
              handleFilterChange("endTime", value ? value : null)
            }
            use12Hours
            minuteStep={15}
          />
          {/* <TimePicker
            fullWidth
            value={filter?.fromTime || null}
            onChange={(value) => handleFilterChange("fromTime", value)}
            className={classes.InputRoot}
            autoOk
            variant="inline"
            inputVariant="outlined"
            minutesStep={15}
          /> */}
          {/* <TimePicker
            fullWidth
            value={filter?.endTime || null}
            onChange={(value) => handleFilterChange("endTime", value)}
            className={classes.InputRoot}
            autoOk
            variant="inline"
            inputVariant="outlined"
            minutesStep={15}
          /> */}
        </Box>
        <Box className={classes.errorContainer}>
          <Typography variant="body2" color="error">
            {error}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.btnContainer}>
        <Box className={classes.btn1}>
          <Typography variant="body1">
            Price: <b>${selected.bookingPrice}</b>
          </Typography>
        </Box>
        <Button
          className={classes.btn2}
          onClick={handleBookNow}
          disabled={loading}
        >
          BOOK NOW
        </Button>
      </Box>
    </Box>
  );
}
