import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  ModalType,
  MailAction,
  ServiceMetaKey,
} from "../../../core";
import {
  getSelectedLocationId,
  closeModal,
  getCustomerId,
  openModal,
  setCustomerMailCounts,
  setErrorMessage,
  setMailAction,
  setMailItems,
  setMailOptions,
  setMMTotalPages,
  setTotalCount,
  setMailLoading,
  loadMailItemsFailed,
} from "../../../store";
import CloseModalIcon from "../../../assets/icons/closeModalIcon";
import "./request-scan-modal.css";
import { apiMailAction } from "../../../apis";

export default function RequestScanModal() {
  const state = useSelector((state) => state);
  const activeTab = state.mail.activeTab;
  const limit = state.mail.limit;
  const ids = state.mail.selectedRowIds;
  const modalSelected = state.ui.modal;
  const selectedRow = state.mail.selectedRow;
  const isBulkOperation = state.mail.isBulkOperation;
  const customerId = useSelector((state) => getCustomerId(state));
  const locationId = useSelector((state) => getSelectedLocationId(state));
  const mailAccountId = state.auth.data.mail_account_id;
  const isCancelRequestModal =
    modalSelected[ModalType.CancelMailRequestModal] || false;
  const isForwardingModal =
    modalSelected[ModalType.MailRequestForwardModal] || false;
  const options = useSelector((state) => state.mail.options);
  const modal = useSelector(
    (state) => state.ui.modal[ModalType.MailRequestScanModal] || false
  );
  const planService = useSelector(
    (state) =>
      state.admin.customer.planServices.find(
        (plan) =>
          plan.serviceKey === ServiceMetaKey.Scans
      ) || {}
  );
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeModal(ModalType.MailRequestScanModal));
  };

  const handleConfirm = async () => {
    const { servicePrice, serviceQty, customQty, usedQty, id } =
      planService || {};
    let totalQuantities = customQty ? customQty : serviceQty;
    let forwardedPrice = (usedQty || 0) < totalQuantities ? 0 : servicePrice
    dispatch(
      setMailOptions({
        forward_price: forwardedPrice,
        customerPlanServiceId: id,
      })
    );
    dispatch(setMailAction(MailAction.Scan));
    try {
      const data = {
        ids: isBulkOperation ? ids : [selectedRow.id],
        mailAction: MailAction.Scan,
        enabled: !isCancelRequestModal,
        customerPlanServiceId: id || 0,
        price: forwardedPrice || 0,
        params: { page: 1, status: activeTab, limit: limit - ids.length },
        ...(isForwardingModal && {
          forward_address_id: options.forward_address_id,
          ...(options.delivery_days > 0 && {
            delivery_days: options.delivery_days,
          }),
        }),
      };
      const response = await apiMailAction(
        customerId,
        mailAccountId,
        locationId,
        data
      );
      const { mail_items, total_pages, total } = response;
      dispatch(setMailItems(mail_items));
      dispatch(setMMTotalPages(total_pages));
      dispatch(setTotalCount(total));
      dispatch(setCustomerMailCounts(total));
      if (!isCancelRequestModal)
        dispatch(openModal(ModalType.MailSuccessActionModal));
    } catch (error) {
      dispatch(setErrorMessage(error.toString()));
      dispatch(loadMailItemsFailed());
    }
    handleClose();
  };

  const getScanDetail = () => {
    const { servicePrice, serviceQty, customQty, usedQty } = planService || {};
    let totalQuantities = customQty ? customQty : serviceQty;
    if ((usedQty || 0) < totalQuantities)
      return (
        <>
          <b>{totalQuantities - (usedQty || 0)} scans</b> left in your package
        </>
      );
    else return `$${servicePrice} charged per scan`;
  };

  return (
    <>
      <Dialog
        open={modal}
        onClose={handleClose}
        aria-labelledby="junk-dialog-title"
        aria-describedby="junk-dialog-description"
      >
        <div className="scanDialog">
          <DialogTitle
            id="junk-dialog-title"
            className="junkModalHeader requestScanHeader"
          >
            <Grid container sm={12} direction="row">
              <Grid sm={6}>
                <p className="reqForwardModalTitle"> Scanning request</p>
              </Grid>
              <Grid sm={6}>
                <CloseModalIcon
                  handleCloseIcon={handleClose}
                  className={"reqForwardModalCloseIcon"}
                />
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="junk-dialog-description">
              <div className="reqForwardModalContainer">
                <Grid sm={12} direction="row">
                  <Grid sm={12}>
                    <p className="junkModalText">{getScanDetail()}</p>
                    {/* <p className="junkModalText">
                      You will be billed according to your plan terms.
                    </p> */}
                  </Grid>
                </Grid>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="junkModalFooter">
            <Grid direction="row" container sm={12}>
              <Grid sm={6} className="reqForwardModalCautionCol">
                <Button
                  variant="primary"
                  className="reqScanModalButton"
                  onClick={handleConfirm}
                >
                  Request a scan
                </Button>
              </Grid>
              <Grid sm={6} className="reqForwardModalCautionCol" spacing={3}>
                <Button
                  className="reqScanCancelModalButton"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
}
